import Component from 'flarum/Component';
import slidable from 'flarum/utils/slidable';
import CouponExpiry from './storePages/CouponExpiry';
import Button from 'flarum/components/Button';
import CouponModal from './coupon/CouponModal';

export default class CouponCardMini extends Component {
  //used to display coupon card on CategoryListData(on home page),DealsListData(on home page),OfferDiscussionList(on home page)
      init() {
            this.noImageFound = app.forum.data.attributes.baseUrl + '/assets/images/no_image_found.png';
            this.expiredStamp = app.forum.data.attributes.baseUrl + '/assets/images/deal_expired.png';
            this.title = m.prop(this.props.DiscussionData.title || '');
            this.maxPrice = m.prop(this.props.DiscussionData.maxPrice || '');
            this.currentPrice = m.prop(this.props.DiscussionData.currentPrice || '');
            this.discount = m.prop(this.props.DiscussionData.discount || '');
            //this.currency = m.prop(this.props.DiscussionData.currency || '');
            this.url = m.prop(this.props.DiscussionData.url || '');
            this.productName = m.prop(this.props.DiscussionData.productName || '');
            this.isFeatured = m.prop(this.props.DiscussionData.isFeatured || '');
            this.isHotDeal = m.prop(this.props.DiscussionData.isHotDeal || '');
            this.isExpired = m.prop(this.props.DiscussionData.isExpired || '');
            this.startTime = m.prop(this.props.DiscussionData.startTime || '');
            //this.imageUrl_sm = m.prop(this.props.DiscussionData.imageUrl_sm || '');
            this.startUser = m.prop(this.props.DiscussionData.startUser || '');
            this.validFrom = m.prop(this.props.DiscussionData.validFrom || '');
            this.validTo = m.prop(this.props.DiscussionData.validTo || '');
            this.validFor = m.prop(this.props.DiscussionData.validFor || '');
            this.badgeText = m.prop(this.props.DiscussionData.badgeText || '');
            this.discussionID = m.prop(this.props.DiscussionData.id || '');
            this.tags = m.prop(this.props.DiscussionData.tags || '');
            this.customBadge = null;
            if (this.badgeText()) {
                  this.customBadge = this.badgeText();
            } else if (this.discount()) {
                  this.customBadge = this.discount() + '% Off';
            }
            this.validTo_Expired = false;
            this.pastExpired = false;
            this.discussionRoute = app.forum.data.attributes.baseUrl + '/d/' + this.discussionID();
           this.productInfo = m.prop(this.props.DiscussionData.productInfo || '');
           this.couponcode = m.prop(this.props.DiscussionData.couponcode || '');
           this.imagepath= m.prop(this.props.DiscussionData.imagepath || '');
           this.merchantName= m.prop(this.props.DiscussionData.merchantName || '');
                
      }
      setCounDown(setDate, setID, startText, endText, textColor) {
            $('#' + setID).show();
            $('#' + setID).css('color', textColor);
            $('#' + setID).countdown(setDate)
                    .on('update.countdown', function (event) {
                          var format = '%H:%M:%S';
                          if (event.offset.totalDays > 0) {
                                format = '%-d day%!d ' + format;
                          }
                          if (event.offset.weeks > 0) {
                                format = '%-w week%!w ' + format;
                          }
                          $(this).html(startText + event.strftime(format));
                    })
                    .on('finish.countdown', function (event) {
                          $(this).html(endText)
                                  .parent().addClass('disabled');
                          $(this).css('color', textColor);
                    });


      }
      checkFutureTime(myTime) {
            var checkTime = new Date(myTime).getTime();
            var now = new Date().getTime();
            if (checkTime > now) {
                  return true;
            } else {
                  return false;
            }
      }
      convertUTCDateToLocalDate(date) {
            var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var offset = date.getTimezoneOffset() / 60;
            var hours = date.getHours();
            newDate.setHours(hours - offset);
            return newDate;
      }

      view() {
            var endDateForStamp = this.validTo();
            if (endDateForStamp != '0000-00-00 00:00:00') {
                  if (CouponExpiry(this.validTo()) == 'past')
                  {
                        this.validTo_Expired = true;
                  }
            }

            const discussion = this.props.DiscussionData;
            const DiscussionData = {
              title: discussion.title,
              maxPrice: discussion.maxPrice,
              currentPrice: discussion.currentPrice,
              discount: discussion.discount,
              currency: discussion.currency,
              url: discussion.url,
              productName: discussion.productName,
              isFeatured: discussion.isFeatured,
              isHotDeal: discussion.isHotDeal,
              isExpired: discussion.isExpired,
              startTime: discussion.startTime,
              imageUrl_sm: discussion.imageUrl_sm,
              validFrom: discussion.validFrom,
              validTo: discussion.validTo,
              validFor: discussion.validFor,
              badgeText: discussion.badgeText,
              startUser: discussion.startUser,
              id: discussion.id,
              couponcode: discussion.couponcode,
              imagepath: discussion.imagepath,
            };
            
            var tagdata = [];
               var storeName = '';
               var imagePath = '';
             // this.tags().forEach(function(element) {
             //      if(element.data.attributes.parentID === 4){
             //            storeName = element.data.attributes.name;
             //           imagePath = element.data.attributes.imagePath;                        
             //      }
             //  });
            var tags = tagdata;
            const link = true;

            return (
              m("div", {class: "cc_couponCard", config: this.configArea.bind(this)},
                  <div class="cc_couponStoreImg">
                       { this.imagepath() ? 
                    <div class="cc_cpnImgHolder">
                      { tags ? <img className="sm_img lazy" src={this.imagepath()} alt="expired Stamp"/>:'' }
                    </div>
                    : <div class="cupnimg_ntfound"> 
                    {this.merchantName()} </div> }
                  </div>,
                  <div class="cc_couponStoreMain">
                    {this.customBadge}
                  </div>,
                  <div class="cc_couponTitleText">
                    {this.title()}
                  </div>,
                  <div class="cc_couponButton">
                    {
                      Button.component(
                      {
                        id: 'btn'+this.discussionID(),
                        className: 'Button Button--primary',
                        children: 'Get Coupon',
                        onclick: (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          app.modal.show(
                            new CouponModal({ discussion, DiscussionData })
                          )
                        }
                      })
                    }
                  </div>
              )
            );
}
configArea(element, isInitialized)
{
      $('.hotDealBar').show();
      $('.popularBar').show();
      var FromStartText = 'Starts in: ';
      var FromEndText = 'This deal has started!';
      var ToStartText = 'Ends in: ';
      var ToEndText = 'This deal has expired!';
      var startsIn = this.validFrom();
      var endsIn = this.validTo();
      if (startsIn != '0000-00-00 00:00:00' && this.checkFutureTime(startsIn) && startsIn)
      {
            this.setCounDown(startsIn, this.discussionID(), FromStartText, FromEndText, '#00B504');
      } else if (endsIn != '0000-00-00 00:00:00' && endsIn) {
            this.setCounDown(endsIn, this.discussionID(), ToStartText, ToEndText, '#C20000');
      }
}
config(isInitialized)
{
      if (isInitialized)
            return;

      // If we're on a touch device, set up the discussion row to be slidable.
      // This allows the user to drag the row to either side of the screen to
      // reveal controls.
      if ('ontouchstart' in window)
      {
            const slidableInstance = slidable(this.$().addClass('Slidable'));

            this.$('.DiscussionListItem-controls')
                    .on('hidden.bs.dropdown', () => slidableInstance.reset());
      }
}
}
