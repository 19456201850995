import Component from "flarum/Component";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import Button from "flarum/components/Button";
import AllDealCardMini from "../AllDealCardMini";
import AdCardMini from "../AdCardMini";
import ItemList from "flarum/utils/ItemList";
import DealPlaceHolder from "../DealPlaceHolder";
import ScrollListener from "flarum/utils/ScrollListener";
import Placeholder from "flarum/components/Placeholder";
import EndpointsApiUrls from "../EndpointsApiUrls";
import listItems from "flarum/helpers/listItems";
import Select from "flarum/components/Select";

export default class AllDealsList extends Component {
  //Being used for allDeals to display deals
  init() {
    this.loading = true;
    this.deallist = [];
    this.darkMode = m.prop("");
    this.dealData = [];
    this.hasData = true;
    this.refresh();
    this.moreResults = false;
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    this.params1 = m.route.param();
    this.params1.deals = "true";
    this.near = m.route.param("near") || 0;
  }
  view() {
    const params = this.props.params;
    const text = "No Data Found";
    this.CategoryName = m.route.param("categories")
      ? m.route.param("categories").split(">")
      : "";
    var CategoryName = this.CategoryName[this.CategoryName.length - 1];
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    return (
      <div config={this.config.bind(this)}>
        <div class="col-md-4 headerdeals" style="display: inline-block;">
          <h1 style="margin: 0px;display: inline-block;font-weight: normal;">
            {params.categories == "" ||
            params.categories == "category" ||
            params.categories == undefined
              ? params.tag
                ? params.tag
                : "Deals"
              : CategoryName + " Deals"}
          </h1>
        </div>
        <div class="cc_DealStoreHeader row">
          <div
            id="dealstores"
            class="col-md-4 sortdeals"
            style="display: inline-block;"
          >
            <span>Sort :</span>
            <div class="cc_productSort">
              {listItems(this.viewItems().toArray())}
            </div>
          </div>
          <div class="col-md-4 searchdeals" style="display: inline-block;">
            <input
              class="FormControl"
              type="text"
              id="dealseach"
              Placeholder="Find in deals..."
              onkeyup={this.configsearch.bind(this)}
            />
          </div>
        </div>
        <hr />

        {this.deallist.length > 0 || this.loading ? (
          <div className="DiscussionList">
            <ul className="DiscussionList-discussions cc_dealList">
              {/* <li >
                {AdCardMini.component()}
              </li> */}
              {this.loading
                ? loading
                : this.deallist.map((discussion) => {
                    const DiscussionData = {
                      title: discussion._source.title,
                      maxPrice: discussion._source.maxprice,
                      currentPrice: discussion._source.currentprice,
                      discount: discussion._source.discount,
                      ////currency : discussion.data.attributes.currency,
                      url: discussion._source.url,
                      productName: discussion._source.productName,
                      isFeatured: discussion._source.is_featured,
                      isOffer: discussion._source.is_offer,
                      isHotDeal: discussion._source.is_hotdeal,
                      isExpired: discussion._source.is_expired,
                      imageUrl_sm: discussion._source.imageurl_sm,
                      noImageFound: discussion._source.noImageUrl_sm,
                      validFrom: discussion._source.validfrom,
                      validTo: discussion._source.validto,
                      validFor: discussion._source.validfor,
                      badgeText: discussion._source.badgetext,
                      tag_slug: discussion._source.tag_slug,
                      avatar_path: discussion._source.avatar_path,
                      username: discussion._source.username,
                      startUser: "",
                      id: discussion._id,
                      tags: "",
                      secondarytag: discussion._source.secondarytag,
                      start_user_id: discussion._source.user_id,
                      start_time: discussion._source.start_time,
                      refreshdate: discussion._source.refreshdate,
                      created_at: discussion._source.created_at,
                    };
                    var cstatus = this.darkMode();
                    return cstatus == true ? (
                      (Date.parse(discussion._source.validTo) || 0) != "0" ? (
                        CouponExpiry(discussion._source.validTo) !=
                        "Expired" ? (
                          <li key={discussion._id} data-id={discussion._id}>
                            {AllDealCardMini.component({ DiscussionData })}
                          </li>
                        ) : (
                          ""
                        )
                      ) : (
                        <li key={discussion._id} data-id={discussion._id}>
                          {AllDealCardMini.component({ DiscussionData })}
                        </li>
                      )
                    ) : cstatus == false ? (
                      <li key={discussion._id} data-id={discussion._id}>
                        {AllDealCardMini.component({ DiscussionData })}
                        </li>
                        
                    ) : (
                      ""
                    );
                  })}
            
              <li>
                {this.deallist.length > 0
                  ? this.deallist.length % 20 === 0
                    ? DealPlaceHolder.component({})
                    : ""
                  : ""}
              </li>
            </ul>
            <div style="clear:both"></div>
          </div>
        ) : (
          <div className="DiscussionList">
            {Placeholder.component({ text })}
          </div>
        )}
      </div>
    );
  }
  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }

  onScroll(pos) {
    if (this.deallist.length % 20 === 0 && !this.loading) {
      if (pos >= $(document).height() - $(window).height() - 450) {
        this.loadMore();
        /*const url = app.route.deals(
          "category",
          (this.near = this.deallist.length)
        );
        console.log(url);
        m.route(url, true);
        window.history.replaceState(null, document.title, url);*/
      }
    }
  }
  config(isInitialized, context) {
    if (isInitialized) return;
    setTimeout(() => this.scrollListener.start());
    context.onunload = () => {
      this.scrollListener.stop();
    };
  }

  configsearch(isInitialized, context) {
    var searchtxt = $("#dealseach").val();
    var perpage = 0;

    //const params = this.requestParams(perpage?perpage+1:0);
    const params = this.props.params;
    if (params.query == "category") {
      delete params.categories;
    }
    if(params.categories == undefined){
      params.categories == "category";
    }
    //params.categories = searchtxt;
    params.query = searchtxt;
    params.size = 0;

    // const rrrrr = EndpointsApiUrls.component(ApiparamData);

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    const Api_url = ApiEndpoint + "/dealSearch";

    return app
      .request({ method: "GET", url: Api_url, data: params })
      .then(this.parseResultsearch.bind(this));
  }
  loadMore() {
    this.loading = true;
    this.loadResults(this.deallist.length);
  }

  requestParams(offset) {
    if (this.props.params.tag == this.props.params.categories) {
      delete this.props.params.categories;
    }
    const params = this.props.params;
    if(params.categories == undefined && !params.tag)
    {
      params.categories = "category";
    }
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }
  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    if (params.query == "category" || params.categories == "undefined") {
      delete params.categories;
    }

    // const Api_url = 'https://search.thedealapp.in/dealSearch';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    const Api_url = ApiEndpoint + "/dealSearch";
    //const Api_url = 'https://search.thedealapp.in/stageitDeal';
    return app
      .request({ method: "GET", url: Api_url, data: params })
      .then(this.parseResults.bind(this));
  }

  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.productList = [];
    }

    return this.loadResults().then(
      (results) => {},
      () => {
        this.loading = false;
      }
    );
  }
  parseResults(results) {
    if (results.result.hits) {
      [].push.apply(this.deallist, results.result.hits.hits);
      this.loading = false;
      this.moreResults = true;

      m.redraw();
    } else {
      this.loading = false;
      this.hasData = false;
      m.redraw();
    }
  }

  parseResultsearch(results) {
    this.deallist = [];

    var isemptydata = jQuery.isEmptyObject(results.result);

    if (isemptydata) {
      this.loading = false;
      m.redraw();
    } else {
      // when json data is not empty

      if (results.result.hits) {
        [].push.apply(this.deallist, results.result.hits.hits);
        this.loading = false;
        this.moreResults = true;
        m.redraw();
      } else {
        this.loading = false;
        m.redraw();
      }
    }
  }

  viewItems() {
    const items = new ItemList();
    const sortMap = {
      trending: "Trending",
      relevance: "Latest",
      hotness: "Hotness",
    };
    const sortOptions = {
      trending: "Trending",
      relevance: "Latest",
      hotness: "Hotness",
    };
    items.add(
      "sort",
      Select.component({
        options: sortOptions,
        value: m.route.param().sort || Object.keys(sortMap)[0],
        onchange: this.changeSort.bind(this),
      })
    );

    return items;
  }
  changeSort(sort) {
    if (sort !== "") {
      var sort = sort;
      m.route(app.route(this.props.routeName, this.getParams("sort", sort)));
    }
  }

  getParams(paramName, value) {
    let newParams = m.route.param();
    if(newParams.categories == undefined)
    {
        newParams.categories = "category";
        // delete newParams.categories;
    }else{
      if(newParams.tag && newParams.categories == ""){
          newParams.categories = newParams.tag;
        }
    }
    let paramsURL = Object.assign({},  newParams);
    paramsURL[paramName] = value;
    return paramsURL;
  }
}
