import LinkButton from "flarum/components/LinkButton";
import EndpointsApiUrls from "./EndpointsApiUrls";

export default class ProductSearchSource {
  //used in search
  constructor() {
    this.results = {};
  }
  search(query) {
    query = query.toLowerCase();
    this.results[query] = [];

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    return $.ajax({
      type: "GET",
      url: ApiEndpoint + "/suggest",
      data: "query=" + encodeURIComponent(query),
    }).then((results) => (this.results[query] = results.suggestions));
  }

  view(query) {
    query = query.toLowerCase();
    query = query.replace(/(^\s*)|(\s*$)/gi, "");
    query = query.replace(/[ ]{2,}/gi, " ");
    query = query.replace(/\n /, "\n");
    const results = this.results[query] || [];
    return [
      <li className="Dropdown-header">Deals and Products</li>,
      <li data-index="99999999">
        {LinkButton.component({
          icon: "search",
          children: "Search all deals and products for " + query,
          href: "/search?query=" + encodeURIComponent(query),
        })}
      </li>,
      results.map((products) => {
        return (
          <li className="UserSearchResult">
            <a
              href={
                "/search?q=" +
                products.title +
                "&query=" +
                encodeURIComponent(products.title) +
                (products.category
                  ? "&categories=" + encodeURIComponent(products.category)
                  : "")
              }
              config={m.route}
            >
              <div>
                {products.title}{" "}
                <b>
                  {products.category != undefined
                    ? " " +
                      products.category.substring(
                        products.category.lastIndexOf(">") + 1
                      )
                    : ""}
                </b>
              </div>
            </a>
          </li>
        );
      }),
    ];
  }
}
