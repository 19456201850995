import Modal from 'flarum/components/Modal';
import ForgotPasswordModal from 'flarum/components/ForgotPasswordModal';
import SignUpModal from 'flarum/components/SignUpModal';
import listItems from 'flarum/helpers/listItems';
import ItemList from 'flarum/utils/ItemList';
import Select from 'flarum/components/Select';

export default class AlertModal extends Modal {
  //Used for Alert Modal
  init() {
        super.init();
        this.pricedropmethod = "";
        this.pricedropinputRs = "";
        this.pricedropinputPer = "";
        this.multiselect=this.props.multiselect;
        $('.item-alertsort select.Select-input.FormControl').val("select");
    }

  className() {
    return 'Modal--Big brandModal';
  }

  title() {
    return app.translator.trans("Track Product");
  }

hide(){
   $('.item-alertsort select.Select-input.FormControl').val("");
   $("#dataerror").html("");
    app.modal.close();
}
  content() {
   
    let paramdata = m.route.param("categories") ? m.route.param("categories").split('>') : '';
    var CategoryName = paramdata[paramdata.length-1];
    if(!this.multiselect){
      this.AlertData = this.props.data;
      this.tid = this.AlertData["0"].attributes.tid;
      this.currentprice = this.AlertData["0"].attributes.sellingPrice;
      this.mrp = this.AlertData["0"].attributes.maxPrice;
    }else{
      this.AlertData = this.props.data;
      const pricelist = [];
      if(this.AlertData != undefined){
        var AlertData = [];
        $.each(this.AlertData, function(key,element) {
          AlertData = JSON.parse(element.attributes);
          pricelist.push({currentprice:AlertData.currentPrice});
        })
      }
      var maximum = Math.max.apply(Math,pricelist.map(function(i,o){return i.currentprice;}));
      var index = pricelist.findIndex(x => x.currentprice === maximum);
      var highestprice = pricelist.length>0?pricelist[index].currentprice:0;
    }
    return [
      m('div', {className: 'Modal-body AlertModalBox'}, [
      m('div',{id: 'dataerror',style:{color:"red"}},""),
      this.multiselect == "true" ?
      m('div',{id: 'datamsg',},"Please Enter the Price Less than "+ highestprice ) : "",
       m('div', {className: 'dataalert'}, [
        m("table", {className: 'alerttable'}, [
          m("div",
              m("tr", 
                m("td", {className: 'topic'},"WishList Name : "),
                m("td", m('input[type=text][name=topicname][id=topicname]',{value:CategoryName ? CategoryName : "General"}))
              ),
              this.multiselect != "true" ?
              m("tr", 
                m("td", {className: 'mrp'},"MRP "),
                m("td",  this.mrp + "/-")
              ): "",
              this.multiselect != "true" ?
                m("tr", 
                m("td", {className: 'currentprice'},"Current Price "),
                m("td",  this.currentprice + "/-")
              ): "",
            ),
            m("div[className=trackProduct]",
            m("tr", 
              m("td", {className: 'pricedropbyrs'},"Price Drop. "),
              m("td", <div class="cc_productSort cc_pricedrop">
                        {listItems(this.viewItems().toArray())}
                      </div>
                ),
              m("td",[m("input[type=text][style=display: none;]",({value: "" ,id:'userinputdataRs', className: 'userinputdataRs',placeholder:"Please enter the Amount in Rs."}))]),
              m("td",[m("input[type=text][style=display: none;]",({value: "" ,id:'userinputdataPer', className: 'userinputdataPer', maxlength:'2',placeholder:"Please enter the discount less than 100%"}))])
            ),
            m("tr", 
              m("td", {className: 'endtime'},"End Time "),
              m("td",  m('label',{id: "enddatetime"},m("input[type=radio]",({className:"dateid",name:"date",value:"noendtime"})),
                m('span', 'No End Time')),
                m('br'),
                m('label',{id: "enddatetime"},m("input[type=radio]",({className:"dateid",name:"date",value:"endafter"})),m('Span', 'End After'),
                  m("input[type=text][id=endaftertext]",({value: "" , className: 'Endafterdate', maxlength:'10',placeholder:"In Days"}))),
                m('br'),
                m('label',{id: "enddatetime"},m("input[type=radio]",({className:"dateid",name:"date",value:"endby"})),m('Span', 'End By Date'),                     
                m('input',{className:"dealInput", id:"validFromdatetimepicker", value:"" }),
              ),
              )
            )
            
          )
        ])
       ])
      ]),
      
       m("a",({className:'button button-sm btn-danger cc_buyNowBtn_alert',onclick:this.alertChanges.bind(this)}),"SET ALERT") 
        
    ];
  }

  /**
   * Open the forgot password modal, prefilling it with an email if the user has
   * entered one.
   *
   * @public
   */
  forgotPassword() {
    app.modal.show(new ForgotPasswordModal(props));
  }

  /**
   * Open the sign up modal, prefilling it with an email/username/password if
   * the user has entered one.
   *
   * @public
   */
  signUp() {
    
    app.modal.show(new SignUpModal(props));
  }
  viewItems(){
    $('.item-alertsort select.Select-input.FormControl').val("select");
    const items = new ItemList();
    const sortMap ={select: "Select", PriceDropsByRs: "Price Drops By Rs.", MinimumPriceDropsByPercentage: "Minimum Price Drops By Percentage", TotalDiscountonMRP: "Total Discount on MRP"};
    const sortOptions ={select: "Select", PriceDropsByRs: "Price Drops By Rs.", MinimumPriceDropsByPercentage: "Minimum Price Drops By Percentage", TotalDiscountonMRP: "Total Discount on MRP"};
    items.add('alertsort',
      Select.component({
        options: sortOptions,
        value:m.route.param().alertsort || Object.keys(sortMap)[0],
        id:"selectoption",
        onchange: this.changevalue.bind(this)
      })
    );

    return items;
  }
  changevalue(data){
    this.pricedropmethod = data;
    var alertsort = data;
    const params = m.route.param();
    params.alertsort = data;
    
    if(this.pricedropmethod == "PriceDropsByRs"){
      $("#userinputdataRs").show();
      $("#userinputdataPer").hide();
    }else if(this.pricedropmethod == "MinimumPriceDropsByPercentage"){
      $("#userinputdataRs").hide();
      $("#userinputdataPer").show();
    }else if(this.pricedropmethod == "TotalDiscountonMRP"){
      $("#userinputdataRs").hide();
      $("#userinputdataPer").show();
    }else{
      $("#userinputdataRs").hide();
      $("#userinputdataPer").hide();
    }

    $(".dateid").on( "click", function(event) {
      if(event.target.closest( "input" ).value == "noendtime"){
        $("#endaftertext").attr('disabled', true);
        $("#validFromdatetimepicker").attr('disabled', true);
      }else if(event.target.closest( "input" ).value == "endafter"){
        $("#endaftertext").attr('disabled', false);
        $("#validFromdatetimepicker").attr('disabled', true);
      }else{
        $("#endaftertext").attr('disabled', true);
        $("#validFromdatetimepicker").attr('disabled', false);
      }
    });
  }

  alertChanges(AlertData){
    $("#dataerror").html("");
    var curPrice = this.currentprice;
    var userId = app.session.user.data.id;
    var WishListName = $("#topicname").val();
    var Endafterdate = $(".Endafterdate").val();
    var dealInput = $(".dealInput").val();
    var selectedOption = $("input:radio[name=date]:checked").val();
    //var decimalnumbers = /^[0-9]+(\.)?[0-9]+$/;
    var numbers = /^(\d{1,})+$/;
    var valid = true;
    var Endtime = "";
    this.pricedropinputRs = $(".userinputdataRs").val();
    this.pricedropinputPer = $(".userinputdataPer").val();
    var checked_date = document.querySelector('#enddatetime input[name="date"]:checked');

      if(!this.multiselect){
        if($('.item-alertsort select.Select-input.FormControl').val() == "select"){
          $("#dataerror").html("Please Enter the Price Drop of Selected Type");
           valid = false;
        }

        if(valid == true){
          if(this.pricedropinputRs == "" && this.pricedropinputPer == ""){
            $("#dataerror").html("Please Enter the Price Drop of Selected Type");
             valid = false;
          }else if(this.pricedropinputRs != "" && this.pricedropinputPer == ""){
            if(this.pricedropinputRs.match(numbers)) {
              if(this.pricedropinputRs < curPrice && this.pricedropinputRs > 0 ){
                 valid = true;  
              $("#dataerror").html("");
              }else{
                $("#dataerror").html("Please input price Less than Current Price & greater than 0");
               valid = false;
              }
            }else{
               $("#dataerror").html("Please input numeric characters without decimal(.)");
               valid = false;
            }
          }else if(this.pricedropinputRs == "" && this.pricedropinputPer != ""){
            if(this.pricedropinputPer.match(numbers)){
              if(this.pricedropinputPer < 100 && this.pricedropinputPer > 0){
                valid = true;  
                $("#dataerror").html("");
              }else{
                $("#dataerror").html("Please Enter discount less than 100% & discount greater than 0% ");
                valid = false;
              }
            }else{
               $("#dataerror").html("Please input numeric characters without decimal(.)");
               valid = false;
            }
           }
         }  

          if(valid == true){
            if(selectedOption =="noendtime"){
              var Endtime = Date.parse(new Date("1/1/2099 00:00:00"));
            }else if(selectedOption =="endafter"){
              if(Endafterdate != "" || Endafterdate.match(numbers) || Endafterdate != 0){
                var days = $(".Endafterdate").val();
                var Endtime = (new Date().getTime()+(days*24*60*60*1000));
              }else{
                 var Endtime = 0;
                $("#dataerror").html("Please Enter the End After Days only in Numbers");
                valid = false;
              }
            }else if(selectedOption == "endby"){
              if(dealInput != ""){
                var Endtime = Date.parse(new Date(dealInput));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End By Date");
                valid = false;
              }
            }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End Time");
                valid = false;
            }

          if(checked_date === null){  //Test if something was checked
          $("#dataerror").html("Please Select the End Time");
            valid = false;
          }
        }

      }else{
        var pricedropinputPer = this.pricedropinputPer;
        var pricedropinputRs = this.pricedropinputRs;
        var dataalert =[];

        if($('.item-alertsort select.Select-input.FormControl').val() == "select"){
          $("#dataerror").html("Please Enter the Price Drop of Selected Type");
           valid = false;
        }
        if(valid == true){
          $.each(this.props.data, function(key,element) {
            dataalert = JSON.parse(element.attributes);
            if(pricedropinputRs == "" && pricedropinputPer == ""){
              $("#dataerror").html("Please Enter the Price Drop of Selected Type");
              valid = false;
            }else if(pricedropinputRs != "" && pricedropinputPer == ""){
              if(pricedropinputRs.match(numbers)){
                if(pricedropinputRs < dataalert.currentPrice && pricedropinputRs > 0 ){
                  valid = true;  
                  $("#dataerror").html("");
                }else{
                  $("#dataerror").html("Please input price Less than Current Price & greater than 0");
                  valid = false;
                }
              }else{
                $("#dataerror").html("Please input numeric characters without decimal(.)");
                valid = false;
              }
            }else if(pricedropinputRs == "" && pricedropinputPer != ""){
              if(pricedropinputPer.match(numbers)){
                if(pricedropinputPer < 100 && pricedropinputPer > 0){
                  valid = true;  
                  $("#dataerror").html("");
                }else{
                  $("#dataerror").html("discount less than 100% & discount greater than 0% ");
                  valid = false;
                }
              }else{
                $("#dataerror").html("Please input numeric characters without decimal(.)");
                valid = false;
              }
             }
          });
        }

          if(valid == true){
            if(selectedOption =="noendtime"){
              var Endtime = Date.parse(new Date("1/1/2099 00:00:00"));
            }else if(selectedOption =="endafter"){
              if(Endafterdate != "" || Endafterdate.match(numbers)){
                var days = $(".Endafterdate").val();
                var Endtime = (new Date().getTime()+(days*24*60*60*1000));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End After Days only in Numbers");
                valid = false;
              }
            }else if(selectedOption == "endby"){
              if(dealInput != ""){
                var Endtime = Date.parse(new Date(dealInput));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End By Date");
                valid = false;
              }
            }else if(checked_date === null){
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End Time");
                valid = false;
            }
          if(checked_date === null){  //Test if something was checked
          $("#dataerror").html("Please Select the End Time");
            valid = false;
          }
        }
      }

      if(valid){
        if(!this.multiselect){
          var CategoryPath = this.AlertData["0"].attributes.tdaCategory?this.AlertData["0"].attributes.tdaCategory:"";
          var PathArr=[];
            if(CategoryPath.length > 0){
              $.each(CategoryPath, function( index, value ) {
                if(value.tdaCategoryPath!=null){
                  PathArr.push(value.tdaCategoryPath.split('>'));
                }
              });

              var max = PathArr[0]?PathArr[0].length:"";
              for(var i=1; i<PathArr.length; i++){
                if(PathArr[i].length > max){
                   max = PathArr[i].length;
                  var indi= i;
                }
              }
              if(indi === undefined && this.AlertData["0"].attributes.tdaCategory[0].tdaCategoryPath !=="Undefined"){
                this.tdaCategoryPath =this.AlertData["0"].attributes.tdaCategory[0].tdaCategoryPath; 
              }else{
                this.tdaCategoryPath = this.AlertData["0"].attributes.tdaCategory[indi]?this.AlertData["0"].attributes.tdaCategory[indi].tdaCategoryPath:"";
              }
          }else{
            this.tdaCategoryPath = "";
          }
        var tid = this.AlertData["0"].attributes.tid;
        var PriceDrop = "";
        var UserInput = "";
        if(this.AlertData["0"].attributes.TDAProductId != null && this.AlertData["0"].attributes.TDAProductId != ""){
          var TDAProductId = this.AlertData["0"].attributes.TDAProductId[this.AlertData["0"].attributes.TDAProductId.length-1].$oid;
        }
        var MRP = this.AlertData["0"].attributes.maxPrice;
        var currentprice = this.AlertData["0"].attributes.sellingPrice;
        if(this.pricedropmethod == "TotalDiscountonMRP"){
          var TargetDiscount = MRP - (MRP *this.pricedropinputPer/100);
          PriceDrop = "TD";
          UserInput = this.pricedropinputPer;
        }
        if(this.pricedropmethod == "PriceDropsByRs"){
          var TargetPrice = currentprice - this.pricedropinputRs;
          PriceDrop = "PD";
          UserInput = this.pricedropinputRs;
        }
        if(this.pricedropmethod == "MinimumPriceDropsByPercentage"){
          var MinPerDrop = currentprice - (currentprice*this.pricedropinputPer/100);
          PriceDrop = "MPD";
          UserInput = this.pricedropinputPer;
        }
        var Data = [];
        Data.push({userId:userId,tid:tid,categorypath:this.tdaCategoryPath,TargetDiscount:TargetDiscount,TargetPrice:TargetPrice,MinPerDrop:MinPerDrop,Endtime:Endtime,WishListName:WishListName,PriceDrop:PriceDrop,UserInput:UserInput,OldPrice:currentprice,DateOption:selectedOption});
       
      }else{
        var AlertData =[];
        var Data = [];
        var tdaCategoryPath = "";
        var numbers = /^[0-9]+(\.)?[0-9]+$/;
        var regex = /[0-9]|\./;
        var pricedropmethod = this.pricedropmethod;
        var pricedropinputRs = this.pricedropinputRs;
        var pricedropinputPer = this.pricedropinputPer;
        $.each(this.props.data, function(key,element) {
        AlertData = JSON.parse(element.attributes);

            var CategoryPath = AlertData.tdaCategory?AlertData.tdaCategory:"";
            var PathArr=[];
          if(CategoryPath.length > 0){
            $.each(CategoryPath, function( index, value ) {
              if(value.tdaCategoryPath!=null){
                PathArr.push(value.tdaCategoryPath.split('>'));
              }
            });

            var max = PathArr[0]?PathArr[0].length:"";
            for(var i=1; i<PathArr.length; i++){
              if(PathArr[i].length > max){
                 max = PathArr[i].length;
                var indi= i;
              }
            }
            if(indi === undefined && AlertData.tdaCategory[0].tdaCategoryPath !=="Undefined"){
              tdaCategoryPath =AlertData.tdaCategory[0].tdaCategoryPath; 
            }else{
              tdaCategoryPath = AlertData.tdaCategory[indi]?AlertData.tdaCategory[indi].tdaCategoryPath:"";
            }
          }else{
            tdaCategoryPath = "";
          }
          var tid = AlertData.tid;
          var PriceDrop = "";
          var UserInput = "";
          if(AlertData.TDAProductId != null && AlertData.TDAProductId != ""){
            var TDAProductId = AlertData.TDAProductId[AlertData.TDAProductId.length-1].$oid;
          }
          var MRP = AlertData.maxPrice;
          var currentprice = AlertData.currentPrice;
          if(pricedropmethod == "TotalDiscountonMRP"){
            var TargetDiscount = MRP - (MRP *pricedropinputPer/100);
            PriceDrop = "TD";
            UserInput = pricedropinputPer;
          }
          if(pricedropmethod == "PriceDropsByRs"){
            var TargetPrice = currentprice - pricedropinputRs;
            PriceDrop = "PD";
            UserInput = pricedropinputRs;
          }
          if(pricedropmethod == "MinimumPriceDropsByPercentage"){
            var MinPerDrop = currentprice - (currentprice*pricedropinputPer/100);
            PriceDrop = "MPD";
            UserInput = pricedropinputPer;
          }
          Data.push({userId:userId,tid:tid,categorypath:tdaCategoryPath,TargetDiscount:TargetDiscount,TargetPrice:TargetPrice,MinPerDrop:MinPerDrop,Endtime:Endtime,WishListName:WishListName,PriceDrop:PriceDrop,UserInput:UserInput,OldPrice:currentprice,DateOption:selectedOption});
          
        });     
        
      }

      const Api_url = app.forum.attribute('baseUrl') +'/api/productalert';
      return app.request({
        method: 'GET',
        url: Api_url,
        data: Data, 
        }).then(
          this.parseResults.bind(this),
      ); 

      }
  }

  getParams(paramName,value){ 
    let paramsURL = Object.assign({},  m.route.param());
    paramsURL[paramName] = value;
    return paramsURL;
  }

  parseResults(result){
    if(result.data.attributes.message =="Already Exits")
    {
      $("#dataerror").html("The Product is already present in your wishlist");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else if(result.data.attributes.message =="inserted"){
      $("#dataerror").css("color","Blue").html("Thank You for your input. We will Notify you when prices are change");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else if(result.data.attributes.message =="Not inserted"){
      $("#dataerror").html("Product is not added in your wishlist. please fill the correct data");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else{
      ("#dataerror").html("");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }
  }

  config(isInitialized) {
      this.datepicker();
  }

  datepicker() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    $('#validFromdatetimepicker').datetimepicker({
      minDate: 0,
       onShow:function( ct ){
          this.setOptions({
            Date:jQuery('#validFromdatetimepicker').val()?jQuery('#validFromdatetimepicker').val():false
          })
          },
    });
  }

  onerror(error) {
    if (error.status === 401) {
      error.alert.props.children = app.translator.trans('core.forum.log_in.invalid_login_message');
    }
    super.onerror(error);
  }
}//end