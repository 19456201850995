import Page from 'flarum/components/Page';
import DealResults from './../search/DealResults';
import CategoryProductResults from './../search/CategoryProductResults';
import CategoriesNav from './../categoriesDeals/CategoriesNavigation';

export default class myProductIndexPage extends Page {
  //used for Search page(Indexpage) https://thedealapp.in/product?query=samsung
      init() {
        super.init();
        const params1 = m.route.param();
        this.category_id = m.route.param('categories') ? m.route.param('categories') : '';            
        this.CategoryProductResults = new CategoryProductResults({params:params1,routeName:this.props.routeName});
      }
     view() {
      return(
        <div class="searchResultsPage" >
            <div className="container resultsContainer">
            <nav className="IndexPage-nav sideNav categoryNav">
              {CategoriesNav.component({routeName:this.props.routeName })}      
            </nav>
            {this.CategoryProductResults.render()}
            </div>
        </div>
      )
    }
}