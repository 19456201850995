import { extend, override } from "flarum/extend";
//import CouponIndexPage from '../coupon/CouponIndexPage';
import CouponIndexPage from "../coupon/CouponIndexPagev2";
import CouponDiscussionList from "../coupon/CouponDiscussionList";

export default function () {
  //used in Coupons page for sidebar
  CouponIndexPage.prototype.currentTag = function () {
    const slug = this.params().tags;

    if (slug) return app.store.getBy("tags", "slug", slug);
  };

  // Add a parameter for the CouponIndexPage to pass on to the CouponDiscussionList that
  // will let us filter discussions by tag.
  extend(CouponIndexPage.prototype, "params", function (params) {
    params.tags = m.route.param("tags");
  });

  // Translate that parameter into a gambit appended to the search query.
  extend(CouponDiscussionList.prototype, "requestParams", function (params) {
    params.include.push("tags");

    if (this.props.params.tags) {
      params.filter.q =
        (params.filter.q || "") + " tag:" + this.props.params.tags;
    }
  });
}
