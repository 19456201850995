import myComposerBody from "./myComposerBody";
import CategoryDiscussionModal from "./CategoryDiscussionModal";
import extractText from "flarum/utils/extractText";
import Button from "flarum/components/Button";
import TextEditorCKEditor from "./texteditor/TextEditorCKEditor";
import LogInModal from "flarum/components/LogInModal";
import sortTags from "flarum/tags/utils/sortTags";
import fastselect from "fastselect";
import flatpickr from "flatpickr";
//import EndpointsApiUrls from "./EndpointsApiUrls";

/**
 * The `myDiscussionCoupon` component displays the composer content for starting
 * a new coupon. It adds a text field as a header control so the user can
 * enter the title of their discussion. It also overrides the `submit` and
 * `willExit` actions to account for the title.
 *
 * ### Props
 *
 * - All of the props for myComposerBody
 * - `titlePlaceholder`
 */
export default class myDiscussionCoupon extends myComposerBody {
  init() {
    super.init();
    /**
     * The text editor component instance.
     *
     * @type {TextEditor}
     */
    if (!app.session.user) {
      app.modal.show(new LogInModal());
    }
    /**
     * The value of the title input.
     *
     * @type {Function}
     */
    this.productURL = m.prop("");
    this.title = m.prop("");
    this.url = m.prop("");
    this.productID = m.prop("");
    this.productBrand = m.prop("");
    this.productName = m.prop("");
    this.productInfo = m.prop("");
    this.categoryID = m.prop("");
    this.categoryOther = m.prop("others_category");
    this.MerchantNameOther = m.prop("others_Online");
    this.MerchantID = m.prop("");
    this.MerchantParentID = m.prop("");
    this.MerchantName = m.prop("");
    this.MerchantTag = m.prop("");
    this.MerchantParentTag = m.prop("");
    this.maxPrice = m.prop("");
    this.currentPrice = m.prop("");
    this.discount = m.prop("");
    this.currency = m.prop("");
    this.imageUrl_sm = m.prop("");
    this.imageUrl_md = m.prop("");
    this.imageUrl_lg = m.prop("");
    this.contentPost = "";
    this.contentPreview = "";
    this.couponcode = m.prop("");
    this.mytags = [];
    //kunal new added for store merchants tags
    this.mystoretags = [];
    this.validFrom = m.prop("");
    this.validTo = m.prop("");
    this.validFor = m.prop("");
    this.badgeText = m.prop("");
    this.productContent = m.prop("");
    this.editor = new TextEditorCKEditor({
      submitLabel: this.props.submitLabel,
      placeholder: this.props.placeholder,
      onchange: this.productInfo,
      onsubmit: this.onsubmit.bind(this),
      value: this.productInfo(),
    });

    this.categoryTagOther = app.store.getBy(
      "tags",
      "slug",
      this.categoryOther()
    );
    this.categoryParentID = m.prop(
      this.categoryTagOther.data.relationships.parent.data.id
    );
    this.categoryParentTag = app.store.getBy(
      "tags",
      "id",
      this.categoryParentID()
    );
    this.myTagSelected = [];
    this.mySelectedTags = "";
    //new added
    this.myTagstoresSelected = [];
    this.mystoresSelectedTags = "";
    this.categoryCount = 0;
    this.filter = m.prop("");
    this.tags = app.store.all("tags");
    this.categorytags = app.store.all("tags");

    //new added
    var stores = [];
    var i = 0;
    var storesID = "";
    $.each(this.tags, function (index, value) {
      if (value.data.attributes.slug == "stores") {
        i++;
        storesID = value.data.id;
      }
      if (value.data.relationships) {
        if (value.data.relationships.parent) {
          if (value.data.relationships.parent.data.id == storesID) {
            stores[i] = value;
            i++;
          }
        }
      }
    });
    this.tags = stores;
    this.tags = sortTags(this.tags);
    this.StoredatalistItem = "";
    this.chooseTags = function () {
      const props = {
        url: this.url(),
        productID: this.productID(),
        productBrand: this.productBrand(),
        productName: this.productName(),
        productInfo: this.productInfo(),
        categoryID: this.categoryID(),
        MerchantID: this.MerchantID(),
        maxPrice: this.maxPrice(),
        currentPrice: this.currentPrice(),
        discount: this.discount(),
        currency: this.currency(),
        imageUrl_sm: this.imageUrl_sm(),
        imageUrl_md: this.imageUrl_md(),
        imageUrl_lg: this.imageUrl_lg(),
        content: this.contentPost,
      };

      app.modal.show(
        new CategoryDiscussionModal({
          selectedTags: this.mytags,
          props,
        })
      );
    }; //choose stores merchant
    this.flag = 1;
    this.check = 1;
  }

  static initProps(props) {
    super.initProps(props);
    props.placeholder =
      "Enter additional description describing the deal here. you may include additional coupons, terms and conditions, wiki etc";
    props.placeholder =
      props.placeholder ||
      extractText(
        app.translator.trans("core.forum.composer_discussion.body_placeholder")
      );
    props.submitLabel =
      props.submitLabel ||
      app.translator.trans("core.forum.composer_discussion.submit_button");
    props.confirmExit =
      props.confirmExit ||
      extractText(
        app.translator.trans(
          "core.forum.composer_discussion.discard_confirmation"
        )
      );
    props.titlePlaceholder =
      props.titlePlaceholder ||
      extractText(
        app.translator.trans("core.forum.composer_discussion.title_placeholder")
      );
    props.className = "ComposerBody--discussion myComposerBody";
  }
  previewItems() {
    const items = super.previewItems();
    //items.add(
    //  "productpreivew",
    //  <div className="cc_productPreview">
    //  <h2>Terms and conditions</h2>
    //  {}
    // </div>
    // );
    //items.add(
    // "productInfo",
    // <div className="cc_prodInfo">
    // <div className="ComposerBody-editor" style="height:100%;">
    //  {this.editor.render()}{" "}
    //</div>
    // </div>
    // );
    return items;
  }
  configDatePicker(el, isInitialized) {
    if (isInitialized) return;

    // flatpickr(el, {
    //   enableTime: true,
    //   //minDate: this.endDate() || 'today',

    //   //defaultDate: this.endDate(),
    //   //wrap: true,

    //   //onChange: dates => this.endDate(dates[0]),
    // });
  }

  config(isInitialized) {
    if (isInitialized) return;
    if (!this.loading) this.datepicker();
    $(".multipleSelect").fastselect();

    this.categorytags = app.store.all("tags");
    var category = {};
    var i = 0;
    var categoryID = "";

    $.each(this.categorytags, function (index, value) {
      if (value.data.attributes.slug == "category") {
        i++;
        categoryID = value.data.id;
      }

      if (value.data.relationships) {
        if (value.data.relationships.parent) {
          if (value.data.relationships.parent.data.id == categoryID) {
            category[value.data.attributes.slug] = value.data.attributes.name;
            i++;
          }
        }
      }
    });

    $.each(category, function (val, text) {
      $("#selectCategory").append($("<option></option>").val(val).html(text));
    });
  }
  datepicker() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    $("#validFromdatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      minDate: jQuery("#validFromdatetimepicker").val()
        ? jQuery("#validFromdatetimepicker").val()
        : false,
      onReady: function (dateObj, dateStr, instance) {
        const $clear = $('<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>')
          .on('click', () => {
            instance.clear();
            instance.close();
          })
          .appendTo($(instance.calendarContainer));
      }
    });
    $("#validTodatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      minDate: jQuery("#validFromdatetimepicker").val()
        ? jQuery("#validFromdatetimepicker").val()
        : false,
      onReady: function (dateObj, dateStr, instance) {
        const $clear = $('<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>')
          .on('click', () => {
            instance.clear();
            instance.close();
          })
          .appendTo($(instance.calendarContainer));
      }
    });

    // $("#validFromdatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       maxDate: jQuery("#validTodatetimepicker").val()
    //         ? jQuery("#validTodatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
    // $("#validTodatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       minDate: jQuery("#validFromdatetimepicker").val()
    //         ? jQuery("#validFromdatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
  }
  // destroyDatePicker() {
  //   $("#validTodatetimepicker").datetimepicker("destroy");
  //   $("#validFromdatetimepicker").datetimepicker("destroy");
  // }
  headerItems() {
    if (app.cache.selectedCategory) {
      this.mySelectedTags = app.cache.selectedCategory;
    }
    if (app.cache.selectedstores) {
      this.mystoresSelectedTags = app.cache.selectedstores;
    }

    const items = super.headerItems();
    items.add("",
      <div id="stepProgressBar">
        <section>
          <div class="wizzard">
            <div class="Steps">
              <ul>
                <li><div id="step1" class="step active">1</div></li>
                <li><div id="step2" class="step">2</div></li>
                <li><div id="step3" class="step">3</div></li>
              </ul>
            </div>
            <div class="content" id="content-1" data-id='1'>
              <div className="group cc_parentBorderNone">
                <label class="dealInputLabel">
                  Start by entering deal url here..
                </label>
                <input
                  className="dealInput"
                  value={this.url()}
                  oninput={m.withAttr("value", this.url)}
                  disabled={!!this.props.disabled}
                  onkeydown={this.onkeydown.bind(this)}
                  onchange={this.Process1.bind(this)}
                  id="txturl"
                  placeholder="https://Example.com"
                  style="width: 100%;"
                  required
                />
                <span className="myHighlight"></span>
              </div>
            </div>
            <div class="content" id="content-2" data-id='2' style="display:none">
              <div className="group">
                <label class="dealInputLabel">Post Title</label>
                <input
                  className="dealInput"
                  value={this.title()}
                  oninput={m.withAttr("value", this.title)}
                  disabled={!!this.props.disabled}
                  onkeydown={this.onkeydown.bind(this)}
                  style="width: 100%;"
                  placeholder="Post Title"
                  required
                />
                <span className="myHighlight"></span>
              </div>
              <div className="group gList">
                <label class="dealInputLabel">Select Merchant </label>
                <div
                  style="background: transparent; width: 95%;display: grid; padding:0;"
                  className={"TagsInput FormControl cc_dealInput" + (this.focused ? "focus" : "")}
                >
                  <span
                    className="FormControl myFields myComposerItems"
                    style="background: #f4f3f3; border: 1px solid #eaeaea;!important;"
                  >
                    {m(".autocomplete", [
                      m("input#search[list=results]", {
                        oninput: this.onInputm.bind(this),
                      }),
                      m("datalist#results", [
                        this.tags.map((item) =>
                          m("option[value=" + item.data.attributes.name + "]")
                        ),
                      ]),
                    ])}
                  </span>
                </div>
              </div>
              <div className="group gMRP" >
                <label class="dealInputLabel">Coupon code</label>
                <input
                  className="dealInput cc_dealInput"
                  value={this.couponcode()}
                  oninput={m.withAttr("value", this.couponcode)}
                  disabled={!!this.props.disabled}
                  onkeydown={this.onkeydown.bind(this)}
                  style="width: 95%;"
                  placeholder="Coupon code"
                  required
                />
                <span className="myHighlight"></span>
              </div>
              <div className="group gDealPrice">
                <label class="dealInputLabel">
                  Badge Text (max 20 characters - eg. 30% off)
                </label>
                <input
                  className="dealInput cc_dealInput"
                  value={this.badgeText()}
                  oninput={m.withAttr("value", this.badgeText)}
                  disabled={!!this.props.disabled}
                  onkeydown={this.onkeydown.bind(this)}
                  maxlength="20"
                  style="width: 100%;"
                  placeholder="max 20 characters - eg. 30% off"
                  required
                />
                <span className="myHighlight"></span>
              </div>

              <div>
                <label className="dealInputLabel">
                  Select all product categories that may be applicable to this Coupon{" "}
                </label>
                <select id="selectCategory" class="multipleSelect" multiple></select>
              </div>
              <div className="cc_parentBorderNone cc_Couponvalidity">
                <br />
                <div><label className="dealInputLabel">This Coupon has Validity: </label><label className="switch">
                  {this.check == 1 ?
                    <input id="Dealvaliditycheck" type="checkbox" checked onclick={this.toggleDate.bind(this)} />
                    : <input id="Dealvaliditycheck" type="checkbox" onclick={this.toggleDate.bind(this)} />
                  }
                  <span className="slider round"></span>
                </label>
                </div>
                <div id="datetime" className="cc_datetime" style="display:block !important">
                  <div className="group cc_group cc_dealvalidity">
                    <label className="dealInputLabel">valid From</label>
                    <input
                      className="dealInput cc_dealInputmb"
                      id="validFromdatetimepicker"
                      value={this.validFrom()}
                      onchange={m.withAttr("value", this.validFrom)}
                      disabled={!!this.props.disabled}
                      onkeydown={this.onkeydown.bind(this)}
                      config={this.configDatePicker.bind(this)}
                      style="border: none;
                border-bottom: 1px solid #b8b8b8;
                border-radius: unset;"
                      required
                      placeholder="valid From"
                    />

                    <span className="myHighlight"></span>
                  </div>

                  <div className="group cc_group cc_dealvalidity">
                    <label className="dealInputLabel">valid To</label>
                    <input
                      className="dealInput cc_dealInputmb"
                      id="validTodatetimepicker"
                      value={this.validTo()}
                      config={this.configDatePicker.bind(this)}
                      onchange={m.withAttr("value", this.validTo)}
                      disabled={!!this.props.disabled}
                      onkeydown={this.onkeydown.bind(this)}
                      style="border: none;
                border-bottom: 1px solid #b8b8b8;
                border-radius: unset;"
                      required
                      placeholder="valid To"
                    />
                    <span className="myHighlight"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="content" id="content-3" data-id='3' style="display:none">
              <div className="cc_prodInfo">
                <div style="position: relative;top: 14px;" id="inboxx"><label className="dealInputLabel">More Coupon Details: </label><label className="switchdescription">
                  {this.flag == 1 ?
                    <input id="Descriptioncheck" type="checkbox" checked onclick={this.toggleDescription.bind(this)} />
                    : <input id="Descriptioncheck" type="checkbox" onclick={this.toggleDescription.bind(this)} />
                  }
                  <span className="slider round"></span>
                </label>
                </div>
                <div id="productDescription">
                  <div className="cc_productPreview">
                    <h2>Terms and conditions</h2>
                  </div>
                  <div className="ComposerBody-editor" style="height:100%;">
                    {this.editor.render()}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cc_btn_footer" style="text-align:center;">
            <div class="cc_button pull-left buttonprev" style="display:none;float:left !important;">
              <button id="prev" class="Button Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.previous.bind(this)}>&larr; Previous</button>
              <i id="cc_prev" class='fas cc_fas' onclick={this.previous.bind(this)} style='font-size:24px; color:#000;'>&#xf30a;</i>
            </div>
            <div class="cc_button pull-right">
              <button id="next" class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.next.bind(this)}>Next &rarr;</button>
              <i id="cc_next" style='font-size:24px;color:#000;' class='fas cc_fas' onclick={this.next.bind(this)}>&#xf30b;</i>
            </div>
            <div class="cc_button">
              <button id="btnSubmitDeal" style="display:none" icon="check" class="Button Button--primary mysubmit cc_mysubmit" onclick={this.onsubmit.bind(this)}>Post Coupon</button>
            </div>
          </div>

        </section>
      </div>
    );
    // step process
    $('.step').each(function (index, el) {
      $(el).not('.active').addClass('done');
      $('.done').html('<i class="icon-valid"></i>');
      if ($(this).is('.active')) {
        $(this).parent().addClass('pulse')
        return false;
      }
    });

    // items.add(
    //   "mysubmit",
    //   Button.component({
    //     children: "Post Coupon",
    //     icon: "check",
    //     className: "Button Button--primary mysubmit cc_mysubmit",
    //     itemClassName: "",
    //     onclick: this.onsubmit.bind(this),
    //   })
    // );

    this.discount = m.prop(
      ((1 - this.currentPrice() / this.maxPrice()) * 100).toFixed(2)
    );
    if (!this.productURL()) {
      this.productURL = m.prop(this.url());
    }
    var line_image = "";
    var line_this = "";
    if (this.imageUrl_sm()) {
      line_image =
        "<a href=" +
        this.imageUrl_sm() +
        " ><img src=" +
        this.imageUrl_sm() +
        ' alt="Smiley face" height="150" width="150"/></a>';
    }
    line_this =
      " <p>Coupon Code is <strong> " + this.couponcode() + "</strong></p>";
    this.contentPost = this.productInfo();

    /**
     * Parse a given text and render it into given HTML element {html} target
     */
    return items;
  }
  configPreview(element, isInitialized, context) {
    if (isInitialized) return;
    // Every 50ms, if the composer content has changed, then update the post's
    // body with a preview.
    let preview;
    const updateInterval = setInterval(() => {
      const content = this.productInfo();
      if (preview === content) return;
      preview = content;
      const anchorToBottom =
        $(window).scrollTop() + $(window).height() >= $(document).height();
      s9e.TextFormatter.preview(preview || "", element);
      if (anchorToBottom) {
        $(window).scrollTop($(document).height());
      }
    }, 50);

    context.onunload = () => clearInterval(updateInterval);
  }
  success(data) {
    if (data.responseMsg == "success") {
      this.setAPIvalues(data);
      this.apiSuccess();
      $(".messageLineText").html(
        "We have prefilled product details below for you, Edit if necessary."
      );
    } else {
      this.apiError();
      if (data.responseMsg == "noURL") {
        $(".messageLineText").html("Please fill URL correctly");
      } else if (data.responseMsg == "InvalidURL") {
        $(".messageLineText").html(
          "We could not find specific product on this link, Please fill the details of product below!"
        );
      }
    }
    m.redraw();
  }
  failure(response) {
    this.apiError();
    $(".messageLineText").html(
      "We could not find specific product on this link, Please fill the details of product below!"
    );
    m.redraw();
  }
  setAPIvalues(data) {
    this.title = m.prop(data.productName + " @ " + data.sellingPrice);
    this.productURL = m.prop(data.productUrl);
    this.productID = m.prop(data.productId);
    this.productBrand = m.prop(data.productBrand);
    this.productName = m.prop(data.productName);
    this.categoryID = m.prop(data.category);
    this.MerchantName = m.prop(data.merchantName);
    this.maxPrice = m.prop(data.maximumRetailPrice);
    this.currentPrice = m.prop(data.sellingPrice);
    //    this.discount = m.prop(((1 - (this.currentPrice() / this.maxPrice()))*100).toFixed(2));
    this.currency = m.prop(data.currency);
    this.imageUrl_sm = m.prop(data.imageUrls.imageUrl_sm);
    this.imageUrl_md = m.prop(data.imageUrls.imageUrl_md);
    this.imageUrl_lg = m.prop(data.imageUrls.imageUrl_lg);
    this.imageUrl = m.prop("");

    if (this.MerchantName()) {
      this.MerchantName = m.prop(this.MerchantNameOther());
    }
    this.MerchantTag = app.store.getBy("tags", "slug", this.MerchantName());
    this.MerchantID = m.prop(this.MerchantTag.data.id);
    this.MerchantParentID = m.prop(
      this.MerchantTag.data.relationships.parent.data.id
    );
    this.MerchantParentTag = app.store.getBy(
      "tags",
      "id",
      this.MerchantParentID()
    );
    this.myTags = [this.MerchantParentTag, this.MerchantTag];
  }
  myContent() {
    return (
      <div className=" ">
        <a href={this.url()}>
          {this.productName()} <i class="fa fa-angle-double-right"></i>
        </a>
      </div>
    );
  }
  apiWait() {
    $(".processIcon").removeClass("fa fa-exclamation-triangle");
    $(".processIcon").removeClass("fa fa-check");
    $(".processIcon").addClass("fa fa-cog fa-spin fa-1x fa-fw");
  }
  apiSuccess() {
    $(".processIcon").removeClass("fa fa-cog fa-spin fa-1x fa-fw");
    $(".processIcon").removeClass("fa fa-exclamation-triangle");
    $(".processIcon").addClass("fa fa-check");
  }
  apiError() {
    $(".processIcon").removeClass("fa fa-cog fa-spin fa-1x fa-fw");
    $(".processIcon").removeClass("fa fa-check");
    $(".processIcon").addClass("fa fa-exclamation-triangle");
  }
  Process1() {
    const splitUrl = this.url().split("/")[2].split(".");
    var hostnameTag;
    if (app.store.getBy("tags", "slug", splitUrl[splitUrl.length - 2])) {
      hostnameTag = app.store.getBy(
        "tags",
        "slug",
        splitUrl[splitUrl.length - 2]
      );
    } else {
      hostnameTag = app.store.getBy("tags", "slug", "others_Online");
    }
    $("#search").val(hostnameTag.data.attributes.name);
    $('[data-id="1"]').hide();
    $('[data-id="2"]').show();
    $('.buttonprev').show();
    $('#prev').show();
    $('#cc_prev').show();
    $('#step1').removeClass("active");
    $('#step2').addClass("active");
  }

  /**
   * Handle the title input's keydown event. When the return key is pressed,
   * move the focus to the start of the text editor.
   *
   * @param {Event} e
   */
  onkeydown(e) {
    if (e.which === 13) {
      // Return
      e.preventDefault();
      this.editor.setSelectionRange(0, 0);
    }
    m.redraw.strategy("none");
  }
  preventExit() {
    return (this.title() || this.content()) && this.props.confirmExit;
  }
  /**
   * Get the data to submit to the server when the discussion is saved.
   *
   * @return {Object}
   */
  data() {
    return {
      title: this.title(),
      url: this.url(),
      productID: this.productID(),
      productBrand: this.productBrand(),
      productName: this.productName(),
      productInfo: this.productInfo(),
      categoryID: this.categoryID(),
      MerchantID: this.MerchantID(),
      maxPrice: this.maxPrice(),
      currentPrice: this.currentPrice(),
      discount: this.discount(),
      currency: this.currency(),
      imageUrl_sm: this.imageUrl_sm(),
      imageUrl_md: this.imageUrl_md(),
      imageUrl_lg: this.imageUrl_lg(),
      discussionType: "c",
      //  content: this.content()
      //  content: this.contentPost,
      content: this.productContent,
      couponcode: this.couponcode(),
      validFrom: this.validFrom(),
      validTo: this.validTo(),
      validFor: this.validFor(),
      badgeText: this.badgeText(),
      Orderdate: "true",
    };
  }
  onInputm() {
    var val = document.getElementById("search").value;
    var opts = document.getElementById("results").childNodes;
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === val) {
        // An item was selected from the results!
        // yourCallbackHere()
        this.StoredatalistItem = opts[i].value;
        break;
      }
    }
  }

  onsubmit() {
    if (!app.session.user) {
      app.modal.show(new LogInModal());
      return;
    }
    this.productContent =
      '<a  href="' +
      this.url() +
      ' ">' +
      this.title() +
      "</a><br>" +
      this.productInfo();
    this.loading = true;
    this.myTags = [
      app.store.getBy("tags", "slug", "stores"),
      app.store.getBy("tags", "slug", "coupons"),
    ];
    var hostnameTag;
    if (this.url()) {
      const splitUrl = this.url().split("/")[2].split(".");
      if (app.store.getBy("tags", "slug", splitUrl[splitUrl.length - 2])) {
        hostnameTag = app.store.getBy(
          "tags",
          "slug",
          splitUrl[splitUrl.length - 2]
        );
        this.myTags = this.myTags.concat(hostnameTag);
      } else {
        hostnameTag = app.store.getBy("tags", "slug", "others_Online");
        this.myTags = this.myTags.concat(hostnameTag);
      }
    }
    var myCategoryselectedtTag = [];
    $("#selectCategory option:selected").each(function () {
      myCategoryselectedtTag.push(
        app.store.getBy("tags", "slug", $(this).val())
      );
    });
    if (myCategoryselectedtTag.length > 0) {
      this.myTags = this.myTags.concat(myCategoryselectedtTag);
    }

    const data = this.data();
    if (this.myTags) {
      data.relationships = data.relationships || {};
      data.relationships.tags = this.myTags;
    }
    delete app.cache.selectedCategory;
    //added new
    delete app.cache.selectedstores;
    var storeName = "";
    this.myTags.forEach(function (element) {
      if (element.data.attributes.parentID === 4) {
        storeName = element.data.attributes.name;
      }
    });
    storeName = storeName.toLowerCase();
    const urlparams = {};
    urlparams.query = "coupons";
    urlparams.couponCode = data.couponcode;
    urlparams.stores = storeName;
    //this.destroyDatePicker();
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    const Api_url = ApiEndpoint + "/api/v3/coupons";

    $.ajax({
      type: "GET",
      url: Api_url,
      data: urlparams, //Returns ID in body
      success: function (response) {
        if (response.result.hits == undefined) {
          app.store
            .createRecord("discussions")
            .save(data)
            .then((discussion) => {
              app.composer.hide();
              //app.cache.discussionList.addDiscussion(discussion);
              m.route(app.route.discussion(discussion));
            }, this.loaded.bind(this));

        } else {
          if (response.result.hits.hits.length >= 1) {
            alert("Coupon is Already Listed");
            $(".LoadingIndicator").hide();
          }
          $(".LoadingIndicator").hide();
        }
      },
      error: function () {
        alert("Something went wrong. Please try again.");
        $(".LoadingIndicator").hide();
      },
    });

  }

  toggleDate(data) {
    if ($('#Dealvaliditycheck').is(':checked')) {
      this.check = 1;
      document.getElementById("datetime").style.display = "block";
    } else {
      document.getElementById("datetime").style.display = "none";
      $('#Dealvaliditycheck').removeAttr('checked');
      this.check = 0;
    }
  }

  toggleDescription(e) {
    if ($('#Descriptioncheck').is(':checked')) {
      this.flag = 1;
      document.getElementById("productDescription").style.display = "block";
    } else {
      document.getElementById("productDescription").style.display = "none";
      $('#Descriptioncheck').removeAttr('checked');
      this.flag = 0;
    }

  }

  next(data) {
    if ($('#txturl').val()) {
      var id = $('.content:visible').data('id');
      var nextId = $('.content:visible').data('id') + 1;
      $('[data-id="' + id + '"]').hide();
      $('[data-id="' + nextId + '"]').show();
      $('#step' + id).removeClass("active");
      $('#step' + nextId).addClass("active");

      if ($('#prev:hidden').length == 1) {
        $('#prev,#cc_prev,.buttonprev').show();
      }

      if (nextId == 3) {
        $('#next').hide();
        $('#cc_next').hide();
        $('#btnSubmitDeal').show();
      }
    } else {
      alert("Please Enter the URL");
    }
  }

  previous(data) {
    var id = $('.content:visible').data('id');
    var prevId = $('.content:visible').data('id') - 1;
    $('[data-id="' + id + '"]').hide();
    $('[data-id="' + prevId + '"]').show();
    $('#step' + id).removeClass("active");
    $('#step' + prevId).addClass("active");

    if (prevId == 1) {
      $('.buttonprev').hide();
      $('#prev').hide();
      $('#btnSubmitDeal').hide();
    } else {
      $('.buttonprev').show();
      $('#next').show();
      $('#prev').show();
      $('#cc_next').show();
      $('#btnSubmitDeal').hide();
    }
  }

}


