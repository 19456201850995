import Component from "flarum/Component";
import Button from "flarum/components/Button";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import Placeholder from "flarum/components/Placeholder";
import CouponStoreListItems from "../coupon/CouponStoreListItems";
import CouponExpiry from "../coupon/CouponExpiry";
import Switch from "flarum/components/Switch";
import SlickmainSlider from "../SlickmainSlider";
import FeatureCouponslide from "../FeatureCouponslide";
import store from "../categoriesDeals/store";
import EndpointsApiUrls from "../EndpointsApiUrls";
import CouponDetailsStructre from "../coupon/CouponDetailsStructre";

export default class CouponListPage extends Component {
  //Used in Coupons for display Coupon List
  init() {
    this.darkMode = m.prop("");
    this.isExpiredChk = false;
    this.loading = true;
    this.storename = "";
    this.moreResults = false;
    this.discussions = [];
    this.CouponSlider = [];
    this.loadFeaturedCoupons();
    this.refresh();
    this.textsearch = false;
  }

  loadFeaturedCoupons() {
    var ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    let Api_url = "";
    if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
      Api_url = ApiEndpoint + "/featuredCoupon";
    } else {
      Api_url = ApiEndpoint + "/api/v3/featuredCoupon";
    }

    //var Api_url = "https://search.thedealapp.in/featuredCoupon";

    app
      .request({
        method: "GET",
        url: Api_url,
      })
      .then(this.couponresult.bind(this));
  }

  view() {
    const params = this.props.params;
    let loading;

    if (this.loading) {
      loading = LoadingIndicator.component();
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    const text = "It looks like there are currently no Coupons available for this store. ";
    if (this.discussions.length === 0 && !this.loading) {
      const text =
        "It looks like there are currently no Coupons available for this store. ";
      return (
        <div className="DiscussionList">{Placeholder.component({ text })}</div>
      );
    }

    if (this.discussions.length === 0 && !this.loading) {
      const text =
        "It looks like there are currently no Coupons available for this store. ";
      return (
        <div>
          <div class="cc_DealStoreHeader row">
            <div class="col-md-4 headerdeals" style="display: inline-block;">
              <h1 style="margin: 0px;display: inline-block;font-weight: normal;">
                Stores
              </h1>
            </div>
            <div class="col-md-4 searchdeals" style="display: inline-block;">
              <input
                class="FormControl"
                type="text"
                id="dealseach"
                Placeholder="Find in coupons..."
                onkeyup={this.configsearch.bind(this)}
              />
            </div>
          </div>
          <div className="DiscussionList">
            {Placeholder.component({ text })}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div class="cc_DealStoreHeader row">
          <div
            class="col-md-4 headerdeals"
            style="display: inline-block;"
          ></div>
          <div class="col-md-4 searchdeals" style="display: inline-block;width:100% !important;">
            <input
              class="FormControl"
              type="text"
              id="dealseach"
              Placeholder="Find in coupons..."
              onkeyup={this.configsearch.bind(this)}
            />
          </div>
        </div>

        {this.textsearch == false && this.discussions.length == 0 ?
          (!loading ? <div className="DiscussionList-loadMore">{loading}</div> :
            <div className="DiscussionList">{Placeholder.component({ text })}</div>)

          :
          this.textsearch == true ? (
            <div class="detailscoupondiv" style="">
              {this.discussions.map((discussion) => {
                return CouponDetailsStructre.component({
                  discussion,
                });
              })}
            </div>
          ) : (
            <div className="DiscussionList cc_coupon_slide" style="">
              <div
                id="couponstores"
                class="cc_couponStoreHeader"
                style="display:none;"
              >
                <h1 style="margin: 0px;">{this.storename} Coupons</h1>
              </div>
              {this.CouponSlider.length != 0 ?
                <div class="banner cc_banner">
                  <div className="slidecoupon" id="featurecouponslide">
                    <h1 class="sliderTitle" style="color:#0a3e82fa; height:0px;">
                      Featured Coupons
                    </h1>
                    {SlickmainSlider.component({
                      slidertype: "others",
                      slidesToShowDesktop: this.slidesToShowDesktop,
                      slidesToScrollDesktop: this.slidesToScrollDesktop,
                      children: this.CouponSlider.reduce(function (
                        result,
                        discussion
                      ) {
                        var DiscussionData = {
                          title: discussion._source.title,
                          maxprice: discussion._source.maxprice,
                          currentprice: discussion._source.currentprice,
                          discount: discussion._source.discount,
                          url: discussion._source.url,
                          productname: discussion._source.productname,
                          is_featured: discussion._source.is_featured,
                          is_hotdeal: discussion._source.is_hotdeal,
                          is_expired: discussion._source.is_expired,
                          start_time: discussion._source.start_time,
                          imageurl_sm: discussion._source.merchantLogo,
                          validfrom: discussion._source.createdOn,
                          validto: discussion._source.endDate,
                          validfor: discussion._source.validfor,
                          badgetext: discussion._source.badge,
                          discussiontype: discussion._source.type,
                          startUser: "",
                          id: discussion._source.id,
                          couponcode: discussion._source.couponcode,
                          hasPromoCode: discussion._source.hasPromoCode,
                          merchantDomain: discussion._source.merchantDomain,
                          merchantLogo: discussion._source.merchantLogo,
                          merchantName: discussion._source.merchantName,
                          description: discussion._source.description,
                          imagepath: discussion._source.imagepath,
                        };
                        result.push(
                          FeatureCouponslide.component({ DiscussionData })
                        );
                        return result;
                      },
                        []),
                    })}
                  </div>
                  <div style="clear:both"></div>
                </div>
                : ""
              }
              <div className="col-sm-12 cc_couponListcc">
                <h1 class="sliderTitle">Top Stores</h1>
                {this.discussions.map((discussion) => {
                  var cstatus = this.darkMode();
                  return cstatus == true ? (
                    (Date.parse(discussion._source.validTo) || 0) != "0" ? (
                      <div class="row" style="">
                        {CouponDetailsStructre.component({
                          discussion,
                        })}
                      </div>
                    ) : (
                      <div class="row" style="">
                        {CouponDetailsStructre.component({
                          discussion,
                          params,
                          cstatus,
                        })}
                      </div>
                    )
                  ) : cstatus == false ? (
                    <div>
                      {CouponStoreListItems.component({
                        discussion,
                        params,
                        cstatus,
                      })}
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
              <div className="DiscussionList-loadMore">{loading}</div>
            </div>
          )}
      </div>
    );
  }

  /**
   * Get the parameters that should be passed in the API request to get
   * discussion results.
   *
   * @return {Object}
   * @api
   */
  requestParams() {
    const params = { filter: {} };

    params.sort = this.sortMap()[this.props.params.sort];
    var hostnameTag;
    hostnameTag = app.store.getBy("tags", "slug", this.props.params.tags);

    hostnameTag.data.attributes.slug != "stores"
      ? (this.storename = hostnameTag.data.attributes.name)
      : (this.storename = "");
    this.props.params.q = "is:cupn";
    if (this.props.params.q) {
      params.filter.q = this.props.params.q;
    }

    return params;
  }

  /**
   * Get a map of sort keys (which appear in the URL, and are used for
   * translation) to the API sort value that they represent.
   *
   * @return {Object}
   */
  sortMap() {
    const map = {};

    if (this.props.params.q) {
      map.relevance = "";
    }
    map.latest = "-lastTime";
    map.top = "-commentsCount";
    map.newest = "-startTime";
    map.oldest = "startTime";

    return map;
  }

  /**
   * Clear and reload the discussion list.
   *
   * @public
   */
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.discussions = [];
    }

    return this.loadResults().then(
      (results) => {
        this.discussions = [];
        this.parseResults(results);
      },
      () => {
        this.loading = false;
        m.redraw();
      }
    );
  }

  configsearch(isInitialized, context) {
    var searchtxt = $("#dealseach").val();
    this.loading = true;
    var perpage = 0;

    const urlparams = this.props.params;

    delete urlparams.filter;
    //delete urlparams.q;
    //delete urlparams.tags;

    urlparams.query = searchtxt;
    //alert($("#dealseach").val().length);
    if ($("#dealseach").val().length == 0) {

      //alert("blank");
      this.refresh();
    } else {

      const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
      let Api_url = "";
      if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
        Api_url = ApiEndpoint + '/searchforcoupon';
      } else {
        Api_url = ApiEndpoint + "/api/v3/coupons";
      }
      return app.request({ method: "GET", url: Api_url, data: urlparams }).then(
        this.parseResultssearch.bind(this),
      );
    }
  }

  /**
   * Load a new page of discussion results.
   *
   * @param {Integer} offset The index to start the page at.
   * @return {Promise}
   */
  loadResults(offset) {
    // const preloadedDiscussions = app.preloadedDocument();
    // if (preloadedDiscussions) {
    //   return m.deferred().resolve(preloadedDiscussions).promise;
    // }

    const params = this.requestParams();
    params.page = { offset };

    // var Api_url = "https://search.thedealapp.in/searchforcoupon?query=coupon&size=0";
    var ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
      var Api_url = ApiEndpoint + "/getStores?type=coupons";
    } else {
      var Api_url = ApiEndpoint + "/api/v3/coupons/stores?type=coupons";
    }

    // var Api_url = "https://search.thedealapp.in/getStores?type=coupons";

    return app
      .request({
        method: "GET",
        url: Api_url,
      })
      .then(this.defaultcouponresult.bind(this));
  }

  /**
   * Load the next page of discussion results.
   *
   * @public
   */
  loadMore() {
    this.loading = true;

    this.loadResults(this.discussions.length).then(
      this.parseResults.bind(this)
    );
  }

  /**
   * Parse results and append them to the discussion list.
   *
   * @param {Discussion[]} results
   * @return {Discussion[]}
   */
  parseResults(results) {
    if (results) {
      [].push.apply(this.discussions, results);

      this.loading = false;
      this.textsearch = false;
      // this.moreResults = !!results.payload.links.next;

      m.lazyRedraw();

      return results;
    }
  }

  parseResultssearch(results) {
    this.discussions = [];
    if (results.result) {
      if (results.result.hits) {
        if (results.result.hits.hits) {
          [].push.apply(this.discussions, results.result.hits.hits);
          this.loading = false;
          this.textsearch = true;
          // this.moreResults = !!results.payload.links.next;

          // m.lazyRedraw();
          m.redraw();
          return results;
        }
      }
    } else {
      this.discussions = "";
    }
  }

  couponresult(results) {
    if (results.result.hits != undefined) {
      const topcoupon = results.result.hits.hits;

      this.CouponSlider = topcoupon;
    }
  }

  defaultcouponresult(results) {
    // const defaultcoupon = results.result.hits.hits;
    const defaultcoupon = results["coupon_store_list"];
    //this.defalutcouponlist = defaultcoupon;
    this.discussions = defaultcoupon;
    return defaultcoupon;
  }
}
