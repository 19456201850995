import Component from 'flarum/Component';
import { extend } from 'flarum/extend';
import Page from 'flarum/components/Page';
import Placeholder from 'flarum/components/Placeholder';
import Button from 'flarum/components/Button';
import ProductCard from '../ProductCard';
import ItemList from 'flarum/utils/ItemList';
import listItems from 'flarum/helpers/listItems';
import Select from 'flarum/components/Select';

export default class KeywordPriceDrop extends Component
{//start
  init()
  { 
   // this.params = m.route.param();
    this.params = this.props.params;
    this.checkwishlistname(this.props.params);
    //this.existinglist = [];
    this.datapricedrop=[];
    this.editdata = [];
  }
  view()
  {
  var tabledata =[];
    return (
      m('div',{className: 'pricedropcomponent'}, [
        m('div', {className: 'existingrecordcomp hide'},[
          m("table", {className: 'existingtabel'}, [
            m("thead", [m("tr", [
              m("th", "WishList Name"),
              m("th", "PriceDrop Type"),
              m("th", "Amount"),
              m("th", "Edit"),
            ])]),
            m("tbody", [
            this.existinglist !=undefined ? 
            this.existinglist["0"].PriceDropInfo != undefined ?
            this.existinglist["0"].PriceDropInfo.map(function(list){
            this.datapricedrop = {
                    Endtime : this.existinglist["0"].Endtime,
                    PriceDrop : list.PriceDrop,
                    UserInput : list.UserInput,
                    SearchKey : this.existinglist["0"].SearchKey,
                    WishListName : this.existinglist["0"].WishListName,
                    categorypath : this.existinglist["0"].categorypath,
                    inserted_on : this.existinglist["0"].inserted_on,
                    updated_on : this.existinglist["0"].updated_on,
                    userId : this.existinglist["0"].userId,
                    _id : this.existinglist["0"]._id,
                    DateOption: this.existinglist["0"].DateOption
                  };
              return m("tr",[
                      m("td", this.existinglist["0"].WishListName),
                      m("td", list.PriceDrop == "DD" ? "Discount" : "PriceDrop"),
                      m("td", list.UserInput),
                      m("td", m("input[type=hidden]",({id:"editbutton"+list.PriceDrop, value:JSON.stringify(this.datapricedrop)})),
                              m("a",{value:list.PriceDrop, onclick:this.editdetails.bind(this.datapricedrop)}, "Edit"))
                    ])
              }.bind(this))
            :"" :""
            ])
          ])
        ]),
        m('div', {className: 'newrecordcomp hide'},[
        m("table", {className: 'alerttable'}, [
         m("tr", 
                m("td", {className: 'pricedropbyrs'},"Price Drop. "),
                m("td", <div class="cc_productSort cc_pricedrop">
                          {listItems(this.viewItems().toArray())}
                        </div>
                  ),
                m("td",[m("input[type=text]",({id:'userinputdataRs', className: 'userinputdataRs hide',placeholder:"Please enter the Amount in Rs."}))]),
                m("td",[m("input[type=text]",({id:'userinputdataPer', className: 'userinputdataPer hide', maxlength:'2',placeholder:"Please enter the discount less than 100%"}))])
              ),
              m("tr[className=searchkeyword hide]", 
                m("td", {className: ''},"Keyword : "),
                m("td", m('input[type=text][name=searchkeywordinput][id=searchkeywordinput]'))
              ),
              m("tr", 
                m("td", {className: 'endtime'},"End Time "),
                m("td",  m('label',{id: "enddatetime"},m("input[type=radio]",({className:"dateid",name:"date",value:"noendtime"})),
                  m('span', 'No End Time')),
                  m('br'),
                  m('label',{id: "enddatetime"},m("input[type=radio]",({className:"dateid",name:"date",value:"endafter"})),m('Span', 'End After'),
                    m("input[type=text][id=endaftertext]",({className: 'Endafterdate', maxlength:'10',placeholder:"In Days"}))),
                  m('br'),
                  m('label',{id: "enddatetime"},m("input[type=radio]",({className:"dateid",name:"date",value:"endby"})),m('Span', 'End By Date'),                     
                  m('input',{className:"dealInput", id:"validFromdatetimepicker"}),
                ),
                )
              ),
              m("a",({id:"buyNowAlert" ,className:'button button-sm btn-danger cc_buyNowBtn_alert',onclick:this.alertChanges.bind(this)}),"SET ALERT") 
              
        ])
        ]),
        //this.editdata !=undefined ? console.log(this.editdata) :""
      ])
    );

  }

  viewItems(){
    $('.item-alertsort select.Select-input.FormControl').val("select");
    const items = new ItemList();
    const sortMap ={select: "Select", PriceRangeInRs: "Price In Rs.", Discount: "Discount"};
    const sortOptions ={select: "Select", PriceRangeInRs: "Price In Rs.", Discount: "Discount"};
    items.add('alertsort',
      Select.component({
        options: sortOptions,
        value:m.route.param().alertsort || Object.keys(sortMap)[0],
        onchange: this.changevalue.bind(this)
      })
    );

    return items;
  }

  changevalue(data){
    this.pricedropmethod = data;
    var alertsort = data;
    const params = m.route.param();
    params.alertsort = data;
    if(this.pricedropmethod == "PriceRangeInRs"){
      $("#userinputdataRs" ).removeClass("userinputdataRs hide").addClass("userinputdataRs show");
      $(".userinputdataRs").css("display","block");
      $(".userinputdataPer").css("display","none");
      $("#userinputdataPer").removeClass("userinputdataPer show").addClass("userinputdataPer hide");
    }else if(this.pricedropmethod == "Discount"){
      $("#userinputdataPer").removeClass("userinputdataPer hide").addClass("userinputdataPer show");
      $(".userinputdataPer").css("display","block");
      $(".userinputdataRs").css("display","none");
      $("#userinputdataRs" ).removeClass("userinputdataRs show").addClass("userinputdataRs hide");
    }else{
      $("#userinputdataPer").removeClass("userinputdataPer show").addClass("userinputdataPer hide");
      $("#userinputdataRs" ).removeClass("userinputdataRs show").addClass("userinputdataRs hide");
      $(".userinputdataPer").css("display","none");
      $(".userinputdataRs").css("display","none");
    }

    $("#searchkeywordinput").on( "keyup", function(event)
    {
      var yourInput = $(this).val();
      var re = /[`~!@#$%^&*()_|+=?;:'<>\{\}\[\]\\\/]/gi;
      var isSplChar = re.test(yourInput);
      if(isSplChar)
      {
        var no_spl_char = yourInput.replace(/[`~!@#$%^&*()_|+=?;:'<>\{\}\[\]\\\/]/gi, '');
        $(this).val(no_spl_char);
      }
    });

    $(".dateid").on( "click", function(event) {
      if(event.target.closest( "input" ).value == "noendtime"){
        $("#endaftertext").attr('disabled', true);
        $("#validFromdatetimepicker").attr('disabled', true);
      }else if(event.target.closest( "input" ).value == "endafter"){
        $("#endaftertext").attr('disabled', false);
        $("#validFromdatetimepicker").attr('disabled', true);
      }else{
        $("#endaftertext").attr('disabled', true);
        $("#validFromdatetimepicker").attr('disabled', false);
      }
    });

  }//changevalue end

  checkwishlistname(params){
    const Api_url = app.forum.attribute('baseUrl') +'/api/userSearch';
      return app.request({
        method: 'GET',
        url: Api_url,
        data: params, 
        }).then(
          this.parseResults.bind(this),
      ); 
  }

  parseResults(result){
    if(result.data.attributes.message =="Duplicate")
    {
      $('#topicname').removeAttr("disabled");
      $("#dataerror").html("If you want to create Alert Please change the WishListName");
      $('.item-searchsort select.Select-input.FormControl').val("select");
      $(".newrecordcomp").hide();
      $(".existingrecordcomp").hide();
      $(".searchkeyword").hide();
    }else if(result.data.attributes.message =="Discount"){
      $('#topicname').removeAttr("disabled");
      $(".existingrecordcomp").css("display","none");
      $(".newrecordcomp").show();
      $('select').children('option[value="' + result.data.attributes.message + '"]').attr('disabled', true);
      $(".userinputdataPer").css("display","none");
      $(".userinputdataRs").css("display","none");
      $(".searchkeyword").show();
    }else if(result.data.attributes.message =="PriceRangeInRs"){
      $('#topicname').removeAttr("disabled");
      $(".existingrecordcomp").css("display","none");
      $(".newrecordcomp").show();
      $('select').children('option[value="' + result.data.attributes.message + '"]').attr('disabled', true);
      $(".userinputdataPer").css("display","none");
      $(".userinputdataRs").css("display","none");
      $(".searchkeyword").show();
    }else if(result.data.attributes.message =="New Record"){
      $('#topicname').removeAttr("disabled");
      $(".newrecordcomp").removeClass("newrecordcomp hide").addClass("newrecordcomp show");
      $(".newrecordcomp").css("display","block");
      $(".existingrecordcomp").removeClass("existingrecordcomp show").addClass("existingrecordcomp hide");
      $('.item-searchsort select.Select-input.FormControl').val("NewRecord");
      $('.item-alertsort select.Select-input.FormControl').val("select");
      $(".searchkeyword").show();
      $(".trackProductexist .cc_buyNowBtn_alert").addClass("button button-sm btn-danger cc_buyNowBtn_alert newrecord");
    }else if(result.data.attributes.message =="inserted"){
      $("#dataerror").css("color","Blue").html("Thank You for your input.");
      $(".trackProductexist .newrecordcomp").removeClass("newrecordcomp show").addClass("newrecordcomp hide");
      $(".newrecordcomp").css("display","none");
      $(".alertbutton").hide();
    }else if(result.data.attributes.message =="Not inserted"){
      $("#dataerror").html("Alert for this search is not added in your wishlist. please fill the correct data");
      $(".alertbutton").hide();
    }else if(result.data.attributes){
      $(".newrecordcomp").removeClass("newrecordcomp show").addClass("newrecordcomp hide");
      $(".newrecordcomp").css("display","none");
      $(".existingrecordcomp").css("display","block");
      $("#topicname").attr("disabled", "disabled");
      $('.item-searchsort select.Select-input.FormControl').val("ExistingRecord");
      $(".searchkeyword").show();
      $(".trackProductexist .cc_buyNowBtn_alert").addClass("button button-sm btn-danger cc_buyNowBtn_alert existing");
      this.existinglist = result.data.attributes;
      m.lazyRedraw();
    }else{
      $("#dataerror").html("");
      $('#topicname').removeAttr("disabled");
      $(".trackProductnew").removeClass("trackProductnew show").addClass("trackProductnew hide");
      $(".trackProductexist").removeClass("trackProductexist show").addClass("trackProductexist hide");
      $(".searchkeyword").removeClass("searchkeyword show").addClass("searchkeyword hide");
      $(".existingrecordcomp").removeClass("existingrecordcomp show").addClass("existingrecordcomp hide");
    }
  }

  editdetails(data){
    var fired_button = [];
    if(data.target.previousElementSibling.id == "editbuttonDD"){
      fired_button = $("#editbuttonDD").val();
    }else{
      fired_button = $("#editbuttonPD").val();
    }
    this.editdata = JSON.parse(fired_button);
    $(".newrecordcomp").show();
    $('.item-searchsort select.Select-input.FormControl').val("ExistingRecord");
    if(data.target.previousElementSibling.id == "editbuttonDD"){
      $('.item-alertsort select.Select-input.FormControl').val("Discount").change();
      $(".trackProductexist #userinputdataPer").removeClass("userinputdataPer hide").addClass("userinputdataPer show");
      $(".trackProductnew #userinputdataPer").removeClass("userinputdataPer show").addClass("userinputdataPer hide");
      $(".trackProductexist #userinputdataRs").removeClass("userinputdataRs show").addClass("userinputdataRs hide");
      $(".trackProductnew #userinputdataRs").removeClass("userinputdataRs show").addClass("userinputdataRs hide");
      $(".trackProductexist .userinputdataPer").val(this.editdata.UserInput);
      $(".trackProductexist #searchkeywordinput").val(this.editdata.SearchKey);
      if(this.editdata.DateOption == "endafter"){
        var enddate = this.editdata.Endtime.$date.$numberLong;
        var updateddate = this.editdata.updated_on.$date.$numberLong;
        var timeDiff = enddate- updateddate;
        var DaysDiff = Math.round(timeDiff / (1000 * 3600 * 24));
        $(".trackProductexist input:radio[name=date][value=endafter]").attr('checked','checked');
        $(".trackProductexist .Endafterdate").val(DaysDiff);
      }else if(this.editdata.DateOption == "endby"){
        $(".trackProductexist input:radio[name=date][value=endby]").attr('checked','checked');
        var saveddate = new Date(parseInt(this.editdata.Endtime.$date.$numberLong));
        var twoDigitMonth=((saveddate.getMonth()+1)>=10)? (saveddate.getMonth()+1) : '0' + (saveddate.getMonth()+1);
        var selecteddate = saveddate.getFullYear()+"/"+twoDigitMonth+"/"+saveddate.getDate()+" "+saveddate.getHours()+":"+saveddate.getMinutes()
        $(".trackProductexist .dealInput").val(selecteddate);
      }else{
        $(".trackProductexist input:radio[name=date][value=noendtime]").attr('checked','checked');
      }
    }else{
      $('.item-alertsort select.Select-input.FormControl').val("PriceRangeInRs").change();
      $(".trackProductexist #userinputdataRs").removeClass("userinputdataRs hide").addClass("userinputdataRs show");
      $(".trackProductnew #userinputdataRs").removeClass("userinputdataRs show").addClass("userinputdataRs hide");
      $(".trackProductexist #userinputdataPer").removeClass("userinputdataPer show").addClass("userinputdataPer hide");
      $(".trackProductnew #userinputdataPer").removeClass("userinputdataPer show").addClass("userinputdataPer hide");
      $(".trackProductexist .userinputdataRs").val(this.editdata.UserInput);
      $(".trackProductexist #searchkeywordinput").val(this.editdata.SearchKey);
      if(this.editdata.DateOption == "endafter"){
        var enddate = this.editdata.Endtime.$date.$numberLong;
        var updateddate = this.editdata.updated_on.$date.$numberLong;
        var timeDiff = enddate- updateddate;
        var DaysDiff = Math.round(timeDiff / (1000 * 3600 * 24));
        $(".trackProductexist input:radio[name=date][value=endafter]").attr('checked','checked');
        $(".trackProductexist .Endafterdate").val(DaysDiff);
      }else if(this.editdata.DateOption == "endby"){
        $(".trackProductexist input:radio[name=date][value=endby]").attr('checked','checked');
        var saveddate = new Date(parseInt(this.editdata.Endtime.$date.$numberLong));
        var twoDigitMonth=((saveddate.getMonth()+1)>=10)? (saveddate.getMonth()+1) : '0' + (saveddate.getMonth()+1);
        var selecteddate = saveddate.getFullYear()+"/"+twoDigitMonth+"/"+saveddate.getDate()+" "+saveddate.getHours()+":"+saveddate.getMinutes()
        $(".trackProductexist .dealInput").val(selecteddate);
      }else{
        $(".trackProductexist input:radio[name=date][value=noendtime]").attr('checked','checked');
      }
    }
    
  }

  alertChanges(AlertData){
    if(AlertData.target.className == "button button-sm btn-danger cc_buyNowBtn_alert existing"){
      var searchkey = $(".trackProductexist #searchkeywordinput").val();
      var Endafterdate = $(".trackProductexist .Endafterdate").val();
      var dealInput = $(".trackProductexist .dealInput").val();
      this.pricedropinputRs = $(".trackProductexist .userinputdataRs").val();
      this.pricedropinputPer = $(".trackProductexist .userinputdataPer").val();
    }else if(AlertData.target.className == "button button-sm btn-danger cc_buyNowBtn_alert newrecord"){
      var searchkey = $(".trackProductexist #searchkeywordinput").val();
      var Endafterdate = $(".trackProductexist .Endafterdate").val();
      var dealInput = $(".trackProductexist .dealInput").val();
      this.pricedropinputRs = $(".trackProductexist .userinputdataRs").val();
      this.pricedropinputPer = $(".trackProductexist .userinputdataPer").val();
    }else{
      var searchkey = $("#searchkeywordinput").val();
      var Endafterdate = $(".Endafterdate").val();
      var dealInput = $(".dealInput").val();
      this.pricedropinputRs = $(".userinputdataRs").val();
      this.pricedropinputPer = $(".userinputdataPer").val();
    }

    $("#dataerror").html("");
    var userId = app.session.user.data.id;
    var WishListName = $("#topicname").val();
    var selectedOption = $("input:radio[name=date]:checked").val();
    //var decimalnumbers = /^[0-9]+(\.)?[0-9]+$/;
    var numbers = /^(\d{1,})+$/;
    var valid = true;
    var Endtime = "";
    var checked_date = document.querySelector('#enddatetime input[name="date"]:checked');
    var pricedropinputPer = this.pricedropinputPer;
    var pricedropinputRs = this.pricedropinputRs;

        if($('.item-alertsort select.Select-input.FormControl').val() == "select"){
          $("#dataerror").html("Please Enter the Price Drop of Selected Type");
           valid = false;
        }

        if(valid == true){    
            if(pricedropinputRs == "" && pricedropinputPer == ""){
              $("#dataerror").html("Please Enter the Price Drop of Selected Type");
              valid = false;
            }else if(pricedropinputRs != "" && pricedropinputPer == ""){
              if(pricedropinputRs.match(numbers)){
                if(pricedropinputRs > 0){
                  valid = true;  
                  $("#dataerror").html("");
                }else{
                  $("#dataerror").html("Please Enter the Price Greater than 0");
                  valid = false;
                }
              }else{
                $("#dataerror").html("Please input numeric characters without decimal(.)");
                valid = false;
              }
            }else if(pricedropinputRs == "" && pricedropinputPer != ""){
              if(pricedropinputPer.match(numbers)){
                if(pricedropinputPer < 100 && pricedropinputPer > 0){
                  valid = true;  
                  $("#dataerror").html("");
                }else{
                  $("#dataerror").html("discount less than 100% & discount greater than 0% ");
                  valid = false;
                }
              }else{
                $("#dataerror").html("Please input numeric characters without decimal(.)");
                valid = false;
              }
             }
           }

           if(valid == true){
            if(searchkey != ""){
              valid = true;
              $("#dataerror").html("");
            }else{
              $("#dataerror").html("Please Enter the Keywords for your search");
                valid = false;
            }
          }

          if(valid == true){
            if(selectedOption =="noendtime"){
              var Endtime = Date.parse(new Date("1/1/2099 00:00:00"));
            }else if(selectedOption =="endafter"){
              if(Endafterdate != "" || Endafterdate.match(numbers)){
                var days = $(".Endafterdate").val();
                var Endtime = (new Date().getTime()+(days*24*60*60*1000));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End After Days only in Numbers");
                valid = false;
              }
            }else if(selectedOption == "endby"){
              if(dealInput != ""){
                var Endtime = Date.parse(new Date(dealInput));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End By Date");
                valid = false;
              }
            }else if(checked_date === null){
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End Time");
                valid = false;
            }

          if(checked_date === null){  //Test if something was checked
          $("#dataerror").html("Please Select the End Time");
            valid = false;
          }
        }
      

      if(valid){
        //if(this.multiselect){
        var AlertData =[];
        var Data = [];
        var tdaCategoryPath = "";
        var pricedropmethod = this.pricedropmethod;
        var pricedropinputRs = this.pricedropinputRs;
        var pricedropinputPer = this.pricedropinputPer;
        var CategoryPath = m.route.param().categories;

          var PathArr=[];
          if(CategoryPath!=null){
              tdaCategoryPath = CategoryPath; 
          }else{
            tdaCategoryPath = "";
          }
          
          var UserInput ="";
          var PriceDrop="";
          if(pricedropmethod == "Discount"){
            PriceDrop = "DD";
            UserInput = pricedropinputPer;
          }
          if(pricedropmethod == "PriceRangeInRs"){
            PriceDrop = "PD";
            UserInput = pricedropinputRs;
          }
    
          Data.push({userId:userId,categorypath:tdaCategoryPath,Endtime:Endtime,WishListName:WishListName,PriceDrop:PriceDrop,UserInput:UserInput,SearchKey:searchkey,DateOption:selectedOption}); 
      //}

      const Api_url = app.forum.attribute('baseUrl') +'/api/userSearch';
      return app.request({
        method: 'GET',
        url: Api_url,
        data: Data, 
        }).then(
          this.parseResults.bind(this),
      ); 

      }
  }

  config(isInitialized) {
      this.datepicker();

  }

  datepicker() {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    if($('.item-searchsort select.Select-input.FormControl').val() == "ExistingRecord"){
      $('.trackProductexist #validFromdatetimepicker').datetimepicker({
        minDate: 0,
         onShow:function( ct ){
            this.setOptions({
              Date:jQuery('#validFromdatetimepicker').val()?jQuery('#validFromdatetimepicker').val():false
            })
            },
      });
    }else{
      $('.trackProductnew #validFromdatetimepicker').datetimepicker({
        minDate: 0,
         onShow:function( ct ){
            this.setOptions({
              Date:jQuery('#validFromdatetimepicker').val()?jQuery('#validFromdatetimepicker').val():false
            })
            },
      });
    }

  }

 
}//end
