import Notification from 'flarum/components/Notification';

export default class DownvotedNotification extends Notification {
    icon() { return 'thumbs-down'; }

    href() { return app.route.post(this.props.notification.subject()); }

    content() { return 'downvote'; }

    excerpt() { return this.props.notification.subject().contentPlain(); }
}
