import Component from "flarum/Component";
import slider from "../slider";
import category from "./category";
import store from "./store";
import expiredFilter from "./expiredFilter";
import brand from "../sideBarNav/brand";

export default class CategoriesNavigation extends Component {
  //used in All Deals for Sidebar categories,price filter,store,expiredFilter
  init() {
    this.searchString = "";

    if (m.route.param("query")) {
      this.searchString = m.route.param("query");
    }
    this.params1 = m.route.param();
    this.storeList = [];
    var tags = app.store.all("tags");
    var myTags = [];
    var i = 0;
    var storesID = "";
    $.each(tags, function (index, value) {
      if (value.data.attributes.slug == "stores") {
        storesID = value.data.id;
      }

      if (value.data.relationships) {
        if (value.data.relationships.parent) {
          if (value.data.relationships.parent.data.id == storesID) {
            myTags[i] = value;
            i++;
          }
        }
      }
    });
    myTags.map((value) => {
      this.storeList.push({ store: value.data.attributes.name });
    });
    if (app.cache.storeList == undefined || app.cache.storeList.length == 0) {
      app.cache.storeList = this.storeList;
    }

    this.brandList = app.cache.BrandList ? app.cache.BrandList.slice(0, 8) : [];
    this.selectedStores = [];
    this.selectedBrands = [];
    this.selectedCategory = "";
    this.selectedresult = [];

    var acc = document.getElementsByClassName("cc_catAccordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function (e) {
        this.classList.toggle("active");
        var cc_accPanel = this.nextElementSibling;
        if (cc_accPanel.style.display === "block") {
          cc_accPanel.style.display = "none";
        } else {
          cc_accPanel.style.display = "block";
        }
        e.stopPropagation();
      });
    }

    //this.categoryList = this.props.categoryData;
    //app.cache.DealsCategories = this.props.categoryData;
  }

  view() {
    let storeList = [];
    if(this.props.storeData != undefined){
    if(this.props.storeData.length > 0){
      this.props.storeData.map((value) => {
        storeList.push({ store: value.key });
        app.cache.storeList= storeList;
      });
    }
    }
    this.categoryList = this.props.categoryData;
    this.props.brandData = app.cache.BrandList;
    this.brandList = app.cache.BrandList ? app.cache.BrandList.slice(0, 8) : [];
    var parameter ="";
    if(this.props.params != undefined){
      parameter = this.props.params;
    }else{
      parameter = this.params1;
    }
    return (
      <ul config={this.config.bind(this)}>
        <li class="item-nav1">
          <div class="ButtonGroup Dropdown dropdown Dropdown--select App-titleControl">
            <button class="Dropdown-toggle Button" data-toggle="dropdown">
              <i class="fa fa-filter">FILTER</i>
              <i class="icon fa fa-sort Button-caret"></i>
            </button>

            {
              <ul className="Dropdown-menu dropdown-menu">
                <li class="cc_FilterHeading">REFINE RESULTS</li>
                <li>
                  {category.component({
                    children: this.categoryList,
                    params: parameter,
                    routeName: this.props.routeName,
                  })}
                </li>
                <li>
                  <div class="cc_catAccordion"> Stores </div>
                  {store.component({
                    children: app.cache.storeList,
                    routeName: this.props.routeName,
                  })}
                </li>
                  <li>
                    <div class="cc_catAccordion"> Brand </div>
                    {brand.component({
                      children: this.brandList,
                      routeName: this.props.routeName,
                    })}
                  </li>
                <li>
                  <div class="cc_menuSlider">
                    <div class="cc_catAccordion"> Price Range </div>
                    {slider.component({
                      routeName: this.props.routeName,
                      min: "minRange",
                      max: "maxRange",
                    })}
                  </div>
                </li>
                <li>
                  <div class="cc_menuSlider">
                    <div class="cc_catAccordion"> Discount Range </div>
                    {slider.component({
                      routeName: this.props.routeName,
                      min: "minDisc",
                      max: "maxDisc",
                    })}
                  </div>
                </li>
                {this.props.routeName != "brand" ? 
                  <li>
                    <div class="cc_catAccordion"> Include? </div>
                    {expiredFilter.component({
                      expiredOffer: "expiredOffer",
                      filterList: this.props.filterList,
                      routeName: this.props.routeName,
                    })}
                  </li>
                : ""}
              </ul>
            }
          </div>
        </li>
      </ul>
    );
  }
}
