import Component from "flarum/Component";
import Placeholder from "flarum/components/Placeholder";
import Button from "flarum/components/Button";
import ProductCard from "../ProductCard";
import CategoriesNav from "../categoriesDeals/CategoriesNavigation";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import Select from "flarum/components/Select";
import DealPlaceHolder from "../DealPlaceHolder";
import ScrollListener from "flarum/utils/ScrollListener";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class BrandProductResults extends Component {
  //used for brand products display
  init() {
    const params = this.props.params;
    const routeName = this.props.routeName;
    this.loading = true;
    this.productList = [];
    this.product_id = m.route.param("product_id");
    this.refresh();
    this.moreResults = false;
    const params1 = m.route.param();
    this.categoryList = [];
    this.brandList = [];
    this.storeList = [];
    this.brandstorecall();
    // bottom load more code // Harshal
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    //  bottom load more code end
  }
  view() {
    let loading;
    let loadingCat;
    let varbrandList = this.brandList;
    let varstoreList = this.storeList;
    let varcategoryList = this.categoryList;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
      loadingCat = DealPlaceHolder.component({ type: "nav" });
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    if (this.productList.length > 0 || this.loading) {
      return (
        <div>
          <nav className="IndexPage-nav sideNav categoryNav">
            {this.loading
              ? loadingCat
              : CategoriesNav.component({ routeName: this.props.routeName,brandData: varbrandList,
                storeData: varstoreList,
                categoryData: varcategoryList, })}
          </nav>

          <div id="IndexPage_data" class="IndexPage-results cc_dealResults">
            <div className="DiscussionList">
              <div id="dealstores" class="cc_productListHeader">
                <span>Sort :</span>
                <div class="cc_productSort">
                  {listItems(this.viewItems().toArray())}
                </div>
              </div>

              <div class="productlist">
                <ul className="DiscussionList-discussions cc_dealList">
                  {this.loading
                    ? loading
                    : this.productList.map((product) => {
                        const ProductData = {
                          title: product._source.Title,
                          product_id: product._id,
                          maxPrice: product._source.MRP,
                          currentPrice: product._source.sellingPrice,
                          productOutOfStock: product._source.productOutOfStock,
                          discount: product._source.discount,
                          url:
                            app.forum.data.attributes.baseUrl +
                            "/blossom?bid=" +
                            product._source.linkid +
                            "&url=" +
                            product._source.productURL +
                            "&src=product",
                          imageUrl_sm: product._source.imageURL,
                          merchantName: product._source.merchantName,
                          slug: product._source.slug,
                          Hotness: product._source.Hotness,
                        };

                        return (
                          <li class="cc_productCard">
                            {ProductCard.component({ ProductData })}
                          </li>
                        );
                      })}
                  <li>
                    {this.productList.length % 20 === 0
                      ? DealPlaceHolder.component({})
                      : ""}
                  </li>
                </ul>
                <div style="clear:both"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const text =
        "It looks like there are currently no products available for this search. ";
      return (
        <div className="DiscussionList">{Placeholder.component({ text })}</div>
      );
    }
  }
  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  loadMore() {
    this.loading = true;
    this.loadResults(this.productList.length).then(
      this.parseResults.bind(this)
    );
  }
  onScroll(pos) {
    if (this.productList.length % 20 === 0 && !this.loading) {
      if (pos >= $(document).height() - $(window).height() - 450) {
        this.loadMore();
      }
    }
  }
  config(isInitialized, context) {
    if (isInitialized) return;
    setTimeout(() => this.scrollListener.start());
    context.onunload = () => {
      this.scrollListener.stop();
    };
  }
  parseResults(results) {
    if (results.result.hits) {
      [].push.apply(this.productList, results.result.hits.hits);
      // if (results.categoryList == undefined) {
      //   app.cache.CategoryList = [];
      // } else {
      //   this.processCategory(
      //     results.categoryList,
      //     results.brandList,
      //     results.storeList,
      //     results.selectedCat
      //   );
      // }
      app.cache.ProductList = this.productList;
      this.loading = false;
      this.moreResults = true;
      m.redraw();
      return results;
    } else {
      results = "";
      this.productList = [];
      this.loading = false;
      this.moreResults = false;
      $(".ph-item").hide();
      m.redraw();
    }
  }

  loadResults(perpage) {
    // const preloadedDiscussions = app.preloadedDocument();
    // if (preloadedDiscussions) {
    //   return m.deferred().resolve(preloadedDiscussions).promise;
    // }
    const params = this.requestParams(perpage ? perpage + 1 : 0);

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    const Api_url = ApiEndpoint;
    return $.ajax({
      type: "GET",
      context: this,
      //  async: false,
      data: params,
      url: Api_url,
      error: { 404: function () {} },
    });
  }
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.productList = [];
    }

    return this.loadResults().then(
      (results) => {
        this.parseResults(results);
      },
      () => {
        this.loading = false;
      }
    );
  }
  requestParams(offset) {
    const params = this.props.params;
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }

  // processCategory(categories, brandList, storeList, selectedCat) {
  //   const params = this.props.params;
  //   this.categoriesList = categories;
  //   this.brandList = brandList;
  //   this.storeList = storeList;
  //   params.categories = selectedCat ? selectedCat : "";
  //   if (selectedCat != "" && params.src != "cat") {
  //     params.allcat = 1;
  //   } else {
  //     params.allcat = 0;
  //   }
  //   if (this.categoriesList.length > 0 || selectedCat != "") {
  //     this.categoriesList = this.categoriesList
  //       ? this.categoriesList
  //       : selectedCat;
  //     if (
  //       m.route.param("stores") == undefined &&
  //       m.route.param("brands") == undefined &&
  //       (m.route.param("categories") == undefined ||
  //         m.route.param("categories") == "")
  //     ) {
  //       sessionStorage.setItem("brand", JSON.stringify(this.brandList));
  //       sessionStorage.setItem("store", JSON.stringify(this.storeList));
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //       app.cache.CategoryList = this.categoriesList;
  //     } else if (
  //       m.route.param("categories") &&
  //       m.route.param("stores") == undefined &&
  //       m.route.param("brands") == undefined
  //     ) {
  //       app.cache.CategoryList = this.categoriesList;
  //       sessionStorage.setItem("brand", JSON.stringify(this.brandList));
  //       sessionStorage.setItem("store", JSON.stringify(this.storeList));
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //     } else if (
  //       m.route.param("brands") &&
  //       m.route.param("categories") == undefined &&
  //       m.route.param("stores") == undefined
  //     ) {
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //       app.cache.CategoryList = this.categoriesList;
  //       sessionStorage.setItem("store", JSON.stringify(this.storeList));
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //     } else if (
  //       m.route.param("stores") &&
  //       m.route.param("categories") == undefined &&
  //       m.route.param("brands") == undefined
  //     ) {
  //       app.cache.CategoryList = this.categoriesList;
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //       sessionStorage.setItem("brand", JSON.stringify(this.brandList));
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //     } else if (
  //       m.route.param("categories") &&
  //       m.route.param("brands") &&
  //       m.route.param("stores") == undefined
  //     ) {
  //       app.cache.CategoryList = this.categoriesList;
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //       sessionStorage.setItem("store", JSON.stringify(this.storeList));
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //     } else if (
  //       m.route.param("categories") &&
  //       m.route.param("stores") &&
  //       m.route.param("brands") == undefined
  //     ) {
  //       app.cache.CategoryList = this.categoriesList;
  //       sessionStorage.setItem("brand", JSON.stringify(this.brandList));
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //     } else if (
  //       m.route.param("stores") &&
  //       m.route.param("brands") &&
  //       m.route.param("categories") == undefined
  //     ) {
  //       app.cache.CategoryList = this.categoriesList;
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //     } else {
  //       app.cache.CategoryList = this.categoriesList;
  //       app.cache.storeList = JSON.parse(sessionStorage.getItem("store"));
  //       app.cache.BrandList = JSON.parse(sessionStorage.getItem("brand"));
  //     }
  //   } else {
  //     app.cache.CategoryList = [];
  //     app.cache.storeList = this.storeList;
  //     app.cache.BrandList = this.brandList;
  //   }
  //   app.cache.storeList = this.storeList;
  //   app.cache.BrandList = this.brandList;
  //   app.cache.CategoryList = this.categoriesList;
  // }
  viewItems() {
    const items = new ItemList();
    const sortMap = {
      relevance: "Relevance",
      salesAsc: "Popularity",
      priceAsc: " Price Low-To-High",
      priceDesc: "Price High-To-Low",
      discDesc: "Discount High To Low",
    };
    const sortOptions = {
      relevance: "Relevance",
      salesAsc: "Popularity",
      priceAsc: "Price Low-To-High",
      priceDesc: "Price High-To-Low",
      discDesc: "Discount High To Low",
    };
    items.add(
      "sort",
      Select.component({
        options: sortOptions,
        value: m.route.param().sort || Object.keys(sortMap)[0],
        onchange: this.changeSort.bind(this),
      })
    );

    return items;
  }
  changeSort(sort) {
    if (sort !== "") {
      var sort = sort;
      m.route(app.route(this.props.routeName, this.getParams("sort", sort)));
    }
  }
  getParams(paramName, value) {
    let paramsURL = Object.assign({}, m.route.param());
    paramsURL[paramName] = value;
    return paramsURL;
  }
  failure(response) {
    $(".IndexPage-results").html("We could not find specific product!");
  }

  //Brand & category & store Call
  brandstorecall() {
    let Api_url;
    const params = m.route.param();
    if(params.categories == 'category'){
    delete params.categories;
    }
    // Api_url = 'https://search.thedealapp.in/search';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    Api_url = ApiEndpoint + "/getFilter";
    return app
      .request({
        method: "GET",
        context: this,
        url: Api_url,
        data: params,
      })
      .then(this.brandResults.bind(this));
  }

  brandResults(results) {
    if(this.categoryList != "undefined" && this.brandList != "undefined" && this.storeList != "undefined"){
    [].push.apply(this.brandList, results.brandList);
    [].push.apply(this.storeList, results.storeList);
    [].push.apply(this.categoryList,results.categoryList);
    app.cache.BrandList = this.brandList;
    app.cache.storeList = this.storeList;
    app.cache.DealsCategories = this.categoryList;
    m.redraw();
    }
  }

} //end
