import Component from 'flarum/Component';
import avatar from 'flarum/helpers/avatar';
import humanTime from 'flarum/utils/humanTime';
import ItemList from 'flarum/utils/ItemList';
import abbreviateNumber from 'flarum/utils/abbreviateNumber';
import TerminalPost from 'flarum/components/TerminalPost';
import SubtreeRetainer from 'flarum/utils/SubtreeRetainer';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import slidable from 'flarum/utils/slidable';
import extractText from 'flarum/utils/extractText';
import classList from 'flarum/utils/classList';
import CouponExpiry from './../coupon/CouponExpiry';
import Button from 'flarum/components/Button';
import CouponModal from './../coupon/CouponModal';
import tagsLabel from 'flarum/tags/helpers/tagsLabel';

export default class CouponDiscussionListItem extends Component {
  //Used in Coupons for display Particular Coupons
  init() {
    /**
     * Set up a subtree retainer so that the discussion will not be redrawn unless new data comes in.
     * @type {SubtreeRetainer}
     */
    this.subtree = new SubtreeRetainer(
      () => this.props.discussion.freshness,
      () => {
        const time = app.session.user && app.session.user.readTime();
        return time && time.getTime();
      },
      () => this.active()
    );
  }

  attrs() {
    return {
      className: classList([
        'DiscussionListItem',
        this.active() ? 'active' : '',
        this.props.discussion.isHidden() ? 'DiscussionListItem--hidden' : ''
      ])
    };
  }

  view() {
    const retain = this.subtree.retain();
    if (retain) return retain;
    const discussion = this.props.discussion;
    const startUser = discussion.startUser();
    const isUnread = discussion.isUnread();
    const isRead = discussion.isRead();
    const showUnread = !this.showRepliesCount() && isUnread;
    const jumpTo = Math.min(discussion.lastPostNumber(), (discussion.readNumber() || 0) + 1);
    const relevantPosts = this.props.params.q ? discussion.relevantPosts() : [];
    const controls = DiscussionControls.controls(discussion, this).toArray();
    const attrs = this.attrs();

    const DiscussionData = {
      title: discussion.data.attributes.title,
      maxPrice: discussion.data.attributes.maxPrice,
      currentPrice: discussion.data.attributes.currentPrice,
      discount: discussion.data.attributes.discount,
      currency: discussion.data.attributes.currency,
      url: discussion.data.attributes.url,
      productName: discussion.data.attributes.productName,
      isFeatured: discussion.data.attributes.isFeatured,
      isHotDeal: discussion.data.attributes.isHotDeal,
      isExpired: discussion.data.attributes.isExpired,
      startTime: discussion.data.attributes.startTime,
      imageUrl_sm: discussion.data.attributes.imageUrl_sm,
      validFrom: discussion.data.attributes.validFrom,
      validTo: discussion.data.attributes.validTo,
      validFor: discussion.data.attributes.validFor,
      badgeText: discussion.data.attributes.badgeText,
      startUser: discussion.startUser(),
      id: discussion.id(),
      couponcode: discussion.data.attributes.couponcode,
    };
    var tags = this.props.discussion.tags();
    if (tags)
      tags = this.props.discussion.tags().filter(model => model['parent']() === app.store.getBy('tags', 'slug', 'stores'));
    else
      tags = this.props.discussion.tags();
    return (
      <div {...attrs}>
        <div class="cc_couponParentBlock">
          <div class="cc_couponLeftBlock">
            <div class="cc_couponImageTextBox">
              <div style="display: table; height: 100%; width: 100%; text-align: center;">
                <span style="display: table-cell; vertical-align: middle; max-width: 85px; word-wrap: break-word;">
                  {discussion.data.attributes.badgeText}
                </span>
              </div>
            </div>
            <div style="padding-top: 7%;">
              {tags ? tagsLabel(tags, {}) : ''}
            </div>
          </div>
          <a href={app.route.discussion(discussion, jumpTo)} config={m.route}>
            <div class="cc_couponRightBlock">
              <div class="cc_couponTopLeft">
                {discussion.data.attributes.title}
                <br />
              </div>
              <div class="cc_couponTopRight">
                {
                  Button.component(
                    {
                      id: 'btn'+discussion.data.id,
                      className: 'Button Button--primary myDiscussionCouponBtn',
                      children: 'Get Coupon',
                      onclick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        app.modal.show(
                          new CouponModal({ discussion, DiscussionData })
                        )
                      }
                    })
                }
                <div class="cc_couponExpiryText">
                  {
                    (Date.parse(discussion.data.attributes.validTo) || 0) != "0" ? CouponExpiry(discussion.data.attributes.validTo) != "Expired" ? <span>{"Expiring " + CouponExpiry(discussion.data.attributes.validTo)} </span> : <span>{"Expired"} </span>
                      : ""
                  }
                </div>
              </div>
              <div class="cc_couponTopBottomLeft" style="font-size: inherit;">
                <div class="cc_inlineBlock" style="margin-right: 10px;">
                  <a href={startUser ? app.route.user(startUser) : '#'}  title={extractText(app.translator.trans('core.forum.discussion_list.started_text', { user: startUser, ago: humanTime(discussion.startTime()) }))}
                    config={function (element) {
                      $(element).tooltip({ placement: 'right' });
                      m.route.apply(this, arguments);
                    }}>
                    {avatar(startUser, { title: '' })}
                  </a>
                </div>
                <div class="cc_inlineBlock">
                  { TerminalPost.component({
                      discussion: this.props.discussion,
                      lastPost: !this.showStartPost()
                    })}
                </div>
                <div class="DiscussionListItem-count cc_inlineBlock" style="left: 320px !important; top: 0px !important; font-weight: bold;">
                    {abbreviateNumber(discussion[showUnread ? 'unreadCount' : 'repliesCount']())}
                </div>
              </div>
              <div style="clear:both"></div>
              <hr />
              <div class="cc_couponBottomFull" id={discussion.id}>
                {m("div", { class: "cc_couponDescriptionBlock" }, [
                  m.trust(discussion.data.attributes.productInfo)
                ])
                }
                <div class="cc_couponBtnBar">
                  {
                    (discussion.data.attributes.productInfo).length > 100 ?
                      Button.component({
                        id: 'btnReadMore',
                        className: 'button',
                        style: 'border: 0px; background: none; color: #00B; text-transform: none !important;',
                        children: 'Read more',
                        onclick: (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if ($(e.currentTarget).text() == "Read more") {
                            $(e.currentTarget).parent().parent().find(".cc_couponDescriptionBlock").css({
                              'height': 'auto'
                            })
                            $(e.currentTarget).text("Read less");
                          }
                          else {
                            $(e.currentTarget).parent().parent().find(".cc_couponDescriptionBlock").removeAttr('style');
                            $(e.currentTarget).text("Read more");
                          }
                        }
                      })
                      : ''
                  }
                </div>
              </div>
            </div>
            <div style="clear:both"></div>
          </a>
        </div></div>
    );
  }

  config(isInitialized) {
    if (isInitialized) return;

    // If we're on a touch device, set up the discussion row to be slidable.
    // This allows the user to drag the row to either side of the screen to
    // reveal controls.
    if ('ontouchstart' in window) {
      const slidableInstance = slidable(this.$().addClass('Slidable'));

      this.$('.DiscussionListItem-controls')
        .on('hidden.bs.dropdown', () => slidableInstance.reset());
    }
  }

  /**
   * Determine whether or not the discussion is currently being viewed.
   *
   * @return {Boolean}
   */
  active() {
    const idParam = m.route.param('id');
    return idParam && idParam.split('-')[0] === this.props.discussion.id();
  }

  /**
   * Determine whether or not information about who started the discussion
   * should be displayed instead of information about the most recent reply to
   * the discussion.
   *
   * @return {Boolean}
   */
  showStartPost() {
    return ['newest', 'oldest'].indexOf(this.props.params.sort) !== -1;
  }

  /**
   * Determine whether or not the number of replies should be shown instead of
   * the number of unread posts.
   *
   * @return {Boolean}
   */
  showRepliesCount() {
    return this.props.params.sort === 'replies';
  }

  /**
   * Mark the discussion as read.
   */
  markAsRead() {
    const discussion = this.props.discussion;

    if (discussion.isUnread()) {
      discussion.save({ readNumber: discussion.lastPostNumber() });
      m.redraw();
    }
  }

  /**
   * Build an item list of info for a discussion listing. By default this is
   * just the first/last post indicator.
   *
   * @return {ItemList}
   */
  infoItems() {
    const items = new ItemList();
    items.add('terminalPost',
      TerminalPost.component({
        discussion: this.props.discussion,
        lastPost: !this.showStartPost()
      })
    );

    return items;
  }
}