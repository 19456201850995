import { extend } from "flarum/extend";
import Button from "flarum/components/Button";
import DiscussionHero from "flarum/components/DiscussionHero";
import CouponExpiry from "../storePages/CouponExpiry";
import tagsLabel from "flarum/tags/helpers/tagsLabel";
//import vote_bar from "tda/discussionvotes/components/vote_bar";
import PriceGraphModal from "../PriceGraphModal";
import IsOutOfStockModal from "../IsOutOfStockModal";
import CompareProduct from "../CompareProduct";
import GetTDAProductID from "../GetTDAProductID";
import humanTime from "flarum/utils/humanTime";
import Badge from "flarum/components/Badge";

export default function () {
  //used for single deal display https://thedealapp.in/d/78362-swarn-solid-tie-pack-of-2-91

  let validTo_Expired = false;
  extend(DiscussionHero.prototype, "init", function (vdoms) {
    var storeName = "";
    app.cache.compareprice = [];
    var imagePath = "";
    const discussion = this.props.discussion;
    var tags = discussion.tags();
    var storeName = "";
    var productId = discussion.data.attributes.productID;
    tags.forEach(function (element) {
      if (element.data.attributes.parentID === 4) {
        storeName = element.data.attributes.name;
        imagePath = element.data.attributes.imagePath;
      }
    });

    function convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
      return newDate;
    }

    this.discussionID = discussion.data.id;
    if (this.refreshDate === undefined) {
      this.refreshDate = discussion.data.attributes.refreshDate;
    }
    var testdate = this.refreshDate;
    var d = new Date(this.refreshDate);
    var refreshDate = convertUTCDateToLocalDate(d);
    var twoDigitMonth =
      refreshDate.getMonth() + 1 >= 10
        ? refreshDate.getMonth() + 1
        : "0" + (refreshDate.getMonth() + 1);
    var twoDigitDate =
      refreshDate.getDate() >= 10
        ? refreshDate.getDate()
        : "0" + refreshDate.getDate();
    var createdDateTo =
      refreshDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
    var oldDatetime =
      createdDateTo +
      " " +
      refreshDate.getHours() +
      ":" +
      refreshDate.getMinutes() +
      ":" +
      refreshDate.getSeconds();
    var sdd = new Date();
    var utc = sdd.getTime() + sdd.getTimezoneOffset() * 60000;
    var currentDate = new Date(utc + 3600000 * +5.5);
    var twoDigitMonth =
      currentDate.getMonth() + 1 >= 10
        ? currentDate.getMonth() + 1
        : "0" + (currentDate.getMonth() + 1);
    var twoDigitDate =
      currentDate.getDate() >= 10
        ? currentDate.getDate()
        : "0" + currentDate.getDate();
    var createdDateTo =
      currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
    var currentDatetime =
      createdDateTo +
      " " +
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds();
    const currDate = new Date(currentDatetime);
    const oldDate = new Date(oldDatetime);
    let discussionID = discussion.data.id;
    let Getproductdetailsdata = new GetTDAProductID();
    let Product_ID = Getproductdetailsdata.getProductid(storeName, productId);
    let isoutofstock = "";
    if (
      discussion.data.attributes.discussionType != "c" &&
      discussion.data.attributes.isOffer == false
    ) {
      getProductDetails(Product_ID, discussionID);
    }

    function getProductDetails(Product_ID, discussionID) {
      const Api_url =
        app.forum.data.attributes.baseUrl + "/api/updateDealdetails";
      app
        .request({
          method: "GET",
          dataType: "json",
          data: { product_id: Product_ID, pid: discussionID, enc: 1 },
          url: Api_url,
        })
        .then(processDetails.bind(this));
    }

    function processDetails(resultdata) {
      if (resultdata.data != "") {
        if (
          resultdata.data[0].id != "undefined" &&
          resultdata.data[0].id != ""
        ) {
          isoutofstock = resultdata.data[0].id;
          if (discussion.data.attributes.isOffer != true) {
            if (testdate != "0000-00-00 00:00:00") {
              if ((currDate - oldDate) / 60000 > 10) {
                testdate = currDate;
                if (isoutofstock == "deal expired") {
                  discussion.data.attributes.isExpired = true;
                  app.modal.show(
                    new IsOutOfStockModal({ data1: isoutofstock })
                  );
                }
              }
            } else {
              testdate = currDate;
              if (isoutofstock == "deal expired") {
                discussion.data.attributes.isExpired = true;
                app.modal.show(new IsOutOfStockModal({ data1: isoutofstock }));
              }
            }
          }
        }
      }
    }
  }); //end of extend(DiscussionHero.prototype, 'init', function (vdoms)

  extend(DiscussionHero.prototype, "items", function (items) {
    function setCounDown(setDate, setID, startText, endText, textColor) {
      $("#" + setID).show();
      $("#" + setID).css("color", textColor);
      $("#" + setID)
        .countdown(setDate)
        .on("update.countdown", function (event) {
          var format = "%H:%M:%S";
          if (event.offset.totalDays > 0) {
            format = "%-d day%!d " + format;
          }
          if (event.offset.weeks > 0) {
            format = "%-w week%!w " + format;
          }
          $(this).html(startText + event.strftime(format));
        })
        .on("finish.countdown", function (event) {
          $(this).html(endText).parent().addClass("disabled");
          $(this).css("color", textColor);
        });
    }

    function checkFutureTime(myTime) {
      var checkTime = new Date(myTime).getTime();
      var now = new Date().getTime();
      return checkTime > now;
    }

    function convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
      return newDate;
    }

    this.noImageFound =
      app.forum.data.attributes.baseUrl + "/assets/images/no_image_found.png";
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/deal_expired.png";
    validTo_Expired = false;
    const discussion = this.props.discussion;
    this.discussionID = discussion.data.id;
    var ID = discussion.data.id;
    var endDateForStamp = discussion.data.attributes.validTo;
    if (endDateForStamp != "0000-00-00 00:00:00") {
      if (CouponExpiry(discussion.data.attributes.validTo) == "past") {
        validTo_Expired = true;
      }
    }
    if (discussion.data.attributes.isExpired == true) {
      validTo_Expired = true;
    }

    this.superDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_superDeal.png";
    this.CouponDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_coupon.png";
    this.dealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_deal.png";
    this.featuredDealRibbon =
      app.forum.data.attributes.baseUrl +
      "/assets/images/ribbon_featuredDeal.png";
    this.ExpiredDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_expired.png";
    var tags = discussion.tags();
    var storeName = "";
    var imagePath = "";
    var productId = discussion.data.attributes.productID;
    tags.forEach(function (element) {
      if (element.data.attributes.parentID === 4) {
        storeName = element.data.attributes.name;
        imagePath = element.data.attributes.imagePath;
      }
    });
    var pid = discussion.data.attributes.productID;
    if (discussion.data.attributes.discussionType == "d") {
      if (pid) {
        var Getproductdetailsdata = new GetTDAProductID();
        let P_ID = Getproductdetailsdata.getProductid(storeName, pid);
        if (P_ID != "") {
          if (discussion.data.attributes.isOffer != true) {
            if (app.cache.compareprice.length == 0) {
              this.parameter = {
                PID: P_ID,
                mrp: discussion.data.attributes.maxPrice,
                e: true,
              };
              app.cache.compareprice = new CompareProduct({
                params: this.parameter,
                routeName: this.props.routeNamee,
              });
            }
          }
        }
      }
    } //end of discussion.data.attributes.discussionType
    var ribbon = "";
    var blockColour = "";
    var FromStartText = "Starts in: ";
    var FromEndText = "This deal has started!";
    var ToStartText = "Ends in: ";
    var ToEndText = "This deal has expired!";
    var startsIn = discussion.data.attributes.validFrom;
    var endsIn = discussion.data.attributes.validTo;

    if (
      startsIn != "0000-00-00 00:00:00" &&
      checkFutureTime(startsIn) &&
      startsIn
    ) {
      setCounDown(
        startsIn,
        this.discussionID,
        FromStartText,
        FromEndText,
        "#00B504"
      );
    } else if (endsIn != "0000-00-00 00:00:00" && endsIn) {
      setCounDown(endsIn, this.discussionID, ToStartText, ToEndText, "#C20000");
    }
    var votes_discussion = discussion.data.attributes.votes_discussion;

    if (discussion.data.attributes.isExpired || validTo_Expired == true) {
      ribbon = this.ExpiredDealRibbon;
      blockColour = "#555555";
    } else {
      if (discussion.data.attributes.discussionType == "d") {
        if (discussion.data.attributes.isFeatured) {
          ribbon = this.featuredDealRibbon;
          blockColour = "#F16E00";
        } else if (discussion.data.attributes.isHotDeal) {
          ribbon = this.superDealRibbon;
          blockColour = "#CC2200";
        } else {
          ribbon = this.dealRibbon;
          blockColour = "#1339C3";
        }
      } else if (discussion.data.attributes.discussionType == "c") {
        if (discussion.data.attributes.isFeatured) {
          ribbon = this.featuredDealRibbon;
          blockColour = "#F16E00";
        } else if (discussion.data.attributes.isHotDeal) {
          ribbon = this.superDealRibbon;
          blockColour = "#CC2200";
        } else {
          ribbon = this.CouponDealRibbon;
          blockColour = "#B206AB";
        }
      }
    }

    if (discussion.data.attributes.isOffer == false) {
      if (discussion.data.attributes.discussionType == "d") {
        let refDate = discussion.data.attributes.refreshDate;
        let refDateObj = new Date();
        if (refDate !== undefined) {
          if (refDate !== "") {
            refDateObj = new Date(refDate + " UTC");
          }
        }

        this.secondarytag = discussion.data.attributes.secondarytag;
        items.add(
          "discussionDealDetails1",
          <div
            class="cc_custDetailsHeader"
            itemscope
            itemtype="http://schema.org/Product"
          >
            <span itemprop="name" content={discussion.data.attributes.title} />
            <div class="cc_ribbonImagePD">
              <img src={ribbon} width="120" alt="discussion type" />
            </div>
            <div class="cc_custDetailsHeaderLeftBox">
              <div class="cc_discussionHeaderImage">
                <div class="cc_imageFrame">
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={discussion.data.attributes.imageUrl_sm}
                    alt="Product Image"
                    onError={"this.src='" + imagePath + "'"}
                  />
                  {!validTo_Expired == true ? (
                    <div class="cc_secondarytag">
                      {this.secondarytag != "" ? (
                        <div>{this.secondarytag}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div class="cc_productDetailExpiredStamp">
                      <img
                        className="sm_img lazy"
                        width="104px"
                        src={this.expiredStamp}
                        alt="expired Stamp"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="cc_custDetailsHeaderRightBox">
              {discussion.data.attributes.badgeText != "" ? (
                <div class="cc_blockBadgeTextMini">
                  {discussion.data.attributes.badgeText ? (
                    <span>{discussion.data.attributes.badgeText}</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div class="cc_productDetailTag" style="padding-top:10px;">
                {tagsLabel(discussion.tags(), { link: true })}
              </div>
              <div
                class="cc_custH2"
                itemprop="offers"
                itemscope
                itemtype="http://schema.org/Offer"
              >
                <h2 class="cc_productDetailTitle" itemprop="name">
                  {discussion.data.attributes.title}
                </h2>
                <span
                  itemprop="availability"
                  href="http://schema.org/InStock"
                />
                <span
                  itemprop="offeredBy"
                  itemscope
                  itemtype="http://schema.org/Organization"
                >
                  <span itemprop="legalName" content={storeName} />
                </span>
                <div class="cc_blockPriceTextMini" style="margin: 3px 0px;">
                  <span style="text-decoration: line-through; ">
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    {discussion.data.attributes.maxPrice}/-
                  </span>
                  &nbsp;&nbsp;
                  <span
                    class="cc_dealTextColour"
                    style="border-left: 2px solid #999;"
                  >
                    &nbsp;&nbsp;
                    <i itemprop="priceCurrency" content="Rs"></i>
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    {!validTo_Expired == true ? (
                      <span itemprop="price">
                        {discussion.data.attributes.currentPrice}/-
                      </span>
                    ) : (
                      <span
                        itemprop="price"
                        style="text-decoration: line-through; "
                      >
                        {discussion.data.attributes.currentPrice}/-
                      </span>
                    )}
                  </span>
                  <div
                    class="Post-quoteButtonContainer"
                    style="display: inline-block;padding-left: 6px;"
                  >
                    <Badge
                      type="hidden"
                      icon="fa fa-info"
                      label={
                        "Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on " +
                        storeName +
                        " at the time of purchase will apply to the purchase of this product."
                      }
                    />
                  </div>
                  &nbsp;&nbsp;
                  <span style="color: #222 !important;font-size: 11px;">
                    {discussion.data.attributes.refreshDate
                      ? "(as of " +
                        refDateObj
                          .toString()
                          .replace("GMT+0530 (India Standard Time)", "") +
                        " IST)"
                      : ""}
                  </span>
                </div>
                <div class="cc_expiryText">
                  {!validTo_Expired == true ? (
                    <div class="countdown" id={this.discussionID}></div>
                  ) : (
                    ""
                  )}
                  {discussion.data.attributes.discussionType == "d" &&
                  validTo_Expired == true ? (
                    <div style="display:inline-block;padding-right: 10px;color: #C20;font-size:15px;">
                      <span>Deal Expired</span>
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              </div>
              {!validTo_Expired == true ? (
                <div
                  class=""
                  style="display: inline-block; margin-right: 10px;"
                >
                  <a
                    href={discussion.data.attributes.url}
                    class="Button Button--primary hasIcon"
                    target="_blank" rel="nofollow"
                  >
                    <i class="icon fa fa-shopping-cart Button-icon"></i> Buy Now
                  </a>
                </div>
              ) : (
                <div
                  class=""
                  style="display: inline-block; margin-right: 5px;"
                >
                  <a
                    href={discussion.data.attributes.url}
                    class="Button hasIcon"
                    target="_blank"
                    style="background-color:#969696;"
                  >
                    <i class="icon fa fa-clock-o Button-icon"></i> Check
                    Availability
                  </a>
                </div>
              )}
              <div style="display: inline-block;">
                {productId == ""
                  ? ""
                  : Button.component(
                      {
                        className: "Button",
                        icon: "line-chart",
                        children: app.translator.trans(""),
                        onclick: function () {
                          app.modal.show(
                            new PriceGraphModal({
                              storeName: storeName,
                              pid: productId,
                              compareproduct: app.cache.compareprice,
                              enc: 1,
                            })
                          );
                        },
                      },
                      "Show Price History"
                    )}
              </div>
              {/*vote_bar.component({ discussion })*/}
              <div style="clear: both;"></div>
            </div>
          </div>
        );
      }
    } else {
      items.add(
        "discussionofferDetails",
        <div
          class="cc_custofferHeader"
          itemscope
          itemtype="http://schema.org/Product"
        >
          <span itemprop="name" content={discussion.data.attributes.title} />
          <a href={discussion.data.attributes.url} target="_blank">
            <div class="cc_custofferHeaderBox" style="width:100% !important;">
              <div class="cc_discussionOfferHeaderImage">
                <div class="cc_bannerFrame">
                  <picture>
                    <source
                      media="(max-width: 639px)"
                      srcset={discussion.data.attributes.imageUrl_sm}
                    ></source>
                    <source
                      media="(min-width: 640px)"
                      srcset={discussion.data.attributes.imageUrl_lg}
                    ></source>
                    <img
                      itemprop="image"
                      src={discussion.data.attributes.imageUrl_lg}
                      alt={discussion.data.attributes.title}
                      onError={"this.src='" + imagePath + "'"}
                    />
                  </picture>

                  {!validTo_Expired == true ? (
                    ""
                  ) : (
                    <div class="cc_productDetailExpiredStamp">
                      <img
                        className="sm_img lazy"
                        width="104px"
                        src={this.expiredStamp}
                        alt="expired Stamp"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div class="offerfilter" style=""></div>
              <div class="offertext" style="">
                <h2 class="" itemprop="name">
                  {discussion.data.attributes.title}
                </h2>
              </div>
            </div>
          </a>
        </div>
      );
    }
  });
  if (document.body.clientWidth < 768) {
    $(".DiscussionHero--colored").css("width", "211px !important");
  }
}
