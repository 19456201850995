import Component from "flarum/Component";
import priceDropSlider from "../slider";
import store from "../sideBarNav/store";
import category from "./priceDropcategory";

export default class SideNavPriceDrop extends Component {
  //used in pricedrop for sidebar
  init() {
    this.params1 = m.route.param();
    this.storeListpricedrop = [];
    this.storeListpricedrop = [
      { store: "flipkart" },
      { store: "myntra" },
      { store: "snapdeal" },
      { store: "paytmmall" },
      { store: "tatacliq" },
      { store: "nnnow" },
      { store: "ajio" },
      { store: "reliancedigital" },
      { store: "nykaa" },
      { store: "pepperfry" },
      { store: "croma" },
      { store: "shoppersstop" },
      { store: "firstcry" },
    ];
    app.cache.storeList = this.storeListpricedrop;
  }
  view() {
    return (
      <ul config={this.config.bind(this)}>
        <li class="item-nav1">
          <div class="ButtonGroup Dropdown dropdown Dropdown--select App-titleControl">
            <button class="Dropdown-toggle Button" data-toggle="dropdown">
              <i class="fa fa-filter"></i> FILTER
              <i class="icon fa fa-sort Button-caret"></i>
            </button>
            <ul className="Dropdown-menu dropdown-menu">
              <li class="cc_FilterHeading">REFINE RESULTS</li>
              <li>
                <div class="cc_menuSlider">
                  <div class="cc_catAccordion"> Price Drop </div>
                  {priceDropSlider.component({
                    routeName: this.props.routeName,
                    min: "minPrice",
                    max: "maxPrice",
                  })}
                </div>
              </li>
              <li>
                <div class="cc_menuSlider">
                  <div class="cc_catAccordion"> Price Drop On Avg Price</div>
                  {priceDropSlider.component({
                    routeName: this.props.routeName,
                    min: "minAvg",
                    max: "maxAvg",
                  })}
                </div>
              </li>
              <li>
                <div class="cc_menuSlider">
                  <div class="cc_catAccordion"> Total Discount </div>
                  {priceDropSlider.component({
                    routeName: this.props.routeName,
                    min: "minDisc",
                    max: "maxDisc",
                  })}
                </div>
              </li>
              <li>
                <div class="cc_menuSlider">
                  <div class="cc_catAccordion"> Price Range</div>
                  {priceDropSlider.component({
                    routeName: this.props.routeName,
                    min: "minRange",
                    max: "maxRange",
                  })}
                </div>
              </li>
              <li>
                {category.component({
                  params: this.params1,
                  routeName: this.props.routeName,
                })}
              </li>
              <li>
                {store.component({
                  children: app.cache.storeList,
                  routeName: this.props.routeName,
                })}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    );
  }
}
