import Component from 'flarum/Component';

export default class expiredFilter extends Component {
  //used in All Deals for expiredFilter Filter sidebar
      init()
      {
        super.init(this.props);
        this.selectedFiltersBy =[];
        this.expiredOffer = this.props.expiredOffer;
        this.routeName = this.props.routeName;
      }
      view()
      {
        const routeName = this.props.routeName;
        this.filterList = [1];
        this.selectedFiltersBy =[];
        return( <div class="cc_filterMenu">
              <div class="cc_accPanel">
                <ul className="" id="categoryList1">
                  {
                    this.filterList.map(data => {
                      return (
                      <li>
                       <label><input class="filterclass cc_allCatsInput" id="expiredFilter" type="checkbox" value="true" name="filter_comp" checked={'expired' in m.route.param()  && m.route.param('expired')=='true'?'checked':''} onchange={this.expiredFilters.bind(this)}/>&nbsp;&nbsp;Include Expired Deals / Out of Stock</label>
                      </li>
                      );
                    })
                  }
                </ul>
              </div>
            </div>
                 
        )
      }
     
      config(element, isInitialized, context)
      {
            if(isInitialized) return;
      }
      
      initdata(element,productpricedata)
      {

      }
      expiredFilters()
      {
         var searchString = '';
         var hash = $("#expiredFilter:checked").map(function() { 
              return this.value;
          }).toArray();
          hash = hash.join(",");
          var mlink = app.route(this.props.routeName,getParams('expired',hash));
          m.route(mlink);
      } 
}
function  getParams(paramName,value)
{ 
  let newParams = m.route.param();
  if(newParams.categories == undefined)
  {
      newParams.categories = "category";
      // delete newParams.categories;
  }else{
    if(newParams.tag && newParams.categories == ""){
        newParams.categories = newParams.tag;
      }
  }
  let paramsURL = Object.assign({},  newParams);
  paramsURL[paramName] = value;
  if(paramsURL.expired!='true') {
    delete  paramsURL.expired;
  }
  delete  paramsURL.cr;
  return paramsURL;
}