import Component from "flarum/Component";
import Button from "flarum/components/Button";
import SlickSlider from "../SlickmainSlider";
import ProductCard from "../ProductCard";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import Select from "flarum/components/Select";
import DealPlaceHolder from "../DealPlaceHolder";

export default class productDisplay extends Component {
  //used on homepage for recent pricedrops slider
  init() {
    this.loading = true;
    this.dealData = [];
    this.productList = [];
    this.refresh();
    this.moreResults = false;
    this.color = "#e88b00";
    this.headingIcon = "fa fa-arrow-down fa-1x";
    this.headingUnderline = false;
  }
  view() {
    const params = this.props.params;
    this.data = this.props.params;
    this.pricedropCategoryName = m.route.param("categories")
      ? m.route.param("categories").split(">")
      : "";
    var pricedropCategoryName = this.pricedropCategoryName[
      this.pricedropCategoryName.length - 1
    ];
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    if (this.productList.length === 0 && !this.loading) {
      const text =
        "It looks like there are currently no Products available for this search. ";
      return <div></div>;
    } else {
      const deals = this.productList;
      return (
        <div id="productdisplay">
          <div
            class="sliderHeading"
            style={
              "color:" +
              this.color +
              (this.headingUnderline == true
                ? ";border-bottom:1px solid " + this.color
                : "")
            }
          >
            {!params["stores"] ? (
              <h2 class="sliderTitle">
                <i class={this.headingIcon} aria-hidden="true"></i>
                {this.pricedropCategoryName
                  ? "Recent Price Drops In " + pricedropCategoryName
                  : "Recent Price Drops"}
              </h2>
            ) : (
              <h2 class="sliderTitle">
                <i class={this.headingIcon} aria-hidden="true"></i>Recent Price
                Drops In {params["heading"]}
              </h2>
            )}
          </div>
          <div class="cc_viewwall">
            <div id="viewall">
              <a
                class="Button Button--label"
                href={app.route(
                  "pricedrop",
                  this.getParams("pricedropsort", this.data)
                )}
                config={m.route}
              >
                {" "}
                View All
              </a>
            </div>
            <div id="pricedrop">
              <div class="cc_productSort">
                {listItems(this.viewItems().toArray())}
              </div>
            </div>
            <div style="clear: both;"></div>
          </div>

          {SlickSlider.component({
            children: this.loading ? loading : this.dealData,
            slidesToShowDesktop: 4,
          })}
        </div>
      );
    }
  }
  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  requestParams() {
    return this.props.params;
  }
  loadResults() {
    const params = this.requestParams();
    if (params.query) {
      params.src = "search";
      delete params.heading;
    } else if (params.home) {
      params.src = "search";
      delete params.heading;
    } else if (params.stores) {
      params.src = "search";
      delete params.tags;
      delete params.slidesToScrollDesktop;
      delete params.slidesToShowDesktop;
    } else {
      if (params.categories == undefined) {
        var hostnameTag = app.store.getBy("tags", "slug", params.category);
        var nametag = decodeURIComponent(hostnameTag.data.attributes.name);
        params.categories = nametag;
      }
      params.src = "cat";
      delete params.heading;
    }
    delete params.bannerImagePath;
    delete params.q;
    delete params.headingUnderline;
    delete params.color;
    delete params.headingIcon;
    delete params.filter;
    delete params.src;

    const Api_url = app.forum.attribute("baseUrl") + "/api/getProductDisplay";
    return app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.parseResults.bind(this));
  }

  refresh(clear = true) {
    if (clear) {
      this.loading = true;
    }
    this.loadResults();
  }
  parseResults(results) {
    this.parseDiscussionResults(results);
    this.loading = false;
    m.lazyRedraw();
    return results;
  }
  parseDiscussionResults(results) {
    this.productList = [];
    [].push.apply(this.productList, results.data);
    this.loading = false;
    this.dealData = this.productList.map((product) => {
      this.displaypricetracker = product.attributes.DisplayTracker
        ? product.attributes.DisplayTracker.pop()
        : "";
      var ProductData = {
        title: product.attributes.title,
        product_id: product.attributes.id,
        maxPrice: product.attributes.maxPrice,
        currentPrice: product.attributes.currentPrice,
        productOutOfStock: product.attributes.productOutOfStock,
        discount: product.attributes.discount,
        PrevioussellingPrice: this.displaypricetracker.PrevioussellingPrice,
        sellingPrice: this.displaypricetracker.sellingPrice,
        url:
          app.forum.data.attributes.baseUrl +
          "/blossom?bid=" +
          product.attributes.linkid.$oid +
          "&url=" +
          product.attributes.productURL +
          "&src=product",
        imageUrl_sm: product.attributes.imageUrl_sm,
        merchantName: product.attributes.merchantName,
        slug: product.attributes.slug,
        PriceTracker: product.attributes.PriceTracker,
        Hotness: product.attributes.Hotness,
        fulfilledBy: product.attributes.fulfilledBy,
        secondarytag: product.attributes.secondarytag,
        LastUpdatedOn: product.attributes.LastUpdatedOn,
        tid: product.attributes.tid,
      };
      return ProductCard.component({ ProductData });
    });
    return results;
  }
  config(isInitialized) {}
  configSlider(element, isInitialized, context) {
    if (isInitialized && !this.productList) return;
    const sortOptions = { hottest: "Hottest", latest: "Latest" };
  }

  viewItems() {
    const items = new ItemList();
    const sortMap = { hottest: "Hottest", latest: "Latest" };
    const sortOptions = { hottest: "Hottest", latest: "Latest" };
    items.add(
      "sort",
      Select.component({
        options: sortOptions,
        value: this.props.params.pricedropsort || Object.keys(sortMap)[1],
        onchange: this.changeSort.bind(this),
      })
    );

    return items;
  }
  changeSort(pricedropsort) {
    if (pricedropsort !== "") {
      var pricedropsort = pricedropsort;
      this.loading = true;
      const params = this.requestParams();
      params.pricedropsort = pricedropsort;

      const Api_url = app.forum.attribute("baseUrl") + "/api/getProductDisplay";
      return app
        .request({
          method: "GET",
          url: Api_url,
          data: params,
        })
        .then(this.parseResults.bind(this));
    }
  }

  getParams(paramName, value) {
    let paramsURL = Object.assign({}, value);
    paramsURL.src = "pricedrops";
    delete paramsURL.allcat;
    //delete paramsURL.category;
    delete paramsURL.src;
    delete paramsURL.filter;
    delete paramsURL.q;
    delete paramsURL.home;
    delete paramsURL.minihotness;
    delete paramsURL.heading;
    delete paramsURL.headingIcon;
    delete paramsURL.color;
    delete paramsURL.headingUnderline;
    delete paramsURL.slidesToScrollDesktop;
    delete paramsURL.slidesToShowDesktop;
    delete paramsURL.headingName;
    delete paramsURL.bannerImagePath;
    return paramsURL;
  }
}
