import { extend } from "flarum/extend";
import Separator from "flarum/components/Separator";
import LinkButton from "flarum/components/LinkButton";
import TagsPage from "flarum/tags/components/TagsPage";
import sortTags from "flarum/tags/utils/sortTags";
//import CouponIndexPage from '../coupon/CouponIndexPage';
import CouponIndexPage from "../coupon/CouponIndexPagev2";
import TagLinkButton from "../coupon/TagLinkButton";

export default function () {
  // Add a link to the tags page, as well as a list of all the tags,
  // to the index page's sidebar.
  //used in Coupons page for sidebar
  extend(CouponIndexPage.prototype, "navItems", function (items) {
    if (app.current instanceof TagsPage) return;

    items.add("separator", Separator.component(), -10);

    const params = this.stickyParams();
    var tags = app.store.all("tags");

    //pradip lines
    var myTags = [];
    var i = 0;
    var storesID = "";
    var CategoryID = "";
    $.each(tags, function (index, value) {
      if (value.data.attributes.slug == "stores") {
        myTags[i] = value;
        i++;
        storesID = value.data.id;
      }
      if (value.data.attributes.slug == "category") {
        myTags[i] = value;
        i++;
        CategoryID = value.data.id;
      }

      if (value.data.relationships) {
        if (value.data.relationships.parent) {
          if (value.data.relationships.parent.data.id == storesID) {
            myTags[i] = value;
            i++;
          }
          if (value.data.relationships.parent.data.id == CategoryID) {
            myTags[i] = value;
            i++;
          }
        }
      }
    });
    tags = myTags;

    const currentTag = this.currentTag();
    const addTag = (tag) => {
      let active = currentTag === tag;

      if (!active && currentTag) {
        active = currentTag.parent() === tag;
      }
      items.add(
        "tag" + tag.id(),
        TagLinkButton.component({ tag, params, active }),
        -10
      );
    };

    sortTags(tags)
      .filter(
        (tag) =>
          tag.position() !== null &&
          (!tag.isChild() ||
            (currentTag &&
              (tag.parent() === currentTag ||
                tag.parent() === currentTag.parent())))
      )
      .forEach(addTag);

    const more = tags
      .filter((tag) => tag.position() === null)
      .sort((a, b) => b.discussionsCount() - a.discussionsCount());

    more.splice(0, 3).forEach(addTag);

    if (more.length) {
      items.add(
        "moreTags",
        LinkButton.component({
          children: app.translator.trans("flarum-tags.forum.index.more_link"),
          href: app.route("Coupons"),
        }),
        -10
      );
    }
  });
}
