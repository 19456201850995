import Page from "flarum/components/Page";
import LogInModal from "flarum/components/LogInModal";
import CategoryListData from "./categoryHome/CategoryListData";
import OfferDiscussionList from "./OfferDiscussionList";
import DealsListData from "./DealsListData";
import ProductDisplay from "./productdisplay/ProductDisplay";
import ScrollListener from "flarum/utils/ScrollListener";
import LoadingIndicator from "flarum/components/LoadingIndicator";
/**
 * The `myIndexPage` component displays the index page(Home page), including the welcome
 * hero, the sidebar, and the discussion list.
 */
export default class myIndexPage extends Page {
  /**
   * Log the user in and then open the composer for a new discussion.
   *
   * @return {Promise}
   */
  init() {
    super.init();
    this.loading = true;
    this.loadingComplete = false;
    this.firstlevelLoading = true;
    let params = {};
    this.rName = this.props.routeName;
    this.categoryfromtags = [];

    // params.minihotness = 50;
    params.filter = this.getHotDealParams();
    params.heading = "Super Deals";
    params.headingIcon = "fa fa fa-bolt";
    params.color = "#c72026";
    params.headingUnderline = true;
    this.HOT_DealBoxDiscussionList = new DealsListData({ params });

    params.filter = this.getFeaturedDealParams();
    params.heading = "Latest Deals";
    params.headingIcon = "fa fa-clock-o";
    params.color = "#000";
    params.headingUnderline = true;
    this.Resent_DealBoxDiscussionList = new DealsListData({ params });

    if (!app.cache.OfferDiscussionList)
      app.cache.OfferDiscussionList = new OfferDiscussionList({ params });

    this.bodyClass = "App--index";
    this.sortedCategoryTags = [];
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    this.CategoryRender = [];
    this.salestagid = app.store.getBy("tags", "slug", "sales-events");
    this.lastpos = 0;
  }
  onScroll(pos) {
    if (!this.loading) {
      if (pos >= $(document).height() - $(window).height() - 450) {
        if (pos - this.lastpos > 100) {
          this.lastpos = pos;
          this.loadMore();
        }
      }
    }
  }
  loadMore() {
    if (this.sortedCategoryTags.length > 0) {
      this.loading = true;
      let params = {};
      var value = this.sortedCategoryTags[this.sortedCategoryTags.length - 1];
      params.filter = this.getCatDealParams(value.data.attributes.slug);

      if (value.data.attributes.parentID == this.salestagid.data.id) {
        params.tag = value.data.attributes.name;
        params.heading = value.data.attributes.name;
      } else {
        params.tag = "";
        params.heading = value.data.attributes.name;
      }
      params.headingName = value.data.attributes.name;
      params.bannerImagePath = value.data.attributes.bannerImagePath
        ? value.data.attributes.bannerImagePath
        : "";
      params.headingIcon = "";
      params.color = "#000000";
      params.headingUnderline = true;
      params.routename = this.rName;
      this.CategoryRender.push(new CategoryListData({ params }));
      this.sortedCategoryTags.pop();
      this.loading = false;
    } else {
      this.loadingComplete = true;
      //m.redraw.strategy("diff");
      m.redraw();
      this.scrollListener.stop();
    }
  }
  config(isInitialized, context) {
    if (
      this.HOT_DealBoxDiscussionList.loaded &&
      this.Resent_DealBoxDiscussionList.loaded &&
      this.firstlevelLoading
    ) {
      this.firstlevelLoading = false;
      this.loading = false;
      let params = {};
      params.home = "home";
      params.minihotness = 50;
      this.productDisplay = new ProductDisplay({
        params: params,
        routeName: this.rName,
      });
      const categoryTags = app.store.all("tags");
      app.cache.categoryviceHotDeal = null;
      this.sortedCategoryTags = categoryTags.filter(function (itm, i, a) {
        if (itm.data.attributes.showonHomePage == true) {
          return i == a.indexOf(itm);
        }
      });
      this.sortedCategoryTags.sort(function (a, b) {
        return b.data.attributes.displayOrder - a.data.attributes.displayOrder;
      });

      setTimeout(() => this.scrollListener.start());
      context.onunload = () => {
        this.scrollListener.stop();
      };
    }
  }
  view() {
    let loadingComponent = LoadingIndicator.component();
    //this.checkFirstLevelLoading();
    return (
      <div className="IndexPage" style="margin-bottom:40px">
        <div className="IndexPage-results">
          <div class="myDeals">{this.HOT_DealBoxDiscussionList.render()}</div>
          <div class="myDeals" style="background-color:#2d3e50;">
            {app.cache.OfferDiscussionList.render()}
          </div>
          <br></br>
          <div class="myDeals">
            {this.Resent_DealBoxDiscussionList.render()}
          </div>

          <div style="clear:both"></div>

          <div class="myDeals">
            {this.firstlevelLoading == true ? "" : this.productDisplay.render()}
          </div>
          <div style="clear:both"></div>
          {this.firstlevelLoading
            ? ""
            : this.CategoryRender.length > 0
            ? this.CategoryRender.map((value) => {
                return <div class="myDeals">{value.render()}</div>;
              })
            : ""}
        </div>
        {this.loadingComplete ? "" : loadingComponent}
      </div>
    );
  }
  getHotDealParams() {
    const params = { include: ["startUser", "lastUser", "tags"], filter: {} };
    params.filter.q = "is:HotDeal";
    params.include.push(
      "relevantPosts",
      "relevantPosts.discussion",
      "relevantPosts.user"
    );
    return params;
  }
  getFeaturedDealParams() {
    const params = { include: ["startUser", "lastUser", "tags"], filter: {} };
    params.filter.q = "is:featured";
    params.include.push(
      "relevantPosts",
      "relevantPosts.discussion",
      "relevantPosts.user"
    );
    return params;
  }
  getCatDealParams(fparam) {
    const params = { include: ["startUser", "lastUser", "tags"], filter: {} };
    params.filter.q = "is:deal tag:" + fparam;
    params.include.push(
      "relevantPosts",
      "relevantPosts.discussion",
      "relevantPosts.user"
    );
    return params;
  }
}
