import { extend } from "flarum/extend";
import app from "flarum/app";
import Button from "flarum/components/Button";
//import LogInModal from 'flarum/components/LogInModal';
import LogInButtons from "flarum/components/LogInButtons";
import extractText from "flarum/utils/extractText";
import ItemList from "flarum/utils/ItemList";
import HeaderSecondary from "flarum/components/HeaderSecondary";
import myButtons from "./myButtons";
import NewLoginModal from "./modal/NewLogin";
import NotificationsDropdown from "flarum/components/NotificationsDropdown";
import SessionDropdown from "flarum/components/SessionDropdown";

export default function () {
  //app.initializers.add('Flarum/Extension', () => {
  extend(HeaderSecondary.prototype, "items", function (items) {
    if (app.session.user) {
      items.add("notifications", NotificationsDropdown.component(), 10);
      items.add("session", SessionDropdown.component(), 0);
    } else {
      items.add(
        "logIn",

        <div class="">
          <input
            class="Button Button--user Button--flat"
            id="loginpopup"
            type="Button"
            value="LogIn"
          />
        </div>
      );

      <div
        id="addUnit"
        class="modal "
        tabindex="-1"
        role="dialog"
        aria-labelledby="gridModalLabel"
        aria-hidden="true"
        style="display:none;"
        data-backdrop="static"
        data-keyboard="false"
      ></div>;

      $("#loginpopup").click(function (e) {
        app.modal.show(new NewLoginModal({}));
      });
    }
  });
}
