import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function addApproveDealControl() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canApprove()) {
          if (discussion.isApproveDeal() != 1){
                items.add('ApproveDeal', Button.component({
                      children: 'Approve Deal',
                      icon: 'fa fa-check',
                      onclick: this.ApproveDealAction.bind(discussion)
                    }));     
           }else{
                 items.add('ApproveDeal', Button.component({
                      children: 'unApproveDeal',
                      icon: 'fa fa-times',
                      onclick: this.UnApproveDealAction.bind(discussion),                   
                    }));    
          }
          if (discussion.isApproveDeal() != 2){
          items.add('DisApproveDeal', Button.component({
                      children: 'DisApprove Deal',
                      icon: 'fa fa-check',
                      onclick: this.DisApproveDealAction.bind(discussion)
          }));
          }
                    
    }      
  });
  DiscussionControls.DisApproveDealAction = function() {
   
    this.save({isApproveDeal:2}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }

      m.redraw();
    });
  };
  DiscussionControls.ApproveDealAction = function() {
   
    this.save({isApproveDeal: 1}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }

      m.redraw();
    });
  };
  DiscussionControls.UnApproveDealAction = function() {
   
    this.save({isApproveDeal: 0}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }

      m.redraw();
    });
  };

}
