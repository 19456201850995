import Component from "flarum/Component";
import Placeholder from "flarum/components/Placeholder";
import Button from "flarum/components/Button";
import ProductCard from "../ProductCard";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import Select from "flarum/components/Select";
import DealPlaceHolder from "../DealPlaceHolder";
import ScrollListener from "flarum/utils/ScrollListener";

export default class PriceDropProductResults extends Component {
  //Used for Pricedrop products
  init() {
    const params = this.props.params;
    const routeName = this.props.routeName;
    this.loading = true;
    this.productList = [];
    this.refresh();
    this.moreResults = false;
    this.loader = true;
    this.params1 = m.route.param();
    if (!("categories" in this.params1)) {
      this.params1.categories = "";
    }
    //bottom load more code // Harshal
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    //bottom load more code end
  }
  view() {
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    const text =
      "It looks like there are currently no products available for this search. ";
    return (
      <div>
        {this.productList.length > 0 || this.loading ? (
          <div
            id="IndexPage_data"
            class={
              this.params1.deals
                ? "IndexPage-results"
                : "IndexPage-results cc_dealResults"
            }
          >
            {!this.params1.deals ? (
              <div>
                <span>In last:</span>
                <div class="cc_productSort">{this.viewItemsByTime()}</div>
              </div>
            ) : (
              ""
            )}
            {!this.params1.deals ? (
              <div id="pricedropsort" class="cc_productListHeader">
                <div class="cc_productSort">
                  {listItems(this.viewItems().toArray())}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="DiscussionList">
              <div class="productlist">
                <ul className="DiscussionList-discussions cc_dealList">
                  {this.loading
                    ? loading
                    : this.productList.map((product) => {
                        this.displaypricetracker = product.attributes
                          .DisplayTracker
                          ? product.attributes.DisplayTracker[
                              product.attributes.DisplayTracker.length - 1
                            ]
                          : "";
                        var ProductData = {
                          title: product.attributes.title,
                          product_id: product.id,
                          maxPrice: product.attributes.maxPrice,
                          currentPrice: product.attributes.currentPrice,
                          productOutOfStock:
                            product.attributes.productOutOfStock,
                          discount: product.attributes.discount,
                          PrevioussellingPrice: this.displaypricetracker
                            .PrevioussellingPrice,
                          sellingPrice: this.displaypricetracker.sellingPrice,
                          url:
                            app.forum.data.attributes.baseUrl +
                            "/blossom?bid=" +
                            product.attributes.linkid.$oid +
                            "&url=" +
                            product.attributes.productURL +
                            "&src=product",
                          imageUrl_sm: product.attributes.imageUrl_sm,
                          merchantName: product.attributes.merchantName,
                          slug: product.attributes.slug,
                          PriceTracker: product.attributes.PriceTracker,
                          Hotness: product.attributes.Hotness,
                          fulfilledBy: product.attributes.fulfilledBy,
                          secondarytag: product.attributes.secondarytag,
                          LastUpdatedOn: product.attributes.LastUpdatedOn,
                          tid: product.attributes.tid,
                        };
                        return (
                          <li class="cc_productCard">
                            {ProductCard.component({ ProductData })}
                          </li>
                        );
                      })}
                  <li>
                    {this.productList.length > 0 && this.loader
                      ? DealPlaceHolder.component({})
                      : ""}
                  </li>
                </ul>
                <div style="clear:both"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="DiscussionList">
            {Placeholder.component({ text })}
          </div>
        )}
      </div>
    );
  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  loadMore() {
    this.loading = true;
    this.loadResults(this.productList.length);
  }
  onScroll(pos) {
    if (!this.loading) {
      if (pos >= $(document).height() - $(window).height() - 450) {
        this.loadMore();
      }
    }
  }
  config(isInitialized, context) {
    if (isInitialized) return;
    setTimeout(() => this.scrollListener.start());
    context.onunload = () => {
      this.scrollListener.stop();
    };
  }
  parseResults(results) {
    if (results.data.length > 0) {
      [].push.apply(this.productList, results.data);
      this.loading = false;
      this.moreResults = true;
      m.redraw();
      return results;
    } else {
      this.loading = false;
      this.loader = false;
      m.redraw();
    }
  }

  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    const Api_url = app.forum.attribute("baseUrl") + "/api/getProductDisplay";
    return app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.parseResults.bind(this));
  }
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.productList = [];
    }

    return this.loadResults().then(
      (results) => {},
      () => {
        this.loading = false;
      }
    );
  }
  requestParams(offset) {
    const params = this.props.params;

    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }

  viewItems() {
    const items = new ItemList();
    const sortMap = { hottest: "Hottest", latest: "Latest" };
    const sortOptions = { hottest: "Hottest", latest: "Latest" };
    items.add(
      "sort",
      Select.component({
        options: sortOptions,
        value: this.props.params.pricedropsort || Object.keys(sortMap)[1],
        onchange: this.changeSort.bind(this),
      })
    );
    return items;
  }
  changeSort(pricedropsort) {
    if (pricedropsort !== "") {
      var pricedropsort = pricedropsort;
      this.productList = [];
      this.loading = true;
      const params = this.requestParams();
      params.pricedropsort = pricedropsort;
      m.route(
        app.route(
          this.props.routeName,
          this.getParams("pricedropsort", pricedropsort)
        )
      );
      const Api_url = app.forum.attribute("baseUrl") + "/api/getProductDisplay";
      return app
        .request({
          method: "GET",
          url: Api_url,
          data: params,
        })
        .then(this.parseResults.bind(this));
    }
  }

  viewItemsByTime() {
    var sortOptions = [
      { time: "1 Hr" },
      { time: "6 Hr" },
      { time: "12 Hr" },
      { time: "24 Hr" },
    ];
    return m(
      "ul",

      sortOptions.map((k, index) => {
        return m(
          "a",
          { onclick: this.changetime.bind(this) },
          m("li.sortbytime[style='display:inline-block;margin:0 10px']", k.time)
        );
      }),
      m(
        "a",
        { href: app.forum.data.attributes.baseUrl + "/Pricedrop" },
        "Clear"
      )
    );
  }

  changetime(data) {
    var time = data.target.innerText.split(" ");
    if (time !== "") {
      this.productList = [];
      this.loading = true;
      const params = this.requestParams();
      params.time = time[0];
      m.route(app.route(this.props.routeName, this.getParams("time", time[0])));
      const Api_url = app.forum.attribute("baseUrl") + "/api/getProductDisplay";
      return app
        .request({
          method: "GET",
          url: Api_url,
          data: params,
        })
        .then(this.parseResults.bind(this));
    }
  }

  getParams(paramName, value) {
    let paramsURL = Object.assign({}, m.route.param());
    paramsURL[paramName] = value;
    return paramsURL;
  }
}
