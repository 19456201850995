import Modal from "flarum/components/Modal";
import tagsLabel from "flarum/tags/helpers/tagsLabel";
import myDiscussionComposer from "./myDiscussionComposer";

export default class DuplicateProductModal extends Modal {
  //to check deal is already exist or not while adding new deal
  init() {
    super.init();
    this.title = m.prop("This Deal already exists." || "");
    this.productTitle = m.prop(this.props.DiscussionData.title || "");
    this.maxPrice = m.prop(this.props.DiscussionData.maxPrice || "");
    this.currentPrice = m.prop(this.props.DiscussionData.currentPrice || "");
    this.discount = m.prop(this.props.DiscussionData.discount || "");
    this.currency = m.prop(this.props.DiscussionData.currency || "");
    this.url = m.prop(this.props.DiscussionData.url || "");
    this.productName = m.prop(this.props.DiscussionData.productName || "");
    this.isFeatured = m.prop(this.props.DiscussionData.isFeatured || "");
    this.isHotDeal = m.prop(this.props.DiscussionData.isHotDeal || "");
    this.isExpired = m.prop(this.props.DiscussionData.isExpired || "");
    this.startTime = m.prop(this.props.DiscussionData.startTime || "");
    this.imageUrl_sm = this.props.DiscussionData.imageUrl_sm;
    this.startUser = m.prop(this.props.DiscussionData.startUser || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    this.tags = m.prop(this.props.DiscussionData.tags || "");
    this.validFrom = m.prop(this.props.DiscussionData.validFrom || "");
    this.validTo = m.prop(this.props.DiscussionData.validTo || "");
    this.validFor = m.prop(this.props.DiscussionData.validFor || "");
    this.badgeText = m.prop(this.props.DiscussionData.badgeText || "");
    this.couponcode = m.prop(this.props.DiscussionData.couponcode || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    this.discussionRoute =
      app.forum.data.attributes.baseUrl + "/d/" + this.props.DiscussionData.id;
  }
  content() {
    var tags = this.tags();
    if (tags)
      tags = tags = this.tags().filter(
        (model) =>
          model["parent"]() === app.store.getBy("tags", "slug", "stores")
      );
    else tags = this.tags();
    this.storeimagepath = tags[0].data.attributes.imagePath;
    var storeName = tags[0].data.attributes.name;

    const link = true;
    return [
      <div className="Modal-body">
        <div className="TagDiscussionModal-form1">
          <div className="TagDiscussionModal-form-input1">
            <div className={"DiscussionListItem-content1 Slidable-content"}>
              <div
                class="cc_productBlock cc_borderRightBottom"
                itemscope
                itemtype="http://schema.org/Product"
              >
                <a
                  class="cc_dealCardMainBlock"
                  style="position:unset !important;"
                >
                  <div class="cc_imageBox">
                    <a
                      href={this.discussionRoute}
                      config={m.route}
                      class="cc_dealCardMainBlock"
                    />
                    <div class="cc_imageFrameDealPage">
                      <img
                        itemprop="image"
                        className="sm_img lazy"
                        src={this.imageUrl_sm}
                        alt="Product Image"
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div class="cc_compareTable">
                <div class="cc_comapreTableRow">
                <a href={this.discussionRoute} config={m.route}>
                  <div class="cc_comapreCol_duplicate1">
                  <strong class="TagsLabel compareTitle">
                      {this.productTitle()}
                  </strong>
                  </div>
                
                    <div class="cc_comapreCol_duplicate2">
                    <span class="TagsLabel ">
                      {tags ? tagsLabel(tags, { link }) : ""}
                      </span>
                    </div>

                    <div class="cc_comapreCol_duplicate3">
                    {this.maxPrice() ? (
                      <div style="text-decoration: line-through;display:block;font-weight: bold;margin-left: 3px;">
                        {app.translator.trans(
                          "flarum-myindex.lib.number_suffix.currency"
                        )}
                        {this.maxPrice()}
                      </div>
                    ) : (
                      <div class="cc_textBold cc_offerText"></div>
                    )}
                    {this.currentPrice() ? (
                      <div style="color: red;font-weight: bold;margin-left: 3px;">
                        <i itemprop="priceCurrency" content="Rs"></i>
                        {app.translator.trans(
                          "flarum-myindex.lib.number_suffix.currency"
                        )}
                        <span itemprop="price">{this.currentPrice()}</span>/-
                      </div>
                    ) : (
                      <div class="cc_productPrice"></div>
                    )}
                  </div>

                    <div class="cc_comapreCol_duplicate4 urldata1">
                    {this.url() ? (
                        <a class="button" href={this.url()} target="_blank" rel="nofollow">
                          Buy Now
                        </a>
                    ) : (
                      ""
                    )}
                  </div>
                </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>,

      <div className="Modal-footer"></div>,
    ];
  }

  onsubmit(e) {
    e.preventDefault();
    const tags = this.selected;
    if (this.props.onsubmit) this.props.onsubmit(tags);
    const props = {
      tags,
    };

    app.cache.selectedstores = tags;
    app.modal.close();
    const component = new myDiscussionComposer({ user: app.session.user });
  }
}
