import Page from "flarum/components/Page";
import BrandProductResults from "./../search/BrandProductResults";
export default class BrandIndexPage extends Page {
  //used in Brand view page (Indexpage) https://thedealapp.in/Brand?size=0&brands=LG&src=brand
  init() {
    super.init();
    const params1 = m.route.param();
    this.productResults = new BrandProductResults({
      params: params1,
      routeName: this.props.routeName,
    });
  }
  view() {
    return (
      <div class="searchResultsPage">
        <div className="container storeContainer">
          <nav>
            <div hidden>
              <i class="fa fa-thumbs-up" aria-hidden="true"></i> Results from
              deals posted by users
            </div>
          </nav>
        </div>
        <div className="container resultsContainer">
          <h2 class="">Top Products By {m.route.param("brands")} </h2>
          {this.productResults.render()}
        </div>
      </div>
    );
  }
}
