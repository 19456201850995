import Component from "flarum/Component";

export default class BannerCardMini extends Component {
  //used to display banners on homepage
  init() {
    this.noImageFound =
      app.forum.data.attributes.baseUrl + "/assets/images/no_image_found.png";
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/deal_expired.png";
    this.title = m.prop(this.props.DiscussionData.title || "");
    this.maxprice = m.prop(this.props.DiscussionData.maxprice || "");
    this.currentprice = m.prop(this.props.DiscussionData.currentprice || "");
    this.discount = m.prop(this.props.DiscussionData.discount || "");
    this.currency = m.prop(this.props.DiscussionData.currency || "");
    this.url = m.prop(this.props.DiscussionData.url || "");
    this.productname = m.prop(this.props.DiscussionData.productname || "");
    this.is_featured = m.prop(this.props.DiscussionData.is_featured || "");
    this.is_hotdeal = m.prop(this.props.DiscussionData.is_hotdeal || "");
    this.is_expired = m.prop(this.props.DiscussionData.is_expired || "");
    this.start_time = m.prop(this.props.DiscussionData.start_time || "");
    this.imageurl_sm = m.prop(this.props.DiscussionData.imageurl_sm || "");
    this.imageurl_lg = m.prop(this.props.DiscussionData.imageurl_lg || "");
    this.startUser = m.prop(this.props.DiscussionData.startUser || "");
    this.validfrom = m.prop(this.props.DiscussionData.validfrom || "");
    this.validto = m.prop(this.props.DiscussionData.validto || "");
    this.validfor = m.prop(this.props.DiscussionData.validfor || "");
    this.badgetext = m.prop(this.props.DiscussionData.badgetext || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    this.tags = m.prop(this.props.DiscussionData.tags || "");
    this.customBadge = null;
    if (this.badgetext()) {
      this.customBadge = this.badgetext();
    } else if (this.discount()) {
      this.customBadge = this.discount() + "% Off";
    }
    this.validTo_Expired = false;
    this.pastExpired = false;
    this.discussionRoute =
      app.forum.data.attributes.baseUrl + "/d/" + this.discussionID();
    this.storeimagepath = "";
  }


  view() {

    var tags = this.tags();

    if (tags)
      tags = tags = this.tags().filter(
        (model) =>
          model["parent"]() === app.store.getBy("tags", "slug", "stores")
      );
    else tags = this.tags();
    this.storeimagepath = tags[0] ? tags[0].data.attributes.imagePath : "";
    var storeName = tags[0] ? tags[0].data.attributes.name : "";
    const link = true;

    return m(
      "div",
      { class: "", config: this.configArea.bind(this) },
      <div class="">
        <a href={this.discussionRoute} config={m.route} style="padding-bottom:20px">
          <div style="">
            <div style="text-align: center;">
              <picture>
                <source
                  media="(max-width: 639px)"
                  srcset={this.imageurl_sm()}
                ></source>
                <source
                  media="(min-width: 640px)"
                  srcset={this.imageurl_lg()}
                ></source>
                <img
                  src={this.imageurl_lg()}
                  style="border-radius: 5px;
    box-shadow: 0px -4px 8px -5px rgba(0,0,0,0.4), 1px 5px 9px -2px rgba(0,0,0,0.6), -3px 2px 8px 0 rgba(0,0,0,0.6);max-height:320px;max-width:100%;margin-left: auto;margin-right: auto;"
                />
              </picture>
            </div>
          </div>
        
          <div class="bannerText"> {this.title()}</div>
        </a>
      </div>
    );
  }
  configArea(element, isInitialized) {
   
  }
  config(isInitialized) {
    if (isInitialized) return;

    // If we're on a touch device, set up the discussion row to be slidable.
    // This allows the user to drag the row to either side of the screen to
    // reveal controls.
   
  }
}
