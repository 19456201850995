import Modal from "flarum/components/Modal";
import myDiscussionComposer from "../myDiscussionComposer";

export default class CouponModal extends Modal {
  //Used for display modal box on get coupon for redirection
  init() {
    super.init();

    this.title = m.prop(this.props.DiscussionData.title || "");
    this.maxPrice = m.prop(this.props.DiscussionData.maxPrice || "");
    this.currentPrice = m.prop(this.props.DiscussionData.currentPrice || "");
    this.discount = m.prop(this.props.DiscussionData.discount || "");
    this.currency = m.prop(this.props.DiscussionData.currency || "");
    this.url = m.prop(this.props.DiscussionData.url || "");
    this.productName = m.prop(this.props.DiscussionData.productName || "");
    this.isFeatured = m.prop(this.props.DiscussionData.isFeatured || "");
    this.isHotDeal = m.prop(this.props.DiscussionData.isHotDeal || "");
    this.isExpired = m.prop(this.props.DiscussionData.isExpired || "");
    this.startTime = m.prop(this.props.DiscussionData.startTime || "");
    this.imageUrl_sm = m.prop(this.props.DiscussionData.imageUrl_sm || "");
    this.startUser = m.prop(this.props.DiscussionData.startUser || "");
    this.validFrom = m.prop(this.props.DiscussionData.validFrom || "");
    this.validTo = m.prop(this.props.DiscussionData.validTo || "");
    this.validFor = m.prop(this.props.DiscussionData.validFor || "");
    this.badgeText = m.prop(this.props.DiscussionData.badgeText || "");
    this.couponcode = m.prop(this.props.DiscussionData.couponcode || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    var Redirecturl = this.url();

    var sec = 5;
    var sec = 5;
    var timer = setInterval(function () {
      $("#dvdelaycount").css("display", "block");
      $("#dvdelaycount").text(sec--);

      if (sec == -1) {
        clearInterval(timer);
        $("#dvdelaycount").css("display", "none");
      }
    }, 1000);
    var dummy = document.createElement("input");
    // Add it to the document
    document.body.appendChild(dummy);
    // Set its ID
    dummy.setAttribute("id", "dummy_id");
    // Output the array into it
    document.getElementById("dummy_id").value = this.couponcode();
    // Select it
    dummy.select();
    // Copy its contents
    document.execCommand("copy");
    // Remove it as its not needed anymore
    document.body.removeChild(dummy);
    setTimeout(function () {
      $("#dvLink").css("display", "block");
      $("#dvProcessing").css("display", "none");
      var popUp = window.open(Redirecturl, "_blank");
      if (popUp == null || typeof popUp == "undefined") {
        //alert('Please disable your pop-up blocker and click the "Open" link again.');
      }
    }, 5000);
  }
  getInstruction(primaryCount, secondaryCount) {
    if (primaryCount < this.minPrimary) {
      const remaining = this.minPrimary - primaryCount;
      return app.translator.transChoice(
        "flarum-tags.forum.choose_tags.choose_primary_placeholder",
        remaining,
        { count: remaining }
      );
    } else if (secondaryCount < this.minSecondary) {
      const remaining = this.minSecondary - secondaryCount;
      return app.translator.transChoice(
        "flarum-tags.forum.choose_tags.choose_secondary_placeholder",
        remaining,
        { count: remaining }
      );
    }

    return "";
  }

  showdelaycount() {}

  content() {
    return [
      <div className="Modal-body">
        <div style="width: 100%;display: table;">
          <div
            class="cc_couponImageTextBox"
            style="width: 235px; vertical-align: middle; display: table-cell;background:#330;"
          >
            {this.couponcode()}
            {/*{this.showdelaycount()}*/}
          </div>
        </div>
        <div style="text-align: center; PADDING-TOP: 10PX;">
          Copied to your clipboard!!!
        </div>
      </div>,

      <div className="Modal-footer">
        <div id="dvProcessing">Redirecting.......</div>
        <div id="dvLink" style="display:none">
          <a
            id="anchorID"
            class="cssbuttongo"
            href={this.url()}
            target="_blank"
          >
            Click Here To Avail Coupon Now
          </a>{" "}
        </div>
        <div
          class="Button DelayCount"
          style="display:none"
          id="dvdelaycount"
        ></div>
      </div>,
    ];
  }

  onsubmit(e) {
    e.preventDefault();

    const tags = this.selected;
    if (this.props.onsubmit) this.props.onsubmit(tags);
    const props = {
      tags,
    };
    app.cache.selectedstores = tags;

    app.modal.close();
    const component = new myDiscussionComposer({ user: app.session.user });
  }
}
