import Page from 'flarum/components/Page';
import dealTypeResults from './../dailyDeals/dealTypeResults';

export default class dealTypeIndexPage extends Page {
  //Used for Dealmachine to view all deals for live,upcoming,expired Indexpage https://tda.development/dealtype/l?stage=live&dealType=LIGHTNING_DEAL
      init() {
           super.init();
           const params1 = m.route.param();
           this.dealTypeResults = new dealTypeResults({params:params1,routeName:this.props.routeName});  
      }
     view() {
       return(
          <div class="searchResultsPage" >
            <div className="container">
              <h2 style="color:#e88b00;" >
              <i class="fa fa-arrow-down"></i>
              {m.route.param("dealtype") == "l" ? "Live Deals" : "" }
              {m.route.param("dealtype") == "u" ? "Upcoming Deals" : "" }
              {m.route.param("dealtype") == "e" ? "Expired Deals" : "" }
              </h2>
              <hr/>
              {this.dealTypeResults.render()}
            </div>
        </div>
      )
    } 
}