import app from "flarum/app";
import Button from "flarum/components/Button";
import LogInModal from "flarum/components/LogInModal";
import Component from "flarum/Component";
import Badge from "flarum/components/Badge";

export default class discussionVotes extends Component {
  init() {
    //super.init();
    this.discussion = this.props.discussion;
    this.total_votes = this.discussion.data.attributes.votes_discussion;
    this.displayed_votes = this.total_votes;
    this.myUpvoted = false;
    this.myDownvoted = false;
    this.vote_value = 0;
    this.plusMinus = 0;
  }
  view() {
    var discussion = this.discussion;
    let isUpvoted =
      app.session.user &&
      discussion.upvotesD().some((user) => user === app.session.user);
    let isDownvoted =
      app.session.user &&
      discussion.downvotesD().some((user) => user === app.session.user);
    if (isUpvoted) {
      $(".upvoteBtn").prop("disabled", true);
    }
    if (isDownvoted) {
      $(".downvoteBtn").prop("disabled", true);
    }
    this.displayed_votes = this.discussion.data.attributes.votes_discussion;
    //this.displayed_votes = this.discussion.data.relationships.upvotesD.data.length - this.discussion.data.relationships.downvotesD.data.length
    this.plusMinus = "";
    if (this.displayed_votes > 0) {
      this.plusMinus = "+ " + this.displayed_votes;
    } else {
      this.plusMinus = "" + this.displayed_votes;
    }

    let color = "";
    if (!app.session.user) {
      isDownvoted = false;
      isUpvoted = false;
    }
    let icon = app.forum.attribute("IconName");
    if (icon === null || icon === undefined || icon === "") {
      icon = "fas fa-thumbs";
    }
    var mythis = this;
    $(".downvoteBtn").on("click", function (e) {
      $("#myDropdown").show();
      e.stopPropagation();
    });
    $(".upvoteBtn").on("click", function () {
      $("#myDropdown").hide();
    });

    window.onclick = function (event) {
      if (!event.target.matches(".downvoteBtn")) {
        $("#myDropdown").hide();
      }
    };
    var that = this;
    if (!this.myUpvoted) {
      $(".Discussion-upvote").on("click", function () {
        if (!app.session.user) {
          app.modal.show(new LogInModal());
          return;
        }
        if (!discussion.canVoteD()) return;
        $(".upvoteBtn").prop("disabled", true);
        $(".downvoteBtn").prop("disabled", false);
        var upData = discussion.data.relationships.upvotesD.data;
        var downData = discussion.data.relationships.downvotesD.data;
        isUpvoted = true;
        isDownvoted = false;
        mythis.myUpvoted = true;
        mythis.myDownvoted = false;
        discussion
          .save({
            isUpvoted,
            isDownvoted,
          })
          .then(function (asa) {
            that.discussion = asa;
            m.redraw();
          });
        upData.some((upvote, i) => {
          if (upvote.id === app.session.user.id()) {
            upData.splice(i, 1);
            return true;
          }
        });
        downData.some((downvote, i) => {
          if (downvote.id === app.session.user.id()) {
            downData.splice(i, 1);
            return true;
          }
        });
        if (isUpvoted) {
          upData.unshift({ type: "users", id: app.session.user.id() });
        }
      });
    }

    if (!this.myDownvoted) {
      $(".downvote-reason").on("click", function () {
        var downvoted_reason = $(this).text();
        if (!app.session.user) {
          app.modal.show(new LogInModal());
          return;
        }
        if (!discussion.canVoteD()) return;
        $(".downvoteBtn").prop("disabled", true);
        $(".upvoteBtn").prop("disabled", false);
        var upData = discussion.data.relationships.upvotesD.data;
        var downData = discussion.data.relationships.downvotesD.data;
        isDownvoted = true;
        isUpvoted = false;
        mythis.myUpvoted = false;
        mythis.myDownvoted = true;
        discussion
          .save({
            isUpvoted,
            isDownvoted,
            downvoted_reason,
          })
          .then(function (asa) {
            that.discussion = asa;
            m.redraw();
          });
        upData.some((upvote, i) => {
          if (upvote.id === app.session.user.id()) {
            upData.splice(i, 1);
            return true;
          }
        });
        downData.some((downvote, i) => {
          if (downvote.id === app.session.user.id()) {
            downData.splice(i, 1);
            return true;
          }
        });
        if (isDownvoted) {
          downData.unshift({ type: "users", id: app.session.user.id() });
        }
        $("#myDropdown").hide();
      });
    }
    return (
      <div Class="Discussion-vote">
        <div Class="Discussion-vote-item">
          {Button.component({
            icon: icon + "-up",
            className: "upvoteBtn Discussion-upvote",
          })}
        </div>
        <div id="myDropdown" class="dropdown-content">
          <div class="dropdown-Heading">
            Please select a reason for down vote:
          </div>
          <a href="#" class="downvote-reason">
            Not good price
          </a>
          <a href="#" class="downvote-reason">
            Bad Product/Seller reviews
          </a>
          <a href="#" class="downvote-reason">
            Repost
          </a>
          <a href="#" class="downvote-reason">
            Spam
          </a>
          <a href="#" class="downvote-reason">
            Incorrect information
          </a>
          <a href="#" class="downvote-reason">
            Other
          </a>
        </div>
        <div Class="Discussion-vote-item">
          {Button.component({
            icon: icon + "-down",
            className: "downvoteBtn Discussion-downvote",
            style: isDownvoted !== false ? "color:" + color : "",
          })}
        </div>
        <div class="Discussion-vote-item total_votes">
          <div class="totalVotesScoreText">
            Score
            <div
              style="display: inline-block;padding-left: 6px;max-height:12px;transform:scale(0.5)"
              class="miniInfoIcon"
            >
              <Badge
                type="hidden"
                icon="info"
                style="background: #fff;color: blue;"
                label={
                  "Score indicates the interest this deal has gathered amongst THE DEAL APP users. Higher the engagement, more the score. You may vote on the left side buttons based on your opinion of this deal."
                }
              />
            </div>
          </div>
          <div class="totalVotesScoreNum">{this.plusMinus}</div>
        </div>
      </div>
    );
  }
}
