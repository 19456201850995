import Button from "flarum/components/Button";
import dealComposer from "./dealComposer";
import icon from "flarum/helpers/icon";
import EndpointsApiUrls from "./EndpointsApiUrls";

function minimizeComposerIfFullScreen(e) {
  if (app.composer.isFullScreen()) {
    app.composer.minimize();
    e.stopPropagation();
  }
}
/**
 * The `EditProductComposer` component displays the composer content for editing a
 * post. It sets the initial content to the content of the post that is being
 * edited, and adds a header control to indicate which post is being edited.
 *
 * ### Props
 *
 * - All of the props for ComposerBody
 * - `post`
 */
export default class EditProductComposer extends dealComposer {
  init() {
    super.init();
    const discussionID = this.props.post.data.id;
    this.myDiscussion = app.store.getBy("discussions", "id", discussionID);
    this.props.discussion = this.myDiscussion;
    this.productURL = m.prop("");
    this.title = m.prop(this.myDiscussion.data.attributes.title || "");
    this.url = m.prop(this.myDiscussion.data.attributes.url || "");
    this.productID = m.prop(this.myDiscussion.data.attributes.productID || "");
    this.productBrand = m.prop(
      this.myDiscussion.data.attributes.productBrand || ""
    );
    this.productName = m.prop(
      this.myDiscussion.data.attributes.productName || ""
    );
    this.productInfo = m.prop(
      this.myDiscussion.data.attributes.productInfo || ""
    );
    this.categoryID = m.prop("");
    this.MerchantID = m.prop("");
    this.MerchantParentID = m.prop("");
    this.MerchantName = m.prop("");
    this.MerchantTag = m.prop("");
    this.MerchantParentTag = m.prop("");
    this.maxPrice = m.prop(this.myDiscussion.data.attributes.maxPrice || "");
    this.currentPrice = m.prop(
      this.myDiscussion.data.attributes.currentPrice || ""
    );
    this.discount = m.prop(this.myDiscussion.data.attributes.discount || "");
    this.currency = m.prop(this.myDiscussion.data.attributes.currency || "");
    this.imageUrl_sm = m.prop(
      this.myDiscussion.data.attributes.imageUrl_sm || ""
    );
    this.imageUrl_md = m.prop(
      this.myDiscussion.data.attributes.imageUrl_md || ""
    );
    this.imageUrl_lg = m.prop(
      this.myDiscussion.data.attributes.imageUrl_lg || ""
    );
    this.contentPost = "";
    this.mytags = [];
    this.badgeText = m.prop(this.myDiscussion.data.attributes.badgeText || "");
    this.validFrom = m.prop(
      (Date.parse(this.myDiscussion.data.attributes.validFrom) || 0) != "0"
        ? this.myDiscussion.data.attributes.validFrom || ""
        : ""
    );
    this.validTo = m.prop(
      (Date.parse(this.myDiscussion.data.attributes.validTo) || 0) != "0"
        ? this.myDiscussion.data.attributes.validTo || ""
        : ""
    );
    this.secondarytag = m.prop(
      this.myDiscussion.data.attributes.secondarytag || ""
    );
    this.editor.props.preview = (e) => {
      minimizeComposerIfFullScreen(e);
      m.route(app.route.post(this.props.post));
      originalContent;
    };
  }

  headerItems() {
    const items = super.headerItems();
    const post = this.props.post;
    this.editor.disabled = true;
    $(".Composer:first").css("height", "538px", "important");

    $(".TextEditor").hide();
    const routeAndMinimize = function (element, isInitialized) {
      if (isInitialized) return;
      $(element).on("click", minimizeComposerIfFullScreen);
      m.route.apply(this, arguments);
    };

    items.add(
      "title",
      <h3>
        {icon("pencil")}{" "}
        <a
          href={app.route.discussion(post, post.data.attributes.readNumber)}
          config={routeAndMinimize}
        >
          {app.translator.trans("core.forum.composer_edit.post_link", {
            number: post.data.attributes.readNumber,
            discussion: this.title(),
          })}
        </a>
      </h3>
    );
    items.add(
      "url",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft" style="width: 100% !important;">
        <label class="cc_dealInputLabel "> Product URL:</label>
          <input
            className="dealInput"
            value={this.url()}
            oninput={m.withAttr("value", this.url)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div style="clear:both;"></div>
      </div>
    );

    items.add(
      "productBrand",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft width60">
        <label class="cc_dealInputLabel"> Product Brand:</label>
          <input
            className="dealInput"
            value={this.productBrand()}
            oninput={m.withAttr("value", this.productBrand)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>

        <div className="cc_parentBorderNone thisFloatRight width40">
        <label class="cc_dealInputLabel"> Max Price:</label>
          <input
            className="dealInput"
            value={this.maxPrice()}
            oninput={m.withAttr("value", this.maxPrice)}
            onkeyup={this.changeDiscount.bind(this)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>

        <div style="clear:both;"></div>
      </div>
    );

    items.add(
      "imageUrl_sm",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft width60">
        <label class="cc_dealInputLabel"> Image Url Small:</label>
          <input
            className="dealInput"
            value={this.imageUrl_sm()}
            oninput={m.withAttr("value", this.imageUrl_sm)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div className="cc_parentBorderNone  thisFloatRight width40">
        <label class="cc_dealInputLabel"> Current Price:</label>
          <input
            className="dealInput"
            value={this.currentPrice()}
            oninput={m.withAttr("value", this.currentPrice)}
            disabled={!!this.props.disabled}
            onkeyup={this.changeDiscount.bind(this)}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div style="clear:both;"></div>
      </div>
    );

    items.add(
      "imageUrl_md",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft width60">
        <label class="cc_dealInputLabel"> Image Url Medium:</label>
          <input
            className="dealInput"
            value={this.imageUrl_md()}
            oninput={m.withAttr("value", this.imageUrl_md)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div className="cc_parentBorderNone  thisFloatRight width40">
        <label class="cc_dealInputLabel"> discount:</label>
          <input
            className="dealInput"
            value={this.discount()}
            oninput={m.withAttr("value", this.discount)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div style="clear:both;"></div>
      </div>
    );
    items.add(
      "imageUrl_lg ",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft width60">
        <label class="cc_dealInputLabel"> Image Url Large:</label>
          <input
            className="dealInput"
            value={this.imageUrl_lg()}
            oninput={m.withAttr("value", this.imageUrl_lg)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div className="thisFloatRight width40">
          <div>
            <div style="" className="thisFloatLeft">
            <label class="cc_dealInputLabel"> currency:</label>
              <input
                className="dealInput"
                style="width:99%;"
                value={this.currency()}
                oninput={m.withAttr("value", this.currency)}
                disabled={!!this.props.disabled}
              />
              <span className="myHighlight"></span>
            </div>
            <div className="thisFloatLeft" >
            <label class="cc_dealInputLabel"> Secondary Tag:</label>
              <input
                className="dealInput"
                style="width:100%;"
                value={this.secondarytag()}
                oninput={m.withAttr("value", this.secondarytag)}
                disabled={!!this.props.disabled}
              />
              <span className="myHighlight"></span>
            </div>
          </div>
        </div>
      </div>
    );
    items.add(
      "DealValidityBlock",
      <div className="cc_parentBorderNone">
        <div className="width60">
          <div>
            <div style="float: left;width:%;" className="thisFloatLeft">
            <label className="cc_dealInputLabel">Valid From:</label>
              <input
                className="dealInput cc_dealInputmb"
                id="validFromdatetimepicker"
                value={this.validFrom()}
                onchange={m.withAttr("value", this.validFrom)}
                disabled={!!this.props.disabled}
                onkeydown={this.onkeydown.bind(this)}
                required
                placeholder="Valid From"
              />
              <span className="myHighlight"></span>
            </div>
            <div className="thisFloatLeft">
            <label className="cc_dealInputLabel">Valid To:</label>
              <input
                className="dealInput cc_dealInputmb"
                id="validTodatetimepicker"
                value={this.validTo()}
                onchange={m.withAttr("value", this.validTo)}
                disabled={!!this.props.disabled}
                onkeydown={this.onkeydown.bind(this)}
                required
                placeholder="Valid To"
              />
              <span className="myHighlight"></span>
            </div>
          </div>
        </div>

        <div className="cc_parentBorderNone thisFloatRight width40">
        <label class="cc_dealInputLabel"> Badge Text:</label>
          <input
            className="dealInput"
            value={this.badgeText()}
            oninput={m.withAttr("value", this.badgeText)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
      </div>
    );

    items.add(
      "mysubmit",
      Button.component({
        children: "Save Changes ",
        icon: "check",
        className: "Button Button--primary mysubmit",
        itemClassName: "",
        onclick: this.onsubmit.bind(this),
      })
    );
    return items;
  }

  onkeydown(e) {
    if (e.which === 13) {
      e.preventDefault();
      this.editor.setSelectionRange(0, 0);
    }

    m.redraw.strategy("none");
  }

  data() {
    return {
      url: this.url(),
      productBrand: this.productBrand(),
      productName: this.productName(),
      productInfo: this.productInfo(),
      maxPrice: this.maxPrice(),
      currentPrice: this.currentPrice(),
      discount: this.discount(),
      currency: this.currency(),
      imageUrl_sm: this.imageUrl_sm(),
      imageUrl_md: this.imageUrl_md(),
      imageUrl_lg: this.imageUrl_lg(),
      badgeText: this.badgeText(),
      validFrom: this.validFrom(),
      validTo: this.validTo(),
      secondarytag: this.secondarytag(),
    };
  }

  focus() {
    this.$(":input:enabled:visible:first").focus();
  }

  /**
   * Check if there is any unsaved data – if there is, return a confirmation
   * message to prompt the user with.
   *
   * @return {String}
   */
  preventExit() {
    const content = this.content();
    return (
      content &&
      content !== this.props.originalContent &&
      this.props.confirmExit
    );
  }

  config(isInitialized) {
    if (isInitialized) return;
    if (!this.loading) this.datepicker();

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    $(".multipleInputDynamicWithInitialValue")
      .fastselect({
        url: ApiEndpoint + "/category",
        loadOnce: false,
        apiParam: "query",
        clearQueryOnSelect: true,
        minQueryLength: 1,
        focusFirstItem: true,
        flipOnBottom: true,
        typeTimeout: 150,
        userOptionAllowed: false,
        valueDelimiter: " ",
        matcher: function (text, query) {
          return text;
        },
        placeholder: "Choose option",
        searchPlaceholder: "Search options",
        noResultsText: "No results",
        userOptionPrefix: "Add",
      })
      .bind(this);
  }
  changeDiscount(e) {
    e.preventDefault();
    this.discount = m.prop(
      ((1 - this.currentPrice() / this.maxPrice()) * 100).toFixed(2)
    );
  }
  datepicker() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    $("#validFromdatetimepicker").flatpickr({
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y/m/d H:i",
        allowInput:true,
        minDate: jQuery("#validFromdatetimepicker").val()
            ? jQuery("#validFromdatetimepicker").val()
            : false,
        onReady: function ( dateObj, dateStr, instance ) {
          const $clear = $( '<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>' )
            .on( 'click', () => {
              instance.clear();
              instance.close();
            } )
            .appendTo( $( instance.calendarContainer ) );
        }
    });
    $("#validTodatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      allowInput:true,
      minDate: jQuery("#validFromdatetimepicker").val()
             ? jQuery("#validFromdatetimepicker").val()
             : false,
      onReady: function ( dateObj, dateStr, instance ) {
        const $clear = $( '<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>' )
          .on( 'click', () => {
            instance.clear();
            instance.close();
          } )
          .appendTo( $( instance.calendarContainer ) );
      }
    });
    // $("#validFromdatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       maxDate: jQuery("#validTodatetimepicker").val()
    //         ? jQuery("#validTodatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
    // $("#validTodatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       minDate: jQuery("#validFromdatetimepicker").val()
    //         ? jQuery("#validFromdatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
  }
  // destroyDatePicker() {
  //   $("#validTodatetimepicker").datetimepicker("destroy");
  //   $("#validFromdatetimepicker").datetimepicker("destroy");
  // }
  onsubmit() {
    this.loading = true;
    const data = this.data();
    //this.destroyDatePicker();
    this.props.discussion
      .save(data)
      .then(() => app.composer.hide(), this.loaded.bind(this));
  }
}
