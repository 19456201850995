import Page from 'flarum/components/Page';
import DailyDealResults from './../dailyDeals/DailyDealResults';

export default class DailyDealIndexPage extends Page {
  //Used for Dealmachine to show DailyDealIndexPage https://tda.development/Dealmachine
      init() {
            super.init();
            const params={heading:"Live Deal",stage:"live", merchantname: "Amazon", dealType: "LIGHTNING_DEAL"}
            this.liveResults = new DailyDealResults({params: params ,routeName:this.props.routeName});
            const params1={heading:"Upcoming Deal",stage:"upcoming", merchantname: "Amazon", dealType: "LIGHTNING_DEAL"}
            this.upcomingResults = new DailyDealResults({params: params1 ,routeName:this.props.routeName});
            const params2={heading:"Expired Deal",stage:"expired", merchantname: "Amazon", dealType: "LIGHTNING_DEAL"}
            this.expiredResults = new DailyDealResults({params: params2 ,routeName:this.props.routeName});
      }

    attachSlider()
    {  
      if($(".regularExpired").hasClass("slick-initialized")) 
      {
        $(".regularExpired").slick('unslick');
        return;
      }          
      $(".regularExpired").slick({
        dots: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        mobileFirst: true,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            infinite: false
          }
        }, {
          breakpoint: 500,
          settings: {
            slidesToShow: 6,
            dots: true
          }
        }, {
          breakpoint: 300,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        }]
      });
    }

      view() {
        let loading;

        $('.MissedBar').show() ; 
          return(
            <div >
              {this.liveResults.render()}
              {this.upcomingResults.render()}
              {this.expiredResults.render()}
              <div className="container storeContainer" config={this.configPreview.bind(this)}>
                <nav style="width: 93%; position: relative; margin: 0 auto;">
                  <div hidden>
                    <i class="fa fa-thumbs-up" aria-hidden="true"></i> Results from deals posted by users
                  </div>
                </nav>
              </div>
          </div>
        )
    }
  
  config(isInitialized)
  {
    if(isInitialized) return;
  }

  configPreview(element, isInitialized, context)
  {
    if (isInitialized ) return;
    // const sortOptions ={relevance: "-relevance", asc: "Low-To-High", desc: "High-To-Low"};
    this.attachSlider();
  }

}