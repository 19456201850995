import Component from "flarum/Component";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class priceDropcategory extends Component {
  //used in All Deals for Category Filter sidebar
  init() {
    this.params1 = m.route.param();
    this.selectedCategory = "";
    this.catpath = "";
    this.mainparams = this.props.params;
    if (
      this.props.params.query == "category" ||
      this.props.params.categories == "category"
    ) {
      this.cat_select = "";
    } else {
      if ("categories" in this.props.params) {
        if (this.props.params.categories == this.props.params.tag) {
          this.cat_select = "";
          this.props.params.categories = "";
        } else {
          this.cat_select = this.props.params.categories;
        }
      } else {
        this.cat_select = this.props.params.query;
      }
    }
    this.refresh();
    if (this.props.routeName == "Category") {
      if (this.params1.categories.indexOf(">") != -1) {
        let changerouteName = this.params1.categories.split(">");
        this.changedrouteName = this.props.routeName + "/" + changerouteName[0];
      }
    }
  }
  view() {
    this.params1 = m.route.param();
    this.categoriesList = app.cache.DealsCategories
      ? app.cache.DealsCategories
      : [];
    this.categoryLevel = 1;
    this.catpath = "";

    if (!("categories" in this.mainparams)) {
      if (this.props.routeName == "search") {
        this.selected_cat = "";
      } else {
        this.mainparams.categories = this.mainparams.query;
      }
    }

    if (this.mainparams.categories != "category") {
      this.selected_cat = this.mainparams.categories;
    } else {
      this.selected_cat = "";
    }
    if (this.mainparams.query != "category") {
      this.selectedCategory = this.mainparams.query;
    } else {
      this.selectedCategory = "";
    }
    return (
      <ul class="categoryTree">
        <li class="cc_catAccordion">
          <label>Categories </label>
        </li>
        {this.props.routeName != "Category" ? (
          this.mainparams.categories != "category" &&
          this.mainparams.categories != "" &&
          this.mainparams.categories != undefined ? (
            this.mainparams.categories ? (
              <li className="liIndent0">
                <a
                  class="allcategories"
                  href={
                    this.props.routeName == "Deals"
                      ? "/Deals/category"
                      : app.route(this.props.routeName, this.resetParams())
                  }
                  config={m.route}
                >
                  All Categories
                </a>
              </li>
            ) : (
              <li className="liIndent0">
                <a
                  class="allcategories"
                  href={app.route(this.props.routeName, this.resetParams())}
                  config={m.route}
                >
                  All Categories 123
                </a>
              </li>
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {this.selected_cat
          ? this.selected_cat.split(">").map(function (cat, i, arr) {
              return (
                <li
                  className={
                    this.selectedCategory &&
                    this.selectedCategory == this.catpath + cat
                      ? "active selected-category " +
                        "liIndent" +
                        this.categoryLevel
                      : "liIndent" + this.categoryLevel
                  }
                >
                  <a
                    href={app.route(
                      this.props.routeName,
                      this.getParams("categories", this.catpath + cat)
                    )}
                    class="child categoriesname"
                    id={cat}
                    tag-id={this.catpath + cat}
                    config={m.route}
                  >
                    {((this.catpath += cat + ">"), (this.categoryLevel += 1))
                      ? ""
                      : ""}
                    {cat == "Undefined" ? "Miscellaneous" : cat}
                  </a>
                </li>
              );
            }, this)
          : ""}
        {this.categoriesList.length > 0
          ? this.categoriesList.map((tag) => {
              //let changepath = tag.path.replace(/ /g, "*");
              if (tag.value != "Undefined") {
                return (
                  <li
                    className={
                      this.selectedCategory && this.selectedCategory == tag.path
                        ? "active selected-category " +
                          "liIndent" +
                          this.categoryLevel
                        : "liIndent" + this.categoryLevel
                    }
                  >
                    <a
                      href={
                        this.params1.query == "category"
                          ? "/Deals/" + encodeURIComponent(tag.path)
                          : app.route(
                              this.props.routeName,
                              this.getParams("categories", tag.path)
                            )
                      }
                      class="child categoriesname"
                      id={
                        this.selectedCategory &&
                        this.selectedCategory == tag.path
                          ? "selected_category"
                          : ""
                      }
                      tag-id={tag.path}
                      config={m.route}
                    >
                      <span class="icon Button-icon"></span>
                      {tag.name != "Undefined" ? tag.name : "Miscellaneous"}
                    </a>
                  </li>
                );
              }
            })
          : ""}
      </ul>
    );
  }

  getParams(paramName, value) {
    let paramsURL = Object.assign({}, m.route.param());
    paramsURL[paramName] = value;
    delete paramsURL.cr;
    return paramsURL;
  }
  resetParams() {
    let paramsURL = Object.assign({}, m.route.param());
    let newParams = {};
    newParams.query = paramsURL.query;
    if (paramsURL.src) {
      if (paramsURL.brands) newParams.brands = paramsURL.brands;
      newParams.src = paramsURL.src;
    }
    newParams.cr = 1;
    return newParams;
  }

  refresh(clear = true) {
    if (clear) {
      this.loading = true;
    }
    return this.loadresult().then(
      (results) => {
        this.parseResults(results);
      },
      () => {
        this.loading = false;
        m.lazyredraw();
      }
    );
  }
  loadresult() {
    let Api_url = "";
    //const selected_cat = this.mainparams.query ? encodeURIComponent(this.mainparams.query.replace("!", ",")) : "";
    // Api_url = 'https://search.thedealapp.in/categoryTree?categories='+(this.cat_select?encodeURIComponent(this.cat_select):"");

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    Api_url =
      ApiEndpoint +
      "/categoryTree?categories=" +
      (this.cat_select ? encodeURIComponent(this.cat_select) : "");

    return app
      .request({ method: "GET", url: Api_url })
      .then(this.parseResults.bind(this));
  }
  parseResults(results) {
    //app.cache.DealsCategories = [];
    if (results) {
      app.cache.DealsCategories = results.categoryList;
      m.redraw();
    }
  }
}
