import Modal from 'flarum/components/Modal';
import LoadingIndicator from 'flarum/components/LoadingIndicator';

export default class IsOutOfStockModal extends Modal {
  //used to check the deal is expired or not
  init() {
      super.init();
      this.loading = true;
      this.processDetails(this.props.data1);
    }

processDetails(results){
    this.dealstatusdata  = results;
    if(this.dealstatusdata== "deal expired" || this.dealstatusdata== "already expired"){
            this.msg = "This Deal Is Expired"
      }else if(this.dealstatusdata== "not inserted"){
            this.msg = "This Deal Is Not Expired Rigth Now";
      }else if(this.dealstatusdata== "greater"){
            this.msg = "NO any Updates Available";
      }
    this.loading = false;
  }
  className() {
    return 'Modal--Big ';
  }

  content() {
    if(this.loading) {
      return (<div class="loading"> {LoadingIndicator.component()} </div>);
    }
    else if(this.dealstatusdata) {
      return [
          m('div', {className: 'Modal-body', style:"background-color: #fffcfc"},  [
            m('div',{className: 'cc_compareGraph'},[
             m('div',
              m("p",{className: 'text-center'},
                  this.msg !="" ?this.msg:this.myfun(),
                )   
             )
            ])
          ])
      ];
    } else {
        return (<div><p class="text-center">NO Updates Available. We will try to do better next time.</p></div>);
    }
  }
}