import { extend } from "flarum/extend";
import DiscussionControls from "flarum/utils/DiscussionControls";
import Button from "flarum/components/Button";
import myButtons from "../myButtons";

export default function addEditControl() {
  //used to edit single discussion
  
  extend(DiscussionControls, "moderationControls", function (items, post) {
    
    const discussion = post;
    if (discussion.canApprove()) {
    let composeButton = new myButtons();
    if (post.isHidden()) return;
    const check_isDeal = discussion.data.attributes.discussionType;
    const check_firstPost = post.data.attributes.number;
    //if(check_isDeal == 'd' && check_firstPost == 1){
    //  items.remove('edit');
    items.add("editDeal", [
      m(
        Button,
        {
          icon: "fa fa-edit",
          className: "",
          onclick: composeButton.editProduct.bind(composeButton, post),
        },
        "Edit Deal Details"
      ),
    ]);
  }
    //  }
  });
}
