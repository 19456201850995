import Component from "flarum/Component";
import Placeholder from "flarum/components/Placeholder";
import Button from "flarum/components/Button";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import Select from "flarum/components/Select";
import DealPlaceHolder from "../DealPlaceHolder";
import ScrollListener from "flarum/utils/ScrollListener";
import ProductCard from "../ProductCard";
import AllDealCardMini from "../AllDealCardMini";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class CategoryProductResults extends Component {
  //used for search & category page to display the products & deals
  init() {
    const params = this.props.params;
    this.loading = true;
    this.productList = [];
    this.deallist = [];
    this.darkMode = m.prop("");
    this.refresh();
    this.moreResults = false;
    this.params1 = m.route.param();
    this.categoriesList = [];
    this.suggestionList = [];
    this.couponsuggestion = [];
    this.dealsuggestion = [];
    this.count = 1;
    this.flag = 1;
    // bottom load more code // Harshal
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    //  bottom load more code end
    this.searchcall();
  }
  view() {
    let parameter = this.props.params;
    let loading;
    let loadingCat;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
      loadingCat = DealPlaceHolder.component({ type: "nav" });
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    let cat = this.props.params.categories.split(">");
    // let categorycheck = "";
    // cat.map(catlist => {
    //   categorycheck = catlist;
    // })
    const text =
      "It looks like there are currently no products available for this search. ";
    return (
      <div>
        <div id="IndexPage_data" class="IndexPage-results cc_dealResults">
        {this.categoriesList.length <= 1 && this.couponsuggestion.length == 0 && this.dealsuggestion == 0 ? "" :
            <div style="width:100%; border-bottom: 1px solid #CECECE; margin-bottom: 5px;height: 60px;">
              <div class="cc_searchslider" style=""><ul class="categoryTree" >
                <div class="Button cc_Buttonsearch hasIcon" style="padding: 17px;background: #132460;">
                <span class="fas fa-sliders-h"></span>
                </div>
                {this.couponsuggestion != undefined && this.couponsuggestion.length != 0 ?
                  this.couponsuggestion != undefined && this.couponsuggestion.length != 0 ? 
                    this.couponsuggestion.map(list1 => {      
                      return (
                      <a class="" style="color:#fff;" href={app.route("searchCoupons" ,this.getParams('query',m.route.param().query))} config={m.route} >
                      <div class="Button cc_Buttonsearch hasIcon" style="color:#fff;background-color:#142977;">
                      <li class=""  >
                        <span class="" style="font-size:16px;"> {list1} <br></br>in coupons</span>
                      </li>
                      </div>
                      </a>
                      );
                    })
                  : "" 
                  : "" }

                {this.dealsuggestion != undefined && this.dealsuggestion.length != 0 ?
                this.dealsuggestion != undefined && this.dealsuggestion.length != 0 ?
                  this.dealsuggestion.map(list2 => {
                    return (
                    <a class="" href={app.route("myProduct" ,this.getParams('query',m.route.param().query))} config={m.route} >
                    <div class="Button cc_Buttonsearch hasIcon" style="font-size: 17px;">
                    <li class="">
                      <span class="" style="font-size:16px;"> {list2} </span><br></br>in Deals 
                    </li>
                    </div>
                    </a>
                    );
                  })
                : "" 
                
                : "" }

                {this.categoriesList != undefined && this.categoriesList ? 
                  this.categoriesList.map(catlist => {
                        let arraylist = catlist.category.split(">");
                        let list = '';
                        let list1 = '';
                        for(var i=0; i<=arraylist.length-1; i++){
                          if(list == ""){
                            list = list + arraylist[i];
                          }else{
                            if(i != arraylist.length-1){
                              list = list + ">" + arraylist[i];
                            }else{
                              list1 = arraylist[i];
                            }
                          }
                        }
                    let color = (catlist.subheading == cat[0] && catlist.heading == cat[cat.length-1]) ? "color:red;" : "color:black";
                    let url = "/Category/"+catlist.category.split(">")[0]+"?query="+m.route.param().query+"&size=0&categories="+encodeURIComponent(catlist.category)+"&dealsearch=dealsearch";
                    return (
                    <a class="" href={url} config={m.route} style={color}>
                    <div class="Button cc_Buttonsearch hasIcon">
                    <li class=""> 
                    <span class="" style="font-size:16px;">{catlist.heading}</span><br></br>in {catlist.subheading}
                    </li></div>
                    </a>
                    );
                  })
                : "" }
              
              </ul></div> 
              
              <div style="width:100%; float: left;">
                <ul class="categoryTree">
                  {this.suggestionList
                    ? this.suggestionList.map((list) => {
                        return list.text != "" ? (
                          <h3>
                            <li class="">
                              Did You Mean
                              <a
                                href={app.route(
                                  this.props.routeName,
                                  this.getParams("query", list.text)
                                )}
                                class=""
                                id=""
                                style="color: #ab0505;"
                                config={m.route}
                              >
                                <span class="icon Button-icon"></span>
                                {list.text}
                              </a>
                            </li>
                          </h3>
                        ) : (
                          ""
                        );
                      })
                    : ""}
                </ul>
              </div>

              <div style="width:50%; float: right; text-align: right;">
                <div style="    position: absolute; right: 10px; color: #FFF;padding: 10px;border-radius: 4px;font-size: 16px;font-weight: bold;border-radius: 0px 0px 10px 10px;width: 110px;">
                  {/* {this.categoriesList.length != 0 ||
                  this.couponsuggestion.length != 0 ||
                  this.dealsuggestion.length != 0 ||
                  (this.suggestionList[0] != undefined &&
                    this.suggestionList[0].text != "")
                    ? Button.component(
                        {
                          className: "Button Button--primary createalertbutton",
                          style:
                            "right: 20px;z-index: 500;background-color: #c20000;box-shadow: 0px 0px 3px 3px #aaa;top: 70px;",
                          onclick: function () {
                            app.modal.show(
                              new PopupModal({
                                categoriesList: varcategoriesList,
                                suggestionList: varsuggestionList,
                                couponsuggestion: varcouponsuggestion,
                                dealsuggestion: vardealsuggestion,
                              })
                            );
                          },
                        },
                        "Search"
                      )
                    : ""} */}
                    {/* {Button.component({
                      className: 'Button Button--primary createalertbutton1',
                      style:'z-index:500;background-color: #0035c2d6;box-shadow: 0px 0px 3px 3px #aaa;',
                      onclick: function () {
                         if (app.session.user) {
                             app.modal.show(new AlertModalKeyword({'data':alertdata,'multiselect':"true"}));
                          } else {
                            app.modal.show(new NewLoginModal());
                          }
                         
                      }
                    },"Create Alert")}

                    {Button.component({
                      className: 'Button Button--primary alertbutton',
                      style:'display:none;position: fixed;right: 20px;z-index: 500;background-color: #c20000;box-shadow: 0px 0px 3px 3px #aaa;',
                      onclick: function () {
                         if (app.session.user) {
                             app.modal.show(new AlertModal({'data':alertdata,'multiselect':"true"}));
                          } else {
                            app.modal.show(new NewLoginModal());
                          }
                         
                      }
                    },"Track Product")} */}
                </div>
              </div>

              <div style="clear: both;"></div>
            </div>
            }

          <div className="DiscussionList">
            <div
              id="dealstores"
              class="cc_productListHeader"
              style="margin-bottom:10px;"
            >
              <span>Sort :</span>
              <div class="cc_productSort">
                {listItems(this.viewItems().toArray())}
              </div>
            </div>

            {this.loading ||
            this.deallist.length > 0 ||
            this.productList.length > 0 ? (
              <div className="DiscussionList">
              <ul className="DiscussionList-discussions cc_dealList">
                
                {this.deallist != undefined && !this.loading
                  ? this.deallist.map((discussion) => {
                      const DiscussionData = {
                        title: discussion._source.title,
                        maxPrice: discussion._source.maxprice,
                        currentPrice: discussion._source.currentprice,
                        discount: discussion._source.discount,
                        ////currency : discussion.data.attributes.currency,
                        url: discussion._source.url,
                        productName: discussion._source.productName,
                        isFeatured: discussion._source.is_featured,
                        isOffer: discussion._source.is_offer,
                        isHotDeal: discussion._source.is_hotdeal,
                        isExpired: discussion._source.is_expired,
                        imageUrl_sm: discussion._source.imageurl_sm,
                        noImageFound: discussion._source.noImageUrl_sm,
                        validFrom: discussion._source.validfrom,
                        validTo: discussion._source.validto,
                        validFor: discussion._source.validfor,
                        badgeText: discussion._source.badgetext,
                        tag_slug: discussion._source.tag_slug,
                        avatar_path: discussion._source.avatar_path,
                        username: discussion._source.username,
                        startUser: "",
                        id: discussion._id,
                        tags: "",
                        secondarytag: discussion._source.secondarytag,
                        routename: this.props.routeName,
                        start_user_id: discussion._source.user_id,
                        start_time: discussion._source.start_time,
                        refreshdate: discussion._source.refreshdate,
                        created_at: discussion._source.created_at,
                      };
                      var cstatus = this.darkMode();
                      return cstatus == true ? (
                        (Date.parse(discussion._source.validTo) || 0) != "0" ? (
                          CouponExpiry(discussion._source.validTo) !=
                          "Expired" ? (
                            <li key={discussion._id} data-id={discussion._id}>
                              {AllDealCardMini.component({ DiscussionData })}
                            </li>
                          ) : (
                            ""
                          )
                        ) : (
                          <li key={discussion._id} data-id={discussion._id}>
                            {AllDealCardMini.component({ DiscussionData })}
                          </li>
                        )
                      ) : cstatus == false ? (
                        <li key={discussion._id} data-id={discussion._id}>
                          {AllDealCardMini.component({ DiscussionData })}
                        </li>
                      ) : (
                        ""
                      );
                    })
                  : loading}

                {this.productList
                  ? this.productList.map((product) => {
                      var multipleproduct = "true";
                      const ProductData = {
                        title: product._source.Title,
                        product_id: product._id,
                        maxPrice: product._source.MRP,
                        currentPrice: product._source.sellingPrice,
                        productOutOfStock: product._source.productOutOfStock,
                        discount: product._source.discount,
                        url:
                          app.forum.data.attributes.baseUrl +
                          "/blossom?bid=" +
                          product._source.linkid +
                          "&url=" +
                          product._source.productURL +
                          "&src=product",
                        imageUrl_sm: product._source.imageURL,
                        merchantName: product._source.merchantName,
                        slug: product._source.slug,
                        tid: product._source.tid,
                        TDAProductId: product._source.TDAProductId,
                        tdaCategory: product._source.tdaCategory,
                        multipleproduct: multipleproduct,
                        Hotness: product._source.Hotness,
                        fulfilledBy: product._source.fulfilledBy,
                        lastupdatedon: product._source.LastUpdatedOn,
                      };

                      return (
                        <li class="cc_productCard">
                          {ProductCard.component({ ProductData })}
                        </li>
                      );
                    })
                  : loading}
                <li>
                  {this.productList.length > 0 || this.deallist.length > 0
                    ? this.productList.length % 20 === 0 ||
                      this.deallist.length % 20 === 0
                      ? DealPlaceHolder.component({})
                      : ""
                    : ""}
                </li>
              </ul>
              <div style="clear:both"></div>
              </div>
            ) : (
              <div className="DiscussionList">
                {Placeholder.component({ text })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  loadMore() {
    this.loading = true;
    if (m.route.param().dealsearch) {
      this.loadResults(this.deallist.length).then(this.parseResults.bind(this));
    } else {
      this.productdata(this.productList.length);
    }
  }
  onScroll(pos) {
    if (
      (this.productList.length % 20 === 0 &&
        !this.loading &&
        this.productList.length > 0) ||
      (this.deallist.length % 20 === 0 && !this.loading)
    ) {
      if (pos >= $(document).height() - $(window).height() - 450) {
        this.loadMore();
      }
    }
  }
  config(isInitialized, context) {
    if (!isInitialized) return;
    setTimeout(() => this.scrollListener.start());
    context.onunload = () => {
      this.scrollListener.stop();
    };
  }
  parseResults(results) {
    if (results) {
      if (results.result != "category does not exists") {
        if (results.result != undefined) {
          if (results.result.hits != undefined) {
            if (m.route.param().dealsearch) {
              [].push.apply(this.deallist, results.result.hits.hits);
            } else {
              [].push.apply(this.productList, results.result.hits.hits);
            }
          }
        }

        if (this.count == 1) {
          if (
            (this.deallist &&
              this.deallist.length % 20 != 0 &&
              this.productList == "") ||
            this.deallist == ""
          ) {
            this.productdata();
          }
        }

        this.loading = false;
        this.moreResults = true;
        m.lazyRedraw();
        return results;
      } else {
        this.flag++;
        this.loading = false;
        this.moreResults = false;
        m.lazyRedraw();
      }
    } else {
      this.loading = false;
      m.lazyRedraw();
    }
  }

  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    if (params.categories == undefined) {
      if (this.props.routeName != "myProduct") {
        var hostnameTag = app.store.getBy("tags", "slug", params.category);
        var nametag = decodeURIComponent(hostnameTag.data.attributes.name);
        params.categories = nametag;
      } else {
        params.categories = "";
      }
    }
    // let Api_url = 'https://search.thedealapp.in/dealSearch';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    let Api_url = ApiEndpoint + "/dealSearch";

    if ((Api_url = Api_url)) {
      params.dealsearch = "dealsearch";
    }
    return app.request({
      method: "GET",
      url: Api_url,
      data: params,
    });
  }
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.productList = [];
    }

    return this.loadResults().then(
      (results) => {
        this.parseResults(results);
      },
      () => {
        this.loading = false;
        m.lazyRedraw();
      }
    );
  }
  requestParams(offset) {
    const params = m.route.param();
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }

  getParams(paramName, value) {
    let paramsURL = Object.assign({}, m.route.param());
    paramsURL[paramName] = value;
    return paramsURL;
  }

  productdata(perpage) {
    let Api_url;
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    delete params.dealsearch;
    if (params.query) {
      params.src = "search";
    } else {
      if (params.categories == undefined) {
        var hostnameTag = app.store.getBy("tags", "slug", params.category);
        var nametag = decodeURIComponent(hostnameTag.data.attributes.name);
        params.categories = nametag;
      }
      params.src = "cat";
    }
    this.count++;
    if (this.flag == 1) {
      // Api_url = 'https://search.thedealapp.in/';
      const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
      Api_url = ApiEndpoint + "/";
      return $.ajax({
        type: "GET",
        context: this,
        data: params,
        url: Api_url,
        error: { 404: function () {} },
      }).then(this.parseResults.bind(this));
    }
  }
  viewItems() {
    const items = new ItemList();
    const sortMap = {
      relevance: "Relevance",
      salesAsc: "Popularity",
      priceAsc: " Price Low-To-High",
      priceDesc: "Price High-To-Low",
      discDesc: "Discount High To Low",
    };
    const sortOptions = {
      relevance: "Relevance",
      salesAsc: "Popularity",
      priceAsc: "Price Low-To-High",
      priceDesc: "Price High-To-Low",
      discDesc: "Discount High To Low",
    };
    items.add(
      "sort",
      Select.component({
        options: sortOptions,
        value: m.route.param().sort || Object.keys(sortMap)[0],
        onchange: this.changeSort.bind(this),
      })
    );

    return items;
  }
  changeSort(sort) {
    if (sort !== "") {
      var sort = sort;
      m.route(app.route(this.props.routeName, this.getParams("sort", sort)));
    }
  }

  searchcall(perpage) {
    let Api_url;
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    // Api_url = 'https://search.thedealapp.in/search';
    if(!params.search == true){
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    Api_url = ApiEndpoint + "/search";
    return app
      .request({
        method: "GET",
        context: this,
        url: Api_url,
        data: params,
      })
      .then(this.searchResults.bind(this));
    }
  }

  searchResults(results){
    const params = this.props.params;
    if (results) {
      [].push.apply(this.categoriesList, results.category_suggestion);
      if (results.suggestion != undefined) {
        [].push.apply(
          this.suggestionList,
          results.suggestion.simple_phrase[0].options
        );
      }
      [].push.apply(this.couponsuggestion, results.coupon_suggestion);
      [].push.apply(this.dealsuggestion, results.deal_suggestion);
    }
    if(this.categoriesList.length <= 1 && this.categoriesList[0] != undefined)
      {
        m.route.param().categories = this.categoriesList[0].category;
        m.route.param().category =  this.categoriesList[0].category.split(">")[0];
      }
    m.redraw();
  }
} //end
