import Page from 'flarum/components/Page';
import SearchResults from './../search/SearchResults';

export default class ProductSearchPage extends Page {
  //used for Search page(Indexpage) https://thedealapp.in/search?query=samsung
      init() {
            super.init();
            const params1 = m.route.param();
            //let params1 = Object.assign({}, m.route.param());
            //params1["dealsearch"] = "dealsearch";
            this.category_id = m.route.param('categories') ? m.route.param('categories') : '';            
            this.SearchResults = new SearchResults({params:params1,routeName:this.props.routeName});
            
      }
     view() {
      return(
          <div class="searchResultsPage" >
            <div className="container resultsContainer">
               {this.SearchResults.render()}
            </div>
        </div>
      )
    }
}