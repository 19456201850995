import Modal from 'flarum/components/Modal';

export default class PopupModal extends Modal {
  //used for see more button for brands on search and category page
  init() {
        super.init(this.props); 
        this.categoriesList = this.props.categoriesList;
        this.suggestionList = this.props.suggestionList;
        this.couponsuggestion = this.props.couponsuggestion;
        this.dealsuggestion = this.props.dealsuggestion;
    }


  className() {
    return 'Modal--Big brandModal';
  }

  title() {
    let suggestionList = this.suggestionList; 
    return app.translator.trans('Lets Refine Your Search For Better Result' +
      suggestionList != undefined && suggestionList.length != 0 ? 
                  suggestionList.map(list => {      
                    return (
                    <div>
                    <span>Lets Refine Your Search For Better Results</span> <br />
                    <span style="font-size: 17px">Did You Mean
                      <a class="" style="color:#ab0505;" href={app.route("search" ,this.getParams('query',list.text))} config={m.route} >
                      <span class="icon Button-icon"></span>{list.text}
                      </a>  Instead of {m.route.param().query} </span>
                    </div>
                    );
                  })
                : "Lets Refine Your Search For Better Results");
  }

  content() {
    let arraylist = [];
    return [
      m('div', {className: 'Modal-body popupModal'}, [
        <div>
        <ul class="categoryTree" >
                {this.couponsuggestion != undefined && this.couponsuggestion.length != 0 ?
                <div class="Button cc_Buttonsearch hasIcon" style="font-size: 17px;color:#fff;background-color:#142977;">
                {this.couponsuggestion != undefined && this.couponsuggestion.length != 0 ? 
                  this.couponsuggestion.map(list1 => {      
                    return (
                    <li class=""  >Search for 
                      <a class="" href={app.route("searchCoupons" ,this.getParams('query',m.route.param().query))} config={m.route} >
                      <span class="" style="color:#fff;"> {list1} in coupons</span>
                      </a>
                    </li>
                    );
                  })
                : "" }
                </div>
                : "" }
                {this.couponsuggestion != undefined && this.couponsuggestion.length != 0 ? <div style="text-align:center;font-weight:bold;padding-top:10px;"><span>OR</span></div> : ""}
                
                {this.dealsuggestion != undefined && this.dealsuggestion.length != 0 ?
                <div class="Button cc_Buttonsearch hasIcon" style="font-size: 17px;">
                {this.dealsuggestion != undefined && this.dealsuggestion.length != 0 ?
                  this.dealsuggestion.map(list2 => {
                    return (
                    <li class="">Search for
                      <a class="" href={app.route("myProduct" ,this.getParams('query',m.route.param().query))} config={m.route} >
                      <span class=""> {list2} in Deals </span>
                      </a>
                    </li>
                    );
                  })
                : "" }
                </div>
                : "" }
                {this.dealsuggestion != undefined && this.dealsuggestion.length != 0 ? <div style="text-align:center;font-weight:bold;padding-top:10px;"><span>OR</span></div> : ""}
                
                {this.categoriesList != undefined && this.categoriesList.length != 0 ? <div><span><h3>Search in Categories</h3></span></div> : ""}
                {this.categoriesList != undefined && this.categoriesList ? 
                  this.categoriesList.map(catlist => {
                        let arraylist = catlist.split(">");
                        let list = '';
                        let list1 = '';
                        for(var i=0; i<=arraylist.length-1; i++){
                          if(list == ""){
                            list = list + arraylist[i];
                          }else{
                            if(i != arraylist.length-1){
                              list = list + ">" + arraylist[i];
                            }else{
                              list1 = arraylist[i];
                            }
                          }
                        }
                    let url = "/Category/"+catlist.split(">")[0]+"?query="+m.route.param().query+"&size=0&categories="+encodeURIComponent(catlist)+"&dealsearch=dealsearch";
                    return (
                    <li class=""> 
                    
                      <a class="Button cc_Buttonsearch hasIcon" href={url} config={m.route}>
                      <span class="icon Button-icon"></span>{list1 ? list + ">" : <b>{list} </b>} <b>{list1} </b>
                      </a>
                    </li>
                    );
                  })
                : "" } 
                
        </ul> 
        </div> 
      ]),
         m("h3",m("a",({className:'Button Button--primary btnModalBottom',onclick:this.hide.bind()}),"No , I Want to see all results"))
    ];

  }

  hide(){
    app.modal.close();
  }

  onready() {}

  onerror(error) {
    if (error.status === 401) {
      error.alert.props.children = app.translator.trans('core.forum.log_in.invalid_login_message');
    }
    super.onerror(error);
  }

  getParams(paramName,value)
      { 
            let paramsURL = Object.assign({},  m.route.param());
            paramsURL[paramName] = value;
            return paramsURL;    
      }
}
