import Component from "flarum/Component";
import slidable from "flarum/utils/slidable";
import extractText from "flarum/utils/extractText";
import classList from "flarum/utils/classList";
import CouponExpiry from "../coupon/CouponExpiry";
import Button from "flarum/components/Button";
import Coupomod from "../coupon/CouponModalv2";
import DealsListSlider from "./../DealsListSlider";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class CouponDetailsStructre extends Component {
  init() {
    const discussion = this.props.discussion;
    this.CouponName = discussion._source.tag_slug;
    this.MerchantName = discussion._source.merchantname;
    this.discussion = this.props.discussion;
  }

  view() {
    const discussion = this.props.discussion;
    this.CouponName = discussion._source.tag_slug;
    if (discussion._source.slug) {
      this.discussionRoute =
        app.forum.data.attributes.baseUrl + "/d/" + discussion._id + "-" + discussion._source.slug;
    } else {
      this.discussionRoute =
        app.forum.data.attributes.baseUrl + "/d/" + discussion._id;
    }

    return (
      <div
        class="cc_couponParentBlock cc_couponParentBlockcard"
        style="height:auto !important; display: grid;"
      >
        <div class="couponMainContaint">
          <div class="coupondesc" style="">
            <div class="cc_couponImageTextBox" style="background:#fff;">
              <div class="couponCBBox" style="">
                <span style="display: table-cell; vertical-align: middle; max-width: 85px; word-wrap: break-word;color: #000;">
                  {discussion._source.badgetext ? discussion._source.badgetext : discussion._source.badge}
                </span>
              </div>
            </div>
          </div>
          <div class="cc_couponRightBlockDet">
            <div class="cc_couponTopLeft">
              {discussion._source.title}
              <br />
            </div>
            <div class="cc_couponTopRight"></div>
            <div class="cc_couponTopBottomLeft" style="font-size: inherit;">
              <div class="cc_inlineBlock" style="margin-right: 10px;"></div>
              <div class="cc_inlineBlock"></div>
            </div>
          </div>
        </div>
        <div class="couponFooterContaint">
          <div class="couponNav">
            <ul class="couponList">
              {discussion._source.productinfo || discussion._source.description ?
                < li class="couponFooterList btnreadmoreli">
                  {Button.component({
                    id: "btnReadMore",
                    className: "button",
                    style:
                      "border: 0px; background: none; color: #00B; text-transform: none !important;display:inline;",
                    children: "Show Details",
                    onclick: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if ($(e.currentTarget).text() == "Show Details") {
                        $(e.currentTarget)
                          .parent()
                          .parent()
                          .parent()
                          .parent()
                          .children(".cc_couponBotFull")
                          .css({
                            display: "block",
                          });
                        $(e.currentTarget).text("Hide Details");
                      } else {
                        $(e.currentTarget)
                          .parent()
                          .parent()
                          .parent()
                          .parent()
                          .children(".cc_couponBotFull")
                          .css({
                            display: "none",
                          });
                        $(e.currentTarget).text("Show Details");
                      }
                    },
                  })}
                </li> : ""
              }
              {/* {discussion._source.hasPromoCode === false || discussion._source.hasPromoCode == undefined ?
                <li class="couponFooterList btngetcpnli">
                  {Button.component({
                    id: "btn" + discussion._id,
                    className:
                      "Buttongetcoupon Button--primary getcouponbtn btngetcpn",
                    children: "Get Offer",
                    onclick: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      app.modal.show(new Coupomod({ discussion }));

                      this.url = discussion._source.url;
                      var Url = this.url;

                      var RedirectUrl = window.open(Url, "_blank");
                      if (RedirectUrl) {
                        //Browser has allowed it to be opened
                        RedirectUrl.focus();
                      } else {
                        //Browser has blocked it
                        alert("Please allow popups for this website");
                      }
                    },
                  })}
                </li> : */}
              <li class="couponFooterList btngetcpnli">
                {Button.component({
                  id: "btn" + discussion._id,
                  className:
                    "Buttongetcoupon Button--primary getcouponbtn btngetcpn",
                  children: "Get Coupon",
                  onclick: this.getCouponCode.bind({ discussion }),
                  // onclick: (e) => {
                  //   e.stopPropagation();
                  //   e.preventDefault();
                  //   app.modal.show(new Coupomod({ discussion }));

                  //   this.url = discussion._source.url;
                  //   var Url = this.url;

                  //   var RedirectUrl = window.open(Url, "_blank");
                  //   if (RedirectUrl) {
                  //     //Browser has allowed it to be opened
                  //     RedirectUrl.focus();
                  //   } else {
                  //     //Browser has blocked it
                  //     alert("Please allow popups for this website");
                  //   }
                  // },
                })}
              </li>
              {/* } */}

              {!discussion._source.comments_count == '' || !discussion._source.comments_count == undefined ? (
                <li class="couponFooterList commentli">
                  {Button.component({
                    id: "btncomment",
                    className: "button",
                    style:
                      "border: 0px; background: none; color: #00B; text-transform: none !important;display:inline;",
                    children:
                      "Comments (" + discussion._source.comments_count + ")",
                    onclick: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (
                        $("#cc_couponBotcommentFull").css("display") == "none"
                      ) {
                        $(e.currentTarget)
                          .parent()
                          .parent()
                          .parent()
                          .parent()
                          .children(".cc_couponBotcommentFull")
                          .css({
                            display: "block",
                          });

                        $(e.currentTarget).text(
                          "Comments (" + discussion._source.comments_count + ")"
                        );

                        $(".cc_couponDescd").css("display", "none");
                      } else {
                        $(e.currentTarget)
                          .parent()
                          .parent()
                          .parent()
                          .parent()
                          .children(".cc_couponBotcommentFull")
                          .css({
                            display: "none",
                          });

                        $(e.currentTarget).text(
                          "Comments (" + discussion._source.comments_count + ")"
                        );
                        $(".cc_couponDescd").css("display", "none");
                      }
                    },
                  })}
                </li>
              ) : (
                <li class="couponFooterList commentli">
                  {discussion._source.type != "auto" ?
                    <a href={this.discussionRoute} config={m.route}>
                      {Button.component({
                        id: "btncomment",
                        className: "button",
                        style:
                          "border: 0px; background: none; color: #00B; text-transform: none !important;display:inline;",
                        children:
                          "Comments",
                        // onclick: (e) => {
                        //   e.stopPropagation();
                        //   e.preventDefault();
                        //   if (
                        //     $("#cc_couponBotcommentFull").css("display") == "none"
                        //   ) {
                        //     $(e.currentTarget)
                        //       .parent()
                        //       .parent()
                        //       .parent()
                        //       .parent()
                        //       .children(".cc_couponBotcommentFull")
                        //       .css({
                        //         display: "block",
                        //       });
                        //     $(e.currentTarget).text(
                        //       "Comments"
                        //     );
                        //     $(".cc_couponDescd").css("display", "none"); 
                        //   } else {
                        //     $(e.currentTarget)
                        //       .parent()
                        //       .parent()
                        //       .parent()
                        //       .parent()
                        //       .children(".cc_couponBotcommentFull")
                        //       .css({
                        //         display: "none",
                        //       });

                        //     $(e.currentTarget).text(
                        //       "Comments"
                        //     );
                        //     $(".cc_couponDescd").css("display", "none"); 
                        //   }
                        // },
                      })}
                    </a> : ""}
                </li>
              )}


              {!discussion._source.validto == '' || !discussion._source.validto == null ? (

                <li class="couponFooterList ExpireLi">
                  {"Expiring " + CouponExpiry(discussion._source.validto)}
                </li>
              ) : (
                <li class="couponFooterList ExpireLi">
                  {!discussion._source.endDate == '' || !discussion._source.endDate == null ? new Date(discussion._source.endDate) <= new Date() ? "" : "Expiring " + CouponExpiry(discussion._source.endDate) : ""}
                </li>
              )}

            </ul>
          </div>
          <br />
          <div class="cc_couponBotFull" style="display:none;">
            {m("div", { class: "cc_couponDesc" }, [
              m.trust(!discussion._source.productinfo ? discussion._source.description ? discussion._source.description : discussion._source.productinfo : discussion._source.productinfo),
            ])}
          </div>
          <div
            class="cc_couponBotcommentFull"
            id="cc_couponBotcommentFull"
            style="display:none;"
          >
            {m("div", { class: "cc_couponDescd" }, [
              m.trust(discussion._source.productinfo),
            ])}
            <div class="go-replyBox go-cmntSection" data-couponid="55548">
              <textarea
                class="go-textA"
                placeholder="Comment here..."
              ></textarea>
              <span class="cancelReply">cancel</span>
              <small class="sendRep"></small>
            </div>
          </div>
        </div>
      </div >
    );
  }

  getCouponCode(data) {
    const discussion = this.discussion;
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
      app.modal.show(new Coupomod({ discussion }));
      this.url = discussion._source.url;
      var Url = this.url;

      var RedirectUrl = window.open(Url, "_blank");
      if (RedirectUrl) {
        //Browser has allowed it to be opened
        RedirectUrl.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    } else {
      var Api_url = ApiEndpoint + "/api/v3/coupon/promocode?id=" + discussion._source.id;

      var result = app.request({
        method: 'GET',
        url: Api_url,
      }).then(response => {
        var promocode = response.promocode;
        app.modal.show(new Coupomod({ discussion, promocode }));

        this.url = discussion._source.url;
        var Url = this.url;

        var RedirectUrl = window.open(Url, "_blank");
        if (RedirectUrl) {
          //Browser has allowed it to be opened
          RedirectUrl.focus();
        } else {
          //Browser has blocked it
          alert("Please allow popups for this website");
        }
      });
    }

  }

}