import Component from "flarum/Component";
import avatar from "flarum/helpers/avatar";
import humanTime from "flarum/utils/humanTime";
import slidable from "flarum/utils/slidable";
import extractText from "flarum/utils/extractText";
import CouponExpiry from "../storePages/CouponExpiry";

export default class DailyDealCard extends Component {
  //Used for Dealmachine to show single deal
  init() {}
  setDealData() {
    this.title = m.prop(this.props.DiscussionData.title || "");
    this.Discount_rangefrom = m.prop(
      this.props.DiscussionData.Discount_rangefrom || ""
    );
    this.Discount_rangeto = m.prop(
      this.props.DiscussionData.Discount_rangeto || ""
    );
    this.MRP = m.prop(this.props.DiscussionData.MRP || "");
    this.dealId = m.prop(this.props.DiscussionData.dealId || "");
    this.dealPrice = m.prop(this.props.DiscussionData.dealPrice);
    this.dealType = m.prop(this.props.DiscussionData.dealType || "");
    this.disc_rank_HtoL = m.prop(
      this.props.DiscussionData.disc_rank_HtoL || ""
    );
    this.disc_rank_LtoH = m.prop(
      this.props.DiscussionData.disc_rank_LtoH || ""
    );
    this.imageurl = m.prop(this.props.DiscussionData.imageurl || "");
    this.isCalculated = m.prop(this.props.DiscussionData.isCalculated || "");
    this.isExpired = m.prop(this.props.DiscussionData.isExpired || "");
    this.isLive = m.prop(this.props.DiscussionData.isLive || "");
    this.merchantname = m.prop(this.props.DiscussionData.merchantname || "");
    this.pid = m.prop(this.props.DiscussionData.pid || "");
    this.price_rangefrom = m.prop(this.props.DiscussionData.price_rangefrom);
    this.price_rangeto = m.prop(this.props.DiscussionData.price_rangeto || "");
    this.price_rank_HtoL = m.prop(
      this.props.DiscussionData.price_rank_HtoL || ""
    );
    this.price_rank_LtoH = m.prop(
      this.props.DiscussionData.price_rank_LtoH || ""
    );
    this.sellingPrice = m.prop(this.props.DiscussionData.sellingPrice || "");
    this.url = m.prop(this.props.DiscussionData.url || "");
    this.id = m.prop(this.props.DiscussionData.id || "");
    this.tags = app.store.getBy(
      "tags",
      "slug",
      this.merchantname().toLowerCase()
    );
    this.dealStartTime = m.prop(this.props.DiscussionData.dealStartTime || "");
    this.dealEndTime = m.prop(this.props.DiscussionData.dealEndTime || "");
    this.heading = m.prop(this.props.DiscussionData.heading || "");
    this.totaldiscount = ((this.MRP() - this.dealPrice()) / this.MRP()) * 100;
    this.effectivediscount =
      ((this.sellingPrice() - this.dealPrice()) / this.sellingPrice()) * 100;
    this.validTo_Expired = false;
    this.price_rangefrom_original = m.prop(
      this.props.DiscussionData.price_rangefrom_original || ""
    );
    this.price_rangeto_original = m.prop(
      this.props.DiscussionData.price_rangeto_original || ""
    );
    this.storeimagepath = "";
  }
  setCounDown(setDate, setID, startText, endText, textColor) {
    $("#" + setID).show();
    $("#" + setID).css("color", textColor);
    $("#" + setID)
      .countdown(setDate)
      .on("update.countdown", function (event) {
        var format = "%H:%M:%S";
        if (event.offset.totalDays > 0) {
          format = "%-d day%!d " + format;
        }
        if (event.offset.weeks > 0) {
          format = "%-w week%!w " + format;
        }
        $(this).html(startText + event.strftime(format));
      })
      .on("finish.countdown", function (event) {
        $(this).html(endText).parent().addClass("disabled");
        $(this).css("color", textColor);
      });
  }
  checkFutureTime(myTime) {
    var checkTime = new Date(parseInt(myTime)).getTime();
    var now = new Date().getTime();
    if (checkTime > now) {
      return true;
    } else {
      return false;
    }
  }
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  view() {
    this.setDealData();
    var endDateForStamp = this.dealEndTime();
    if (endDateForStamp != "0000-00-00 00:00:00") {
      if (CouponExpiry(new Date(parseInt(this.dealEndTime()))) == "past") {
        this.validTo_Expired = true;
      }
    }

    var tags = this.tags;
    if (tags) {
      this.storeimagepath = tags.data.attributes.imagePath;
      var storeName = tags.data.attributes.name;
    } else {
      this.storeimagepath = "";
      var storeName = "";
    }

    return m(
      "div",
      { class: "dealCard_mini", config: this.configArea.bind(this) },
      <div className={"DiscussionListItem-content"}>
        <div>
          {this.heading() !== "Upcoming Deal" &&
          this.totaldiscount !== 100 &&
          this.totaldiscount !== 0 &&
          this.totaldiscount != "-Infinity" &&
          this.totaldiscount > 0 ? (
            <div class="cc_hotness_DCM">
              {this.totaldiscount.toFixed(2).replace(/\.0+$/, "")}% OFF
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          class="cc_productBlock_mini cc_borderRightBottom"
          itemscope
          itemtype="http://schema.org/Product"
        >
          <div class="noUser"></div>
          <a href="" config={m.route}>
            <div
              class="text-center cc_imageBox_DCM"
              style={
                tags
                  ? tags.data.attributes.name == "TataCliq"
                    ? ""
                    : ""
                  : ""
              }
            >
              <div class="cc_imageFrameDealPage">
                <span class="cc_imageHelper"></span>
                {this.imageurl() ? (
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={this.imageurl()}
                    alt="Product Image"
                    onError={"this.src='" + this.storeimagepath + "'"}
                  />
                ) : (
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={this.storeimagepath}
                    alt="no image found"
                  />
                )}
              </div>
            </div>
          </a>
          <div class="cc_detailsBox_mini">
            <a href="" config={m.route}>
              <div
                itemprop="offers"
                itemscope
                itemtype="http://schema.org/Offer"
              >
                {this.MRP() ? (
                  <div class="cc_offerText_DCM">
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    {this.MRP()}
                  </div>
                ) : (
                  <div class="cc_offerText"></div>
                )}
                {(this.heading() == "Upcoming Deal" &&
                  this.dealPrice() == "") ||
                m.route.param().dealtype == "u" ? (
                  <div
                    class="cc_productPrice_DCM"
                    style="width: 100% !important;"
                  >
                    (Expected Price )
                    <i itemprop="priceCurrency" content="Rs"></i>
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    <span itemprop="price">
                      {this.price_rangefrom().toFixed(2).replace(/\.0+$/, "")}
                    </span>
                    -
                    <span itemprop="price">
                      {this.price_rangeto().toFixed(2).replace(/\.0+$/, "")}/-
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {this.dealPrice() != 0 ? (
                  <div class="cc_productPrice_DCM">
                    <i itemprop="priceCurrency" content="Rs"></i>
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    <span itemprop="price">
                      {this.dealPrice().toFixed(2).replace(/\.0+$/, "")}
                    </span>
                    /-
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <span itemprop="price">
                    {this.price_rangefrom_original()}
                  </span>
                  -
                  <span itemprop="price">
                    {this.price_rangeto_original()}/-(Expected Price)
                  </span>
                </div>
                <span
                  itemprop="availability"
                  href="http://schema.org/InStock"
                />
                <span
                  itemprop="offeredBy"
                  itemscope
                  itemtype="http://schema.org/Organization"
                >
                  <span itemprop="legalName" content={storeName} />
                </span>
              </div>
              <div class="cc_titleBox_DCM">
                <h3 class="cc_titleText_DCM" itemprop="name">
                  {this.title()}
                </h3>
              </div>
            </a>
            <div class="cc_bottomBar_DCM">
              <div class="cc_siteIcons_DCM">
                {this.tags ? (
                  <span class="TagsLabel ">
                    <a class="" title="" href={"/t/" + this.merchantname}>
                      <span class="TagLabel-text">
                        <img
                          src={this.tags.data.attributes.imagePath}
                          height="16.5em"
                        />
                      </span>
                    </a>
                  </span>
                ) : (
                  ""
                )}
              </div>
              {this.url() ? (
                <div class="dealCardMini_Btn">
                  <a
                    class="button button-sm btn-danger cc_buyNowBtn_mini"
                    href={this.url()}
                    target="_blank"
                  >
                    Get Deal
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
            <div class="cc_expiryText">
              {!this.validTo_Expired == true ? (
                <div class="countdown" id={this.id()}></div>
              ) : (
                <div class="countdown" id={this.id()}></div>
              )}
            </div>
            <div class="cc_titleBox_DCM">
              {this.dealType() ? (
                <span>{this.dealType().replace(/_/g, " ")}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <a href="" config={m.route}></a>
      </div>
    );
  }
  configArea(element, isInitialized) {
    $(".hotDealBar").show();
    $(".popularBar").show();
    var FromStartText = "Starts in: ";
    var FromEndText = "This deal has started!";
    var ToStartText = "Ends in: ";
    var ToEndText = "This deal has expired!";
    var startsIn = this.dealStartTime();
    var endsIn = this.dealEndTime();
    var headingdata = this.heading();
    if (
      (startsIn != "0000-00-00 00:00:00" &&
        this.checkFutureTime(startsIn) &&
        startsIn &&
        headingdata == "Upcoming Deal") ||
      m.route.param().dealtype == "u"
    ) {
      this.setCounDown(
        startsIn,
        this.id(),
        FromStartText,
        FromEndText,
        "#00B504"
      );
    } else if (
      (endsIn != "0000-00-00 00:00:00" &&
        endsIn &&
        headingdata == "Live Deal") ||
      m.route.param().dealtype == "l"
    ) {
      this.setCounDown(endsIn, this.id(), ToStartText, ToEndText, "#00B504");
    } else if (
      (endsIn != "0000-00-00 00:00:00" &&
        endsIn &&
        headingdata == "Expired Deal") ||
      m.route.param().dealtype == "u"
    ) {
      this.setCounDown(endsIn, this.id(), ToStartText, ToEndText, "#C20000");
    }
  }
  config(isInitialized) {
    if (isInitialized) return;

    // If we're on a touch device, set up the discussion row to be slidable.
    // This allows the user to drag the row to either side of the screen to
    // reveal controls.
    if ("ontouchstart" in window) {
      const slidableInstance = slidable(this.$().addClass("Slidable"));

      this.$(".DiscussionListItem-controls").on("hidden.bs.dropdown", () =>
        slidableInstance.reset()
      );
    }
  }
}
