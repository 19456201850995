/**
 * The `humanTime` utility converts a date to a localized, human-readable time-
 * ago string.
 *
 * @param {Date} time
 * @return {String}
 */
export default function CouponExpiry(time) {
  let m = moment(time);
  const now = moment();

  // To prevent showing things like "in a few seconds" due to small offsets
  // between client and server time, we always reset future dates to the
  // current time. This will result in "just now" being shown instead.
  //kunal commented code
//  if (m.isBefore(now)) {
//  return "Expired";
//  }
const day = 864e5;
const diff = m.diff(moment());
let ago = null;
if (m < now) {     
//      if (diff >  day) {
//            ago = 'yes';
//      }else{
//            ago = 'past';
//      } 
      ago = 'past';
  }else{
      ago = 'future';      
  }
  return ago;
};