import Component from "flarum/Component";
import slidable from "flarum/utils/slidable";
import extractText from "flarum/utils/extractText";
import classList from "flarum/utils/classList";
import CouponExpiry from "../coupon/CouponExpiry";
import Button from "flarum/components/Button";

export default class CouponStoreListItems extends Component {
  //Used in Coupons for display Particular Coupons
  init() {}

  attrs() {
    return {
      className: classList([
        "DiscussionListItem col",
        this.active() ? "active" : "",
        // this.props.discussion.isHidden() ? "DiscussionListItem--hidden" : "",
      ]),
    };
  }

  view() {
    const discussion = this.props.discussion;
    const attrs = this.attrs();

    //this.storeName = discussion.key.merchantname;
    this.storeName = discussion.key;

    this.couponStoreroute =
      app.forum.data.attributes.baseUrl + "/store/" + this.storeName;
    return (
      <div>
        <a href={this.couponStoreroute} config={m.route}>
          <div class="cc_couponParentBlock">
            <div style="height:115px">
              <div class="cc_couponImageTextBox1">
                <div>
                  {discussion.store_logo != "" ? (
                     <img src={discussion.store_logo} style="" />
                    //<img src={discussion.key.imagepath} style="" />
                  ) : (
                    <span style="text-transform: capitalize;font-size: 45%">
                      {this.storeName}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div style="clear:both"></div>

            <div class="couponStoreText">
              <span
                style="font-size:15px;"
                class="tooltiptext1"
                data-toggle="tooltip"
              >
                Explore Coupons and Offers from {this.storeName} ... >
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  }

  config(isInitialized) {
    if (isInitialized) return;

    // If we're on a touch device, set up the discussion row to be slidable.
    // This allows the user to drag the row to either side of the screen to
    // reveal controls.
    if ("ontouchstart" in window) {
      const slidableInstance = slidable(this.$().addClass("Slidable"));

      this.$(".DiscussionListItem-controls").on("hidden.bs.dropdown", () =>
        slidableInstance.reset()
      );
    }
  }

  /**
   * Determine whether or not the discussion is currently being viewed.
   *
   * @return {Boolean}
   */
  active() {
    const idParam = m.route.param("id");
    return idParam && idParam.split("-")[0] === this.props.discussion.id();
  }
}
