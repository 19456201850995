import Page from 'flarum/components/Page';
import PriceDropProductResults from './PriceDropProductResults';
import SideNavPriceDrop from './SideNavPriceDrop';

export default class PriceDropProductPage extends Page {
  //Used for Pricedrop page(Indexpage) https://thedealapp.in/Pricedrop
      init() {
           super.init();
           const params1 = m.route.param();
           this.ProductResults = new PriceDropProductResults({params:params1,routeName:this.props.routeName});
      }
     view() {
       return(
          <div class="searchResultsPage" >
            <div className="container resultsContainer">
              <h2 style="color:#e88b00;" >
              <i class="fa fa-arrow-down"></i>
              {m.route.param("categories") ? "Price Drops in "+ m.route.param("categories") : "Price Drops"} </h2>
              <p style="color: grey;">Check out recent price drops below already filtered by our proprietary algorithm.. Further, Sort by time and hotness, filter by time, percentage drops, discount, stores etc..</p>
              <hr/>
              <nav className="IndexPage-nav sideNav categoryNav" >
                {SideNavPriceDrop.component({routeName:this.props.routeName })}
              </nav>
              {this.ProductResults.render()}         
            </div>
        </div>
      )
    } 
}