import { extend } from "flarum/extend";
import Button from "flarum/components/Button";
import DiscussionHero from "flarum/components/DiscussionHero";
import CouponExpiry from "../storePages/CouponExpiry";
import tagsLabel from "flarum/tags/helpers/tagsLabel";
//import vote_bar from "tda/discussionvotes/components/vote_bar";

export default function () {
  //used for single coupon display https://thedealapp.in/d/76974-book-zero-movie-tickets-get-50-off-max-upto-rs150
  extend(DiscussionHero.prototype, "items", function (items) {
    function setCounDown(setDate, setID, startText, endText, textColor) {
      $("#" + setID).show();
      $("#" + setID).css("color", textColor);
      $("#" + setID)
        .countdown(setDate)
        .on("update.countdown", function (event) {
          var format = "%H:%M:%S";
          if (event.offset.totalDays > 0) {
            format = "%-d day%!d " + format;
          }
          if (event.offset.weeks > 0) {
            format = "%-w week%!w " + format;
          }
          $(this).html(startText + event.strftime(format));
        })
        .on("finish.countdown", function (event) {
          $(this).html(endText).parent().addClass("disabled");
          $(this).css("color", textColor);
        });
    }
    function checkFutureTime(myTime) {
      var checkTime = new Date(myTime).getTime();
      var now = new Date().getTime();
      return checkTime > now;
    }
    function convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
      return newDate;
    }

    const discussion = this.props.discussion;
    this.discussionID = discussion.data.id;
    var endDateForStamp = discussion.data.attributes.validTo;
    var votes_discussion = discussion.data.attributes.votes_discussion;
    if (endDateForStamp != "0000-00-00 00:00:00") {
      if (CouponExpiry(discussion.data.attributes.validTo) == "past") {
        this.validTo_Expired = true;
      }
    }
    this.superDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_superDeal.png";
    this.CouponDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_coupon.png";
    this.dealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_deal.png";
    this.featuredDealRibbon =
      app.forum.data.attributes.baseUrl +
      "/assets/images/ribbon_featuredDeal.png";
    this.ExpiredDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_expired.png";
    var tags = discussion.tags();
    var storeName = "";
    tags.forEach(function (element) {
      if (element.data.attributes.parentID === 4) {
        storeName = element.data.attributes.name;
      }
    });
    var ribbon = "";
    var blockColour = "";
    var FromStartText = "Starts in: ";
    var FromEndText = "This deal has started!";
    var ToStartText = "Ends in: ";
    var ToEndText = "This deal has expired!";
    var startsIn = discussion.data.attributes.validFrom;
    var endsIn = discussion.data.attributes.validTo;
    if (
      startsIn != "0000-00-00 00:00:00" &&
      checkFutureTime(startsIn) &&
      startsIn
    ) {
      setCounDown(
        startsIn,
        this.discussionID,
        FromStartText,
        FromEndText,
        "#00B504"
      );
    } else if (endsIn != "0000-00-00 00:00:00" && endsIn) {
      setCounDown(endsIn, this.discussionID, ToStartText, ToEndText, "#C20000");
    }

    if (discussion.data.attributes.isExpired) {
      ribbon = this.ExpiredDealRibbon;
      blockColour = "#555555";
    } else {
      if (discussion.data.attributes.discussionType == "d") {
        if (discussion.data.attributes.isFeatured) {
          ribbon = this.featuredDealRibbon;
          blockColour = "#F16E00";
        } else if (discussion.data.attributes.isHotDeal) {
          ribbon = this.superDealRibbon;
          blockColour = "#CC2200";
        } else {
          ribbon = this.dealRibbon;
          blockColour = "#1339C3";
        }
      } else if (discussion.data.attributes.discussionType == "c") {
        ribbon = this.CouponDealRibbon;
        blockColour = "#B206AB";
      }
    }
    if (discussion.data.attributes.discussionType == "c") {
      items.add(
        "couponcode",
        <div
          class="cc_couponSnapshot"
          itemprop="offers"
          itemscope
          itemtype="http://schema.org/Offer"
        >
          <div class="cc_productDetailTag">
            {/*tagsLabel(tags)*/}
            <div>
              {/*vote_bar.component({ discussion })*/}
            </div>
          </div>
          <div class="cc_custH2 cc_couponTitleText">
            <h2 itemprop="name">{discussion.data.attributes.title}</h2>
          </div>
          <span itemprop="availability" href="http://schema.org/InStock" />
          <span
            itemprop="offeredBy"
            itemscope
            itemtype="http://schema.org/Organization"
          >
            <span itemprop="legalName" content={storeName} />
          </span>
          <div class="cc_ribbonImageCoupon">
            <img src={ribbon} width="120" alt="discussion type" />
          </div>
          {discussion.data.attributes.badgeText != "" ? (
            <div class="cc_blockBadgeTextMini cc_10chars2lines">
              {discussion.data.attributes.badgeText ? (
                <div class="blockColourSpan">
                  {discussion.data.attributes.badgeText}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div class="cc_couponCopyBlock" style="background:#000;">
            <div class="cc_couponCopyBlockLeft">
              <input
                id="ccode"
                className="FormControl"
                value={discussion.data.attributes.couponcode}
                type="password"
              />
            </div>
            <div class="cc_couponCopyBlockRight">
              {Button.component({
                id: "btncopycode",
                children: "Copy Code",
                className: "Button Button--primary",
                title: "Copy code",
                style: "width: 90%",
                onclick: (e) => {
                  $(e.currentTarget).text("Copied");
                  $("#ccode").attr("type", "text");
                  $("#ccode").val(discussion.data.attributes.couponcode);
                  $("#ccode").focus();
                  $("#ccode").select();
                  document.execCommand("Copy", false, "null");
                  $("#ccode").blur();
                  $("#btnGo").removeAttr("style");
                  $("#btnGo").css("visibility", "visible");
                  $(e.currentTarget).css("width", "45%");
                  $("#btnGo").css("width", "45%");
                  $("#btnGo").css("float", "right");
                },
              })}
              <a
                href={discussion.data.attributes.url}
                id="btnGo"
                style="display:none;"
                class="Button Button--primary "
                target="_blank"
              >
                Go
              </a>
            </div>
          </div>
          <div style="clear:both"></div>
          <div class="cc_expiryTextCoupon">
            {!this.validTo_Expired == true ? (
              <div
                class="countdown"
                style="font-size: 15px;"
                id={this.discussionID}
              ></div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  });
}
