import Component from "flarum/Component";
import Button from "flarum/components/Button";
import AllDealCardMini from "../AllDealCardMini";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import DealPlaceHolder from "../DealPlaceHolder";
import SlickmainSlider from "../SlickmainSlider";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class DealResults extends Component {
  //used for search & category page for display deals
  init() {
    this.loading = true;
    this.deallist = [];
    this.dealData = [];
    this.refresh();
    this.moreResults = false;
  }
  view() {
    const params = this.props.params;
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    if (this.deallist.length === 0 && !this.loading) {
      const text =
        "It looks like there are currently no Deals available for this search. ";
    }

    if (this.deallist.length !== 0 || this.loading) {
      const deals = this.deallist;
      this.CategoryName = m.route.param("categories")
        ? m.route.param("categories").split(">")
        : "";
      return (
        <div style="width: 98%;margin: 0 auto;">
          <h3 class="popularBar" style="margin-top: 5px;">
            Results in Deals for{" "}
            {params.query
              ? params.query
              : this.CategoryName[this.CategoryName.length - 1]}{" "}
          </h3>
          {SlickmainSlider.component({
            slidesToShowDesktop: 4,
            slidesToScrollDesktop: 4,
            children: this.loading ? loading : this.dealData,
          })}
        </div>
      );
    } else {
      this.loading = false;
    }
    return <div>{loading}</div>;
  }
  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  requestParams(offset) {
    const params = this.props.params;
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }
  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    // const Api_url = 'https://search.thedealapp.in/dealSearch';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    const Api_url = ApiEndpoint + "/dealSearch";
    return app
      .request({ method: "GET", url: Api_url, data: params })
      .then(this.parseResults.bind(this));
  }

  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.deallist = [];
    }
    return this.loadResults();
  }
  parseResults(results) {
    if (results.result.hits) {
      this.parseDiscussionResults(results);
    } else {
      this.loading = false;
      this.deallist = [];
      this.dealData = [];
      m.lazyRedraw();
      return;
    }
  }
  parseDiscussionResults(results) {
    this.deallist = [];
    [].push.apply(this.deallist, results.result.hits.hits);
    this.dealData = this.deallist.map((discussion) => {
      var DiscussionData = {
        title: discussion._source.title,
        maxPrice: discussion._source.maxprice,
        currentPrice: discussion._source.currentprice,
        discount: discussion._source.discount,
        ////currency : discussion.data.attributes.currency,
        url: discussion._source.url,
        productName: discussion._source.productname,
        isFeatured: discussion._source.is_featured,
        isOffer: discussion._source.is_offer,
        isHotDeal: discussion._source.is_hotdeal,
        isExpired: discussion._source.is_expired,
        imageUrl_sm: discussion._source.imageurl_sm,
        noImageFound: discussion._source.noImageUrl_sm,
        validFrom: discussion._source.validfrom,
        validTo: discussion._source.validto,
        validFor: discussion._source.validfor,
        badgeText: discussion._source.badgetext,
        tag_slug: discussion._source.tag_slug,
        avatar_path: discussion._source.avatar_path,
        username: discussion._source.username,
        startUser: "",
        id: discussion._id,
        tags: "",
        secondarytag: discussion._source.secondarytag,
        created_at: discussion._source.created_at,
      };
      return AllDealCardMini.component({ DiscussionData });
    });

    this.loading = false;
    m.lazyRedraw();
  }
}
