import Component from "flarum/Component";
import Button from "flarum/components/Button";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import Placeholder from "flarum/components/Placeholder";
import BannerCardMini from "./BannerCardMini";
import DealPlaceHolder from "./DealPlaceHolder";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import SlickmainSlider from "./SlickmainSlider";
import EndpointsApiUrls from "./EndpointsApiUrls";

export default class OfferDiscussionList extends Component {
  //used to display offer on home page
  init() {
    this.loading = true;
    this.moreResults = false;
    this.discussions = null;
    this.discussions = [];
    this.itemSize = 1;
    this.refresh();
  }

  view() {
    const params = this.props.params;
    this.props.params.q = "is:offer";
    let loading;
    let loadingPlaceHolders;
    if (this.loading) {
      loading = LoadingIndicator.component();
      loadingPlaceHolders = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    if (this.discussions.length === 0 && !this.loading) {
      const text = app.translator.trans(
        "core.forum.discussion_list.empty_text"
      );
      return (
        <div className="DiscussionList">{Placeholder.component({ text })}</div>
      );
    }
    this.OfferDiscussionList = this.discussions;
    return (
      <div class="banner">
        <div className=" ">
          <h2 class="sliderTitle" style="color:#fff;text-align:center background:none;">
            Events / Offers{" "}
          </h2>
          {SlickmainSlider.component({
            slidertype: "offer",
            slidesToShowDesktop: this.slidesToShowDesktop,
            slidesToScrollDesktop: this.slidesToScrollDesktop,
            children: this.OfferDiscussionList.reduce(function (
              result,
              discussion
            ) {
              var DiscussionData = {
                title: discussion._source.title,
                maxprice: discussion._source.maxprice,
                currentprice: discussion._source.currentprice,
                discount: discussion._source.discount,
                ////currency : discussion._source.currency,
                url: discussion._source.url,
                productname: discussion._source.productname,
                is_featured: discussion._source.is_featured,
                is_hotdeal: discussion._source.is_hotdeal,
                is_expired: discussion._source.is_expired,
                start_time: discussion._source.start_time,
                imageurl_sm: discussion._source.imageurl_sm,
                imageurl_lg: discussion._source.imageurl_lg,
                validfrom: discussion._source.validfrom,
                validto: discussion._source.validto,
                validfor: discussion._source.validfor,
                badgetext: discussion._source.badgetext,
                discussiontype: discussion._source.discussiontype,
                startUser: "",
                id: discussion._id,
                tags: "",
                couponcode: discussion._source.couponcode,
              };
              result.push(
                DiscussionData.discussionType == "c"
                  ? CouponCardMini.component({ DiscussionData })
                  : BannerCardMini.component({ DiscussionData })
              );
              return result;
            },
            []),
          })}
        </div>
        <div style="clear:both"></div>
      </div>
    );
  }
  config(isInitialized, context) {
    if (isInitialized) return;
  }
  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  /**
   * Get the parameters that should be passed in the API request to get
   * discussion results.
   *
   * @return {Object}
   * @api
   */
  requestParams() {
    const params = { include: ["startUser", "lastUser", "tags"], filter: {} };
    this.props.params.q = "is:offer";
    params.sort = this.sortMap()[this.props.params.sort];
    if (this.props.params.q) {
      params.filter.q = this.props.params.q;
      params.include.push(
        "relevantPosts",
        "relevantPosts.discussion",
        "relevantPosts.user"
      );
    }

    return params;
  }

  /**
   * Get a map of sort keys (which appear in the URL, and are used for
   * translation) to the API sort value that they represent.
   *
   * @return {Object}
   */
  sortMap() {
    const map = {};
    if (this.props.params.q) {
      map.relevance = "";
    }
    map.latest = "-lastTime";
    map.top = "-commentsCount";
    map.newest = "-startTime";
    map.oldest = "startTime";
    return map;
  }

  /**
   * Clear and reload the discussion list.
   *
   * @public
   */
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.discussions = [];
    }
    return this.loadResults().then(
      (results) => {
        this.discussions = [];
        this.parseResults(results);
      },
      () => {
        this.loading = false;
        m.redraw();
      }
    );
  }

  /**
   * Load a new page of discussion results.
   *
   * @param {Integer} offset The index to start the page at.
   * @return {Promise}
   */
  loadResults(offset) {
    const params = this.requestParams();
    params.page = { offset };
    params.include = params.include.join(",");

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    //const Api_url ='https://search.thedealapp.in/offers';
    const Api_url = ApiEndpoint + "/offers";
    return app
      .request({ method: "GET", url: Api_url })
      .then(this.parseResults.bind(this));
  }

  loadMore() {
    this.loading = true;
    this.loadResults(this.discussions.length).then(
      this.parseResults.bind(this)
    );
  }
  /**
   * Parse results and append them to the discussion list.
   *
   * @param {Discussion[]} results
   * @return {Discussion[]}
   */
  parseResults(results) {
    if (results.result.hits.hits) {
      [].push.apply(this.discussions, results.result.hits.hits);
      this.loading = false;
      m.lazyRedraw();
      return results;
    }
  }
}
