import Component from "flarum/Component";
import Chartist from 'chartist'

export default class priceGraph extends Component {
  //used to display graph on product page
  init() { }
  view() {
    return (
      <div id="canvasHolder" style="display: block;min-height:300px">
        {/* <canvas id="canvas" config={this.configChart.bind(this)}></canvas> */}
        <div class="ct-chart ct-perfect-fourth" config={this.configChart.bind(this)}></div>
      </div>

    );
  }

  getStoreColor(merchantName) {
    //var color = Chart.helpers.color;
    let color1 = "#";
    if (merchantName.toLowerCase() == "amazon") {
      color1 = "#434fae";
    } else if (merchantName.toLowerCase() == "flipkart") {
      color1 = "#2874f0";
    } else if (merchantName.toLowerCase() == "croma") {
      color1 = "#46a8a2";
    } else if (merchantName.toLowerCase() == "snapdeal") {
      color1 = "#e40046";
    } else if (merchantName.toLowerCase() == "tatacliq") {
      color1 = "#a9133d";
    } else if (merchantName.toLowerCase() == "paytmmall") {
      color1 = "#f27f63";
    } else if (merchantName.toLowerCase() == "jabong") {
      color1 = "#f78c24";
    } else if (merchantName.toLowerCase() == "myntra") {
      color1 = "#f13ab1";
    } else if (merchantName.toLowerCase() == "ebay") {
      color1 = "#86b817";
    } else if (merchantName.toLowerCase() == "shopclues") {
      color1 = "#24a3b5";
    } else {
      color1 = "#555555";
    }
    return color(color1);
  }
  configChart(element, isInitialized, context) {
    if (isInitialized && this.props.priceflag == false) return;
    this.props.priceflag = false;
    this.productdata = this.props.children;
    this.marchantname = [];
    this.count = this.productdata.length;
    for (let i = 0; i < this.count; i++) {
      if (this.productdata[i].attributes === undefined) {
        this.marchantname.push(this.productdata[i]["merchantName"]);
      } else {
        this.marchantname.push(this.productdata[i].attributes.merchantName);
      }
    }
    let productDatarr = [];
    for (let j = 0; j < this.props.children.length; j++) {
      if (this.props.children[j].attributes === undefined) {
        productDatarr[j] = this.props.children[j];
      } else {
        productDatarr[j] = this.props.children[j].attributes;
      }
    }

    this.initdata(element, productDatarr);
  }

  initdata(element, productpricedata) {
    //var color = Chart.helpers.color;
    var x = "";
    var datasets = productpricedata.map((obj) => {
      let dataObj = {};
      dataObj.label = obj.merchantName;
      //dataObj.backgroundColor = this.getStoreColor(obj.merchantName)
      //  .alpha(0.6)
      //  .rgbString();
      //dataObj.borderColor = this.getStoreColor(obj.merchantName).rgbString();
      //dataObj.fill = false;
      //dataObj.steppedLine = true;
      dataObj.data = obj.PriceTracker.map((acd) => {
        let pricedata = {};
        let timedata = "";
        timedata = new Date(parseInt(acd.timestamp.$date.$numberLong));
        pricedata.x = timedata; //.toString("MM dd");
        pricedata.y = acd.selling_price;
        return pricedata;
      });
      return dataObj;
    });

    var data = {
      // A labels array that can contain any sort of values
      //labels: [datasets[0].label],
      // Our series array that contains series objects or in this case series data arrays
      series: [
        { meta: datasets[0].label, value: datasets[0].data }
      ]
    };
    var options = {
      height: 300,
      showPoint: true,
      axisX: {
        type: Chartist.AutoScaleAxis,
        divisor: 5,
        labelInterpolationFnc: function (value) {
          return moment(value).format('MMM D');
        }
      }
    };
    // Create a new line chart object where as first parameter we pass in a selector
    // that is resolving to our chart container element. The Second parameter
    // is the actual data object.
    var chart = new Chartist.Line('.ct-chart', data, options);

    chart.on('draw', function (data) {
      if (data.type === 'line') {
        var merchantName = data.seriesMeta;
        var color1 = "#555555";
        if (merchantName.toLowerCase() == "amazon") {
          color1 = "#434fae";
        } else if (merchantName.toLowerCase() == "flipkart") {
          color1 = "#2874f0";
        } else if (merchantName.toLowerCase() == "croma") {
          color1 = "#46a8a2";
        } else if (merchantName.toLowerCase() == "snapdeal") {
          color1 = "#e40046";
        } else if (merchantName.toLowerCase() == "tatacliq") {
          color1 = "#a9133d";
        } else if (merchantName.toLowerCase() == "paytmmall") {
          color1 = "#f27f63";
        } else if (merchantName.toLowerCase() == "jabong") {
          color1 = "#f78c24";
        } else if (merchantName.toLowerCase() == "myntra") {
          color1 = "#f13ab1";
        } else if (merchantName.toLowerCase() == "ebay") {
          color1 = "#86b817";
        } else if (merchantName.toLowerCase() == "shopclues") {
          color1 = "#24a3b5";
        } else {
          color1 = "#555555";
        }
        data.element._node.setAttribute('style', 'stroke: ' + color1 + ';');
      }

      if (data.type === 'point') {
        var merchantName = data.series.meta;
        var color1 = "#555555";
        if (merchantName.toLowerCase() == "amazon") {
          color1 = "#434fae";
        } else if (merchantName.toLowerCase() == "flipkart") {
          color1 = "#2874f0";
        } else if (merchantName.toLowerCase() == "croma") {
          color1 = "#46a8a2";
        } else if (merchantName.toLowerCase() == "snapdeal") {
          color1 = "#e40046";
        } else if (merchantName.toLowerCase() == "tatacliq") {
          color1 = "#a9133d";
        } else if (merchantName.toLowerCase() == "paytmmall") {
          color1 = "#f27f63";
        } else if (merchantName.toLowerCase() == "jabong") {
          color1 = "#f78c24";
        } else if (merchantName.toLowerCase() == "myntra") {
          color1 = "#f13ab1";
        } else if (merchantName.toLowerCase() == "ebay") {
          color1 = "#86b817";
        } else if (merchantName.toLowerCase() == "shopclues") {
          color1 = "#24a3b5";
        } else {
          color1 = "#555555";
        }
        data.element._node.setAttribute('style', 'stroke: ' + color1 + ';');
      }
    });

    var chartDiv = $('.ct-chart');
    var toolTip = $('body').append('<div class="chartist-tooltip"></div>').find('.chartist-tooltip').hide();
    chartDiv.on('mouseenter', '.ct-point', function () {
      var point = $(this),
        value = point.attr('ct:value').split(",");
      var displaydate = moment(new Date(parseInt(value[0]))).format('MMM Do YYYY, h:mm:ss a');
      toolTip.html(displaydate + '<br>' + "Price : " + value[1]).show();
    });

    chartDiv.on('mouseleave', '.ct-point', function () {
      toolTip.hide();
    });

    chartDiv.on('mousemove', function (event) {
      toolTip.css({
        left: (event.pageX || event.originalEvent.layerX) - toolTip.width() / 2 - 10,
        top: (event.pageY || event.originalEvent.layerY) - toolTip.height() - 40
      });
    });

    //     $('#modal').on('shown.bs.modal', function (e) {
    //       //chart.update();
    //  });

    $('#modal').on('shown.bs.modal', function (e) {
      chart.update();
    });

  }
}
