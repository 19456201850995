import Component from "flarum/Component";
import Button from "flarum/components/Button";
import SeeMoreModal from "../modal/SeeMoreModal";

export default class Brand extends Component {
  //used in search & category page for sidebar
  init() {
    super.init(this.props);
    this.selectedBrands = [];
    this.routeName = this.props.routeName;
  }

  view() {
    const routeName = this.props.routeName;
    let brandListAll = Object.assign([], app.cache.BrandList);

    let selectedBrandCount = 0;
    if (m.route.param("brands")) {
      this.selectedBrand = m.route.param("brands").split(",");
      selectedBrandCount = this.selectedBrand.length;
    }

    this.selectedBrandObject = [];

    if (selectedBrandCount > 0) {
      let selectedBrandObject = this.selectedBrand.map(
        function (brand) {
          let obj = brandListAll.find(
            function (element, index) {
              if (element.key == brand) {
                brandListAll.splice(index, 1);
                return true;
              }
              return false;
            }.bind(this)
          );
          if (obj) obj.selected = true;
          return obj;
        }.bind(this)
      );
      brandListAll = selectedBrandObject.concat(brandListAll);
    }

    if (brandListAll[0] === undefined) {
      brandListAll.shift();
    }
    this.brandList = brandListAll ? brandListAll.slice(0, 8) : [];

    if (this.brandList.length == 1 && this.brandList[0] != undefined) {
      this.brandList[0]["selected"] = true;
    }
    const branddata = brandListAll;
    return (
      <div class="cc_storesMenu">
        <div class="cc_accPanel" id="brandadd">
          <div id="users">
            <ul classname="list" id="categoryList2">
              {this.brandList.slice(0, 8).map((tag) => {
                if (tag != undefined) {
                  if (tag.key != "" && tag.key != "unknown") {
                    return (
                      <li>
                        <label className="cc_allCatsLink">
                          {tag.key ? (
                            <input
                              class="brandname cc_allCatsInput"
                              id="brandname"
                              type="checkbox"
                              checked={tag.selected ? true : false}
                              value={tag.key}
                              onchange={this.brand.bind(this)}
                            />
                          ) : (
                            <input
                              class="brandname cc_allCatsInput"
                              id="brandname"
                              type="checkbox"
                              checked={tag.selected == true ? true : false}
                              value="unknown"
                              onchange={this.brand.bind(this)}
                            />
                          )}
                          &nbsp;&nbsp;{tag.key ? tag.key : "Unknown Brand"}
                          &nbsp;&nbsp;({tag.count})
                        </label>
                      </li>
                    );
                  }
                }
              })}
            </ul>
          </div>

          <div style="position:relative;left:25px;">
            {branddata.length > 8
              ? Button.component(
                  {
                    className: "button button-sm cc_sbutton",
                    icon: "",
                    children: app.translator.trans(""),
                    onclick: function () {
                      app.modal.show(
                        new SeeMoreModal({
                          morebrandButtons: branddata,
                          routeName: routeName,
                        })
                      );
                    },
                  },
                  "SEE MORE"
                )
              : ""}
          </div>
        </div>
      </div>
    );
  }

  brand(a) {
    var hash = $(".brandname:checked")
      .map(function () {
        return this.value;
      })
      .toArray();
    hash = hash.join(",");
    var mlink = app.route(this.props.routeName, getParams("brands", hash));
    m.route(mlink);
  }
}
function getParams(paramName, value) {
  let newParams = m.route.param();
  if(newParams.categories == undefined)
  {
      newParams.categories = "category";
      // delete newParams.categories;
  }else{
    if(newParams.tag && newParams.categories == ""){
        newParams.categories = newParams.tag;
      }
  }
  let paramsURL = Object.assign({},  newParams);
  paramsURL[paramName] = value;
  //delete  paramsURL.cr;
  return paramsURL;
}
