import Page from "flarum/components/Page";
import icon from "flarum/helpers/icon";
import DealDiscussionList from "./DealDiscussionList";
import CategoriesNav from "./CategoriesNavigation";
import PriceDropProductResults from "../productdisplay/PriceDropProductResults";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class categoriesDealsIndexPage extends Page {
  //Being used for All Deals page(Indexpage) both for deals and products
  init() {
    super.init();
    app.cache.storeList = [];
    this.params = m.route.param();
    if (app.previous instanceof categoriesDealsIndexPage) {
      app.cache.Deal_DiscussionList = null;
    }

    if (app.cache.Deal_DiscussionList) {
      Object.keys(this.params).some((key) => {
        if (
          app.cache.Deal_DiscussionList.props.params[key] !== this.params[key]
        ) {
          app.cache.Deal_DiscussionList = null;
          return true;
        }
      });
    }
    if (!app.cache.Deal_DiscussionList) {
      app.cache.Deal_DiscussionList = new DealDiscussionList({
        params: this.params,
        routeName: this.props.routeName,
      });
    }
    app.history.push("index", icon("bars"));
    this.bodyClass = "App--index";
    this.brandstorecall();
    this.brandList = [];
    this.storeList = [];
    this.categoryList = [];
  }

  view() {
    let varbrandList = this.brandList;
    let varstoreList = this.storeList;
    let varcategoryList = this.categoryList;
    this.storeLink = window.location.pathname.split("/")[2];
    return m(
      "div",
      { config: this.config.bind(this) },
      <div className="container">
        <nav className="IndexPage-nav sideNav categoryNav">
          {CategoriesNav.component({ routeName: this.props.routeName,brandData: varbrandList,
            storeData: varstoreList,
            categoryData: varcategoryList, })}
        </nav>
        {app.cache.Deal_DiscussionList.hasData ? (
          <div class="IndexPage-results cc_dealResults">
            {app.cache.Deal_DiscussionList.render()}
          </div>
        ) : (
          <div class="IndexPage-results cc_dealResults">
            <div className="DiscussionList">
              {PriceDropProductResults.component({
                params: this.params,
                routeName: this.props.routeName,
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  //Brand & category & store Call
  brandstorecall() {
    let Api_url;
    const params = m.route.param();
    if(params.categories == 'category'){
    delete params.categories;
    }
    if(params.query){
      delete params.query;
    }
    // Api_url = 'https://search.thedealapp.in/search';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    Api_url = ApiEndpoint + "/getFilter";
    return app
      .request({
        method: "GET",
        context: this,
        url: Api_url,
        data: params,
      })
      .then(this.brandResults.bind(this));
  }

  brandResults(results) {
    [].push.apply(this.brandList, results.brandList);
    [].push.apply(this.storeList, results.storeList);
    [].push.apply(this.categoryList,results.categoryList);
    app.cache.BrandList = this.brandList;
    app.cache.storeList = this.storeList;
    app.cache.DealsCategories = this.categoryList;
    m.redraw();
  }
}
