import { extend } from 'flarum/extend';
import Page from 'flarum/components/Page';
import ItemList from 'flarum/utils/ItemList';
import listItems from 'flarum/helpers/listItems';
import icon from 'flarum/helpers/icon';
import DealsListSlider from './../DealsListSlider';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Select from 'flarum/components/Select';
import DiscussionList from 'flarum/components/DiscussionList';
import ProductDisplay from './../productdisplay/ProductDisplay';
/**
 * The `StoreIndexPage` component displays the index page, including the welcome
 * hero, the sidebar, and the discussion list.
 */
export default class StoreIndexPage extends Page {
  init() {
    super.init();
    // If the user is returning from a discussion page, then take note of which
    // discussion they have just visited. After the view is rendered, we will
    // scroll down so that this discussion is in view.
    if (app.previous instanceof DiscussionPage) {
      this.lastDiscussion = app.previous.discussion;
    }

    // If the user is coming from the discussion list, then they have either
    // just switched one of the parameters (filter, sort, search) or they
    // probably want to refresh the results. We will clear the discussion list
    // cache so that results are reloaded.
    if (app.previous instanceof StoreIndexPage) {
      app.cache.Store_discussionList = null;
    }

    const params = this.params();
    this.storename = params.tags.charAt(0).toUpperCase() + params.tags.slice(1);
    if (app.cache.Store_discussionList) {
      // Compare the requested parameters (sort, search query) to the ones that
      // are currently present in the cached discussion list. If they differ, we
      // will clear the cache and set up a new discussion list component with
      // the new parameters.
      Object.keys(params).some(key => {
        if (app.cache.Store_discussionList.props.params[key] !== params[key]) {
          app.cache.Store_discussionList = null;
          return true;
        }
      });
    }
    if (!app.cache.Store_discussionList)
    {
      app.cache.Store_discussionList = new DiscussionList({params});
    }
    // if (!this.Store_Featured_DealBoxDiscussionList)
    // {
    //   params.filter = this.getDealParams('is:featured');
    //   params.heading = 'Latest '+this.storename +' Deals';
    //   params.headingIcon = 'fa fa-thumbs-up';
    //   params.slidesToScrollDesktop = 5;
    //   params.slidesToShowDesktop = 5;
    //   params.color = '#c72026';
    //   params.headingUnderline = true;
    //   this.Store_Featured_DealBoxDiscussionList = new DealsListSlider({params});
    // }

    if (!this.Store_MissedDiscussionList)
    {
      params.heading = this.storename + ' Deals You Missed';
      params.headingIcon = 'fa fa-frown-o';
      params.filter = this.getDealParams('is:expired');
      params.slidesToScrollDesktop = 5;
      params.slidesToShowDesktop = 5;
      params.color = '#0db04a';
      params.headingUnderline = true;
      this.Store_MissedDiscussionList = new DealsListSlider({params});
    }
   
    if (!this.Store_CouponList)
    {
      params.heading = 'Coupons for '+this.storename;
      params.headingIcon = 'fa fa-frown-o';
      params.filter = this.getDealParams('is:cupn');
      params.slidesToScrollDesktop = 5;
      params.slidesToShowDesktop = 5;
      params.color = '#0d2c54';
      params.headingUnderline = true;
      this.Store_CouponList = new DealsListSlider({params});
    }

    if (!this.priceDrop_Merchant)
    {
      params.heading = this.storename;
      params.slidesToScrollDesktop = 5;
      params.slidesToShowDesktop = 5;
      params.stores = this.storename.toLowerCase();
      this.priceDrop_Merchant = new ProductDisplay({params});
    }
    app.history.push('index', icon('bars'));
    this.bodyClass = 'App--index';
  }
 camelize(str) {
      return str.replace(/\W+(.)/g, function(match, chr)
       {
            return chr.toUpperCase();
        });
    }
  onunload() {
    // Save the scroll position so we can restore it when we return to the
    // discussion list.
    app.cache.scrollTop = $(window).scrollTop();
  }

  view() {          
    return (
      <div className="IndexPage">
        <div className="container storeContainer">
          <nav className="IndexPage-nav storeLHS" style="padding-bottom: 20px;">
            <ul>{listItems(this.sidebarItems().toArray())} </ul>
          </nav>
          <hr style="width: 95%"/>
          <div className="IndexPage-results storeRHS">
          <h2> Forum Posts on {this.storename} </h2>
         
            <div className="DiscussionList">
                  <ul className="DiscussionList-discussions">
                        {listItems(this.viewItems().toArray())}
                  </ul>
            </div>
            {app.cache.Store_discussionList.render()}
          </div>
        </div>
      </div>
    );
    
  }
  config(isInitialized, context) {
    super.config(...arguments);
    if (isInitialized) return;
    extend(context, 'onunload', () => $('#app').css('min-height', ''));
    app.setTitle('');
    app.setTitleCount(0);
    // Work out the difference between the height of this hero and that of the
    // previous hero. Maintain the same scroll position relative to the bottom
    // of the hero so that the sidebar doesn't jump around.
    const oldHeroHeight = app.cache.heroHeight;
    const heroHeight = app.cache.heroHeight = this.$('.Hero').outerHeight();
    const scrollTop = app.cache.scrollTop;

    $('#app').css('min-height', $(window).height() + heroHeight);

    // Scroll to the remembered position. We do this after a short delay so that
    // it happens after the browser has done its own "back button" scrolling,
    // which isn't right. https://github.com/flarum/core/issues/835
    const scroll = () => $(window).scrollTop(scrollTop - oldHeroHeight + heroHeight);
    scroll();
    setTimeout(scroll, 1);

    // If we've just returned from a discussion page, then the constructor will
    // have set the `lastDiscussion` property. If this is the case, we want to
    // scroll down to that discussion so that it's in view.
    if (this.lastDiscussion) {
      const $discussion = this.$(`.DiscussionListItem[data-id="${this.lastDiscussion.id()}"]`);

      if ($discussion.length) {
        const indexTop = $('#header').outerHeight();
        const indexBottom = $(window).height();
        const discussionTop = $discussion.offset().top;
        const discussionBottom = discussionTop + $discussion.outerHeight();

        if (discussionTop < scrollTop + indexTop || discussionBottom > scrollTop + indexBottom) {
          $(window).scrollTop(discussionTop - indexTop);
        }
      }
    }
    
  }

  sidebarItems() {
    const items = new ItemList();
    const StoreName = 'amazon';
    // items.add('hotDeals',
    //       <div class="myDeals">
    //         {this.Store_Featured_DealBoxDiscussionList.render()}
    //       </div>
    // );  
    items.add('priceDropMerchant',
          <div class="myDeals">
            {this.priceDrop_Merchant.render()}
          </div>
    );  
    items.add('missedDeals',
          <div class="myDeals">
            {this.Store_MissedDiscussionList.render()}
          </div>
    );   
    items.add('couponView',
          <div class="myDeals">
            {this.Store_CouponList.render()}
          </div>
    );

    return items;
  }

  viewItems() {
    const items = new ItemList();
    const sortMap = app.cache.Store_discussionList.sortMap();
    const sortOptions = {};
    delete sortMap.hot;
    for (const i in sortMap) {
      sortOptions[i] = app.translator.trans('core.forum.index_sort.' + i + '_button');
    }

    items.add('sort',
      Select.component({
        options: sortOptions,
        value: this.params().sort || Object.keys(sortMap)[0],
        onchange: this.changeSort.bind(this)
      })
    );

    return items;
  }
  getDealParams(q)
  {
      const params = {include: ['startUser', 'lastUser', 'tags'], filter: {}};
      params.filter.q = 'tag:' + m.route.param().tags + ' ' + q;             
      params.include.push('relevantPosts', 'relevantPosts.discussion', 'relevantPosts.user');  
    return params;
  }

  /**
   * Redirect to the index page using the given sort parameter.
   *
   * @param {String} sort
   */
  changeSort(sort) {
    const params = this.params();

    if (sort === Object.keys(app.cache.Store_discussionList.sortMap())[0]) {
      delete params.sort;
    } else {
      params.sort = sort;
    }

    m.route(app.route(this.props.routeName, params));
  }

  /**
   * Get URL parameters that stick between filter changes.
   *
   * @return {Object}
   */
  stickyParams() {
    return {
      sort: m.route.param('sort'),
      q: m.route.param('q')
    };
  }

  /**
   * Get parameters to pass to the DiscussionList component.
   *
   * @return {Object}
   */
  params() {
    const params = this.stickyParams();

    params.filter = m.route.param('filter');
    params.tags = m.route.param('tags');

    return params;
  }


}
