import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function requestSuperdealControl() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canrequestSuperdeal()) {
          if (!discussion.isrequestSuperdeal()){
                    items.add('RequestHotDeal', Button.component({
                      children: 'Request For Super Deal',
                      icon: 'fa fa-bolt',
                      onclick: this.RequestHotDealAction.bind(discussion)
                    }));
          }
        //   else{
        //           items.add('RequestHotDeal', Button.component({
        //               children: 'Undo Super Deal',
        //               icon: 'fa fa-bolt',
        //               onclick: this.RequestHotDealAction.bind(discussion),
        //             }));  
        //   }
                    
    }
  });

  DiscussionControls.RequestHotDealAction = function() {

   if(!this.isrequestSuperdeal()){ 
   var retVal = confirm("Confirm whether Hot Deal?");
         if( retVal == true ) {
            this.save({isrequestSuperdeal: !this.isrequestSuperdeal()}).then(() => {
              if (app.current instanceof DiscussionPage) {
                 app.current.stream.update();
                }
                if(this.data.attributes.isHotDeal == false){
                    alert("Your request to make this deal as a superdeal has been submitted");
                  }
               m.redraw();
           }); 
           // return true;
          // confirmhotdeal();
         } else {
          //  alert("User does not want to continue!");
            return false;
         }

      } else {  // else is not hot deal
     //   var retValundo = confirm("Do you want to Undo Post Hot Deal?");
           this.save({isrequestSuperdeal: !this.isrequestSuperdeal()}).then(() => {
              if (app.current instanceof DiscussionPage) {
                 app.current.stream.update();
                }
               m.redraw();
           }); 

      }   
  };

   function confirmhotdeal(){ 

    
    }
}
