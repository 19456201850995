import Component from "flarum/Component";


export default class AdCardMini extends Component {
    //used to display deal card on DealDiscussionList(All Deals),CategoryListData(on home page),DealsListData(on home page),DealResults(search)
    init() {
    }

    view() {
        return m(
            "div",
            { class: "dealCard_mini" },
            <div className={"DiscussionListItem-content"} style="text-align:center;padding-top:5px !important">
                {window.innerWidth < 1600 ?
                   ( <ins class="adsbygoogle"
                        style="display:inline-block;width:170px;height:230px"
                        data-ad-client="ca-pub-6474753002603226"
                        data-ad-slot="1340138260"></ins>)
                    : (<ins class="adsbygoogle"
                        style="display:block"
                        data-ad-format="fluid"
                        data-ad-layout-key="-7m+e6+2m-1r-3j"
                        data-ad-client="ca-pub-6474753002603226"
                        data-ad-slot="1412027128"></ins>)
                }
                <div style="text-align:center">                   
                        Advertisement                   
                </div>
            </div>
        );
    }

    config(isInitialized) {
        if (isInitialized) return;
        (adsbygoogle = window.adsbygoogle || []).push({});
    }


}
