import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function addExpiredControl() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canExpired()) {
          if (!discussion.isExpired()){
                    items.add('Expired', Button.component({
                      children: 'Expired',
                      icon: 'fa fa-frown',
                      onclick: this.ExpiredAction.bind(discussion)
                    }));
          }else{
                  items.add('Expired', Button.component({
                      children: 'unExpired',
                      icon: 'fa fa-smile',
                      onclick: this.ExpiredAction.bind(discussion),                   
                    }));  
          }
                    
    }
  });

  DiscussionControls.ExpiredAction = function() {
    this.save({isExpired: !this.isExpired()}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }

      m.redraw();
    });
  };
}
