import Component from 'flarum/Component';
import icon from 'flarum/helpers/icon';
import listItems from 'flarum/helpers/listItems';

export default class StoreMenu extends Component {
  //used for display store dropdown in menu
  static initProps(props) {
    super.initProps(props);
    props.className = props.className || '';
    props.buttonClassName = props.buttonClassName || '';
    props.menuClassName = props.menuClassName || '';
    props.label = props.label || '';
    props.caretIcon = typeof props.caretIcon !== 'undefined' ? props.caretIcon : 'caret-down';
  }
  init(){
    this.showing = false;
  }

  view() {
    const items = this.props.children ? listItems(this.props.children) : [];
    return (
      <div className={'ButtonGroup Dropdown dropdown ' + this.props.className + ' itemCount' + items.length + (this.showing ? ' open' : '')}>
        {this.getButton()}
        {this.getMenu(items)}
      </div>
    );
  }

  config(isInitialized) {
    if (isInitialized) return;
    //When opening the dropdown menu, work out if the menu goes beyond the
    // bottom of the viewport. If it does, we will apply class to make it show
    // above the toggle button instead of below it.
    this.$().on('shown.bs.dropdown', () =>
    {
      this.showing = true;
      if (this.props.onshow)
      {
        this.props.onshow();
      }
      const $menu = this.$('.Dropdown-menu');
      const isRight = $menu.hasClass('Dropdown-menu--right');
      $menu.removeClass('Dropdown-menu--top Dropdown-menu--right');
      $menu.toggleClass(
        'Dropdown-menu--top',
        $menu.offset().top + $menu.height() > $(window).scrollTop() + $(window).height()
      );

      $menu.toggleClass(
        'Dropdown-menu--right',
        isRight || $menu.offset().left + $menu.width() > $(window).scrollLeft() + $(window).width()
      );
    });

    this.$().on('hidden.bs.dropdown', () => {
      this.showing = false;
      if (this.props.onhide) {
        this.props.onhide();
      }
    });
  }

  /**
   * Get the template for the button.
   *
   * @return {*}
   * @protected
   */
  getButton() {
    return (
      <button
        className={'Dropdown-toggle ' + this.props.buttonClassName}
        data-toggle="dropdown"
        onclick={this.props.onclick}>
        {this.getButtonContent()}
      </button>
    );
  }

  /**
   * Get the template for the button's content.
   *
   * @return {*}
   * @protected
   */
  getButtonContent() {
    return [
      this.props.icon ? icon(this.props.icon, {className: 'Button-icon'}) : '',
      <span className="Button-label">{this.props.label} </span>,
      this.props.caretIcon ? icon(this.props.caretIcon, {className: 'Button-caret'}) : ''
    ];
  }

  getMenu(items) {
    return (
      <ul className={'Dropdown-menu dropdown-menu' + this.props.menuClassName}>
      <li class="store_menu"><span  style="text-align:center;"><b class="stor_col">Retail & Electronics</b></span>
      <div class="cat_headline"></div>
      <ul class="menu_items">
      {/* <li><a href={'/store/amazon'} config={m.route}>Amazon</a></li> */}
      <li><a href={'/store/flipkart'} config={m.route}>Flipkart</a></li>
      <li><a href={'/store/tatacliq'} config={m.route}>Tatacliq</a></li>
      <li><a href={'/store/paytmmall'} config={m.route}>Paytmmall</a></li>
      <li><a href={'/store/croma'} config={m.route}>Croma</a></li>
      <li><a href={'/store/vijaysales'} config={m.route}>Vijay Sales</a></li>     
      </ul>
      </li>

      <li class="store_menu"><span  style="text-align:center;"><b class="stor_col">Fashion</b></span>
      <div class="cat_headline"></div>
      <ul class="menu_items">
      <li><a href={'/store/Ajio'} config={m.route}>AJIO</a></li>
      <li><a href={'/store/myntra'} config={m.route}>Myntra</a></li>
      <li><a href={'/store/koovs'} config={m.route}>KOOVS</a></li>
      <li><a href={'/store/pantaloons'} config={m.route}>Pantaloons</a></li>
      <li><a href={'/store/brandfactory'} config={m.route}>Brand Factory</a></li>
      </ul>
      </li>

      <li class="store_menu"><span  style="text-align:center;"><b class="stor_col">Travel</b></span>
      <div class="cat_headline"></div>
      <ul class="menu_items">
      <li ><a href={'/store/makemytrip'} config={m.route}>MakeMyTrip</a></li>
      <li><a href={'/store/yatra'} config={m.route}>Yatra</a></li>
      <li><a href={'/store/goibibo'} config={m.route}>Goibibo</a></li>
      <li><a href={'/store/uber'} config={m.route}>Uber</a></li>
      <li><a href={'/store/olacabs'} config={m.route}>Ola</a></li>     
      </ul>
      </li>

      <li class="store_menu"><span style="text-align:center;"><b class="stor_col">Services</b></span>
      <div class="cat_headline"></div>
      <ul class="menu_items">
      <li><a href={'/store/paytm'} config={m.route}>Paytm</a></li>
      <li><a href={'/store/freecharge'} config={m.route}>Freecharge</a></li>
      <li><a href={'/store/UrbanCompany'} config={m.route}>UrbanClap</a></li>
      <li><a href={'/store/nearbuy'} config={m.route}>Nearbuy</a></li>
      <li><a href={'/store/dunzo'} config={m.route}>Dunzo</a></li>
      </ul>
      </li>

      <li class="store_menu"><span style="text-align:center;"><b class="stor_col">Food and Entertainment</b></span>
      <div class="cat_headline"></div>
      <ul class="menu_items">
      <li><a href={'/store/bookmyshow'} config={m.route}>Bookmyshow</a></li>
      <li><a href={'/store/swiggy'} config={m.route}>Swiggy</a></li>
      <li><a href={'/store/Zomato'} config={m.route}>Zomato</a></li>
      <li><a href={'/store/dominos'} config={m.route}>Dominos</a></li>
      <li><a href={'/store/ticketnew'} config={m.route}>Ticket New</a></li>
      </ul>
      </li>
      </ul>
    );
  }
}
