import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function addHotDealControl() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canFeatured()) {
          if (!discussion.isHotDeal()){
                    items.add('HotDeal', Button.component({
                      children: 'Super Deal',
                      icon: 'fa fa-bolt',
                      onclick: this.HotDealAction.bind(discussion)
                    }));
          }
          else{
                  items.add('HotDeal', Button.component({
                      children: 'Undo Super Deal',
                      icon: 'fa fa-bolt',
                      onclick: this.HotDealAction.bind(discussion),
                    }));  
          }
                    
    }
  });

  DiscussionControls.HotDealAction = function() {

   if(!this.isHotDeal()){ 
   var retVal = confirm("Confirm whether Hot Deal?");
         if( retVal == true ) {
            this.save({isHotDeal: !this.isHotDeal()}).then(() => {
              if (app.current instanceof DiscussionPage) {
                 app.current.stream.update();
                }
               m.redraw();
           }); 
           // return true;
          // confirmhotdeal();
         } else {
          //  alert("User does not want to continue!");
            return false;
         }

      } else {  // else is not hot deal
     //   var retValundo = confirm("Do you want to Undo Post Hot Deal?");
           this.save({isHotDeal: !this.isHotDeal()}).then(() => {
              if (app.current instanceof DiscussionPage) {
                 app.current.stream.update();
                }
               m.redraw();
           }); 

      }   
  };

   function confirmhotdeal(){ 

    
    }
}
