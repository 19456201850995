import Component from "flarum/Component";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import Button from "flarum/components/Button";
import ProductCard from "../ProductCard";
import SlickmainSlider from "../SlickmainSlider";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class SimilarProductsSlider extends Component {
  //used for Similar product slider
  init() {
    this.loading = true;
    this.deallist = [];
    this.dealData = [];
    this.productList = [];
    this.refresh();
    this.moreResults = false;
  }
  view() {
    const params = this.props.children;
    let loading;
    if (this.loading) {
      loading = LoadingIndicator.component();
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    if (this.loading) {
      return <div>{loading}</div>;
    } else if (this.productList.length === 0 && !this.loading) {
      const text =
        "It looks like there are currently no Products available for this search. ";
      return <div></div>;
    } else if (this.productList.length !== 0) {
      const deals = this.productList;
      return (
        <div id="productdisplay" style="width: 100%;margin: 0 auto;">
          <div
            id="dealstores"
            class=""
            style="float: right;margin-right:50px;margin-top: -12px;"
          ></div>
          <h3 class="popularBar" style="margin-top: 50px;">
            Similar Products
          </h3>
          {SlickmainSlider.component({
            slidesToShowDesktop: this.slidesToShowDesktop,
            slidesToScrollDesktop: this.slidesToScrollDesktop,
            children: this.loading ? loading : this.dealData,
          })}
        </div>
      );
    }
  }
  requestParams() {
    const params = this.props;
    return params;
  }
  loadResults() {
    const params = this.requestParams();

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    //const Api_url =  'https://search.thedealapp.in/similar';
    const Api_url = ApiEndpoint + "/similar";
    return app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.parseResults.bind(this));
  }

  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.deallist = [];
    }
    this.loadResults();
  }
  parseResults(results) {
    this.parseDiscussionResults(results);
    this.loading = false;
    m.lazyRedraw();
  }
  parseDiscussionResults(results) {
    if (results.result.hits) {
      this.productList = [];
      [].push.apply(this.productList, results.result.hits.hits);
      this.loading = false;
      this.dealData = this.productList.map((product) => {
        var ProductData = {
          title: product._source.Title,
          product_id: product._id,
          maxPrice: product._source.MRP,
          currentPrice: product._source.sellingPrice,
          productOutOfStock: product._source.productOutOfStock,
          discount: product._source.discount,
          url:
            app.forum.data.attributes.baseUrl +
            "/blossom?bid=" +
            product._source.linkid +
            "&url=" +
            product._source.productURL +
            "&src=product",
          imageUrl_sm: product._source.imageURL,
          merchantName: product._source.merchantName,
          slug: product._source.slug,
          Hotness: product._source.Hotness,
        };
        return ProductCard.component({ ProductData });
      });
      return results;
    }
  }
  config(isInitialized) {}
  configSlider(element, isInitialized, context) {
    if (isInitialized && !this.productList) return;
  }
}
