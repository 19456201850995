import Component from "flarum/Component";
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import listItems from "flarum/helpers/listItems";
import slick from "slick-carousel";

export default class SlickmainSlider extends Component {
  //used to display slider on CategoryListData(on home page),DailyDealResults(daily deals),DealsListData(on home page),
  //DealsListSlider(store page),OfferDiscussionList(on home page),ProductDisplay(on home page),SimilarProductsSlider,DealResults(search & category)
  init() {
    if (this.props.slidertype == "offer") {
      this.slidesToShowDesktop = this.props.slidesToShowDesktop
        ? this.props.slidesToShowDesktop
        : 1;
      this.slidesToScrollDesktop = this.props.slidesToScrollDesktop
        ? this.props.slidesToScrollDesktop
        : 1;
      this.dotsipad = this.dotsipad ? this.dotsipad : true;
      this.mainslidesToShow = 1;
      this.dots = true;
      this.arrows = false;
      this.autoplay = true;
      this.breakslidesToShow = this.slidesToShowDesktop;
      this.breakslidesToScroll = this.slidesToScrollDesktop;
      this.adaptiveHeight = true;
      this.speed = 300;
    } else {
      this.slidesToShowDesktop = this.props.slidesToShowDesktop
        ? this.props.slidesToShowDesktop
        : 5;
      this.slidesToScrollDesktop = this.props.slidesToScrollDesktop
        ? this.props.slidesToScrollDesktop
        : 5;
      this.dotsipad = this.dotsipad ? this.dotsipad : true;
      this.mainslidesToShow = 'auto';
      this.dots = false;
      this.arrows = true;
      this.autoplay = false;
      this.breakslidesToShow = 1;
      this.breakslidesToScroll = 1;
      this.adaptiveHeight = false;
      Swiper.use([Navigation, Autoplay, Pagination]);


    }
  }

  config(isInitialized) {
    if (isInitialized) return;
  }
  configSlider(element, isInitialized, context) {
    if (isInitialized) return;
    if (this.props.children && this.props.children.length) {
      if (this.autoplay) {
        const swiper = new Swiper(element, {             
          pagination: {
            el: '.swiper-pagination',
            clickable:true
          },
            slidesPerView: this.mainslidesToShow,
            autoHeight: this.adaptiveHeight,
            centeredSlides: this.autoplay,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          loop: true,
            
          });
      }
      else {
        const swiper = new Swiper(element, {
          navigation: {
            nextEl: '.slick-next',
            prevEl: '.slick-prev',
          
          },
          freeModeFluid: true,
          slidesPerView: this.mainslidesToShow,        
          freeMode: true,
          grabCursor: true,
          preloadImages: false,

         
        });
      }
      

    }
  }
  view() {
    if (this.props.children && this.props.children.length) {
      let maxheight =
        this.props.heading == "Upcoming Deal"
          ? "slyframe_upcoming"
          : "slyframe";
      return (
        <div>
          <div class="swiper-container" config={this.configSlider.bind(this)}>

            <div class="swiper-wrapper slyframe">
              {this.props.children.map((discussion) => {
                return (<div class="swiper-slide" style={this.props.slidertype == "offer" ? "" : "max-width:260px; margin:20px;"} >
                  {discussion}
                </div>)
              })}
            </div>
            {this.props.slidertype == "offer" ?<div class="swiper-pagination"></div>:(<div>
            <div class="slick-prev"></div>
              <div class="slick-next"></div></div>)
            }
            
          </div>
         
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
