import Modal from "flarum/components/Modal";
import DealCardMini from "../DealCardMini";

export default class DealPreviewModal extends Modal {
  //to check deal is already exist or not while adding new deal
  init() {
    super.init();
    this.title = m.prop("Deal Preview" || "");
  }
  content() {
    const discussion = this.props.discussion;
    let DiscussionData = {
        title: discussion.title,
        maxPrice: discussion.maxPrice,
        currentPrice: discussion.currentPrice,
        apicurrentPrice: discussion.apicurrentPrice,
        discount: discussion.discount,
        ////currency : discussion.data.attributes.currency,
        url: discussion.url,
        productName: discussion.productName,
        isFeatured: discussion.isFeatured,
        isHotDeal: discussion.isHotDeal,
        isExpired: discussion.isExpired,
        startTime: discussion.startTime,
        imageUrl_sm: discussion.imageUrl_sm,
        noImageFound: discussion.noImageUrl_sm,
        validFrom: discussion.validFrom,
        validTo: discussion.validTo,
        validFor: discussion.validFor,
        badgeText: discussion.badgeText,
        refreshDate: discussion.refreshDate,
        //startUser: discussion.user(),
        //id: discussion.id(),
        //tags: discussion.tags(),
        secondarytag: discussion.secondarytag,
      };

    return [
      <div className="Modal-body">
        <div className="TagDiscussionModal-form1">
          <div className="TagDiscussionModal-form-input1">
            <div className={"DiscussionListItem-content1 Slidable-content"}>
             <div>{DealCardMini.component({ DiscussionData })}</div>
            </div>
          </div>
        </div>
      </div>,

      <div className="Modal-footer"></div>,
    ];
  }

  onsubmit(e) {
    e.preventDefault();
    const tags = this.selected;
    if (this.props.onsubmit) this.props.onsubmit(tags);
    const props = {
      tags,
    };

    app.modal.close();
  }
}
