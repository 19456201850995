import Component from "flarum/Component";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import Button from "flarum/components/Button";
import ItemList from "flarum/utils/ItemList";
import DealPlaceHolder from "./DealPlaceHolder";
import EndpointsApiUrls from "./EndpointsApiUrls";

export default class CompareProduct extends Component {
  //used to display compare table on products & deal page
  init() {
    this.loading = true;
    this.moreResults = false;
    this.loadResults();
    this.parameter = this.props.params;
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/out_of_stock.png";
    this.attributes = [];
  }

  view() {
    let loading;
    if (this.loading) {
      loading = LoadingIndicator.component();
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    const text =
      "It looks like there are currently no products available for this search. ";

    //Start Sort the Products inside the Compare Price table on the basis of price
    var comparepricesort = [];
    if (this.compareproductlist != undefined) {
      this.compareproductlist
        .filter(function (value) {
          return value;
        })
        .sort(function (a, b) {
          return (
            a.attributes._source.sellingPrice -
            b.attributes._source.sellingPrice
          );
        })
        .map(function (value) {
          comparepricesort.push(value);
        });
    }
    //End Sort the Products inside the Compare Price table on the basis of price

    return (
      <div class="cc_compareTable">
        {comparepricesort.map((value) => {
          let tag = app.store.getBy(
            "tags",
            "slug",
            value.attributes._source.merchantName.toLowerCase()
          );
          let storeimagepath = tag.data.attributes.imagePath;
          return (
            <div class="cc_comapreTableRow">
              <a
                class="comparetitle"
                href={
                  app.forum.data.attributes.baseUrl +
                  "/product_details/" +
                  value.attributes._source.slug
                }
                config={m.route}
              >
                <div class="cc_compareCol0">
                  <div class="cc_imageFrame">
                    {!value.attributes._source.productOutOfStock == true ? (
                      ""
                    ) : (
                      <div class="cc_productDetailExpiredStamp_compare">
                        <img src={this.expiredStamp} alt="expired Stamp" />
                      </div>
                    )}
                  </div>
                  <div class="cc_compareProdDetail">
                    {value.attributes._source.imageURL ? (
                      <img
                        itemprop="image"
                        src={value.attributes._source.imageURL.replace(
                          /\s/g,
                          ""
                        )}
                        alt="Product Image"
                        onError={"this.src='" + storeimagepath + "'"}
                      />
                    ) : (
                      <img
                        itemprop="image"
                        src={storeimagepath}
                        alt="no image found"
                      />
                    )}
                  </div>
                </div>
                <div class="cc_comapreCol1">
                  <strong class="TagsLabel compareTitle">
                    {value.attributes._source.Title.length > 100
                      ? value.attributes._source.Title.substr(0, 70) + "..."
                      : value.attributes._source.Title}
                  </strong>
                </div>
                <div class="cc_comapreCol2">
                  {tag ? (
                    <span class="TagsLabel ">
                      <a
                        class=""
                        title=""
                        href={"/t/" + value.attributes._source.merchantName}
                      >
                        <span class="TagLabel-text">
                          <img
                            src={tag.data.attributes.imagePath}
                            height="20px"
                          />
                        </span>
                      </a>
                    </span>
                  ) : (
                    value.attributes._source.merchantName
                  )}
                </div>
                <div class="cc_comapreCol3">
                  {value.attributes._source.MRP !== 0 ? (
                    <span
                      id="lowmrp"
                      style="text-decoration: line-through;font-size:0.8em"
                    >
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      {" " + value.attributes._source.MRP}/-
                    </span>
                  ) : (
                    <strong class="cc_dealTextColour">
                      <span id="lowmrp">Not Available</span>
                    </strong>
                  )}
                  {value.attributes._source.sellingPrice != 0 ? (
                    <span
                      itemprop="price"
                      value="test"
                      style="color: red;"
                    >
                      <i itemprop="priceCurrency" content="Rs"></i>
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      {" " + value.attributes._source.sellingPrice.toFixed(2)}/-
                    </span>
                  ) : (
                    <strong class="cc_dealTextColour">
                      {" "}
                      Price Not Available
                    </strong>
                  )}
                </div>
              </a>
              <div class="cc_comapreCol4 dealCardMini_Btn">
                <a
                  href={
                    app.forum.data.attributes.baseUrl +
                    "/blossom?bid=" +
                    value.attributes._source.linkid +
                    "&url=" +
                    value.attributes._source.productURL +
                    "&src=product"
                  }
                  class="Button"
                  target="_blank" rel="nofollow"
                >Buy Now
                </a>
              </div>
            </div>
          );
        })}
        <div className="DiscussionList-loadMore">{loading}</div>
      </div>
    );
  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }

  loadMore() {
    this.loading = true;
    this.loadResults(this.compareproductlist.length);
  }

  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    // const Api_url =  'https://search.thedealapp.in/compairePrice';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    const Api_url = ApiEndpoint + "/compairePrice";

    return app
      .request({
        method: "GET",
        dataType: "json",
        url: Api_url,
        data: params,
      })
      .then(this.parseResults.bind(this));
  }

  parseResults(results) {
    for (var i = 0; i < results.length; i++) {
      this.attributes.push({ attributes: results[i] });
    }
    this.compareproductlist = this.attributes;
    this.loading = false;
    if (results.length >= 10) {
      this.loader = false;
      this.moreResults = true;
      m.lazyRedraw();
    } else {
      this.loader = true;
      this.moreResults = false;
      m.lazyRedraw();
    }
  }

  requestParams(offset) {
    const params = this.props.params;
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }
}
