import Modal from 'flarum/components/Modal';

export default class SeeMoreModal extends Modal {
  //used for see more button for brands on search and category page
  init() {
        super.init(this.props);
        this.socialButtons = this.props.morebrandButtons;
        this.brandLookupdata= {};
        this.brandListdata = [];
        const brandLookup ={};
        const brandListdatapush=[];
        const brandselectd=[];
        $.each(this.socialButtons, function(index, value ){
           brandListdatapush.push(value.key.trim());
           brandLookup[value.key] = value.count; 
           brandselectd[value.key] = value.selected;    

        });
        this.brandListdata =brandListdatapush.sort();
        this.brandLookupdata = brandLookup;
        this.brandselectd = brandselectd;   
    }


  className() {
    return 'Modal--Big brandModal';
  }

  title() {
    return app.translator.trans('Select Brands');
  }

  content() {
    const routename =  this.routename;
    const datapass = this.brandLookupdata;
    const selectedbrand =  this.brandselectd;
    return [
      m('div', {className: 'Modal-body ShareSocialModal cc_ShareSocialModal'}, [
         m("input[type=text]", ({className:'modal_search', id:'txtBrandFilter',placeholder:"Filter brands" ,onkeyup:this.filterBrands.bind(this)})),
           m('ul',{id:'showbrand' , className: 'cc_allCats'},[
             this.brandListdata.map(function (key) {
              if(key != "unknown" && key !=""){
                return m('li',
                  m("label",{className: 'cc_allCatsLink'},
                   m("input[type=checkbox]", ({value: key, className: 'brandname cc_allCatsInput',checked:selectedbrand[key], name:'asd'+key})),
                   key.toUpperCase()," ("+datapass[key] +")"
                  )
                );
              }
              })
            ])
      ]),
        m("a",({className:'Button Button--primary btnModalBottom',onclick:this.addBrand.bind(this)}),"APPLY")
        
    ];

  }

  addBrand(){
    var selected=[];
    var hash1=[];
    selected = $(".brandname:checkbox:checked").map(function() {
        return this.value;
    }).get();

    selected = selected.join(",");
    var brands = selected?"brands="+encodeURIComponent(selected):"";
    var searchString = '';
    if(m.route.param('query')) {
      searchString = '&query='+encodeURIComponent(m.route.param('query'));
    }
    var sort = m.route.param('sort') ? '&sort='+m.route.param('sort') : '';
    var dataurl = window.location.pathname;
    var hash = $(".storename:checked").map(function() {
                  return this.value;
              }).toArray();
    hash = hash.join(",");
    var stores = hash?"stores="+encodeURIComponent(hash):"";
    var categoriesparam = m.route.param('categories') ;
    var category = categoriesparam?'&categories='+encodeURIComponent(categoriesparam):"";
    var link = dataurl + "?"+ brands + stores + category + searchString + sort;
    var mlink = app.route(this.props.routeName,getParams('brands',selected));
    m.route(mlink);
  }
  filterBrands(){
        var input, filter,ul,li,i,a;
        input = document.getElementById('txtBrandFilter');
        filter = input.value.toUpperCase();
        ul = document.getElementById("showbrand");
        li = ul.getElementsByTagName('li');
        // Loop through all list items, and hide those who don't match the search query
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("label")[0];
            if (a.innerText.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
      }

  onready() {}

  onerror(error) {
    if (error.status === 401) {
      error.alert.props.children = app.translator.trans('core.forum.log_in.invalid_login_message');
    }
    super.onerror(error);
  }
}

 function getParams(paramName,value)
      { 
            let paramsURL = Object.assign({},  m.route.param());
            paramsURL[paramName] = value;
            delete  paramsURL.cr;
            return paramsURL;
            
      }