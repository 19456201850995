/* global $ */
/* global m */
/* global CKEDITOR */

import TextEditor from "flarum/components/TextEditor";
import listItems from "flarum/helpers/listItems";
import LoadingIndicator from "flarum/components/LoadingIndicator";

export default class TextEditorCKEditor extends TextEditor {
  init() {
    this.value = m.prop(this.props.value || "");
    this.loading = false;
    if (typeof CKEDITOR === "undefined") {
      this.loading = true;

      $.ajax({
        //      url: 'https://cdn.ckeditor.com/4.7.1/full/ckeditor.js',
        //      url: 'http://172.18.0.85//ckeditor/ckeditor.js',
        url: "https://thedealapp.in/assets/ckeditor/ckeditor.js",
        //      url: 'http://tda.dev/assets/ckeditor/ckeditor.js',
        dataType: "script",
        cache: true,
        success: () => {
          this.loading = false;

          m.redraw(true);
        },
      });
    }
  }
  view() {
    if (this.loading) {
      return (
        <p className="TextEditor TextEditor-placeholder">
          {LoadingIndicator.component({ size: "large" })}
        </p>
      );
    }

    return (
      <div className="TextEditor">
        <textarea
          className="FormControl Composer-flexible-editor"
          config={this.configTextarea.bind(this)}
          // disabled={!!this.props.disabled}
          value={this.value()}
        ></textarea>
        <ul className="TextEditor-controls Composer-footer">
          {listItems(this.controlItems().toArray())}
        </ul>
      </div>
    );
  }
  configTextarea(element, isInitialized) {
    if (isInitialized) return;
    // CKEDITOR.replace( '<em>textarea_id</em>', {
    // 	extraPlugins: 'divarea'
    // });

    const editor = CKEDITOR.replace(element, this.editorConfig());

    //  const editor = CKEDITOR.replace( '<em>textarea_id</em>', {
    // 	extraPlugins: 'divarea'
    // });
    editor.on("change", () => {
      this.oninput(editor.getData());
    });
    editor.on("loaded", function (evt) {
      editor.fire("change");
    });
    $(element).data("ckeditor", editor.name);
    editor.disableAutoInline = true;
    this.editor = editor;

    // const handler = () => {
    //   this.onsubmit();
    //   m.redraw();
    // };

    // $(element).bind('keydown', 'meta+return', handler);
    // $(element).bind('keydown', 'ctrl+return', handler);
    $(".TextEditor").css("text-align", "left");
  }

  editorConfig() {
    return {
      toolbar: [
        {
          name: "basicstyles",
          items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "-",
            "RemoveFormat",
            "Image",
            "Table",
            "Smiley",
            "emojione",
            "MediaEmbed",
            "Scayt",
            "CreateDiv",
          ],
        },
        { name: "links", items: ["Link", "Unlink"] },
        //{ name: 'img', items: ['Image'] },
        {
          name: "paragraph",
          items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Blockquote",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
          ],
        },
        { name: "colors", items: ["TextColor"] },
        { name: "styles", items: ["Format"] },
        { name: "tools", items: ["Maximize"] },
        ``,
      ],
      //removePlugins: 'elementspath,Table',
      resize_enabled: true,

      coreStyles_strike: {
        element: "strike",
        overrides: "s",
      },
      extraAllowedContent: "img[width,height,align]",
      disallowedContent: "img{width,height,align}",
      //	extraPlugins: 'divarea',

      format_tags: "p;h1;h2;h3;h4;h5;h6;pre",
    };
  }
}
