import Modal from "flarum/components/Modal";
//import myDiscussionComposer from '../myDiscussionComposer';
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class CouponModalv2 extends Modal {
  //Used for display modal box on get coupon for redirection
  init() {
    this.discussion = m.prop(this.props.discussion);
    // super.init();
    this.imgurl = m.prop(this.props.discussion._source.imagepath || "");
    this.coupontitle = m.prop(this.props.discussion._source.title || "");
    this.couponcode = m.prop(this.props.discussion._source.couponcode || "");
    this.promocode = m.prop(this.props.promocode);
    if (this.promocode) {
      this.couponcode = this.promocode;
    } else {
      this.couponcode = this.couponcode;
    }
    this.merchantLogo = m.prop(this.props.discussion._source.merchantLogo || "");
    if (this.imgurl() == "" && !this.merchantLogo() || this.merchantLogo() != "") {
      this.imgurl = this.merchantLogo;
    }

    this.productinfo = m.prop(this.props.discussion._source.productinfo || "");
    this.description = m.prop(this.props.discussion._source.description || "");
    this.merchantName = m.prop(this.props.discussion._source.merchantName || "");

    // var dummy = document.createElement("input");
    // // Add it to the document
    // document.body.appendChild(dummy);
    // // Set its ID
    // dummy.setAttribute("id", "dummy_id");
    // // Output the array into it
    // document.getElementById("dummy_id").value = this.couponcode();
    // // Select it
    // dummy.select();
    // // Copy its contents
    // document.execCommand("copy");
    this.getCouponCode(this.discussion);
    this.promocode = "";
  }

  content() {
    if (this.promocode && this.couponcode() == undefined) {
      this.promocode = this.promocode;
    }
    return [
      <div className="Modal-header" style="text-align:left;background: #fff !important;display:flex;">
        {/* <div class="col-md-2" style="width:150px;display: inline-block;"> */}
        <div class="col-md-2" style="display: inline-block;">
          {!this.imgurl() ?
            <span style="text-align= center; height: 100px;padding-top: 30px;font-weight: bold;font-size: 25px;">{this.merchantName()}</span>
            : <img src={this.imgurl()} style="height:39px;margin: 0px 0px;" />}
        </div>
        <div class="col-md-8" style="display: inline-block;">
          <h2>
            {this.coupontitle()}
          </h2>
        </div>
      </div>,

      <div className="Modal-body" style="background: #fff !important;">
        {this.discussion()._source.hasPromoCode === false || this.discussion()._source.hasPromoCode == undefined ?
          <div class="col-md-12" style="width: 100%;display: table;">
            <div class="copycouponcode">No Promo Code Required</div></div>
          : <div class="col-md-12" style="width: 100%;display: table;">
            <div class="copycouponcode">
              {this.couponcode() == undefined ? this.promocode : this.couponcode()}
              <span
                class="go-cidCC"
                id="clpCde"
                data-clipboard-text={this.couponcode() == undefined ? this.promocode : this.couponcode()}
              >
                COPY CODE
              </span>
            </div>
          </div>
        }
      </div>,
      <div class="cc_couponBotFull" style="padding-left:40px;padding-right:40px">
        {m("div", { class: "cc_couponDesc" }, [
          m.trust(!this.productinfo() ? this.description() ? this.description() : this.productinfo() : this.productinfo()),
        ])}
      </div>,

      <div className="Modal-footer"></div>,
    ];
  }

  onsubmit(e) {
    e.preventDefault();

    /* const tags = this.selected;    
    if (this.props.onsubmit) this.props.onsubmit(tags);
     const props = {
        tags,  
      };   
      app.cache.selectedstores= tags;
 
   app.modal.close();   
   const component = new myDiscussionComposer({user: app.session.user}); */
  }

  getCouponCode(data) {
    const discussion = data();
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    var Api_url = ApiEndpoint + "/api/v3/coupon/promocode?id=" + discussion._source.id;

    var result = app.request({
      method: 'GET',
      url: Api_url,
    }).then(response => {
      var promocode = response.promocode;
      this.promocode = promocode;
      m.lazyRedraw();
    });
  }


}
