import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function addOrderdate() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canOrderdate()) {
          if (!discussion.isOrderdate()){
                    items.add('Orderdate', Button.component({
                      children: 'Bring to Top',
                      icon: 'fa fa-arrow-up',
                      onclick: this.OrderdateAction.bind(discussion)
                    }));
          }
      }
                    
  });

  DiscussionControls.OrderdateAction = function() {
    this.save({isOrderdate: !this.isOrderdate()}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }
      m.redraw();
    });
  };
}
