import { extend } from "flarum/extend";
import LogInModal from "flarum/components/LogInModal";
import SignUpModal from "flarum/components/SignUpModal";
import NewLogin from "./modal/NewLogin";
import NewSignIn from "./modal/NewSignIn";

export default function () {
  //used to display navigation menu
  extend(LogInModal.prototype, "content", function () {
    //alert("Hi");
    app.modal.close();

    return app.modal.show(new NewLogin({checklogin:true}));
  });

  extend(SignUpModal.prototype, "content", function () {
    app.modal.close();
    return app.modal.show(new NewSignIn());
  });
}
