import Component from "flarum/Component";
import noUiSlider from "nouislider";
export default class slider extends Component {
  //used for price range & discount slider
  init() {
    super.init(this.props);
    this.active = true;
  }
  config(isInitialized) {
    if (isInitialized) return;
  }

  configPreview(element, isInitialized, context) {
    let routeName = this.props.routeName;
    if (isInitialized) return;
    var params = m.route.param();
    var paramsvar = this.props;
    if (paramsvar.min == "minPrice") {
      var minvalrange = 0;
      var maxvalrange = 100;
      var min = params.minPrice ? parseInt(params.minPrice) : 0;
      var max = params.maxPrice ? parseInt(params.maxPrice) : 100;
    } else if (paramsvar.min == "minAvg") {
      var minvalrange = 0;
      var maxvalrange = 100;
      var min = params.minAvg ? parseInt(params.minAvg) : 0;
      var max = params.maxAvg ? parseInt(params.maxAvg) : 100;
    } else if (paramsvar.min == "minDisc") {
      var minvalrange = 0;
      var maxvalrange = 100;
      var min = params.minDisc ? parseInt(params.minDisc) : 0;
      var max = params.maxDisc ? parseInt(params.maxDisc) : 100;
    } else {
      var minvalrange = 0;
      var maxvalrange = 100000;
      var min = params.minRange ? parseInt(params.minRange) : 0;
      var max = params.maxRange ? parseInt(params.maxRange) : 100000;
    }
    delete paramsvar.routeName;

    var rangeSlider = element;
    noUiSlider.create(rangeSlider, {
      start: [min, max],
      connect: true,
      range: {
        min: [minvalrange],
        max: [maxvalrange],
      },
    });

    var stepSliderValueElement = [
      document.getElementById("slider-range-lower-" + this.props.min),
      document.getElementById("slider-range-upper-" + this.props.min),
    ];

    rangeSlider.noUiSlider.on("update", function (values, handle) {
      stepSliderValueElement[0].value = values[0];
      stepSliderValueElement[1].value = values[1];
    });

    rangeSlider.noUiSlider.on(
      "change",
      function (values, handle) {
        stepSliderValueElement[0].value = values[0];
        stepSliderValueElement[1].value = values[1];
        let routeName = "";
        if (this.props.routeName != "Deals") {
          if (this.props.min == "minRange") {
            if (this.props.routeName == "myProduct") {
              routeName = "myProduct";
            }
            if (this.props.routeName == "Category") {
              routeName = "Category";
            }
            if (this.props.routeName == "pricedrop") {
              routeName = "pricedrop";
            }
            if (this.props.routeName == "brand") {
              routeName = "brand";
            }
            if (this.props.routeName == "search") {
              routeName = "search";
            }
          } else {
            if (this.props.routeName == "search") {
              routeName = "search";
            }
            if (this.props.routeName == "pricedrop") {
              routeName = "pricedrop";
            }
            if (this.props.routeName == "Category") {
              routeName = "Category";
            }
            if (this.props.routeName == "brand") {
              routeName = "brand";
            }
          }
        } else {
          routeName = "Deals";
        }
        m.route(app.route(routeName, this.getParams(paramsvar, values)));
      }.bind(this)
    );
  }
  getParams(paramName, value) {
    let newParams = m.route.param();
  if(newParams.categories == undefined)
  {
      newParams.categories = "category";
      if(newParams.src == "brand"){
        newParams.categories = "";
      }
  }else{
    if(newParams.tag && newParams.categories == ""){
        newParams.categories = newParams.tag;
      }
  }
  let paramsURL = Object.assign({},  newParams);
    paramsURL[paramName.min] = value[0];
    paramsURL[paramName.max] = value[1];
    return paramsURL;
  }
  view() {
    this.props = this.props;
    let params = this.props;
    return (
      <div style="padding: 0px 15px;">
        <div
          id={"slider-range-" + this.props.min}
          config={this.configPreview.bind(this)}
        ></div>
        <div class="row cc_slider">
          <div class="cc_sliderCol0">
            <label
              style={
                this.props.min == "minRange"
                  ? "display:inline-block;"
                  : "display:none;"
              }
            >
              ₹
            </label>
          </div>
          <div class="cc_sliderCol1">
            <input
              id={"slider-range-lower-" + this.props.min}
              class="sliderRange"
            />
          </div>
          <div class="cc_sliderCol2">
            <label
              style={
                this.props.min == "minRange"
                  ? "display:none;"
                  : "display:inline-block;"
              }
            >
              %
            </label>
          </div>
          <div style="width:5%;display:inline-block;"></div>
          <div class="cc_sliderCol3">
            <label
              style={
                this.props.min == "minRange"
                  ? "display:inline-block;"
                  : "display: none;"
              }
            >
              ₹
            </label>
          </div>
          <div class="cc_sliderCol4">
            <input
              id={"slider-range-upper-" + this.props.min}
              class="sliderRange"
            />
          </div>
          <div class="cc_sliderCol5">
            <label
              style={
                this.props.min == "minRange"
                  ? "display:none;"
                  : "display:inline-block;"
              }
            >
              %
            </label>
          </div>
          <div class="cc_sliderCol6">
            <input
              type="button"
              class="Button Button--primary ButtonGo"
              id={"Go" + this.props.min}
              value="Go"
              onclick={this.valueChanges.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  valueChanges(data) {
    let values = [];
    var numbers = /^[0-9]+\.?[0-9]*$/;
    let valid = false;
    if (data.target.id == "GominDisc") {
      if (
        $("#slider-range-lower-minDisc").val().match(numbers) &&
        $("#slider-range-upper-minDisc").val().match(numbers)
      ) {
        if (
          $("#slider-range-lower-minDisc").val() <= 100 &&
          $("#slider-range-upper-minDisc").val() <= 100
        ) {
          values[0] = $("#slider-range-lower-minDisc").val();
          values[1] = $("#slider-range-upper-minDisc").val();
          valid = true;
        } else {
          alert("Please Enter numbers less than 100");
          values[0] = $("#slider-range-lower-minDisc").val(0.0);
          values[1] = $("#slider-range-upper-minDisc").val(100.0);
          valid = false;
        }
      } else {
        alert("Please Enter only numbers");
        values[0] = $("#slider-range-lower-minDisc").val(0.0);
        values[1] = $("#slider-range-upper-minDisc").val(100.0);
        valid = false;
      }
    } else if (data.target.id == "GominPrice") {
      if (
        $("#slider-range-lower-minPrice").val().match(numbers) &&
        $("#slider-range-upper-minPrice").val().match(numbers)
      ) {
        if (
          $("#slider-range-lower-minPrice").val() <= 100 &&
          $("#slider-range-upper-minPrice").val() <= 100
        ) {
          values[0] = $("#slider-range-lower-minPrice").val();
          values[1] = $("#slider-range-upper-minPrice").val();
          valid = true;
        } else {
          alert("Please Enter numbers less than 100");
          values[0] = $("#slider-range-lower-minPrice").val(0.0);
          values[1] = $("#slider-range-upper-minPrice").val(100.0);
          valid = false;
        }
      } else {
        alert("Please Enter only numbers");
        values[0] = $("#slider-range-lower-minPrice").val(0.0);
        values[1] = $("#slider-range-upper-minPrice").val(100.0);
        valid = false;
      }
    } else if (data.target.id == "GominAvg") {
      if (
        $("#slider-range-lower-minAvg").val().match(numbers) &&
        $("#slider-range-upper-minAvg").val().match(numbers)
      ) {
        if (
          $("#slider-range-lower-minAvg").val() <= 100 &&
          $("#slider-range-upper-minAvg").val() <= 100
        ) {
          values[0] = $("#slider-range-lower-minAvg").val();
          values[1] = $("#slider-range-upper-minAvg").val();
          valid = true;
        } else {
          alert("Please Enter numbers less than 100");
          values[0] = $("#slider-range-lower-minAvg").val(0.0);
          values[1] = $("#slider-range-upper-minAvg").val(100.0);
          valid = false;
        }
      } else {
        alert("Please Enter only numbers");
        values[0] = $("#slider-range-lower-minAvg").val(0.0);
        values[1] = $("#slider-range-upper-minAvg").val(100.0);
        valid = false;
      }
    } else {
      if (
        $("#slider-range-lower-minRange").val().match(numbers) &&
        $("#slider-range-upper-minRange").val().match(numbers)
      ) {
        values[0] = $("#slider-range-lower-minRange").val();
        values[1] = $("#slider-range-upper-minRange").val();
        valid = true;
      } else {
        alert("Please Enter only numbers");
        values[0] = $("#slider-range-lower-minRange").val(0.0);
        values[1] = $("#slider-range-upper-minRange").val(100000.0);
        valid = false;
      }
    }

    if (valid == true) {
      let routeName = "";
      var paramsvar = this.props;
      if (this.props.routeName != "Deals") {
        if (this.props.min == "minRange") {
          if (this.props.routeName == "myProduct") {
            routeName = "myProduct";
          }
          if (this.props.routeName == "Category") {
            routeName = "Category";
          }
          if (this.props.routeName == "pricedrop") {
            routeName = "pricedrop";
          }
          if (this.props.routeName == "brand") {
            routeName = "brand";
          }
          if (this.props.routeName == "search") {
            routeName = "search";
          }
        } else {
          if (this.props.routeName == "search") {
            routeName = "search";
          }
          if (this.props.routeName == "pricedrop") {
            routeName = "pricedrop";
          }
          if (this.props.routeName == "Category") {
            routeName = "Category";
          }
        }
      } else {
        routeName = "Deals";
      }
      m.route(app.route(routeName, this.getParams(paramsvar, values)));

      var stepSliderValueElement = [
        document.getElementById("slider-range-lower-" + this.props.min),
        document.getElementById("slider-range-upper-" + this.props.min),
      ];
      stepSliderValueElement.forEach(function (input, handle) {
        input.addEventListener("change", function (event) {
          let inputvalue = event.target.value;
          rangeSlider.noUiSlider.setHandle(handle, inputvalue);
        });
      });
    }
  }
}
