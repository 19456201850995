import AddAttributes from "./components/AddAttributes";
import AddVoteButtons from "./components/AddVoteButtons";
import DownvotedNotification from "./components/DownvotedNotification";
import UpvotedNotification from "./components/UpvotedNotification";
import { extend } from "flarum/extend";

app.initializers.add("tda-discussionvotes", (app) => {
  app.notificationComponents.downvoted = DownvotedNotification;
  app.notificationComponents.upvoted = UpvotedNotification;
  AddVoteButtons();
  AddAttributes();
});
// Expose compat API
import discussionvotesCompat from "./compat";
import { compat } from "@flarum/core/forum";

Object.assign(compat, discussionvotesCompat);
