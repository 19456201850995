import Component from 'flarum/Component';

export default class store extends Component {
  //used in All Deals for stores Filter sidebar
      init()
      {
        super.init(this.props);
        this.selectedStores =[];
        this.routeName = this.props.routeName;
      }
      view()
      {
        const routeName = this.props.routeName;
        this.selectedStores =[];
        this.storeList = app.cache.storeList? app.cache.storeList : [];
        if(m.route.param('stores')) {
          this.selectedStores = m.route.param('stores').split(',');
        } 
        return( <div class="cc_storesMenu">
              <div class="cc_accPanel">
                <ul className="" id="categoryList1">
                  {
                    this.storeList.map(data => {
                      
                      return (
                      
                      <li>
                      <label className="cc_allCatsLink">
                {data.store? <input class="storename cc_allCatsInput" id="storename" type="checkbox" checked={this.selectedStores.length > 0 && this.selectedStores.indexOf(data.store) !== -1 ? true : ''} value={data.store} onchange={this.stores.bind(this)}/> 
                : <input class="brandname cc_allCatsInput" id="brandname" type="checkbox" checked={this.selectedStores.length > 0 && this.selectedStores.indexOf(data.store) !== -1 ? true : ''} value="unknown" onchange={this.stores.bind(this)}/>}
                
                &nbsp;&nbsp;{data.store ?data.store:"Unknown Brand" }&nbsp;&nbsp;{data.count?"("+data.count+")":''}
                </label>
              </li>
                        
                      );
                    })
                  }
                </ul>
              </div>
            </div>
                 
        )
      }
     
      config(element, isInitialized, context)
      {
            if(isInitialized) return;
      }
      
      stores()
      {
         var searchString = '';
         var hash = $(".storename:checked").map(function() {
              return this.value;
          }).toArray();
          hash = hash.join(",");
        var mlink = app.route(this.props.routeName,getParams('stores',hash));
          m.route(mlink);
      }
      
}
  function  getParams(paramName,value)
      { 
        let newParams = m.route.param();
        if(newParams.categories == undefined)
        {
            newParams.categories = "category";
            if(newParams.src == "brand"){
              newParams.categories = "";
            }
        }else{
          if(newParams.tag && newParams.categories == ""){
              newParams.categories = newParams.tag;
            }
        }
            let paramsURL = Object.assign({},  newParams);
            paramsURL[paramName] = value;
            delete  paramsURL.cr;
            return paramsURL;
            
      }

