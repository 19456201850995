import app from "flarum/app";
import myComposerBody from "./myComposerBody";
import CategoryDiscussionModal from "./CategoryDiscussionModal";
import extractText from "flarum/utils/extractText";
import Button from "flarum/components/Button";
import TextEditorCKEditor from "./texteditor/TextEditorCKEditor";
import DuplicateProductModal from "./DuplicateProductModal";
import PriceGraphModal from "./PriceGraphModal";
import LogInModal from "flarum/components/LogInModal";
import LinkButton from "flarum/components/LinkButton";
import GetTDAProductID from "./GetTDAProductID";
import CompareProduct from "./CompareProduct";
import listItems from "flarum/helpers/listItems";
import ItemList from "flarum/utils/ItemList";
import Select from "flarum/components/Select";
import EndpointsApiUrls from "./EndpointsApiUrls";
import datetimepicker from "jquery-datetimepicker";
import fastselect from "fastselect";
import DealPreviewModal from "./modal/DealPreviewModal";

/**
 * The `myDiscussionComposer` component displays the composer content for starting
 * a new discussion. It adds a text field as a header control so the user can
 * enter the title of their discussion. It also overrides the `submit` and
 * `willExit` actions to account for the title.
 *
 * ### Props
 *
 * - All of the props for myComposerBody
 * - `titlePlaceholder`
 */
export default class myDiscussionComposer extends myComposerBody {
  init() {
    super.init();

    var matches = /url=([^&#=]*)/.exec(window.location.search);
    if (matches) {
      var uri_dec = decodeURIComponent(matches[1]);
      this.paramURL = m.prop(uri_dec);
    } else {
      this.paramURL = m.prop(null);
    }

    this.queryParams = m.route.param();
    // condition for getting maxprice in url
    var maxpricematches = /maxPrice=([^&#=]*)/.exec(window.location.search);
    if (maxpricematches) {
      var maxprice_dec = decodeURIComponent(maxpricematches[1]);
      this.parammaxprice = m.prop(maxprice_dec);
      this.maxpriceurl = "maxurl";
    } else {
      this.parammaxprice = m.prop(null);
      this.maxpriceurl = null;
    }

    // condition for getting deal/expected price in url
    var exppricematches = /expctprice=([^&#=]*)/.exec(window.location.search);
    if (exppricematches) {
      var expprice_dec = decodeURIComponent(exppricematches[1]);
      this.paramexpprice = m.prop(expprice_dec);
      this.exppriceurl = "exppriceurl";
    } else {
      this.paramexpprice = m.prop(null);
      this.exppriceurl = null;
    }

    // condition for get title from url
    var titlematches = /title=([^&#=]*)/.exec(window.location.search);
    if (titlematches) {
      var title_dec = decodeURIComponent(titlematches[1]);
      this.paramtitle = m.prop(title_dec);
      this.titleurl = "titleurl";
    } else {
      this.paramtitle = m.prop(null);
      this.titleurl = null;
    }

    // condition for get validfrom date from url
    var validfrommatches = /validfrom=([^&#=]*)/.exec(window.location.search);
    if (validfrommatches) {
      var validfrom_dec = decodeURIComponent(validfrommatches[1]);
      //var validfrom_dec = validfrom_dec.replace(/'-'/g,'/');
      var validfrom_dec = new Date(validfrom_dec);

      var year = "" + validfrom_dec.getFullYear();
      var month = "" + (validfrom_dec.getMonth() + 1);
      var day = "" + validfrom_dec.getDate();
      var hour = "" + validfrom_dec.getHours();
      var minute = "" + validfrom_dec.getMinutes();

      var validfrom_dec =
        year + "/" + month + "/" + day + " " + hour + ":" + minute;

      this.paramvalidfrom = m.prop(validfrom_dec);
      this.validfromurl = "validfromurl";
    } else {
      this.paramvalidfrom = m.prop(null);
      this.validfromurl = null;
    }

    // condition for get validto from url
    var validtomatches = /validto=([^&#=]*)/.exec(window.location.search);
    if (validtomatches) {
      var validto_dec = decodeURIComponent(validtomatches[1]);
      //var validto_dec = validto_dec.replace(/'-'/g,'/');
      var validto_dec = new Date(validto_dec);
      var year = "" + validto_dec.getFullYear();
      var month = "" + (validto_dec.getMonth() + 1);
      var day = "" + validto_dec.getDate();
      var hour = "" + validto_dec.getHours();
      var minute = "" + validto_dec.getMinutes();

      var validto_dec =
        year + "/" + month + "/" + day + " " + hour + ":" + minute;

      this.paramvalidto = m.prop(validto_dec);
      this.validtourl = "validtourl";
    } else {
      this.paramvalidto = m.prop(null);
      this.validtourl = null;
    }

    // condition for get secondary tag from url
    var secondarytagmatches = /secondarytag=([^&#=]*)/.exec(
      window.location.search
    );
    if (secondarytagmatches) {
      var secondarytag_dec = decodeURIComponent(secondarytagmatches[1]);
      this.paramsecondarytag = m.prop(secondarytag_dec);
      this.secondarytagurl = "secondarytagurl";
    } else {
      this.paramsecondarytag = m.prop(null);
      this.secondarytagurl = null;
    }

    this.categorySelectionEnabled = m.prop(false);
    this.productURL = m.prop("");
    //this.title = m.prop('');
    this.title = m.prop(this.paramtitle() || "");
    this.myCategoryselectedtTag = [];
    this.url = m.prop(this.paramURL() || "");
    this.productID = m.prop("");
    this.productBrand = m.prop("");
    this.productName = m.prop("");
    this.productInfo = m.prop("");
    this.categoryID = m.prop("");
    this.categoryOther = m.prop("others_category");
    this.MerchantNameOther = m.prop("others_Online");
    this.MerchantID = m.prop("");
    this.MerchantParentID = m.prop("");
    this.MerchantName = m.prop("");
    this.MerchantTag = m.prop("");
    this.MerchantParentTag = m.prop("");
    this.multipleProducts = false;
    //this.maxPrice = m.prop('');
    this.maxPrice = m.prop(this.parammaxprice() || "");
    //this.currentPrice = m.prop('');
    this.currentPrice = m.prop(this.paramexpprice() || "");
    this.apicurrentPrice = m.prop("");
    this.refreshDate = m.prop("");
    this.discount = m.prop("");
    this.currency = m.prop("");
    this.imageUrl_sm = m.prop("");
    this.imageUrl_md = m.prop("");
    this.imageUrl_lg = m.prop("");
    this.contentPost = "";
    this.contentPreview = "";
    this.getproductDetail = "";

    //new added
    this.imagePreview = "";
    this.Description = m.prop("");
    this.mytags = [];
    //this.validFrom = m.prop('');
    this.validFrom = m.prop(this.paramvalidfrom() || "");
    //this.validTo = m.prop('');
    this.validTo = m.prop(this.paramvalidto() || "");
    this.badgeText = m.prop("");
    this.categoryTagOther = app.store.getBy(
      "tags",
      "slug",
      this.categoryOther()
    );
    this.categoryParentID = m.prop(
      this.categoryTagOther.data.relationships.parent.data.id
    );
    this.categoryParentTag = app.store.getBy(
      "tags",
      "id",
      this.categoryParentID()
    );
    this.myTagSelected = [];
    this.mySelectedTags = "";
    this.categoryCount = 0;
    //this.secondarytag = m.prop('');
    this.secondarytag = m.prop(this.paramsecondarytag() || "");
    this.merchantdropdown = new ItemList();
    this.secondarytagoptions = [];
    this.chooseTags = function () {
      const props = {
        url: this.url(),
        productID: this.productID(),
        productBrand: this.productBrand(),
        productName: this.productName(),
        productInfo: this.productInfo(),
        categoryID: this.categoryID(),
        MerchantID: this.MerchantID(),
        maxPrice: this.maxPrice(),
        currentPrice: this.currentPrice(),
        apicurrentPrice: this.apicurrentPrice(),
        refreshDate: this.refreshDate(),
        discount: this.discount(),
        currency: this.currency(),
        imageUrl_sm: this.imageUrl_sm(),
        imageUrl_md: this.imageUrl_md(),
        imageUrl_lg: this.imageUrl_lg(),
        content: this.contentPost,
        secondarytag: this.secondarytag(),
      };

      app.modal.show(
        new CategoryDiscussionModal({
          selectedTags: this.mytags,
          props,
        })
      );
    };
    this.flag = 1;
  }

  static initProps(props) {
    super.initProps(props);
    props.placeholder =
      "Enter additional description describing the deal here. you may include additional coupons, terms and condition, wiki, etc.";
    props.placeholder =
      props.placeholder ||
      extractText(
        app.translator.trans("core.forum.composer_discussion.body_placeholder")
      );
    props.submitLabel =
      props.submitLabel ||
      app.translator.trans("core.forum.composer_discussion.submit_button");
    props.confirmExit =
      props.confirmExit ||
      extractText(
        app.translator.trans(
          "core.forum.composer_discussion.discard_confirmation"
        )
      );
    props.titlePlaceholder =
      props.titlePlaceholder ||
      extractText(
        app.translator.trans("core.forum.composer_discussion.title_placeholder")
      );
    props.className = "ComposerBody--discussion myComposerBody";
  }
  previewItems() {
    const items = super.previewItems();
    //items.add(
     // "productpreivew",
     // <div className="cc_productPreview" id="productPreview">
      //  <h2>Deal Details</h2>
     // </div>
   // );

   // items.add(
     // "productInfo",
     // <div className="cc_prodInfo">
      //  <div className="ComposerBody-editor" style="height:100%;border: 1px solid #ddd;padding: 10px;">
       //   {this.editor.render()}
       // </div>
     // </div>
    //);
    return items;
  }
  config(isInitialized) {
    if (isInitialized) return;
    if (!this.loading) this.datepicker();

    /* $('.multipleInputDynamicWithInitialValue').fastselect({url: 'https://search.thedealapp.in/category?query='+this.title(),
    loadOnce: false,
    apiParam: 'query',
    clearQueryOnSelect: true,
    minQueryLength: 1,
    focusFirstItem: true,
    flipOnBottom: true,
    typeTimeout: 150,
    userOptionAllowed: false,
    valueDelimiter: ' ',
    initialValue: null,
    onItemSelect: function($item, itemModel) {
          this.asd(itemModel);
      }.bind(this),    
    matcher: function(text, query){ return text; },
    placeholder: 'Choose option',  
    searchPlaceholder: 'Search options',
    noResultsText: 'No results',
    userOptionPrefix: 'Add'}).bind(this);*/
    if (this.url() && this.url() != "") {
      this.Process1();
    }

    $(".multipleSelect").fastselect({
      placeholder: "Choose Events",
    });

    this.categorytags = app.store.all("tags");
    var category = {};
    var i = 0;
    var categoryID = "";
    $.each(this.categorytags, function (index, value) {
      if (value.data.attributes.slug == "sales-events") {
        i++;
        categoryID = value.data.id;
      }

      if (value.data.relationships) {
        if (value.data.relationships.parent) {
          if (value.data.relationships.parent.data.id == categoryID) {
            if (
              value.data.attributes.showInTagLabels === true &&
              value.data.attributes.canStartDiscussion == true
            ) {
              category[value.data.attributes.slug] = value.data.attributes.name;
            }
            i++;
          }
        }
      }
    });

    var count = Object.keys(category).length;
    if (count == undefined || count < 1) {
      $("#selectdropdown").hide();
    } else {
      $.each(category, function (val, text) {
        $("#selectEvent").append($("<option></option>").val(val).html(text));
      });
    }
  }

  // rahul Patil: on url paste fetch Special tag in dropdown
  parseResults(results) {
    const sortOptions = results["response"];

    //if(this.secondarytag() == ''){
    //  sortOptions.unshift("--Select--");
    //}else{
    sortOptions.unshift(this.secondarytag());
    //}

    const sortOptionsss = [];
    $.map(sortOptions, function (val, i) {
      sortOptionsss[val] = val;
    });

    this.secondarytagoptions = sortOptionsss;
    this.merchantdropdown.add(
      "secondarytag",
      Select.component({
        options: sortOptionsss,
        value: this.secondarytag(),
        onchange: this.secondarytag,
      })
    );

    m.lazyRedraw();
  }

  changemerchant(paramsecondarytag) {
    this.secondarytag = m.prop(paramsecondarytag);
    m.lazyRedraw();
  }

  fastselectConfig(element, isInitialized, context) {
    if (this.categorySelectionEnabled) {
      var querystring = this.title();
      const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

      $(element).fastselect({
        url: ApiEndpoint + "/category?query=" + this.title(),
        loadOnce: false,
        apiParam: "query",
        clearQueryOnSelect: true,
        minQueryLength: 1,
        focusFirstItem: true,
        flipOnBottom: true,
        typeTimeout: 150,
        userOptionAllowed: false,
        valueDelimiter: " ",
        initialValue: null,
        onItemSelect: function ($item, itemModel) {
          this.asd(itemModel);
        }.bind(this),
        matcher: function (text, query) {
          return text;
        },
        placeholder: "Choose option",
        searchPlaceholder: "Search options",
        noResultsText: "No results",
        userOptionPrefix: "Add",
      });
      //.bind(this);
      // $(".multipleSelect").fastselect({
      //   placeholder: "Choose Events",
      // });
    }
  }

  asd(itm) {
    var datamake = [];
    $(".fstChoiceItem").map(function () {
      app.store
        .find("tdacategory", { path: $(this).data("value").toString() })
        .then((a) => {
          datamake.push({ data: a.payload.data[0] });
        });
    });
    this.myCategoryselectedtTag = datamake;
  }

  datepicker() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    $("#validFromdatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      minDate: jQuery("#validFromdatetimepicker").val()
        ? jQuery("#validFromdatetimepicker").val()
        : false,
      onReady: function ( dateObj, dateStr, instance ) {
        const $clear = $( '<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>' )
          .on( 'click', () => {
            instance.clear();
            instance.close();
          } )
          .appendTo( $( instance.calendarContainer ) );
      }
    });
    $("#validTodatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      minDate: jQuery("#validFromdatetimepicker").val()
        ? jQuery("#validFromdatetimepicker").val()
        : false,
      onReady: function ( dateObj, dateStr, instance ) {
        const $clear = $( '<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>' )
          .on( 'click', () => {
            instance.clear();
            instance.close();
          } )
          .appendTo( $( instance.calendarContainer ) );
      }
    });
    // $("#validFromdatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       maxDate: jQuery("#validTodatetimepicker").val()
    //         ? jQuery("#validTodatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
    // $("#validTodatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       minDate: jQuery("#validFromdatetimepicker").val()
    //         ? jQuery("#validFromdatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
  }
  // destroyDatePicker() {
  //   $("#validTodatetimepicker").datetimepicker("destroy");
  //   $("#validFromdatetimepicker").datetimepicker("destroy");
  // }
  headerItems() {
    if (app.cache.selectedCategory) {
      this.mySelectedTags = app.cache.selectedCategory;
    }

    const items = super.headerItems();
    items.add("stepProgressBar",
    <div id="stepProgressBar">
    <section >
    <div class="wizzard">
    <div class="Steps">
      <ul>
        <li><div id="step1" class="step active">1</div></li>
        <li><div id="step2" class="step">2</div></li>
        <li><div id="step3" class="step">3</div></li>
      </ul>
    </div>
        <div class="content" id="content-1" data-id='1'>
        <div style="display:inline-flex; width: 100%;" className="group cc_parentBorderNone">
            <div className="group">
              <label class="dealInputLabel" id="lblurl">
                Start by entering deal url here..
              </label>
              <input
                className="dealInput"
                value={this.url()}
                oninput={m.withAttr("value", this.url)}
                disabled={!!this.props.disabled}
                onkeydown={this.onkeydown.bind(this)}
                onchange={this.Process1.bind(this)}
                id="txturl"
                placeholder="https://example.com/"
                style="width: 100%;"
                required
              />
              <span className="myHighlight"></span>
            
            </div>
            <div>
              {LinkButton.component({
                className: "Button Button--user Button--flat",
                href: "/newDealComposer",
                id: "lnkReset",
                icon: "fas fa-times",
                style: "display: none;",
              })}
            </div>
        </div>
        <div className="cc_parentBorderNone">
          <span>
            <i class=" processIcon"></i>
          </span>
          &nbsp;
          <span className="messageLineText"></span>
        </div>
        </div>
   
    <div class="content" id="content-2" data-id='2' style="display:none">
    <div style=""className="cc_parentBorderNone" >
        <div className="group cc_group gMRP">
        <label className="dealInputLabel">MRP</label>
          <input
            className="cc_dealInput dealInput"
            type="number"
            step="any"
            value={this.maxPrice()}
            oninput={m.withAttr("value", this.maxPrice)}
            disabled={!!this.props.disabled}
            onkeydown={this.onkeydown.bind(this)}
            onkeyup={this.changemaxprice.bind(this)}
            placeholder="MRP"
            style="width:95%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div className="group cc_group gDealPrice">
        <label className="dealInputLabel">Deal Price</label>
          <input
            className="cc_dealInput dealInput"
            value={this.currentPrice()}
            type="number"
            step="any"
            oninput={m.withAttr("value", this.currentPrice)}
            disabled={!!this.props.disabled}
            onkeydown={this.onkeydown.bind(this)}
            onkeyup={this.changeCurrentprice.bind(this)}
            style="width:95%;"
            placeholder="Deal Price"
            required
          />
          <input
            className="cc_dealInput dealInput"
            value={this.apicurrentPrice()}
            type="hidden"
            step="any"
            placeholder="Deal Price"
          />
           
          <input
            className="dealInput"
            value={this.refreshDate()}
            type="hidden"
            step="any"
            placeholder="Deal Price"
          />
          <input
            className="cc_dealInput dealInput"
            value={this.secondarytag()}
            type="hidden"
            step="any"
            placeholder="MRP"
          />
          <span className="myHighlight"></span>
        </div>
        
        {/* {Object.keys(this.secondarytagoptions).length > 0 ? ( */}
          <div className="group cc_group gList">
          <span className="myHighlight"></span>
            <label className="dealInputLabel">
              Special Tag
            </label>
            <div className="DiscussionList">
              <ul className="DiscussionList-discussions">
              {Object.keys(this.secondarytagoptions).length > 0 ?
              Select.component({
                  options: this.secondarytagoptions,
                  value: this.secondarytag(),
                  onchange: this.secondarytag,
                })
                : Select.component({
                  options: this.secondarytagoptions,
                  value: this.secondarytag(),
                  onchange: this.secondarytag,
                  disabled:true,
                }) }
              </ul>
            </div>
          </div>
        {/*) : (
          ""
        )}*/}
      </div>
      <div className="group cc_group cc_parentBorderNone">
        <label className="dealInputLabel">Deal Title (Upto 80 Character)</label>
        <input
         className="dealInput"
         id="titlename"
         value={this.title()}
         oninput={m.withAttr("value", this.title)}
         disabled={!!this.props.disabled}
         onkeydown={this.onkeydown.bind(this)}
         onkeyup={this.changetitle.bind(this)}
         style="width: 100%;"
         placeholder="(Upto 80 Character)"
         required
       />
        <span className="myHighlight"></span>
        {this.title().length > 80 ? (
         <div>
           <label style="color:red;">
             Please limit title length to 80 character. Current length :-{" "}
              {this.title().length}
           </label>
           </div>
        ) : (
          ""
        )}
      </div>
      {this.categorySelectionEnabled() ? 
          <div style={this.categorySelectionEnabled() == true ? "display:block" : "display:none"}>
            <div style="padding-bottom: 10px">
            <div className="cc_group">
            <label className="dealInputLabel">
              Select all Deals categories that may be applicable to this deal
            </label>
            <input
              type="text"
              multiple
              class="multipleInputDynamicWithInitialValue"
              config={this.fastselectConfig.bind(this)}
              id="multipleInputDynamic"
              name="language"
              disabled={!this.categorySelectionEnabled}
            />
            </div>
            </div>
          </div>: ""}
          <div style="padding-bottom: 10px;" id="selectdropdown">
            <label className="dealInputLabel">
              Select all Sale/Events that may be applicable to this deal(Optional)
            </label>
            <select id="selectEvent" class="multipleSelect" multiple></select>
          </div>
          <div className="cc_parentBorderNone Dealvalidity">
            <br />
            <div className="cc_dealvalidityswitch" style="font-weight:600px;"><label className="dealInputLabel">This deal has Validity: </label><label className="switch">
              <input id="Dealvaliditycheck" type="checkbox" onclick={this.toggleDate.bind(this)}/>
              <span className="slider round"></span>
            </label>
            </div>
            <div id="datetime" className="cc_datetime">
              <div className="group cc_validgroup">
              <label className="dealInputLabel">
                  Valid From (Optional)
                </label>
                <input
                  className="dealInput cc_dealInputmb"
                  id="validFromdatetimepicker"
                  value={this.validFrom()}
                  onchange={m.withAttr("value", this.validFrom)}
                  disabled={!!this.props.disabled}
                  onkeydown={this.onkeydown.bind(this)}
                  required
                  placeholder="Valid From"
                />
                <span className="myHighlight"></span>
              </div>
              <div className="group cc_validgroup">
              <label className="dealInputLabel">
                  Valid To (Optional)
                </label>
                <input
                  className="dealInput cc_dealInputmb"
                  id="validTodatetimepicker"
                  value={this.validTo()}
                  onchange={m.withAttr("value", this.validTo)}
                  disabled={!!this.props.disabled}
                  onkeydown={this.onkeydown.bind(this)}
                  style="width: 100%;"
                  required
                  placeholder="Valid To"
                />
                <span className="myHighlight"></span>
              </div>
            </div>
          </div>
    </div>
    <div class="content" id="content-3" data-id='3' style="display:none">
      <div className="cc_prodInfo" style="padding-top:20px;">
        <div><label className="dealInputLabel">More Deal Details: </label><label className="switchdescription" style="padding-left:20px;margin-left: 17px;">
          { this.flag ==1 ?
            <input id="Descriptioncheck" type="checkbox" checked onclick={this.toggleDescription.bind(this)}/>
            : <input id="Descriptioncheck" type="checkbox" onclick={this.toggleDescription.bind(this)}/>
          }
          <span className="slider round"></span>
        </label>
        </div>
        <div id="productDescription" >
          {!this.imageUrl_sm() ? 
          <div className="group cc_parentBorderNone cc_couponimg">
            <label className="dealInputLabel">Deal Image</label>
            <input
            className="dealInput"
            id="imagename"
            value=""
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          </div>
          : ""} 
          <div className="cc_productPreview" id="productPreview">
            <h2>Deal Details</h2>
          </div>
          <div className="ComposerBody-editor" style="height:100%;border: 1px solid #ddd;padding:5px;border-radius: 10px;">
            {this.editor.render()}
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="cc_btn_footer" style="text-align:center;">
      <div class="cc_button buttonprev" style="display:none; float:left">
      <button id= "prev" class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.previous.bind(this)}>&larr; Previous</button>
      <i id= "cc_prev"class='fas cc_fas' onclick={this.previous.bind(this)} style='font-size:24px; color:#000;position: relative;'>&#xf30a;</i>
      </div>
      { this.title().length < 80 ?
      (<div class="cc_button pull-right">
      <button id= "next" class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.next.bind(this)}>Next &rarr;</button>
      <i id= "cc_next" style='font-size:24px;color:#000;position: relative;' class='fas cc_fas' onclick={this.next.bind(this)}>&#xf30b;</i>
      </div>)
      : ("")
      }
     
       <div class="cc_button" >
          <button id="btnShowPreview" style="display:none" icon="check" className="Button Button--primary mysubmit cc_mysubmit" onclick={this.ShowPreview.bind(this)}>Preview</button>
        </div> 
        <div class="cc_button cc_prbutton">
          <button id="btnShowPriceGraph" style="display:none" icon="check" className="Button Button--primary mysubmit cc_mysubmit" onclick={this.ShowPriceGraph.bind(this)}>Compare</button>
        </div> 
      {/* {app.cache.storename != "" && app.cache.hpid != undefined ?  */}
      <div class="cc_button" >
      <button id= "btnSubmitDeal" style="display:none" disabled={this.editor.loading} icon="check" class="Button  Button--primary mysubmit cc_mysubmit" onclick={this.onsubmit.bind(this)}>Submit</button>
      </div>
     
      {/* : ""} */}
      
      </div>
      </section>
    </div>
    )
    
    items.add(
      "discussionTitle",
      
    );
    $('.step').each(function(index, el) {
      $(el).not('.active').addClass('done');
      $('.done').html('<i class="icon-valid"></i>');
      if($(this).is('.active')) {
        $(this).parent().addClass('pulse')
        return false;
      }
    });

    // if (this.categorySelectionEnabled()) {
    //   items.add(
    //     "selectCategory",
    //     m(
    //       "div",
    //       {
    //         style: {
    //           "padding-bottom": "10px",
    //           display:
    //             this.categorySelectionEnabled() == true ? "block" : "none",
    //         },
    //       },
          
    //       <div style="">
    //         <label className="dealInputLabel">
    //           Select all Deals categories that may be applicable to this deal
    //         </label>
    //         <input
    //           type="text"
    //           multiple
    //           class="multipleInputDynamicWithInitialValue"
    //           config={this.fastselectConfig.bind(this)}
    //           id="multipleInputDynamic"
    //           name="language"
    //           disabled={!this.categorySelectionEnabled}
    //         />
    //       </div>
    //     )
    //   );
    // }
    // items.add(
    //   "productpreivew",
    //    <div className="cc_productPreview" id="productPreview">
    //      <h2>Deal Details</h2>
    //    </div>
    // );

    
   
    // items.add(
    //   "selectEvent",
    //   <div style=" padding-bottom: 10px;" id="selectdropdown">
    //     <label>
    //       Select all Sale/Events that may be applicable to this deal(Optional)
    //     </label>
    //     <select id="selectEvent" class="multipleSelect" multiple></select>
    //   </div>
    // );

    // items.add(
    //   "mysubmit",
    //   Button.component({
    //     id: "btnSubmitDeal",
    //     children: "Post Deal",
    //     icon: "check",
    //     className: "Button Button--primary mysubmit cc_mysubmit",
    //     itemClassName: "",
    //     onclick: this.onsubmit.bind(this),
    //   })
    // );
    // if (app.cache.storename != "" && app.cache.hpid != undefined) {
    //   items.add(
    //     "Show-price-history",
    //     Button.component({
    //       id: "btnSubmitDeal",
    //       children: "Show Price History",
    //       icon: "check",
    //       className: "Button Button--primary mysubmit cc_mysubmit",
    //       itemClassName: "",
    //       onclick: function () {
    //         app.modal.show(
    //           new PriceGraphModal({
    //             storeName: app.cache.storename,
    //             pid: app.cache.hpid,
    //             compareproduct: app.cache.compareprice,
    //           })
    //         );
    //       },
    //     })
    //   );
    // }

    this.discount = m.prop(
      ((1 - this.currentPrice() / this.maxPrice()) * 100).toFixed(2)
    );
    if (!this.productURL()) {
      this.productURL = m.prop(this.url());
    }
    var line_image = "";
    var line_this = "";
    var description = "";
    var productDetail = "";
    if (this.imageUrl_sm()) {
      line_image =
        "<a href=" +
        this.imageUrl_sm() +
        " ><img src=" +
        this.imageUrl_sm() +
        ' alt="Smiley face" height="150"/></a>';
    }

    if (this.MerchantName()) {
      line_this =
        " <p>This deal is available at discount <strong> " +
        this.discount() +
        " % off</strong></p>";
    }
    if (this.Description()) {
      description = this.Description();
    }
    //new added
    if (this.productName()) {
      productDetail =
        '<p style="text-align: left;">MRP:<strong>' +
        this.maxPrice() +
        "</strong><br>Selling Price:<strong>" +
        this.currentPrice() +
        "</strong><br>Discount :<strong>" +
        this.discount() +
        ' %</strong><br><br><a style="width: 100px;" href="' +
        this.productURL() +
        ' " target="_blank" rel="nofollow"> Buy Now</a></p><br>';
    }

    this.imagePreview = line_image;
    return items;
  }

  configPreview(element, isInitialized, context) {
    if (isInitialized) return;
    //e.preventDefault();
    // Every 50ms, if the composer content has changed, then update the post's
    // body with a preview.
    let preview;
    const updateInterval = setInterval(() => {
      const content = this.productInfo();
      if (preview === content) return;
      preview = content;
      const anchorToBottom =
        $(window).scrollTop() + $(window).height() >= $(document).height();
      s9e.TextFormatter.preview(preview || "", element);
      if (anchorToBottom) {
        $(window).scrollTop($(document).height());
      }
    }, 50);
    context.onunload = () => clearInterval(updateInterval);
  }
  initFieldsOnErrorResponse() {

    this.title = m.prop(""); 
    this.productURL = m.prop(this.url());
    this.correctURL = m.prop(this.url());  
    this.categorySelectionEnabled = m.prop(true);
    
    this.productBrand = m.prop("");
    this.productName = m.prop("");
    this.categoryID = m.prop("");
    this.maxPrice = m.prop(0);
    this.currentPrice = m.prop(0);


    this.imageUrl = m.prop("");

    if (this.MerchantName()) {
      this.MerchantName = m.prop(this.MerchantNameOther());
    } else {
      const splitsUrl = this.url().split("/")[2].split(".");
      this.MerchantName = m.prop(splitsUrl[splitsUrl.length - 2]);
    }


    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    var Api_url = ApiEndpoint + "/storeTag?store=" + this.MerchantName();

    var result = app
      .request({ method: "GET", url: Api_url })
      .then(this.parseResults.bind(this));

    this.MerchantTag = app.store.getBy("tags", "slug", this.MerchantName());
    this.MerchantID = m.prop(this.MerchantTag.data.id);
    this.MerchantParentID = m.prop(
      this.MerchantTag.data.relationships.parent.data.id
    );
    this.MerchantParentTag = app.store.getBy(
      "tags",
      "id",
      this.MerchantParentID()
    );
    this.myTags = [this.MerchantParentTag, this.MerchantTag];   //new added
      
    this.description = m.prop("");    
    


    //TABLE ADDED
    this.contentPreview =
      "<div>" +
    (this.MerchantName()
      ? "<strong>" + this.MerchantName().toUpperCase() + "</strong> has "
        : "") +
      '<strong><a id="pURL"  href="' +
    this.correctURL() +
      ' ">' +
      (this.productName() ? this.productName() : "Offer") +
      "</a></strong> " +
      (this.multipleProducts
        ? ""
        : "&nbsp;on sale for " +
        this.currency() +
        '&nbsp;<strong><em id="dprice">' +
        this.currentPrice() +
        "</em></strong>&nbsp;Only. ") +
      "<p><strong><h3>How to get the deal:</h3></strong>" +
      "<ol><li>Click Buy Now to go to " +
    this.MerchantName() +
      "</li><li> Add the desired product(s) to cart.</li><li>Enter Login Information if not logged in</li><li>Select the address</li><li>Select the payment method</li><li>Place the Order. Happy <strong> DEALS </strong>!!</li></ol></p>" +
      (this.description().length > 3
        ? "<strong><h3>More Details:</strong></h3>" + this.description()
        : "") +
      "</div>";
    this.getEditior(this.contentPreview);
  }
  success(data) {
    if (data != null && data.Data != undefined) {
      if (data.Data.responseMsg == "success") {
        this.setAPIvalues(data.Data);
        this.apiSuccess();
        $(".messageLineText").html(
          "We have prefilled product details below for you, Edit if necessary."
        );
        $('[data-id="1"]').hide();
        $('[data-id="2"]').show();
        $('#prev').show();
        $('.buttonprev').show();
        $('#cc_prev').show();
        $('#btnShowPriceGraph').show();
        $('#btnShowPreview').show();
        $('#btnSubmitDeal').show();
        $('#step1').removeClass("active");
        $('#step2').addClass("active");
      } else {
        this.apiError();
        if (data.responseMsg == "noURL") {
          $(".messageLineText").html("Please fill URL correctly");
        } else if (data.responseMsg == "InvalidURL") {
          $(".messageLineText").html(
            "We could not find specific product on this link, Please fill the details of product by clicking Next!"
          );
          $('#next').show();
          this.initFieldsOnErrorResponse();
          //this.getckeditor();
        } else {
          $(".messageLineText").html("Please fill the details of product below!");
          this.initFieldsOnErrorResponse();
          $('#next').show();
          //this.getckeditor();
        }
      }
      $("#productPreview").css("opacity", "1");
    }
    else
    {
      this.apiError();
      $(".messageLineText").html(
        "We could not find specific product on this link, Please fill the details of product by clicking Next!"
      );
      $('#next').show();
      this.initFieldsOnErrorResponse();
      //this.getckeditor();
    }
    m.redraw();
  }
  failure(response) {
    this.apiError();
    $(".messageLineText").html(
      "We could not find specific product on this link, Please fill the details of product by clicking Next!"
    );
    this.initFieldsOnErrorResponse();
    this.getckeditor();
    m.redraw();
  }
  html2text(html) {
    var tag = document.createElement("div");
    tag.innerHTML = html;
    return tag.innerText;
  }
  setAPIvalues(data) {
    //this.title = m.prop(data.products[0].secondarytag ? data.products[0].secondarytag + ' : ' + this.html2text(data.productName) + ' @ ' + data.sellingPrice : (this.html2text(data.productName) + ' @ ' + data.sellingPrice));
    // put deal start time and end time from api: rahul p
    var validToresp = m.prop(data.products[0].Deal_End_time);
    var validFromresp = m.prop(data.products[0].Deal_Start_time);
    var validto_dec = new Date(validToresp());

    var year = validto_dec.getFullYear();
    var month = validto_dec.getMonth() + 1;
    var day = validto_dec.getDate();
    var hour = validto_dec.getHours();
    var minute = validto_dec.getMinutes();

    var validto_decresp =
      year + "/" + month + "/" + day + " " + hour + ":" + minute;
    if (year != "0001") {
      this.validTo = m.prop(validto_decresp);
    }

    var validfrom_dec = new Date(validFromresp());

    var year = validfrom_dec.getFullYear();
    var month = validfrom_dec.getMonth() + 1;
    var day = validfrom_dec.getDate();
    var hour = validfrom_dec.getHours();
    var minute = validfrom_dec.getMinutes();

    var validfrom_decresp =
      year + "/" + month + "/" + day + " " + hour + ":" + minute;

    if (year != "0001") {
      this.validFrom = m.prop(validfrom_decresp);
    }

    if (this.titleurl == null) {
      this.title = m.prop(this.html2text(data.productName));
    }

    let str = [];
    this.productURL = m.prop(this.url());
    this.correctURL = m.prop(data.productUrl ? data.productUrl : this.url());
    if (data.products.length > 0 && data.products[0].pid != null) {
      this.productID = m.prop(data.products[0].pid);
      this.productcategory = data.products[0].ProductCategory;
      let Getproductdetailsdata = new GetTDAProductID();
      app.cache.hpid = data.products[0].pid;
      app.cache.storename = data.merchantName;
      app.cache.hproductId = Getproductdetailsdata.getProductid(
        $.trim(app.cache.storename),
        data.products[0].pid
      );
      this.parameter = {
        PID: app.cache.hproductId,
        mrp: data.maximumRetailPrice,
      };
      app.cache.compareprice = new CompareProduct({
        params: this.parameter,
        routeName: this.props.routeName,
      });

      if (data.products.length > 1) {
        const pids = data.products.map(function (val) {
          return Getproductdetailsdata.getProductid(
            $.trim(data.merchantName),
            val.pid
          );
        });
        const pidss = pids.join(",");
        this.categoryajaxcall(data.merchantName, pidss, this.productcategory);
      } else {
        const id = Getproductdetailsdata.getProductid(
          $.trim(data.merchantName),
          data.products[0].pid
        );
        this.categoryajaxcall(data.merchantName, id, this.productcategory);
      }
    } else {
      this.categorySelectionEnabled = m.prop(true);
    }
    this.productBrand = m.prop(data.productBrand);
    this.productName = m.prop(data.productName);
    this.categoryID = m.prop(data.category);
    this.MerchantName = m.prop(data.merchantName);

    if (this.maxpriceurl == null) {
      this.maxPrice = m.prop(data.maximumRetailPrice);
    }

    //this.currentPrice = m.prop(data.sellingPrice);
    if (this.exppriceurl == null) {
      this.currentPrice = m.prop(data.sellingPrice);
    }

    this.apicurrentPrice = m.prop(data.sellingPrice);
    var dNow = new Date();
    var localdate =
      dNow.getFullYear() +
      "/" +
      (dNow.getMonth() + 1) +
      "/" +
      dNow.getDate() +
      " " +
      dNow.getHours() +
      ":" +
      dNow.getMinutes();
    this.refreshDate = m.prop(localdate);
    //  localdate;
    this.discount = m.prop(
      ((1 - this.currentPrice() / this.maxPrice()) * 100).toFixed(2)
    );
    this.currency = m.prop(data.currency);
    //debugger;

    if (this.secondarytagurl == null) {
      if (data.products.length > 1) {
        this.secondarytag = m.prop("MANY PRODUCTS");
        this.multipleProducts = true;
      } else {
        this.secondarytag = m.prop(data.products[0].secondarytag);
      }
    }

    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    var Api_url = ApiEndpoint + "/storeTag?store=" + this.MerchantName();

    var result = app
      .request({ method: "GET", url: Api_url })
      .then(this.parseResults.bind(this));

    if (data.ImageUrls) {
      this.imageUrl_sm = m.prop(data.ImageUrls.imageUrl_sm);
      this.imageUrl_md = m.prop(data.ImageUrls.imageUrl_md);
      this.imageUrl_lg = m.prop(data.ImageUrls.imageUrl_lg);
    }
    this.imageUrl = m.prop("");

    if (this.MerchantName()) {
      this.MerchantName = m.prop(this.MerchantNameOther());
    } else {
      const splitsUrl = this.url().split("/")[2].split(".");
      this.MerchantName = m.prop(splitsUrl[splitsUrl.length - 2]);
    }
    this.MerchantTag = app.store.getBy("tags", "slug", this.MerchantName());
    this.MerchantID = m.prop(this.MerchantTag.data.id);
    this.MerchantParentID = m.prop(
      this.MerchantTag.data.relationships.parent.data.id
    );
    this.MerchantParentTag = app.store.getBy(
      "tags",
      "id",
      this.MerchantParentID()
    );
    this.myTags = [this.MerchantParentTag, this.MerchantTag];
    //new added
    var BriefDescription = "";
    if (data.description == null || data.description == "") {
      this.description = m.prop("");
      BriefDescription = "";
    } else {
      this.description = m.prop(data.description);
      BriefDescription = "<strong>Brief Description</strong><br>";
    }
    var productDetail = "";
    this.getproductDetail =
      '<div class="maxPriceDetail" id="mprice" style="text-align: left;">MRP(' +
      this.currency() +
      '):<strong id="mxp">' +
      this.maxPrice() +
      "</strong><br>Selling Price(" +
      this.currency() +
      "):<strong>" +
      this.currentPrice() +
      "</strong><br>Discount :<strong>" +
      this.discount() +
      ' %</strong><br><br><a style="width: 100px;" href="' +
      this.correctURL() +
      ' " target="_blank">Click here to buy now</a></div><br>';

    //TABLE ADDED
    this.contentPreview =
      "<div>" +
      (data.merchantName
        ? "<strong>" + data.merchantName.toUpperCase() + "</strong> has "
        : "") +
      '<strong><a name="pURL" id="pURL"  href="' +
      this.correctURL() +
      ' ">' +
      this.productName() +
      "</a></strong> " +
      (this.multipleProducts
        ? ""
        : "&nbsp;on sale for " +          
          this.currency() +
          '&nbsp;<strong><em id="dprice">' +
          this.currentPrice() +
          "</em></strong>&nbsp;Only. ") +
      "<strong><h3>How to get the deal:</h3></strong>" +
      "<ol><li>Click Buy Now to go to " +
      data.merchantName +
      "</li><li> Add the desired product(s) to cart.</li><li>Enter Login Information if not logged in</li><li>Select the address</li><li>Select the payment method</li><li>Place the Order. Happy <strong> DEALS </strong>!!</li></ol>" +
      (this.description().length > 3
        ? "<strong><h3>More Details:</strong></h3>" + this.description()
        : "") +
      "</div>";
    this.getEditior(this.contentPreview);
  } //end setAPIvalues
  isUrlValid() {
    var userInput = this.url();
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) {
      this.apiError();
      $(".messageLineText").html("Please fill URL correctly");
      document.getElementById("btnSubmitDeal").style.visibility = "hidden";
      return true;
    }
  }
  getckeditor() {
    var hostnameTag;
    var imgurl;
    if (this.url()) {
      const splitUrl = this.url().split("/")[2].split(".");
      if (app.store.getBy("tags", "slug", splitUrl[splitUrl.length - 2])) {
        hostnameTag = app.store.getBy(
          "tags",
          "slug",
          splitUrl[splitUrl.length - 2]
        );
        imgurl = hostnameTag.data.attributes.imagePath;
        this.imageUrl_sm = m.prop(imgurl);
      } else {
        hostnameTag = app.store.getBy("tags", "slug", "others_Online");
        imgurl = hostnameTag.data.attributes.imagePath;
        this.imageUrl_sm = m.prop(imgurl);
      }
    }
    this.getproductDetail =
      '<div class="maxPriceDetail" id="mprice" style="text-align: left;">MRP:<strong>' +
      "-----" +
      "</strong><br>Selling Price:<strong>" +
      "-----" +
      "</strong><br>Discount :<strong>" +
      "---" +
      '%</strong><br><br><a id="btnproducturl"  style="width: 100px;" href="' +
      this.url() +
      ' " target="_blank">click here to buy now</a></div><br>';

    this.contentPreview =
      '<table><tbody><tr>	<td><div><p style="text-align: left;"><a id="pURL" name-"pURL" href="' +
      this.url() +
      ' ">' +
      "------------" +
      "</a></p>" +
      this.getproductDetail +
      "</div></td><td> <div><a href=" +
      this.url() +
      ' ><img id="dimg" class="right" src=' +
      imgurl +
      ' alt="Smiley face" height="50" width="100" align="Right"/></a></div></td></tr></tbody></table><p style="text-align: left;">' +
      "" +
      "</p>";

    this.contentPreview =
      "<div><strong>" +
      hostnameTag.data.attributes.name +
      "</strong> has on sale " +
      '<span><a id="pURL" name="pURL"  href="' +
      this.productURL() +
      ' ">' +
      this.productName() +
      "</a> &nbsp;&nbsp;&nbsp;&nbsp;</span>" +
      "<br><p><strong>How to get the deal:</strong>" +
      "<ol><li>Click Get Deal to go to " +
      hostnameTag.data.attributes.name +
      "</li><li> Add the desired product(s) to cart.</li><li>Enter Login Information if not logged in</li><li>Select the address</li><li>Select the payment method</li><li>Place the Order. Happy DEALS!!</li></ol></p><br>" +
      (this.description().length > 3
        ? "<p><strong>More Details:</strong></p></div>" + this.description()
        : "</div>");
    this.getEditior(this.contentPreview);
  }
  myContent() {
    return (
      <div className=" ">
        <a href={this.url()}>
          {this.productName()} <i class="fa fa-angle-double-right"></i>
        </a>
      </div>
    );
  }
  apiWait() {
    $(".processIcon").removeClass("fa fa-exclamation-triangle");
    $(".processIcon").removeClass("fa fa-check");
    $(".processIcon").addClass("fa fa-cog fa-spin fa-1x fa-fw");
  }
  apiSuccess() {
    $(".processIcon").removeClass("fa fa-cog fa-spin fa-1x fa-fw");
    $(".processIcon").removeClass("fa fa-exclamation-triangle");
    $(".processIcon").addClass("fa fa-check");
  }
  apiError() {
    $(".processIcon").removeClass("fa fa-cog fa-spin fa-1x fa-fw");
    $(".processIcon").removeClass("fa fa-check");
    $(".processIcon").addClass("fa fa-exclamation-triangle");
  }
  Process1() {
    $("#txturl").attr("disabled", "disabled");
    $("#lblurl").text("");
    $("#lnkReset").css("display", "block");
    this.apiWait();
    var url_val = this.url();
    $(".messageLineText").html("Processing URL.....");
    if (this.isUrlValid()) {
      return true;
    }
    url_val = decodeURIComponent(url_val);
    url_val = encodeURIComponent(url_val);
    const Api_url = app.forum.attribute("baseUrl") + "/apple?url=" + url_val;
    app
      .request({
        method: "GET",
        dataType: "json",
        url: Api_url,
      })
      .then(this.success.bind(this), this.failure.bind(this));
  }

  /**
   * Handle the title input's keydown event. When the return key is pressed,
   * move the focus to the start of the text editor.
   *
   * @param {Event} e
   */
  onkeydown(e) {
    if (e.which === 13) {
      // Return
      e.preventDefault();
      this.editor.setSelectionRange(0, 0);
    }
    m.redraw.strategy("none");
  }
  preventExit() {
    return (this.title() || this.content()) && this.props.confirmExit;
  }

  /**
   * Get the data to submit to the server when the discussion is saved.
   *
   * @return {Object}
   */
  data() {
    var imageUrl_sm;
    var imageUrl_md;
    var imageUrl_lg;
    if(this.imageUrl_sm() == null){
      imageUrl_sm = $("#imagename").val();
      imageUrl_md = $("#imagename").val();
      imageUrl_lg = $("#imagename").val();
    }else{
      imageUrl_sm = this.imageUrl_sm();
      imageUrl_md = this.imageUrl_md();
      imageUrl_lg = this.imageUrl_lg();
    }
    return {
      title: this.title(),
      // url: this.url(),
      url: this.correctURL(),
      productID: this.productID()
        .replace(/Item&nbsp;#/gi, "")
        .trim(),
      productBrand: this.productBrand(),
      productName: this.productName(),
      productInfo: this.productInfo(),
      categoryID: this.categoryID(),
      MerchantID: this.MerchantID(),
      maxPrice: this.maxPrice(),
      currentPrice: this.currentPrice(),
      apicurrentPrice: this.apicurrentPrice(),
      refreshDate: this.refreshDate(),
      discount: this.discount(),
      currency: this.currency(),
      imageUrl_sm: imageUrl_sm,
      imageUrl_md: imageUrl_md,
      imageUrl_lg: imageUrl_lg,
      discussionType: "d",
      validFrom: this.validFrom(),
      validTo: this.validTo(),
      //  content: this.content()
      // content: this.contentPost
      //content: this.productInfo()
      content: $(".cke_wysiwyg_div").html(),
      secondarytag: this.secondarytag(),
      Orderdate :"true",
      badgeText:
        this.discount() > 10
          ? Math.round(this.discount()) + "% OFF"
          : (this.currency() == "INR" ? "RS " : "$") +
            this.currentPrice() +
            " ONLY",
    };
  }
  changemaxprice(e) {
    e.preventDefault();
    this.discount = m.prop(
      ((1 - this.currentPrice() / this.maxPrice()) * 100).toFixed(2)
    );
    if (document.getElementById("mprice")) {
      document.getElementById(
        "mprice"
      ).childNodes[1].innerHTML = this.maxPrice();
      document.getElementById(
        "mprice"
      ).childNodes[7].innerHTML = this.discount();
    }
  }
  changeCurrentprice(e) {
    e.preventDefault();
    this.discount = m.prop(
      ((1 - this.currentPrice() / this.maxPrice()) * 100).toFixed(2)
    );
    if (document.getElementsByTagName("em")) {
      document.getElementsByTagName("em")[0].innerHTML = this.currentPrice();
    }
  }
  changetitle(e) {
    e.preventDefault();
    document.getElementById("pURL").innerHTML = this.title();
    if (!this.productURL()) {
      document.getElementById("pURL").href = this.productName();
    }
  }

  getEditior(contentvalue) {
    this.editor = new TextEditorCKEditor({
      submitLabel: this.props.submitLabel,
      placeholder: this.props.placeholder,
      onchange: this.productInfo,
      onsubmit: this.onsubmit.bind(this),
      value: contentvalue,
    });
  }
  categoryajaxcall(merchantName, pid, productcategory) {
    var pcid = productcategory ? "&productcategory=" + productcategory : "";
    var data = {
      pid: pid,
      productcategory: productcategory,
      merchantname: merchantName,
    };
    const category_url = app.forum.attribute("baseUrl") + "/getcategory";
    app
      .request({
        method: "GET",
        dataType: "json",
        data: { data },
        url: category_url,
      })
      .then(this.addcategory.bind(this));
  }
  addcategory(addcateg) {
    let datamakeds = [];
    addcateg.map(function (value) {
      app.store.find("tdacategory", { path: value.toString() }).then((a) => {
        datamakeds.push({ data: a.payload.data[0] });
      });
    });
    this.myCategoryselectedtTag = datamakeds;
    if (addcateg.length > 0) {
      this.categorySelectionEnabled = m.prop(false);
      m.redraw();
    } else {
      this.categorySelectionEnabled = m.prop(true);
      m.redraw();
    }
  }
  onsubmit() {
    $(".LoadingIndicator").show();
    if (!app.session.user) {
      app.modal.show(new LogInModal());
      return;
    }
    this.loading = true;
    this.myTags = [
      app.store.getBy("tags", "slug", "stores"),
      app.store.getBy("tags", "slug", "online-deals"),
      app.store.getBy("tags", "slug", "category"),
    ];

    var hostnameTag;
    if (this.url()) {
      const splitUrl = this.url().split("/")[2].split(".");
      if (app.store.getBy("tags", "slug", splitUrl[splitUrl.length - 2])) {
        hostnameTag = app.store.getBy(
          "tags",
          "slug",
          splitUrl[splitUrl.length - 2]
        );
        this.myTags = this.myTags.concat(hostnameTag);
      } else {
        hostnameTag = app.store.getBy("tags", "slug", "others_Online");
        this.myTags = this.myTags.concat(hostnameTag);
      }
    }
    //kunal added new dropdown code
    this.add_categoryTags = [];
    let splitPatharr = [];
    let uniquearr = [];
    if (this.myCategoryselectedtTag.length > 0) {
      this.tdacategory = this.myCategoryselectedtTag;
      this.myCategoryselectedtTag.map(function (value) {
        let catVal = value.data.attributes.path.split(">");
        splitPatharr.push(catVal[0]);
      });
    }
    if (splitPatharr.length > 0) {
      uniquearr = splitPatharr.filter(function (itm, i, a) {
        return i == a.indexOf(itm);
      });
    }
    if (uniquearr.length > 0) {
      uniquearr.map(
        function (value) {
          this.myTags = this.myTags.concat(
            app.store.getBy("tags", "name", value)
          );
        }.bind(this)
      );
    }
    var myEventselectedtTag = [];
    $("#selectEvent option:selected").each(function () {
      myEventselectedtTag.push(app.store.getBy("tags", "slug", $(this).val()));
    });
    if (myEventselectedtTag.length > 0) {
      this.myTags = this.myTags.concat(myEventselectedtTag);
    }
    this.add_categoryTags;

    const data = this.data();
    if (this.myTags) {
      data.relationships = data.relationships || {};
      data.relationships.tags = this.myTags;
      if (this.tdacategory != undefined) {
        data.relationships.tdacategory = this.tdacategory;
      }
    }
    delete app.cache.selectedCategory;
    if (isNaN(data.discount)) {
      data.discount = "";
    }

    $.ajax({
      type: "POST",
      url: app.forum.data.attributes.baseUrl + "/api/checkProduct",
      contentType: "application/json",
      dataType: "json",
      context: this,
      data:
        '{"productId":"' +
        data.productID +
        '", "price":"' +
        data.currentPrice +
        '"}', //Returns ID in body
      success: function (response) {
        if (response.data.attributes.status === true) {
          //this.destroyDatePicker();
          app.store
            .createRecord("discussions")
            .save(data)
            .then(
              (discussion) => {
                app.composer.hide();
                m.route(app.route.discussion(discussion));
              },
              (error) => {
                $(".LoadingIndicator").hide();
              },
              this.loaded.bind(this)
            );
        } else {
          app.store
            .find("discussions", response.data.attributes.status.id, "")
            .then(function (e) {
              const discussion = e;
              const DiscussionData = {
                title: discussion.data.attributes.title,
                maxPrice: discussion.data.attributes.maxPrice,
                currentPrice: discussion.data.attributes.currentPrice,
                apicurrentPrice: discussion.data.attributes.apicurrentPrice,
                discount: discussion.data.attributes.discount,
                ////currency : discussion.data.attributes.currency,
                url: discussion.data.attributes.url,
                productName: discussion.data.attributes.productName,
                isFeatured: discussion.data.attributes.isFeatured,
                isHotDeal: discussion.data.attributes.isHotDeal,
                isExpired: discussion.data.attributes.isExpired,
                startTime: discussion.data.attributes.startTime,
                imageUrl_sm: discussion.data.attributes.imageUrl_sm,
                noImageFound: discussion.data.attributes.noImageUrl_sm,
                validFrom: discussion.data.attributes.validFrom,
                validTo: discussion.data.attributes.validTo,
                validFor: discussion.data.attributes.validFor,
                badgeText: discussion.data.attributes.badgeText,
                refreshDate: discussion.data.attributes.refreshDate,
                startUser: discussion.user(),
                id: discussion.id(),
                tags: discussion.tags(),
                secondarytag: discussion.data.attributes.secondarytag,
              };
              app.modal.show(
                new DuplicateProductModal({ discussion, DiscussionData })
              );
            });
          $(".LoadingIndicator").hide();
        }
      },
      error: function () {
        alert("Something went wrong. Please try again.");
        $(".LoadingIndicator").hide();
      },
    });
  }

  toggleDate(data){
    $("#datetime").toggle();
  }

  toggleDescription(e){
    if($('#Descriptioncheck').is(':checked')){
      this.flag = 1;
      document.getElementById("productDescription").style.display = "block";
    }else{
      document.getElementById("productDescription").style.display = "none";
      $('#Descriptioncheck').removeAttr('checked');
      this.flag = 0;
    }
    
  }

  next(data){
    if($('#txturl').val()){
    var id = $('.content:visible').data('id');
    var nextId = $('.content:visible').data('id')+1;
    $('[data-id="'+id+'"]').hide();
    $('[data-id="'+nextId+'"]').show();
    $('#step'+id).removeClass("active");
    $('#step'+nextId).addClass("active");
    
    if($('#prev:hidden').length == 1){
        $('#prev,#cc_prev,.buttonprev').show();
    }

    if(nextId == 2){
      $('#btnShowPriceGraph').show();
      $('#btnShowPreview').show();
      $('#btnSubmitDeal').show();
    }

      if(nextId == 3){
        $('#next').hide();
        $('#cc_next').hide();
        $('#btnSubmitDeal').show();
        $('#btnShowPriceGraph').show();
        $('#btnShowPreview').show();
      }
      
    }else{
      alert("Please Enter the URL");
    }
  }
  
  previous(data){
    var id = $('.content:visible').data('id');
    var prevId = $('.content:visible').data('id')-1;
    $('[data-id="'+id+'"]').hide();
    $('[data-id="'+prevId+'"]').show();
    $('#step'+id).removeClass("active");
    $('#step'+prevId).addClass("active");

    if(prevId == 1){
        $('.buttonprev').hide();
        $('#prev').hide();
        $('#cc_prev').hide();
        $('#btnSubmitDeal').hide();
        $('#btnShowPriceGraph').hide();
        $('#btnShowPreview').hide();
    }else if(prevId == 2){
        $('.buttonprev').show();
        $('#prev').show();
        $('#next').show();
        $('#cc_next').show();
        $('#btnSubmitDeal').show();
        $('#btnShowPriceGraph').show();
        $('#btnShowPreview').show();
    }

  }

  ShowPriceGraph(data){
    app.modal.show(
      new PriceGraphModal({
        storeName: app.cache.storename,
        pid: app.cache.hpid,
        compareproduct: app.cache.compareprice,
      })
    );
  }

  ShowPreview(data){
    const discussion = this.data();
    app.modal.show(
      new DealPreviewModal({discussion})
    );
  }

}
