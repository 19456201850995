export default class EndpointsApiUrls {
  //var searchUrl = "skdfjksdhgkjdfshgksjh";
  getSearchUrl(endpointName) {
    var BaseUrl = app.forum.attribute("baseUrl");

    if (BaseUrl == "https://thedealsapp.com") {
      switch (endpointName) {
        case "searchAPI":
          return "https://search.thedealapp.com";
          break;
        default:
          return "https://thedealsapp.com";
          break;
      }
    } else if (BaseUrl == "https://tda.development") {
      switch (endpointName) {
        case "searchAPI":
          return "https://search.thedealapp.in";
          break;
        default:
          return "https://thedealsapp.com";
          break;
      }
    } else if (BaseUrl == "https://thedealapp.com") {
      switch (endpointName) {
        case "searchAPI":
          return "https://search.thedealapp.com";
          break;
        default:
          return "https://thedealapp.com";
          break;
      }
    } else {
      switch (endpointName) {
        case "searchAPI":
          return "https://search.thedealapp.in";
          break;
        default:
          return "https://thedealapp.in";
          break;
      }
    }
  }
  isFeatureOn(featureName) {
    var BaseUrl = app.forum.attribute("baseUrl");
    if (BaseUrl == "https://thedealsapp.com") {
      switch (featureName) {
        case "PRICEDROP":
          return false;
          break;
        case "STOREMENU":
          return false;
          break;
        case "COUPONS":
          return true;
          break;
        default:
          return true;
      }
    }
    if (BaseUrl == "https://thedealapp.com") {
      switch (featureName) {
        case "PRICEDROP":
          return false;
          break;
        case "STOREMENU":
          return false;
          break;
        case "COUPONS":
          return true;
          break;
        default:
          return true;
      }
    } else if (BaseUrl == "https://tda.development") {
      switch (featureName) {
        case "PRICEDROP":
          return true;
          break;
        case "STOREMENU":
          return true;
          break;
        case "COUPONS":
          return true;
          break;
        default:
          return true;
      }
    } else {
      switch (featureName) {
        case "PRICEDROP":
          return true;
          break;
        case "STOREMENU":
          return true;
          break;
        case "COUPONS":
          return true;
          break;
        default:
          return true;
      }
    }
  }
}
