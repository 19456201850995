import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function addOfferControl() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canOffer()) {
          if (!discussion.isOffer()){
                    items.add('Offer', Button.component({
                      children: 'Offer',
                      icon: 'fa fa-thumbs-up',
                      onclick: this.OfferAction.bind(discussion)
                    }));
          }
          else{
                  items.add('Offer', Button.component({
                      children: 'Undo Offer',
                      icon: 'fa fa-thumbs-down',
                      onclick: this.OfferAction.bind(discussion),    
                    }));  
          }
                    
    }
  });

  DiscussionControls.OfferAction = function() {
    this.save({isOffer: !this.isOffer()}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }
      m.redraw();
    });
  };
}
