import Page from 'flarum/components/Page';
import CategoryProductResults from './../search/CategoryProductResults';
import CategoriesNav from './../categoriesDeals/CategoriesNavigation';
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class myCategoryIndexPage extends Page {
  //Indexpage for Cagtegory vise results https://tda.development/Category/Automobile
      init() {
            super.init();
            const params1 = m.route.param();
            this.CategoryProductResults = new CategoryProductResults({params:params1,routeName:this.props.routeName});
            this.categoryName = m.route.param("categories") != undefined ? m.route.param("categories").split('>') : "";
            this.brandstorecall();
            this.brandList = [];
            this.storeList = [];
            this.categoryList = [];
          }
      view() { 
        let loading;
        let varbrandList = this.brandList;
        let varstoreList = this.storeList;
        let varcategoryList = this.categoryList;
          return(
            <div  class="searchResultsPage" >             
              <div className="container storeContainer" >
                <nav style="width: 93%; position: relative; margin: 0 auto;">
                  <div hidden>
                    <i class="fa fa-thumbs-up" aria-hidden="true"></i> Results from deals posted by users
                  </div>
                </nav>
              </div>
              <div className="container resultsContainer">
                <h2 class="" style="margin-bottom: 10px;">Top Products In {this.categoryName[this.categoryName.length-1]} </h2>
                <nav className="IndexPage-nav sideNav categoryNav">
                  {CategoriesNav.component({routeName:this.props.routeName, brandData: varbrandList,
            storeData: varstoreList,
            categoryData: varcategoryList, })}      
                </nav>
                {this.CategoryProductResults.render()}
              </div>
          </div>
        )
      }

      //Brand & category & store Call
  brandstorecall() {
    let Api_url;
    const params = m.route.param();
    if(params.categories == 'category'){
    delete params.categories;
    }
    // Api_url = 'https://search.thedealapp.in/search';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    Api_url = ApiEndpoint + "/getFilter";
    return app
      .request({
        method: "GET",
        context: this,
        url: Api_url,
        data: params,
      })
      .then(this.brandResults.bind(this));
  }

  brandResults(results) {
    [].push.apply(this.brandList, results.brandList);
    [].push.apply(this.storeList, results.storeList);
    [].push.apply(this.categoryList,results.categoryList);
    app.cache.BrandList = this.brandList;
    app.cache.storeList = this.storeList;
    app.cache.DealsCategories = this.categoryList;
    m.redraw();
  }

}
