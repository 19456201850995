import Modal from "flarum/components/Modal";
import extractText from "flarum/utils/extractText";
import Button from "flarum/components/Button";
import ForgotPasswordModal from "flarum/components/ForgotPasswordModal";
import NewSignInModal from "../modal/NewSignIn";
import * as firebase from "firebase/app";
import "firebase/auth";
import Alert from "flarum/components/Alert";
import LogInButtons from "flarum/components/LogInButtons";

export default class NewSignIn extends Modal {
  init() {
    this.identification = m.prop(this.props.identification || "");
    //this.email = m.prop(this.props.email || '');
    this.password = m.prop(this.props.password || "");
    this.mobileno = m.prop(this.props.mobileno || "");
    this.isVerify = m.prop(this.props.isVerify || "0");
    this.tokennum = m.prop(this.props.tokennum || "");
    this.idtoken = m.prop(this.props.tokennum || "");
    this.remember = m.prop(this.props.remember || "");
    this.isloginOTP = m.prop(this.props.isloginOTP || "0");
    this.countrycode;
    this.verificationId = "";
    this.userid = "";
    this.isUpdateNumber = false;
    this.firebaseResumeDownloadAdd = null;
    this.selectcountry();
    this.isConfigured = false;
  } // end init

  className() {
    return "Modal--Big brandModal";
  }

  title() {
    return (
      <div>
        <span>LogIn</span>
      </div>
    );
  }

  content() {
    return [
      m("div", { className: "Modal-body popupModal" }, [
        <div className="Modal-body" config={this.config.bind(this)}>
          <div className="Form Form--centered" id="loginform">
            <div className="Form-group usernamediv" id="usernamediv">
              <input
                className="FormControl"
                name="username"
                type="text"
                value={this.identification()}
                placeholder={extractText(
                  app.translator.trans(
                    "core.forum.log_in.username_or_email_placeholder"
                  )
                )}
                bidi={this.identification}
                disabled={this.loading}
              />
            </div>
            <div className="Form-group passworddiv" id="passworddiv">
              <input
                className="FormControl"
                name="password"
                type="password"
                value={this.password()}
                placeholder={extractText(
                  app.translator.trans("core.forum.log_in.password_placeholder")
                )}
                bidi={this.password}
                disabled={this.loading}
              />
            </div>
            <div
              id="sign-in-card"
              class="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"
            >
              <div class="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white"></div>
              <div class="mdl-card__supporting-text mdl-color-text--grey-600">
                <form id="sign-in-form" action="#">
                  <div
                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label"
                    id="mobilediv"
                    style="display:none;"
                  >
                    <div>
                      <select
                        className="countrycode"
                        name="country"
                        id="country"
                        onchange={this.checkCountry.bind(this)}
                      >
                        <option value="+91">
                          INDIA
                        </option>
                        <option value="+1">USA</option>
                      </select>
                    </div>
                    <div>
                      <input
                        className="FormControl-signin"
                        type="text"
                        value={this.mobileno()}
                        placeholder={extractText(
                          app.translator.trans(
                            "core.forum.log_in.mobileno_placeholder"
                          )
                        )}
                        id="phone-number"
                        bidi={this.mobileno}
                        onkeyup={this.validmobile.bind(this)}
                      ></input>
                    </div>
                  </div>{" "}
                  <br />
                  <div className="Form-group">
                    <div>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          bidi={this.remember}
                          disabled={this.loading}
                        />
                        {app.translator.trans(
                          "core.forum.log_in.remember_me_label"
                        )}
                      </label>

                      <label className="checkbox">
                        <input
                          type="checkbox"
                          id="otplogincheckbox"
                          onclick={this.OtpLogin.bind(this)}
                        />{" "}
                        LogIn With Mobile Number
                      </label>
                    </div>
                  </div>{" "}
                  <br />
                  <button
                    class="Button Button--primary Button--block"
                    id="sign-in-button"
                    style="display:none;"
                    
                  >
                    Request OTP
                  </button>
                </form>

                <div id="verification-code-form" style="display:none;">
                  <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <input
                      className="FormControl"
                      type="text"
                      id="verification-code"
                      placeholder="Enter the verification code..."
                    />
                    <br />
                    <input
                      type="submit"
                      class="Button Button--primary Button--block"
                      id="verify-code-button"
                      value="Verify Code"
                      onclick={this.onVerifyCodeSubmit.bind(this)}
                    />
                    <input type="hidden" id="ismobverifyval" value="" />
                    <input type="hidden" id="accesstoken" value="" />
                  </div>
                </div>
              </div>
            </div>{" "}
            <br />
            <div className="Form-group" id="logginbtn">
              {Button.component({
                className: "Button Button--primary Button--block",
                type: "submit",
                loading: this.loading,
                children: app.translator.trans(
                  "core.forum.log_in.submit_button"
                ),
              })}
            </div>
          </div>
        </div>,
        <div class="Form Form--centered" id="mobileNo" style="display:none;">
          <div style="margin-bottom: 20px;">
            <div>
              <select
                className="countrycodemo"
                name="countrymo"
                id="countrymo"
                onchange={this.checkCountry.bind(this)}
              >
                <option value="+91">
                  INDIA
                </option>
                <option value="+1">USA</option>
              </select>
            </div>
            <div>
              <input
                className="FormControl-signinmo"
                type="text"
                value={this.mobileno()}
                placeholder={extractText(
                  app.translator.trans("core.forum.log_in.mobileno_placeholder")
                )}
                id="phone-numbermo"
                bidi={this.mobileno}
                onkeyup={this.validAddmobile.bind(this)}
              ></input>
            </div>
          </div>
          <div>
            <button
              class="Button Button--primary Button--block otprequestbtn"
              id="add-mobile-button"
              onclick={this.getOtpForAddingMobile.bind(this)}
            >
              Add Mobile Number
            </button>
          </div>
        </div>,
        <div className="Modal-footer">
          <p className="LogInModal-forgotPassword">
            <a onclick={this.forgotPassword.bind(this)}>
              {app.translator.trans("core.forum.log_in.forgot_password_link")}
            </a>
          </p>

          {app.forum.attribute("allowSignUp") ? (
            <p className="LogInModal-signUp">
              {app.translator.trans("core.forum.log_in.sign_up_text", {
                a: <a onclick={this.signUp.bind(this)} />,
              })}
            </p>
          ) : (
            ""
          )}
        </div>,
      ]),
      //  m("h3",m("a",({className:'Button Button--primary btnModalBottom',onclick:this.hide.bind()}),"No , I Want to see all results"))
    ];
  }

  forgotPassword() {
    const email = this.identification();
    const props = email.indexOf("@") !== -1 ? { email } : undefined;

    app.modal.show(new ForgotPasswordModal(props));
  }

  signUp() {
    const props = { password: this.password() };
    // const username = this.username();
    // props[username.indexOf('@') !== -1 ? 'email' : 'username'] = username;
    app.modal.show(new NewSignInModal(props));
  }

  config(isInitialized, context) {

    if (!isInitialized || this.props.checklogin == true || !this.isConfigured) {
      this.props.checklogin = false;
      this.isConfigured = true;
      this.isVerify = 0;
      document.getElementById("verification-code-form").style.display = "none";
      //var code = document.getElementById("country").selectedIndex;
      // var countryVal = document.getElementById("country").options;
      // this.countrycode = countryVal[code].value;
      
      var firebaseConfig = {
        apiKey: "AIzaSyAHSEvkApJnNJjkKqmFrg1nqleo3sycPQQ",
        authDomain: "thedealapp-7612a.firebaseapp.com",
        databaseURL: "https://thedealapp-7612a.firebaseio.com",
        projectId: "thedealapp-7612a",
        storageBucket: "thedealapp-7612a.appspot.com",
        messagingSenderId: "535942616872",
        appId: "1:535942616872:web:86a40d81976b69cc70a21d",
        measurementId: "G-LJSKRWBPDS",
      };

      // if(this.firebaseResumeDownloadAdd == null){
      if (!firebase.apps.length) {
        this.firebaseResumeDownloadAdd = firebase.initializeApp(firebaseConfig);
      }

      //}
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          var uid = user.uid;
          var email = user.email;
          var photoURL = user.photoURL;
          var phoneNumber = user.phoneNumber;
          var isAnonymous = user.isAnonymous;
          var displayName = user.displayName;
          var providerData = user.providerData;
          var emailVerified = user.emailVerified;
        }
        // updateSignInButtonUI();
        // updateSignInFormUI();
        // updateSignOutButtonUI();
        updateSignedInUserStatusUI();
        // updateVerificationCodeFormUI();
      });

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: function (response) {
            {
              if (document.getElementById("phone-number").value == "") {
                alert("Please Enter Mobile Number");
              } else {
                document.getElementById("sign-in-form").style.display = "none";
                document.getElementById("mobileNo").style.display = "none";
                document.getElementById(
                  "verification-code-form"
                ).style.display = "block";
                $("#verification-code").attr(
                  "placeholder",
                  "Enter the verification code..."
                );
                document.getElementById("verify-code-button").style.display =
                  "block";
                $("#verify-code-button").prop("disabled", false);
                window.signingIn = true;
                //updateSignInButtonUI();
                var selectedcountrycode;
                var countryVal = document.getElementById("country").options;
                Object.keys(countryVal).forEach(function (item) {
                  if(countryVal[item].selected == true){
                    selectedcountrycode = countryVal[item].value;
                  }
                });
                var countrycode = selectedcountrycode;
                var phoneNumber = document.getElementById("phone-number").value;
                var phoneNumber = countrycode + phoneNumber;
                var appVerifier = window.recaptchaVerifier;
                //debugger;
                // alert(4);
                firebase
                  .auth()
                  .signInWithPhoneNumber(phoneNumber, appVerifier)
                  .then(function (confirmationResult) {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    document.getElementById("sign-in-button").style.display =
                      "none";
                    document.getElementById("usernamediv").style.display =
                      "none";

                    document.getElementById("passworddiv").style.display =
                      "none";

                    document.getElementById(
                      "accesstoken"
                    ).value = JSON.stringify(confirmationResult.verificationId);

                    window.confirmationResult = confirmationResult;
                    window.signingIn = false;
                    //alert(5);
                  })
                  .catch(function (error) {
                    // alert(error);
                    // Error; SMS not sent
                    if (error.code == "auth/too-many-requests") {
                      alert(error.message);
                    } else if (error.code == "auth/invalid-phone-number") {
                      alert("Please Enter Vaild Phone Number");
                    } else {
                      window.alert(error.message);
                    }
                    //window.alert('Error during signInWithPhoneNumber:\n\n'
                    //    + error.code + '\n\n' + error.message);
                    window.signingIn = false;
                    //  updateSignInFormUI();
                    //  updateSignInButtonUI();
                  });
              }
            }
          },
        }
      );

      recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
        //updateSignInButtonUI();
      });

      /**
       * Updates the Signed in user status panel.
       */
      function updateSignedInUserStatusUI() {
        var user = firebase.auth().currentUser;
        if (user) {
          $("#sign-in-status").html("Signed in");
          var jsonobj = JSON.stringify(user, null, "  ");

          var responseobj = JSON.parse(jsonobj);

          var tokanvalue = responseobj.stsTokenManager.refreshToken;
          //$("#accesstoken").val(tokanvalue);
        } else {
          $("#sign-in-status").html("Signed out");
          //document.getElementById('account-details').textContent = 'null';
          $("#account-details").html("null");
        }
      }
    } // is initialize closed
  } // config function closed

  // on verify submit click
  /**
   * Function called when clicking the "Verify Code" button.
   */
  onVerifyCodeSubmit(isInitialized) {
    //recaptchaVerifier.clear();
    if (!!this.getCodeFromUserInput()) {
      this.verificationId = $("#accesstoken").val();
      window.verifyingCode = true;
      this.updateVerifyCodeButtonUI();
      var code = this.getCodeFromUserInput();
      var contextP = this;
      confirmationResult
        .confirm(code)
        .then(function (result) {
          $("#ismobverifyval").val("1");
          document.getElementById("verification-code-form").style.display =
            "hide";
          document.getElementById("verification-code").value = "";
          document.getElementById("verify-code-button").style.display = "none";
          document.getElementById("logginbtn").style.display = "block";
          document.getElementById("usernamediv").style.display = "block";
          // document.getElementById('emaildiv').style.display = 'block';
          document.getElementById("passworddiv").style.display = "block";
          // User signed in successfully.
          var user = result.user;

          window.verifyingCode = false;
          window.confirmationResult = null;

          contextP.updateVerificationCodeFormUI();
        })
        .catch(function (error) {
          window.alert(error.message);
          document.getElementById("sign-in-form").style.display = "block";
          document.getElementById("verification-code-form").style.display =
            "none";
          document.getElementById("sign-in-button").style.display = "block";
          document.getElementById("verification-code").value = "";
          //$("#verify-code-button").prop("disabled", false);
          //document.getElementById('verify-code-button').attr("enabled");
          window.verifyingCode = false;

          //this.updateSignInButtonUI();
          //this.updateVerifyCodeButtonUI();
        });
    }
  } // on verify submit

  /**
   * Reads the verification code from the user input.
   */
  getCodeFromUserInput() {
    return document.getElementById("verification-code").value;
  }

  /**
   * Updates the Verify-code button state depending on form values state.
   */
  updateVerifyCodeButtonUI() {
    document.getElementById("verify-code-button").disabled =
      !!window.verifyingCode || !this.getCodeFromUserInput();
  }

  /*
   * Updates the Sign-in button state depending on ReCAptcha and form values state.
   */
  updateSignInButtonUI() {
    document.getElementById("sign-in-button").disabled =
      !this.isPhoneNumberValid() || !!window.signingIn;
  }

  /**
   * Updates the state of the Verify code form.
   */
  updateVerificationCodeFormUI() {
    //alert("in update verifc code");

    if (!firebase.auth().currentUser && window.confirmationResult) {
      document.getElementById("verification-code-form").style.display = "block";
      document.getElementById("verify-code-button").style.display = "block";
    } else {
      var contextP = this;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          contextP.idtoken = idToken;
          if (contextP.isUpdateNumber) {
            contextP.addMobile();
          } else contextP.onotpsubmit();
          document.getElementById("verification-code-form").style.display =
            "none";
        })
        .catch(function (error) {
          // Handle error
        });
    }
  }

  isPhoneNumberValid() {
    var pattern = /^\+[0-9\s\-\(\)]+$/;
    var phoneNumber = getPhoneNumberFromUserInput();
    return phoneNumber.search(pattern) !== -1;
  }

  /**
   * put data in for insert
   */
  data() {
    var isverifyval = $("#ismobverifyval").val();
    var tokennum = $("#accesstoken").val();
    if (this.mobileno() == "") {
      var country_code = "";
    } else {
      var country_code = $("#country").val();
    }
    this.verificationId = this.verificationId.replace(/^"|"$/g, "");
    return {
      identification: this.identification(),
      // email: this.email(),
      password: this.password(),
      mobileno: this.mobileno(),
      isVerify: isverifyval,
      tokennum: this.verificationId,
      remember: this.remember(),
      isloginOTP: this.isloginOTP,
      country_code: country_code,
      idtoken: this.idtoken,
    };
  }

  /**
   * validation for mobile no
   **/

  validmobile(isInitialized, context) {
    var mobileno = this.mobileno();
    var validmobno = /\d{10}/;

    if (mobileno.match(validmobno)) {
      $("#sign-in-button").attr("disabled", false);
    } else {
      $("#sign-in-button").attr("disabled", true);
    }
  }

  onsubmit(e) {
    e.preventDefault();

    this.loading = true;

    const data = this.data();
    /* app.session.login({identification, password, remember}, {errorHandler: this.onerror.bind(this)})
      .then(
        () => window.location.reload(),
        this.loaded.bind(this)
      ); */
    app
      .request({
        url: app.forum.attribute("baseUrl") + "/loginv2",
        method: "POST",
        data,
        errorHandler: this.onerror.bind(this),
      })
      .then(
        this.verifymobile.bind(this)
        //,() => window.location.reload(),
      );
  }

  onotpsubmit(isInitialized, context) {
    const data = this.data();

    app
      .request({
        url: app.forum.attribute("baseUrl") + "/loginv2",
        method: "POST",
        data,
        errorHandler: this.onerror.bind(this),
      })
      .then(this.loaded.bind(this));
  }

  loaded(result) {
    const jsonresult = JSON.parse(JSON.stringify(result));
    if (jsonresult.isNotRegistered == true) {
      alert("Please Create An Acoount Using Sign up");
      app.modal.show(new NewSignInModal());
    } else {
      window.location.reload();
    }
  }

  OtpLogin() {
    if ($("#otplogincheckbox").prop("checked") == true) {
      $("#usernamediv").hide();
      $("#passworddiv").hide();
      $("#mobilediv").show();
      $("#sign-in-button").show();
      $("#logginbtn").hide();
      this.isloginOTP = 1;
    } else {
      $("#usernamediv").show();
      $("#passworddiv").show();
      $("#mobilediv").hide();
      $("#sign-in-button").hide();
      $("#logginbtn").show();
      this.isloginOTP = 0;
    }
  }

  checkCountry(countrycode) {
    this.countrycode = countrycode.target.value;
    $("select#country option[value='"+this.countrycode+"']").attr("selected","selected");
  }

  verifymobile(result) {
    if (result.userId != undefined) {
      this.userid = result.userId;
      app
        .request({
          url: app.forum.attribute("baseUrl") + "/api/verifyNumber",
          method: "POST",
          data: { id: result.userId },
        })
        .then(this.processDetails.bind(this));
    } else {
      alert("Please Create An Acoount Using Sign up");
      app.modal.show(new NewSignInModal());
    }
  }

  processDetails(result) {
    //window.location.reload();
    //return;
    if (result.data.attributes.isVerify == false) {
      //sign-in-form
      document.getElementById("sign-in-form").style.display = "block";
      $("#usernamediv").hide();
      $("#passworddiv").hide();
      $("#mobilediv").show();
      $("#sign-in-button").show();
      $("#logginbtn").hide();
      this.isloginOTP = 1;
      this.isUpdateNumber = true;
      //document.getElementById('mobileNo').style.display = 'block';

      //document.getElementById('loginform').style.display = 'none';
    } else {
      window.location.reload();
    }
  }

  validAddmobile(isInitialized, context) {
    var mobileno = this.mobileno();
    var validmobno = /\d{10}/;

    if (mobileno.match(validmobno)) {
      $("#add-mobile-button").attr("disabled", false);
    } else {
      $("#add-mobile-button").attr("disabled", true);
    }
  }

  getOtpForAddingMobile() {
    ///alert(1);
    document.getElementById("phone-number").value = this.mobileno();
    $("#sign-in-button").click();
    //alert(2);
  }

  addMobile() {
    //const userObj = {id:this.userid};
    var dataObj = this.data();
    dataObj.id = this.userid;
    app
      .request({
        url: app.forum.attribute("baseUrl") + "/api/registerNumber",
        method: "POST",
        data: dataObj,
      })
      .then(this.processNumber.bind(this));
  }

  processNumber(result) {
    if (result.data.attributes.isMobileAlreadyused == true) {
      alert("Mobile Number Already Used");
      document.getElementById("sign-in-form").style.display = "block";
      $("#usernamediv").hide();
      $("#passworddiv").hide();
      $("#mobilediv").show();
      $("#sign-in-button").show();
      $("#logginbtn").hide();
      this.isloginOTP = 1;
      this.isUpdateNumber = true;
    } else if (result.data.attributes.isUpdated == true) {
      alert("Mobile Number Added Successfully");
      window.location.reload();
    } else if (result.data.attributes.isNotRegistered == true) {
      alert("Authentication verification Failed. Please reauthenticate using mobile number");
      window.location.reload();
    } else {
      alert("Mobile Number Not Added");
      window.location.reload();
    }
  }

  selectcountry(){
    var BaseUrl = app.forum.attribute("baseUrl");
    if(BaseUrl == "https://thedealapp.in"){
      $("select#country option[value='+91']").attr("selected","selected");
    }else{
      $("select#country option[value='+1']").attr("selected","selected");
    }
  }

  onerror(error) {
    if (error.status === 401) {
      error.alert.props.children = app.translator.trans('core.forum.log_in.invalid_login_message');
    }
    super.onerror(error);
  }
  
}
