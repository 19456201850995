import { extend } from "flarum/extend";
import DiscussionHero from "flarum/components/DiscussionHero";
import EndpointsApiUrls from "../EndpointsApiUrls";
import AllDealCardMini from "../AllDealCardMini";
import SlickmainSlider from "../SlickmainSlider";

export default function () {
  //used for single deal display https://thedealapp.in/d/78362-swarn-solid-tie-pack-of-2-91
  let flag =1;
  extend(DiscussionHero.prototype, "init", function (vdoms) {
    app.cache.DealList = [];
  }); //end of extend(DiscussionHero.prototype, 'init', function (vdoms)

  extend(DiscussionHero.prototype, "items", function (items) {
    const discussion = this.props.discussion;
    this.discussionID = discussion.data.id;

    if (discussion.data.attributes.isOffer == false) {
      if (discussion.data.attributes.discussionType == "d" &&  discussion.data.attributes.isExpired == true) {

        if (flag == "1" && app.cache.DealList == 0 && 
          discussion.data.attributes.discussionType != "c" &&
          discussion.data.attributes.isOffer == false && discussion.data.attributes.isExpired == true
        ) {
          getSmiliarDeals(this.discussionID);
          flag++;
        }
    
        function getSmiliarDeals(discussionID){
            var discussionID = discussionID;
            const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
            const Api_url = ApiEndpoint + "/similarDeal";
    
            app
            .request({
              method: "GET",
              dataType: "json",
              data: { deal_id: discussionID},
              url: Api_url,
            })
            .then(processDeals.bind(this));
        }
    
        function processDeals(results){
          if (results.result.hits) {
            var discussions = [];
            if (results.result !== undefined) {
              if (results.result.hits !== undefined) {
                if (results.result.hits.hits) {
                  [].push.apply(discussions, results.result.hits.hits);
                  var DealListdata = discussions.reduce(function (
                    result,
                    discussion
                  ) {
                    var DiscussionData = {
                      title: discussion._source.title,
                      maxPrice: discussion._source.maxprice,
                      currentPrice: discussion._source.currentprice,
                      discount: discussion._source.discount,
                      ////currency : discussion.data.attributes.currency,
                      url: discussion._source.url,
                      productName: discussion._source.productName,
                      isFeatured: discussion._source.is_featured,
                      isOffer: discussion._source.is_offer,
                      isHotDeal: discussion._source.is_hotdeal,
                      isExpired: discussion._source.is_expired,
                      imageUrl_sm: discussion._source.imageurl_sm,
                      noImageFound: discussion._source.noImageUrl_sm,
                      validFrom: discussion._source.validfrom,
                      validTo: discussion._source.validto,
                      validFor: discussion._source.validfor,
                      badgeText: discussion._source.badgetext,
                      tag_slug: discussion._source.tag_slug,
                      avatar_path: discussion._source.avatar_path,
                      username: discussion._source.username,
                      startUser: "",
                      id: discussion._id,
                      tags: "",
                      couponcode: discussion._source.couponcode,
                      secondarytag: discussion._source.secondarytag,
                      start_user_id: discussion._source.user_id,
                      start_time: discussion._source.start_time,
                      refreshdate: discussion._source.refreshdate,
                      created_at: discussion._source.created_at,
                    };
                    result.push(AllDealCardMini.component({ DiscussionData }));
                    return result;
                  },
                  []);
        
                  
                  if (DealListdata) {
                    app.cache.DealList = DealListdata;
                  }
                }
              }
              m.redraw();
              flag = 1;
              return results;
            }
          }
        }
        if(app.cache.DealList != 0){
        items.add(
          "discussionDeal",
          <div class="tabs">
             <div class="tab">
             <input type="checkbox" id="chck1" style="position: absolute;opacity: 0;z-index: -1;"></input>
             <label class="tab-label" for="chck1"><h2 class="popularBar" style="color:#037d05;font-weight: 500;">
          More Deals Like This
          </h2></label>   
          
          
          <div class="tab-content">
          {SlickmainSlider.component({
            slidesToShowDesktop: this.slidesToShowDesktop,
            slidesToScrollDesktop: this.slidesToScrollDesktop,
            children: this.loading ? loading : app.cache.DealList,
          })}</div>
        </div>
        </div>
        );
        }
      }
    } 
  });
  if (document.body.clientWidth < 768) {
    $(".DiscussionHero--colored").css("width", "211px !important");
  }
}
