import { extend } from "flarum/extend";
import PostControls from "flarum/utils/PostControls";
import Button from "flarum/components/Button";
import myButtons from "./myButtons";

export default function () {
  extend(PostControls, "moderationControls", function (items, post) {
    const discussion = post.discussion();
    let composeButton = new myButtons();
    if (post.isHidden()) return;

    const check_isDeal = discussion.data.attributes.discussionType;
    const check_firstPost = post.data.attributes.number;
    if (check_isDeal == "c" && check_firstPost == 1) {
      //  items.remove('edit');
      items.add("editCoupon", [
        m(
          Button,
          {
            icon: "fa fa-edit",
            className: "",
            onclick: composeButton.editCoupon.bind(composeButton, post),
          },
          "Edit Coupon Details"
        ),
      ]);
    }
  });
}
