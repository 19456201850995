import Button from "flarum/components/Button";
import dealComposer from "./dealComposer";
import icon from "flarum/helpers/icon";
import TextEditorCKEditor from "./texteditor/TextEditorCKEditor";
import flatpickr from "flatpickr";

function minimizeComposerIfFullScreen(e) {
  if (app.composer.isFullScreen()) {
    app.composer.minimize();
    e.stopPropagation();
  }
}

/**
 * The `EditCouponComposer` component displays the composer content for editing a
 * coupon. It sets the initial content to the content of the post that is being
 * edited, and adds a header control to indicate which post is being edited.
 *
 * ### Props
 *
 * - All of the props for ComposerBody
 * - `post`
 */
export default class EditCouponComposer extends dealComposer {
  init() {
    super.init();

    const discussionID = this.props.post.data.relationships.discussion.data.id;
    this.myDiscussion = app.store.getBy("discussions", "id", discussionID);
    this.props.discussion = this.myDiscussion;
    this.productInfodata = this.myDiscussion.data.attributes.productInfo;
    this.productInfo = m.prop(this.productInfodata);
    this.productURL = m.prop("");
    this.title = m.prop(this.myDiscussion.data.attributes.title || "");
    this.url = m.prop(this.myDiscussion.data.attributes.url || "");
    this.badgeText = m.prop(this.myDiscussion.data.attributes.badgeText || "");
    this.validFrom = m.prop(
      (Date.parse(this.myDiscussion.data.attributes.validFrom) || 0) != "0"
        ? this.myDiscussion.data.attributes.validFrom || ""
        : ""
    );
    this.validTo = m.prop(
      (Date.parse(this.myDiscussion.data.attributes.validTo) || 0) != "0"
        ? this.myDiscussion.data.attributes.validTo || ""
        : ""
    );
    this.editor.props.preview = (e) => {
      minimizeComposerIfFullScreen(e);
      m.route(app.route.post(this.props.post));
      originalContent;
    };
    this.editor = {};
    this.editor = new TextEditorCKEditor({
      submitLabel: this.props.submitLabel,
      placeholder: this.props.placeholder,
      onchange: this.productInfo,
      //onsubmit: this.onsubmit.bind(this),
      value: this.productInfo(),
    });
  }

  headerItems() {
    const items = super.headerItems();
    const post = this.props.post;
    this.editor.disabled = false;
    $(".Composer:first").css("height", "538px", "important");
    $(".TextEditor").show();

    const routeAndMinimize = function (element, isInitialized) {
      if (isInitialized) return;
      $(element).on("click", minimizeComposerIfFullScreen);
      m.route.apply(this, arguments);
    };

    items.add(
      "title",
      <h3>
        {icon("pencil")}{" "}
        <a
          href={app.route.discussion(post.discussion(), post.number())}
          config={routeAndMinimize}
        >
          {app.translator.trans("core.forum.composer_edit.post_link", {
            number: post.number(),
            discussion: post.discussion().title(),
          })}
        </a>
      </h3>
    );
    items.add(
      "url",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft" style="width: 100% !important;">
        <label class="dealInputLabel"> Coupon URL:</label>
          <input
            className="dealInput"
            value={this.url()}
            oninput={m.withAttr("value", this.url)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div style="clear:both;"></div>
      </div>
    );

    items.add(
      "DealValidityBlock",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft width60">
        <label className="dealInputLabel">Valid From:</label>
          <input
            className="dealInput cc_dealInputmb"
            id="validFromdatetimepicker"
            value={this.validFrom()}
            onchange={m.withAttr("value", this.validFrom)}
            disabled={!!this.props.disabled}
            onkeydown={this.onkeydown.bind(this)}
            style="width: 94%;"
            placeholder="Valid From"
            required
          />
          <span className="myHighlight"></span>
        </div>

        <div className="cc_parentBorderNone thisFloatRight width40">
        <label className="dealInputLabel">Valid To:</label>
          <input
            className="dealInput cc_dealInputmb"
            id="validTodatetimepicker"
            value={this.validTo()}
            onchange={m.withAttr("value", this.validTo)}
            disabled={!!this.props.disabled}
            onkeydown={this.onkeydown.bind(this)}
            style="width: 94%;"
            required
            placeholder="Valid To"
          />
          <span className="myHighlight"></span>
        </div>
      </div>
    );
    
    items.add(
      "producttitle ",
      <div className="cc_parentBorderNone">
        <div className="thisFloatLeft width60">
        <label class="dealInputLabel"> Title:</label>
          <input
            className="dealInput"
            value={this.title()}
            oninput={m.withAttr("value", this.title)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>
        <div className="cc_parentBorderNone thisFloatRight width40">
        <label className="dealInputLabel">Badge Text:</label>
          <input
            className="dealInput"
            value={this.badgeText()}
            oninput={m.withAttr("value", this.badgeText)}
            disabled={!!this.props.disabled}
            style="width: 100%;"
            required
          />
          <span className="myHighlight"></span>
        </div>

        <div style="clear:both;"></div>

        <div className="cc_prodInfo">
          <div className="ComposerBody-editor" style="height:100%;">
            {this.editor.render()}{" "}
          </div>
        </div>
      </div>
    );

    items.add(
      "mysubmit",
      Button.component({
        children: "Save Changes ",
        icon: "check",
        className: "Button Button--primary mysubmit",
        itemClassName: "",
        onclick: this.onsubmit.bind(this),
      })
    );

    return items;
  }
  /**
   * Handle the title input's keydown event. When the return key is pressed,
   * move the focus to the start of the text editor.
   *
   * @param {Event} e
   */
  onkeydown(e) {
    if (e.which === 13) {
      // Return
      e.preventDefault();
      this.editor.setSelectionRange(0, 0);
    }
    m.redraw.strategy("none");
  }
  /**
   * Get the data to submit to the server when the post is saved.
   *
   * @return {Object}
   */
  data() {
    return {
      title: this.title(),
      url: this.url(),
      badgeText: this.badgeText(),
      validFrom: this.validFrom(),
      validTo: this.validTo(),
      productInfo: this.productInfo(),
    };
  }
  /**
   * Draw focus to the text editor.
   */
  focus() {
    this.$(":input:enabled:visible:first").focus();
  }

  /**
   * Check if there is any unsaved data – if there is, return a confirmation
   * message to prompt the user with.
   *
   * @return {String}
   */
  preventExit() {
    const content = this.content();
    return (
      content &&
      content !== this.props.originalContent &&
      this.props.confirmExit
    );
  }

  config(isInitialized) {
    if (isInitialized) return;
    if (!this.loading) this.datepicker();
  }
  datepicker() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    $("#validFromdatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      minDate: jQuery("#validFromdatetimepicker").val()
            ? jQuery("#validFromdatetimepicker").val()
            : false,
      onReady: function ( dateObj, dateStr, instance ) {
        const $clear = $( '<div class="flatpickr-clear"><button class="Button  flatpickr-clear-button">X</button></div>' )
          .on( 'click', () => {
            instance.clear();
            instance.close();
          } )
          .appendTo( $( instance.calendarContainer ) );
      }
    });
    $("#validTodatetimepicker").flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y/m/d H:i",
      minDate: jQuery("#validFromdatetimepicker").val()
            ? jQuery("#validFromdatetimepicker").val()
            : false,
      onReady: function ( dateObj, dateStr, instance ) {
        const $clear = $( '<div class="flatpickr-clear"><button class="Button flatpickr-clear-button">X</button></div>' )
          .on( 'click', () => {
            instance.clear();
            instance.close();
          } )
          .appendTo( $( instance.calendarContainer ) );
      }
    });
    // $("#validFromdatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       maxDate: jQuery("#validTodatetimepicker").val()
    //         ? jQuery("#validTodatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
    // $("#validTodatetimepicker").datetimepicker({
    //   onShow: function (ct) {
    //     this.setOptions({
    //       minDate: jQuery("#validFromdatetimepicker").val()
    //         ? jQuery("#validFromdatetimepicker").val()
    //         : false,
    //     });
    //   },
    // });
  }
  // destroyDatePicker() {
  //   $("#validTodatetimepicker").datetimepicker("destroy");
  //   $("#validFromdatetimepicker").datetimepicker("destroy");
  // }
  onsubmit() {
    this.loading = true;
    const data = this.data();

    //this.destroyDatePicker();
    this.props.discussion
      .save(data)
      .then(() => app.composer.hide(), this.loaded.bind(this));
  }
}
