import Component from "flarum/Component";
import Placeholder from "flarum/components/Placeholder";
import Button from "flarum/components/Button";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import Select from "flarum/components/Select";
import DealPlaceHolder from "../DealPlaceHolder";
import ScrollListener from "flarum/utils/ScrollListener";
import CouponCardMini from "../CouponCardMini";
import EndpointsApiUrls from "../EndpointsApiUrls";

export default class CouponResults extends Component {
  //used for Coupon search page to display the products
  init() {
    const params = this.props.params;
    this.loading = true;
    this.couponList = [];
    this.refresh();
    this.moreResults = false;
    this.length = 0;
    // bottom load more code // Harshal
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    //  bottom load more code end
  }
  view() {
    let parameter = this.props.params;
    let loading;
    let loadingCat;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
      loadingCat = DealPlaceHolder.component({ type: "nav" });
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }
    const text =
      "It looks like there are currently no products available for this search. ";
    return (
      <div>
        <div className="DiscussionList">
          <div id="couponstores" class="cc_couponStoreHeader">
            <h1 style="margin: 0px;">{this.storename} Coupons</h1>
          </div>
          <ul className="DiscussionList-discussions cc_dealList">
            {this.couponList.map((discussion) => {
              var DiscussionData = {
                title: discussion._source.title,
                maxPrice: discussion._source.maxprice,
                currentPrice: discussion._source.currentprice,
                discount: discussion._source.discount,
                ////currency : discussion.data.attributes.currency,
                url: discussion._source.url,
                productName: discussion._source.productName,
                isFeatured: discussion._source.is_featured,
                isOffer: discussion._source.is_offer,
                isHotDeal: discussion._source.is_hotdeal,
                isExpired: discussion._source.is_expired,
                imageUrl_sm: discussion._source.imageurl_sm,
                noImageFound: discussion._source.noImageUrl_sm,
                validFrom: discussion._source.validfrom,
                validTo: discussion._source.validto,
                validFor: discussion._source.validfrom,
                badgeText: discussion._source.badgetext,
                tag_slug: discussion._source.tag_slug,
                avatar_path: discussion._source.avatar_path,
                username: discussion._source.username,
                startUser: "",
                id: discussion._id,
                tags: "",
                couponcode: discussion._source.couponcode,
                secondarytag: discussion._source.secondarytag,
                imagepath: discussion._source.imagepath,
                merchantName: discussion._source.merchantname,
              };
              return (
                <li key={discussion._source.id} data-id={discussion._source.id}>
                  {CouponCardMini.component({ DiscussionData })}
                </li>
              );
            })}
            <li>
              {this.couponList.length > 0
                ? this.couponList.length % 20 === 0
                  ? this.length == 0 || this.length == this.couponList.length
                    ? DealPlaceHolder.component({})
                    : ""
                  : ""
                : ""}
            </li>
          </ul>
        </div>
      </div>
    );
  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  loadMore() {
    this.loading = true;
    this.loadResults(this.couponList.length).then(this.parseResults.bind(this));
  }
  onScroll(pos) {
    if (
      this.couponList.length % 20 === 0 &&
      !this.loading &&
      this.couponList.length > 0
    ) {
      // if(this.length == 0 || this.length == this.couponList.length){
      //   this.length = this.couponList.length;
      if (pos >= $(document).height() - $(window).height() - 450) {
        this.loadMore();
      }
      //}
    }
  }
  config(isInitialized, context) {
    if (!isInitialized) return;
    setTimeout(() => this.scrollListener.start());
    context.onunload = () => {
      this.scrollListener.stop();
    };
  }
  parseResults(results) {
    if (results.result.hits) {
      [].push.apply(this.couponList, results.result.hits.hits);
      this.loading = false;
      this.moreResults = true;
      m.lazyRedraw();
      return results;
    } else {
      this.loading = false;
      m.redraw();
    }
  }

  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    if (params.query) {
      params.src = "search";
    } else {
      if (params.categories == undefined) {
        var hostnameTag = app.store.getBy("tags", "slug", params.category);
        var nametag = decodeURIComponent(hostnameTag.data.attributes.name);
        params.categories = nametag;
      }
      params.src = "cat";
    }
    // const Api_url = 'https://search.thedealapp.in/searchforcoupon';
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
      Api_url = ApiEndpoint + "/searchforcoupon";
    } else {
      Api_url = ApiEndpoint + "/api/v3/coupons";
    }

    return app.request({
      method: "GET",
      url: Api_url,
      data: params,
    });
  }
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.couponList = [];
    }

    return this.loadResults().then(
      (results) => {
        this.parseResults(results);
      },
      () => {
        this.loading = false;
        m.lazyRedraw();
      }
    );
  }
  requestParams(offset) {
    const params = m.route.param();
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }
} //end
