import { extend } from "flarum/extend";
import DiscussionControls from "flarum/utils/DiscussionControls";
import DiscussionPage from "flarum/components/DiscussionPage";
import discussionVotes from "./discussionVotes";
export default function () {
  var myDiscussion = "";
  extend(DiscussionControls, "moderationControls", function (
    items,
    discussion
  ) {
    myDiscussion = discussion;
  });
  extend(DiscussionPage.prototype, "sidebarItems", function (items) {
    const discussion = myDiscussion;
    items.add("myVote", discussionVotes.component({ discussion }), 500);
  });
}
