import { extend } from "flarum/extend";
import DiscussionHero from "flarum/components/DiscussionHero";
import tagsLabel from "flarum/tags/helpers/tagsLabel";
//import vote_bar from "tda/discussionvotes/components/vote_bar";

export default function () {
  //used for single deal display https://thedealapp.in/d/78362-swarn-solid-tie-pack-of-2-91
  extend(DiscussionHero.prototype, "items", function (items) {
    const discussion = this.props.discussion;
    var votes_discussion = discussion.data.attributes.votes_discussion;
    this.superDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_superDeal.png";
    this.CouponDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_coupon.png";
    this.dealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_deal.png";
    this.featuredDealRibbon =
      app.forum.data.attributes.baseUrl +
      "/assets/images/ribbon_featuredDeal.png";
    this.ExpiredDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_expired.png";

    if (
      discussion.data.attributes.discussionType != "d" &&
      discussion.data.attributes.discussionType != "c"
    ) {
      items.add(
        "couponcode",
        <div class="cc_couponSnapshot">
          <div class="cc_productDetailTag">
            {tagsLabel(discussion.tags(), { link: true })}
          </div>
          <br />
          <div class="cc_custH2" style="padding-bottom: 10px;">
            {discussion.data.attributes.title}
          </div>
          <div>{/*vote_bar.component({ discussion })*/}</div>
        </div>
      );
    }
  });
}
