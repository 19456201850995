import Component from "flarum/Component";
import avatar from "flarum/helpers/avatar";
import humanTime from "flarum/utils/humanTime";
import slidable from "flarum/utils/slidable";
import extractText from "flarum/utils/extractText";
import tagsLabel from "flarum/tags/helpers/tagsLabel";
import CouponExpiry from "./storePages/CouponExpiry";
import Badge from "flarum/components/Badge";

/**
 * The `DealCardMini` component shows a single discussion in the discussion list on DealsListSlider(for store pages).
 */
export default class DealCardMini extends Component {
  init() {
    this.noImageFound =
      app.forum.data.attributes.baseUrl + "/assets/images/no_image_found.png";
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/deal_expired.png";
    this.title = m.prop(this.props.DiscussionData.title || "");
    this.maxPrice = m.prop(this.props.DiscussionData.maxPrice || "");
    this.currentPrice = m.prop(this.props.DiscussionData.currentPrice || "");
    this.discount = m.prop(this.props.DiscussionData.discount || "");
    this.currency = m.prop(this.props.DiscussionData.currency || "");
    this.url = m.prop(this.props.DiscussionData.url || "");
    this.productName = m.prop(this.props.DiscussionData.productName || "");
    this.isFeatured = m.prop(this.props.DiscussionData.isFeatured || "");
    this.isHotDeal = m.prop(this.props.DiscussionData.isHotDeal || "");
    this.isExpired = m.prop(this.props.DiscussionData.isExpired || "");
    this.startTime = m.prop(this.props.DiscussionData.startTime || "");
    this.imageUrl_sm = m.prop(this.props.DiscussionData.imageUrl_sm || "");
    var urlcontent = this.imageUrl_sm().split('/');
    if (urlcontent[0] == "http:") {
      urlcontent[0] = "https:";
    }
    this.imageUrl = urlcontent.join('/');
    this.startUser = m.prop(this.props.DiscussionData.startUser || "");
    this.validFrom = m.prop(this.props.DiscussionData.validFrom || "");
    this.validTo = m.prop(this.props.DiscussionData.validTo || "");
    this.validFor = m.prop(this.props.DiscussionData.validFor || "");
    this.badgeText = m.prop(this.props.DiscussionData.badgeText || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    this.tags = m.prop(this.props.DiscussionData.tags || "");
    this.customBadge = null;
    if (this.badgeText()) {
      this.customBadge = this.badgeText();
    } else if (this.discount()) {
      this.customBadge = this.discount() + "% Off";
    }
    this.validTo_Expired = false;
    this.pastExpired = false;
    this.discussionRoute =
      app.forum.data.attributes.baseUrl + "/d/" + this.discussionID();
    this.storeimagepath = "";
    this.secondarytag = m.prop(this.props.DiscussionData.secondarytag || "");
    this.discussionType = m.prop(
      this.props.DiscussionData.discussionType || ""
    );
    this.refreshdate = m.prop(this.props.DiscussionData.refreshDate || "");
  }
  setCounDown(setDate, setID, startText, endText, textColor) {
    $("#" + setID).show();
    $("#" + setID).css("color", textColor);
    $("#" + setID)
      .countdown(setDate)
      .on("update.countdown", function (event) {
        var format = "%H:%M:%S";
        if (event.offset.totalDays > 0) {
          format = "%-d day%!d " + format;
        }
        if (event.offset.weeks > 0) {
          format = "%-w week%!w " + format;
        }
        $(this).html(startText + event.strftime(format));
      })
      .on("finish.countdown", function (event) {
        $(this).html(endText).parent().addClass("disabled");
        $(this).css("color", textColor);
      });
  }
  checkFutureTime(myTime) {
    var checkTime = new Date(myTime).getTime();
    var now = new Date().getTime();
    if (checkTime > now) {
      return true;
    } else {
      return false;
    }
  }
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  view() {
    let disRefreshDate = new Date(this.refreshdate());
    var endDateForStamp = this.validTo();
    if (endDateForStamp != "0000-00-00 00:00:00") {
      if (CouponExpiry(this.validTo()) == "past") {
        this.validTo_Expired = true;
      }
    }
    if (this.isExpired() === true) {
      this.validTo_Expired = true;
    }
    var tags = this.tags();
    if (tags)
      tags = tags = this.tags().filter(
        (model) =>
          model["parent"]() === app.store.getBy("tags", "slug", "stores")
      );
    else tags = this.tags();

    this.storeimagepath = tags[0] ? tags[0].data.attributes.imagePath : "";
    var storeName = tags[0] ? tags[0].data.attributes.name : "";
    //console.log(this.storeimagepath,storeName);
    const link = true;

    return m(
      "div",
      { class: "dealCard_mini", config: this.configArea.bind(this) },
      <div class="DiscussionListItem-content">
        <div>
          {this.customBadge ? (
            <div class="cc_hotness_DCM"> {this.customBadge}</div>
          ) : (
            ""
          )}
        </div>
        <div
          class="cc_productBlock_mini cc_borderRightBottom"
          itemscope
          itemtype="http://schema.org/Product"
        >
          {this.startUser() !== ""
            ? this.startUserShow()
            : $(".noUser").css("margin-top", "0px")}
          <div class="noUser"></div>
          <a href={this.discussionRoute} config={m.route}>
            <div
              class="cc_imageBox"
              style={
                tags[0]
                  ? tags[0].data.attributes.name == "TataCliq"
                    ? ""
                    : ""
                  : ""
              }
            >
              <div class="cc_imageFrameDealPage">
                {this.imageUrl ? (
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={this.imageUrl}
                    alt="Product Image"
                    onError={"this.src='" + this.storeimagepath + "'"}
                  />
                ) : (
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={this.storeimagepath}
                    alt="no image found"
                  />
                )}
              </div>
            </div>
          </a>
          <div class="cc_detailsBox_mini">
            <a href={this.discussionRoute} config={m.route}>
              <div
                itemprop="offers"
                itemscope
                itemtype="http://schema.org/Offer"
              >
                {this.maxPrice() && this.maxPrice() !== this.currentPrice() ? (
                  <div class="cc_offerText_DCM">
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    {this.maxPrice()}
                  </div>
                ) : (
                  <div class="cc_offerText"></div>
                )}
                {this.currentPrice() ? (
                  <div class="cc_productPrice_DCM">
                    <i itemprop="priceCurrency" content="Rs"></i>
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    <span itemprop="price">{this.currentPrice()}</span>/-
                  </div>
                ) : (
                  <div class="cc_productPrice"></div>
                )}
                <span
                  itemprop="availability"
                  href="http://schema.org/InStock"
                />
                <span
                  itemprop="offeredBy"
                  itemscope
                  itemtype="http://schema.org/Organization"
                >
                  <span itemprop="legalName" content={storeName} />
                </span>
                {this.discussionType() == "d" ? (
                  <div
                    style="display: inline-block;padding-left: 6px;"
                    class="miniInfoIcon"
                  >
                    <Badge
                      data-html="true"
                      type="hidden"
                      icon="fa fa-info"
                      label={
                        "Price as of " +
                        disRefreshDate +
                        "<br/>Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on " +
                        storeName +
                        " at the time of purchase will apply to the purchase of this product."
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="cc_titleBox_DCM">
                <h3 class="cc_titleText_DCM" itemprop="name">
                  {this.title()}
                </h3>
              </div>
            </a>
            <div class="cc_bottomBar_DCM">
              <div class="cc_siteIcons_DCM">
                {tags ? tagsLabel(tags, { link }) : ""}
              </div>
              {this.url() ? (
                !this.validTo_Expired == true ? (
                  <div class="dealCardMini_Btn">
                    <a
                      class="button button-sm btn-danger cc_buyNowBtn_mini"
                      href={this.url()}
                      target="_blank" rel="nofollow"
                    >
                      Get Deal
                    </a>
                  </div>
                ) : (
                  <div class="dealCardMini_Btn">
                    <a
                      class="Button hasIcon"
                      href={this.url()}
                      target="_blank"
                      style="background-color:#969696;padding: 4px 4px !important;"
                    >
                      Deal Expired
                    </a>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
            <div class="cc_expiryText">
              {!this.validTo_Expired == true ? (
                <div class="countdown" id={this.discussionID()}></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <a href={this.discussionRoute} config={m.route}>
          {!this.validTo_Expired == true ? (
            ""
          ) : (
            <div class="cc_expiredStamp_mini">
              <img
                className="sm_img lazy"
                src={this.expiredStamp}
                alt="expired Stamp"
              />
            </div>
          )}
        </a>
        <div class="cc_secondarytag">
          {this.secondarytag != "" ? <div>{this.secondarytag()}</div> : ""}
        </div>
      </div>
    );
  }
  configArea(element, isInitialized) {
    $(".hotDealBar").show();
    $(".popularBar").show();
    var FromStartText = "Starts in: ";
    var FromEndText = "This deal has started!";
    var ToStartText = "Ends in: ";
    var ToEndText = "This deal has expired!";
    var startsIn = this.validFrom().replace("T", " ");
    var endsIn = this.validTo().replace("T", " ");
    if (
      startsIn != "0000-00-00 00:00:00" &&
      this.checkFutureTime(startsIn) &&
      startsIn
    ) {
      this.setCounDown(
        startsIn.replace(".000Z", ""),
        this.discussionID(),
        FromStartText,
        FromEndText,
        "#00B504"
      );
    } else if (endsIn != "0000-00-00 00:00:00" && endsIn) {
      this.setCounDown(
        endsIn.replace(".000Z", ""),
        this.discussionID(),
        ToStartText,
        ToEndText,
        "#C20000"
      );
    }
  }
  startUserShow() {
    const startUser = this.startUser();
    return (
      <a
        href={startUser ? app.route.user(startUser) : "#"}
        className="DiscussionListItem-author"
        title={extractText(
          app.translator.trans("core.forum.discussion_list.started_text", {
            user: startUser,
            ago: humanTime(this.startTime()),
          })
        )}
        config={function (element) {
          $(element).tooltip({ placement: "right" });
          m.route.apply(this, arguments);
        }}
      >
        {avatar(startUser, { title: "" })}
      </a>
    );
  }
  config(isInitialized) {
    if (isInitialized) return;

    // If we're on a touch device, set up the discussion row to be slidable.
    // This allows the user to drag the row to either side of the screen to
    // reveal controls.
    if ("ontouchstart" in window) {
      const slidableInstance = slidable(this.$().addClass("Slidable"));

      this.$(".DiscussionListItem-controls").on("hidden.bs.dropdown", () =>
        slidableInstance.reset()
      );
    }
  }
}
