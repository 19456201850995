import Component from 'flarum/Component';

export default class GetTDAProductID extends Component {//used to add the store prefix in the product id
  init() { }
  getProductid(store, id) {
    let productid = ''
    if (store != undefined) {
      store = store.toLowerCase();
    }

    if (store == "amazon") {
      productid = "AZ" + id;
      return productid;
    } else if (store == "flipkart") {
      productid = "FK" + id;
      return productid;
    } else if (store == "snapdeal") {
      productid = "SD" + id;
      return productid;
    } else if (store == "myntra") {
      productid = "MT" + id;
      return productid;
    } else if (store == "tatacliq") {
      productid = "TQ" + id;
      return productid;
    } else if (store == "jabong") {
      productid = "JB" + id;
      return productid;
    } else if (store == "ebay") {
      productid = "EB" + id;
      return productid;
    } else if (store == "paytm") {
      productid = "PT" + id;
      return productid;
    } else if (store == "voonik") {
      productid = "VO" + id;
      return productid;
    } else if (store == "croma") {
      productid = "CR" + id;
      return productid;
    } else if (store == "shoppersstop") {
      productid = "SS" + id;
      return productid;
    } else if (store == "pepperfry") {
      productid = "PF" + id;
      return productid;
    } else if (store == "firstCry" || store == "firstcry") {
      productid = "FC" + id;
      return productid;
    } else if (store == "paytmmall") {
      productid = "PT" + id;
      return productid;
    } else if (store == "shopclues") {
      productid = "SC" + id;
      return productid;
    } else if (store == "ajio") {
      productid = "AJ" + id;
      return productid;
    } else if (store == "nnnow") {
      productid = "NN" + id;
      return productid;
    } else if (store == "reliancedigital") {
      productid = "RD" + id;
      return productid;
    } else if (store == "nykaa") {
      productid = "NK" + id;
      return productid;
    } else if (store == "hopscotch") {
      productid = "HP" + id;
      return productid;
    } else if (store == "brandfactory") {
      productid = "BF" + id;
      return productid;
    }else if (store == "mamaearth") {
    productid = "ME" + id;
    return productid;
    }else {
      productid = "";
      return productid;
    }
  }
}