import { extend } from "flarum/extend";
import HeaderPrimary from "flarum/components/HeaderPrimary";
import myIndexPage from "./components/myIndexPage";
import Button from "flarum/components/Button";
import plusButton from "./components/plusButton";
import editCouponPost from "./components/editCouponPost";
import myDiscussionCoupon from "./components/myDiscussionCoupon";
import myDiscussionComposer from "./components/myDiscussionComposer";
import mainSet from "./components/setDiscussion/mainSet";
import StoreIndexPage from "./components/storePages/StoreIndexPage";
import Tag from "flarum/tags/models/Tag";
//import Tdacategory from 'tda/myCategory/Tdacategory';
import CouponIndexPage from "./components/coupon/CouponIndexPagev2";
import addStoreList from "./components/coupon/addStoreList";
import addTagFilter from "./components/coupon/addTagFilter";
import myProductIndexPage from "./components/myProduct/myProductIndexPage";
import myCategoryIndexPage from "./components/category/myCategoryIndexPage";
import categoriesDealsIndexPage from "./components/categoriesDeals/categoriesDealsIndexPage";
import productDetailsPage from "./components/myProduct/productDetailsPage";
import ExtendedSearch from "./components/ExtendedSearch";
import DailyDealIndexPage from "./components/dailyDeals/DailyDealIndexPage";
import BrandIndexPage from "./components/brand/BrandIndexPage";
import PriceDropProductPage from "./components/productdisplay/PriceDropProductPage";
import dealTypeIndexPage from "./components/dailyDeals/dealTypeIndexPage";
//import WishListIndex from './components/wishlist/WishListIndex';
//import WishListSeeMore from './components/wishlist/WishListSeeMore';
import IndexPage from "flarum/components/IndexPage";
import myButtons from "./components/myButtons";
import ProductSearchPage from "./components/myProduct/ProductSearchPage";
import CouponSearchPage from "./components/myProduct/CouponSearchPage";
import signIn from "./components/signIn";
import LogIn from "./components/login";
import CouponStorePage from "./components/coupon/CouponStorePage";
import AddDiscussionDealContent from "./components/discussiondealcontent/AddDiscussionDealContent";
import AddCKEditor from "./components/texteditor/AddCKEditor";
//import AddQuillEditor from "./components/texteditor/AddQuillEditor";
import OldLoginRedirect from "./components/OldLoginRedirect";
// import DealItem from "./components/deal/DealItem"
// import dealRedirector from "./components/deal/dealRedirector"

app.initializers.add("prad-myIndex", function () {
  app.routes.myIndex = { path: "/myIndex", component: myIndexPage.component() };
  app.routes.newCoupon = {
    path: "/newCoupon",
    component: myDiscussionCoupon.component(),
  };
  app.routes.newDealComposer = {
    path: "/newDealComposer",
    component: myDiscussionComposer.component(),
  };
  app.routes.store = {
    path: "/store/:tags",
    component: StoreIndexPage.component(),
  };
  app.routes.Coupons = {
    path: "/Coupons/:tags",
    component: CouponIndexPage.component(),
  };
  app.routes.Category = {
    path: "/Category/:category",
    component: myCategoryIndexPage.component(),
    onmatch: function () {
      alert("Hello");
    },
  };
  app.route.tag = (tag) => app.route("Category", { tags: tag.slug() });

  app.route.tag = (tag) => app.route("Deals", { tags: tag.slug() });
  app.routes.Deals = {
    path: "/Deals/:categories",
    component: categoriesDealsIndexPage.component(),
  };
  //app.routes.categoriesDeals ={ path: '/categoriesDeals/:tags', component: categoriesDealsIndexPage.component() };
  app.route.tag = (tag) => app.route("Coupons", { tags: tag.slug() });
  app.routes.myProduct = {
    path: "/product",
    component: myProductIndexPage.component(),
  };
  app.routes.search = {
    path: "/search",
    component: ProductSearchPage.component(),
  };
  app.routes.searchCoupons = {
    path: "/Coupons",
    component: CouponSearchPage.component(),
  };
  app.routes.product_details = {
    path: "/product_details/:product_id",
    component: productDetailsPage.component(),
  };

  // app.routes.dealArticle = { path: "/deal/:id", component: DealItem.component() };

  // app.routes["dealArticle.near"] = {
  //   path: "/deal/:id/:near",
  //   component: DealItem.component(),
  // };
  //app.routes.Dealmachine = { path: '/Dealmachine', component: DailyDealIndexPage.component() };
  app.routes.brand = { path: "/Brand", component: BrandIndexPage.component() };
  app.routes.pricedrop = {
    path: "/Pricedrop",
    component: PriceDropProductPage.component(),
  };
  app.routes.dealtype = {
    path: "/dealtype/:dealtype",
    component: dealTypeIndexPage.component(),
  };
  app.routes.Couponsc = {
    path: "/store/:tags",
    component: CouponStorePage.component(),
  };
  //app.routes.WishListIndex = { path: '/Wishlist', component: WishListIndex.component() };
  //app.routes.WishListSeeMore = { path: '/Wishlist/:topicname', component: WishListSeeMore.component() };
  app.store.models.tags = Tag;
  //app.store.models.tdacategory=Tdacategory;
  extend(HeaderPrimary.prototype, "items", function (items) { });
  ExtendedSearch();
  //searchButton();
  plusButton();
  // editProductPost();
  editCouponPost();
  //AddVoteButtons();
  mainSet();
  addTagFilter();
  addStoreList();
  signIn();
  LogIn();
  AddDiscussionDealContent();
  AddCKEditor();
  //AddQuillEditor();
  // addTagFilterD();
  // addStoreCategoryList();
  OldLoginRedirect();
  //dealRedirector();
  extend(IndexPage.prototype, "sidebarItems", function (items) {
    const composeButton = new myButtons();
    items.add(
      "coupon",
      Button.component({
        children: "New Coupon ",
        icon: "tag",
        className: "Button Button--primary newcoupon",
        itemClassName: "App-primaryControl",
        onclick: composeButton.newcoupon.bind(composeButton),
      }),
      100
    );
    items.add(
      "Deal",
      Button.component({
        children: "New Deal",
        icon: "gift",
        className: "Button Button--primary newdeal",
        itemClassName: "App-primaryControl",
        onclick: composeButton.newProduct.bind(composeButton),
      }),
      100
    );
  });
});
