import Modal from 'flarum/components/Modal';
import extractText from 'flarum/utils/extractText';
import Button from 'flarum/components/Button';
import * as firebase from "firebase/app";
import "firebase/auth";

export default class NewSignIn extends Modal {

init() { 
   this.username = m.prop(this.props.username || '');
   this.email = m.prop(this.props.email || '');
   this.password = m.prop(this.props.password || '');
   this.mobileno = m.prop(this.props.mobileno || '');
   this.isVerify = m.prop(this.props.isVerify || '0');
   this.tokennum = "";// m.prop(this.props.tokennum || '');
   var contextmob = this;
   this.countrycode;
   this.selectcountry();
   //this.verificationId = "";
   this.firebaseResumeDownloadAdd = null;
   this.referal = m.prop(this.props.referal || '');
   this.isConfigured = false;

   var firebaseConfig = {
    apiKey: "AIzaSyAHSEvkApJnNJjkKqmFrg1nqleo3sycPQQ",
    authDomain: "thedealapp-7612a.firebaseapp.com",
    databaseURL: "https://thedealapp-7612a.firebaseio.com",
    projectId: "thedealapp-7612a",
    storageBucket: "thedealapp-7612a.appspot.com",
    messagingSenderId: "535942616872",
    appId: "1:535942616872:web:86a40d81976b69cc70a21d",
    measurementId: "G-LJSKRWBPDS"
  };
  
  if (!firebase.apps.length) {
    this.firebaseResumeDownloadAdd = firebase.initializeApp(firebaseConfig);
  }

   var boundfunction = (function(user) {
      if (user) {
        var uid = user.uid;
        var email = user.email;
        var photoURL = user.photoURL;
        var phoneNumber = user.phoneNumber;
        var isAnonymous = user.isAnonymous;
        var displayName = user.displayName;
        var providerData = user.providerData;
        var emailVerified = user.emailVerified;
      } 
  
    var user = firebase.auth().currentUser;
    if (user) {
      $("#sign-in-status").html('Signed in'); 
     var jsonobj = JSON.stringify(user, null, '  ');
     var responseobj = JSON.parse(jsonobj);
     
     var tokanvalue = responseobj.stsTokenManager.accessToken; 
     this.verificationId = responseobj.stsTokenManager.accessToken;
     contextmob.setTokenNum(tokanvalue);
  
      $("#accesstoken").val(tokanvalue); 
    //  document.getElementById('account-details').textContent = JSON.stringify(user, null, '  ');
    } else {
  
      $("#sign-in-status").html('Signed out');
      //document.getElementById('account-details').textContent = 'null';
      $("#account-details").html('null');
    }
      //m.render();

    }).bind(this);


      firebase.auth().onAuthStateChanged(boundfunction);   
 } // end init

  className() {
    return 'Modal--Big brandModal';
  }


  title() {
  	return (
        <div>
        <span>Sign Up</span> 
        </div>
        );

  }	

  content() {

  	 return [
  	 	m('div', {className: 'Modal-body popupModal'}, [

  	 	 <div className="Modal-body">
        
        <div className="Form Form--centered">
        <div id="error" style="padding-bottom: 15px;display:none;color:red;font-size: 15px;"></div>
           <div className="Form-group usernamediv" id="usernamediv" style="display:none;">
            <input className="FormControl" id="username" name="username" type="text" value={this.username()} placeholder={extractText(app.translator.trans('core.forum.sign_up.username_placeholder'))}
              bidi={this.username}
              disabled={this.loading} />
          </div>

          <div className="Form-group emaildiv" id="emaildiv" style="display:none;"> 
            <input className="FormControl" name="email" type="text" value={this.email()} placeholder={extractText(app.translator.trans('core.forum.sign_up.email_placeholder'))}
              bidi={this.email}
              disabled={this.loading} />
          </div>

          <div className="Form-group passworddiv" id="passworddiv" style="display:none;"> 
            <input className="FormControl" name="password" type="password" value={this.password()} placeholder={extractText(app.translator.trans('core.forum.log_in.password_placeholder'))}
              bidi={this.password}
              disabled={this.loading} />
          </div>

          <div className="Form-group referaldiv" id="referaldiv" style="display:none;"> 
            <input className="FormControl" name="referalcode" type="text" value={this.referal()} placeholder="Referral Code (Optional)"
              bidi={this.referal}
              disabled={this.loading} />
          </div>


      <div id="sign-in-card" class="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop">
        <div class="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white">
        </div>
        <div class="mdl-card__supporting-text mdl-color-text--grey-600">

          <form id="sign-in-form" action="#" config={this.config.bind(this)}>
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <div>
                <select className="countrycode" name="country" id="country" onchange={this.checkCountry.bind(this)}>
                  <option value="+91">INDIA</option>
                  <option value="+1">USA</option>
                </select>
              </div>
              <div>
                <input className="FormControl-signin"  type="text" value={this.mobileno()} placeholder={extractText(app.translator.trans('core.forum.log_in.mobileno_placeholder'))} 
                 id="phone-number" bidi={this.mobileno} onkeyup={this.validmobile.bind(this)} ></input>
              </div>
            </div> <br/>

            <button disabled class="Button Button--primary Button--block" id="sign-in-button" >Request OTP</button>
          </form>  

          <form id="verification-code-form" action="#" style="display:none;">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
              <input className="FormControl" type="text" id="verification-code" placeholder="Enter the verification code..." value=""/>
            <br/>
            <input type="submit" class="Button Button--primary Button--block" id="verify-code-button" value="Verify Code" onclick={this.onVerifyCodeSubmit.bind(this)}/>
             <input type = "hidden" id="ismobverifyval" value=""/> 
             <input type = "hidden" id="accesstoken" value=""/> 
            </div>
            <br/><br/>
            <div id="timerdiv" style="color:#000;">Resend OTP in <span id="timer"></span></div>
            <div id="resenddiv" style="display:none;">
            <button type='button' id="resendotp" class="resend_btn" onclick={this.resendotpconfig.bind(this)}>ReSend OTP</button></div>
          </form>
        </div>
      </div> <br/>

          <div className="Form-group" id="logginbtn" style="display:none;">
            {Button.component({
              className: 'Button Button--primary Button--block',
              type: 'submit',
              loading: this.loading,
              children: app.translator.trans('core.forum.sign_up.submit_button')
            })}
          </div>
        </div>
      </div>,
      <div className="Modal-footer" >
        
        {app.forum.attribute('allowSignUp') ? (
          <p className="LogInModal-signUp">
          </p>
        ) : ''}
      </div>	
  	 	]),
       //  m("h3",m("a",({className:'Button Button--primary btnModalBottom',onclick:this.hide.bind()}),"No , I Want to see all results"))    	
  	 ];

  }	
  setTokenNum(tokenVal)
  {
    this.tokennum = tokenVal;
  }
  config(isInitialized,context){

    if(!isInitialized || !this.isConfigured){
      this.isConfigured = true;
      this.isVerify = 0;
    document.getElementById('verification-code-form').style.display = 'none';
    // var code = document.getElementById("country").selectedIndex;
    // var countryVal = document.getElementById("country").options;
    // this.countrycode = countryVal[code].value;
    //this.countrycode = countryVal[code].value;

///////////////boundfunction//////////////////////////////////////
   // Event bindings.    
   if(window.recaptchaVerifier){
     window.recaptchaVerifier.clear();
   }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
     'size': 'invisible',
      'callback': function(response) {


      document.getElementById('sign-in-form').style.display = 'none';
      document.getElementById('verification-code-form').style.display = 'block'; 
      window.signingIn = true;
      //updateSignInButtonUI();
      var selectedcountrycode;
      var countryVal = document.getElementById("country").options;
      Object.keys(countryVal).forEach(function (item) {
        if(countryVal[item].selected == true){
          selectedcountrycode = countryVal[item].value;
        }
      });
      var countrycode = selectedcountrycode;
      var phoneNumber = document.getElementById('phone-number').value;
      var phoneNumber = countrycode + phoneNumber;
      var appVerifier = window.recaptchaVerifier;
      firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            document.getElementById('sign-in-button').style.display = 'none';
            document.getElementById('usernamediv').style.display = 'none';
            document.getElementById('emaildiv').style.display = 'none';
            document.getElementById('passworddiv').style.display = 'none';
            document.getElementById('referaldiv').style.display = 'none';
             
            startDownTimer(20);
            // if(confirmationResult.verificationId != undefined){
            //   this.verificationId = confirmationResult.verificationId;
            // }
            //this.verificationId = JSON.stringify(confirmationResult.verificationId);
            //document.getElementById('accesstoken').value = this.verificationId;
            window.confirmationResult = confirmationResult;
            window.signingIn = false;
          }).catch(function (error) {
            if(error.code == "auth/too-many-requests"){
              alert(error.message);
            }else if(error.code == "auth/invalid-phone-number"){
              alert('Please Enter Vaild Phone Number');
            }else{
              window.alert(error.message);
            }
            
            // Error; SMS not sent
            //console.error('Error during signInWithPhoneNumber', error);
            // window.alert('Error During Sign Up:\n\n'
            //     + error.code + '\n\n' + error.message);
            window.signingIn = false;
            document.getElementById('verification-code-form').style.display = 'none';
            document.getElementById('sign-in-form').style.display = 'block';
            //this.updateSignInButtonUI();
          });
        
      }
    });
    
    
    recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
       //grecaptcha.reset(widgetId);
     //updateSignInButtonUI();
    });
    
   
  let timerOn = true;
  /**
  * function for start down timer
  **/
        function startDownTimer(remaining) {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;
        
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        if(document.getElementById('timer')){
        document.getElementById('timer').innerHTML = m + ':' + s;
        
        remaining -= 1;
        
        if(remaining >= 0 && timerOn) {
          setTimeout(function() {
              startDownTimer(remaining);
          }, 1000);
          return;
        }


        if(!timerOn) {
          // Do validate stuff here
          return;
        }
        document.getElementById('timerdiv').style.display = 'none';
        document.getElementById('resenddiv').style.display = 'block'; 
      }
        // Do timeout stuff here
      
      }

      

 } // is initialize closed 

} // config function closed

  /**
   * Function called when clicking the "Verify Code" button.
   */
  onVerifyCodeSubmit(isInitialized) {
    //recaptchaVerifier.clear();
    if (!!this.getCodeFromUserInput()) {
      //this.verificationId = $("#accesstoken").val();
      window.verifyingCode = true;
      this.updateVerifyCodeButtonUI();
      var code = this.getCodeFromUserInput();
      var contextP = this;
      confirmationResult.confirm(code).then(function (result) {
        $("#ismobverifyval").val("1");
        //isVerify = 1;
        document.getElementById('verification-code-form').style.display = 'none';
        document.getElementById('verify-code-button').style.display = 'none';
        document.getElementById('logginbtn').style.display = 'block';
        document.getElementById('usernamediv').style.display = 'block';
        document.getElementById('emaildiv').style.display = 'block';
        document.getElementById('passworddiv').style.display = 'block';
        document.getElementById('referaldiv').style.display = 'block';
        // User signed in successfully.
        var user = result.user;
        window.verifyingCode = false;
        window.confirmationResult = null;
        contextP.updateVerificationCodeFormUI();
      }).catch(function (error) {
        alert(error);
        document.getElementById('verification-code').innerHTML = "";
        document.getElementById('verify-code-button').removeAttribute("disabled");
        //document.getElementById('verify-code-button').style.display = 'block';

        // User couldn't sign in (bad verification code?)
        //console.error('Error while checking the verification code', error);
        // window.alert('Error while checking the verification code check:\n\n'
        //     + error.code + '\n\n' + error.message);
        window.verifyingCode = false;
        //this.updateSignInButtonUI();
        //this.updateVerifyCodeButtonUI();
      });
    }
    
  }
  
   /**
   * Updates the Verify-code button state depending on form values state.
   */
  updateVerifyCodeButtonUI() {
    document.getElementById('verify-code-button').disabled =
        !!window.verifyingCode
        || !this.getCodeFromUserInput();
  }
 /**
   * Updates the Signed in user status panel.
   */
   updateSignedInUserStatusUI() {

    var user = firebase.auth().currentUser;
   // console.log('user:',user);
    if (user) {
    
      $("#sign-in-status").html('Signed in'); 
     var jsonobj = JSON.stringify(user, null, '  ');

     var responseobj = JSON.parse(jsonobj);

     var tokanvalue = responseobj.stsTokenManager.accessToken; 
     
     this.tokennum =  tokanvalue;
     
     // $("#accesstoken").val(tokanvalue); 
    } else {
      $("#sign-in-status").html('Signed out');
      $("#account-details").html('null');
    }
   } 


  /**
   * Updates the Sign-in button state depending on ReCAptcha and form values state.
   */
  updateSignInButtonUI() {
    document.getElementById('sign-in-button').disabled =
        !isPhoneNumberValid()
        || !!window.signingIn;
   }


  /**
   * Updates the state of the Verify code form.
   */
  updateVerificationCodeFormUI() {

    var contextP = this; 
    if (!firebase.auth().currentUser && window.confirmationResult) {
      document.getElementById('verification-code-form').style.display = 'block';
      document.getElementById('verify-code-button').style.display = 'none';
    } else {
      document.getElementById('verification-code-form').style.display = 'none';
    }
  }

   /**
   * Reads the verification code from the user input.
   */
  getCodeFromUserInput() {
    return document.getElementById('verification-code').value;
  }

//=====================================================================////

////================Resnd Section start================================////

//======================================================================///


  /**
  * put data in for insert 
  */
  data(){
    var isverifyval = $("#ismobverifyval").val();
    var country_code = $("#country").val();
    //var NewToken = $("#accesstoken").val();
    this.verificationId = this.verificationId.replace(/^"|"$/g, '');
    //var test = this.verificationId;

    return {   
       username:this.username(),
       email: this.email(),
       password: this.password(),
       mobileno: this.mobileno(),
       isVerify : isverifyval,
       tokennum : this.verificationId,
       country_code : country_code,
       idtoken : this.verificationId,
       referredBy : this.referal(),
      };
  }  

  /** 
  * validation for mobile no  
  **/ 
  validmobile(isInitialized,context){
   var mobileno = this.mobileno();
   var validmobno = /\d{10}/;
   var country_code = $("#country").val();

    if(mobileno.match(validmobno)){
     $("#sign-in-button").attr("disabled", false);
     }else{
      $("#sign-in-button").attr("disabled", true);
     
     }
     
     if(mobileno.length== 10){
      app.request({
        url: app.forum.attribute('baseUrl') + '/api/checkNumber', 
        method: 'POST',
        data:{mobileno:mobileno,country_code:country_code},
      }).then(
        this.processDetails.bind(this),
      ); 
     }
  }
 
 
 processDetails(results){
   if(results.data.attributes.message == "Already Register"){
      document.getElementById('error').style.display = 'block';
      $("#error").html('Mobile number already registered.');
      $("#sign-in-button").attr("disabled", true);
    }else{
      document.getElementById('error').style.display = 'none';
    } 
  }

  /**
 * For Resend config
 */ 
  resendotpconfig(isInitialized,context){
    // recaptchaVerifier.clear();
    // recaptchaVerifier.render().then(function(widgetId) {
    //   window.recaptchaWidgetId = widgetId;
    //   grecaptcha.reset(widgetId);
    //  //updateSignInButtonUI();
    // });
     // window.recaptchaVerifier1 = new firebase.auth.RecaptchaVerifier('resendotp', {
     // 'size': 'invisible',
     //  'callback': function(response) {

     //  document.getElementById('verification-code-form').style.display = 'block'; 
     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "resendotp",
        {
          size: "invisible"
        }
      );
      window.signingIn = true;
      //updateSignInButtonUI();
      var selectedcountrycode;
      var countryVal = document.getElementById("country").options;
      Object.keys(countryVal).forEach(function (item) {
        if(countryVal[item].selected == true){
          selectedcountrycode = countryVal[item].value;
        }
      });
      var countrycode = selectedcountrycode;
      var phoneNumber = document.getElementById('phone-number').value;
      var phoneNumber = countrycode + phoneNumber;
      var appVerifier = window.recaptchaVerifier;
      firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            document.getElementById('sign-in-button').style.display = 'none';
            document.getElementById('usernamediv').style.display = 'none';
            document.getElementById('emaildiv').style.display = 'none';
            document.getElementById('passworddiv').style.display = 'none';
            document.getElementById('sign-in-form').style.display = 'none';
            document.getElementById('referaldiv').style.display = 'none';
             
            window.confirmationResult = confirmationResult;
            window.signingIn = false;
            }).catch(function (error) {
            // Error; SMS not sent
            //window.alert('Error during signInWithPhoneNumber:\n\n'
            //    + error.code + '\n\n' + error.message);
            window.alert(error.message);
            document.getElementById('verification-code-form').style.display = 'none';
            document.getElementById('sign-in-form').style.display = 'block';
            //window.reload();
          });
      //}

    //});
   
 }    // resendconfig function closed

 //check country code

 checkCountry(countrycode){
  this.countrycode = countrycode.target.value;
  $("select#country option[value='"+this.countrycode+"']").attr("selected","selected");
 }

  onsubmit(e) {
   const data = this.data();

   app.request({
      url: app.forum.attribute('baseUrl') + '/registerv2', 
      method: 'POST',
      data,
      errorHandler: this.onerror.bind(this)
    }).then(
      () => window.location.reload(),
      this.loaded.bind(this)
    ); 
    e.preventDefault();
   } 

   selectcountry(){
    var BaseUrl = app.forum.attribute("baseUrl");
    if(BaseUrl == "https://thedealapp.in"){
      $("select#country option[value='+91']").attr("selected","selected");
    }else{
      $("select#country option[value='+1']").attr("selected","selected");
    }
  }

}