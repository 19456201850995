import { extend } from "flarum/extend";
import Search from "flarum/components/Search";
import ProductSearchSource from "./ProductSearchSource";
import ScrollListener from "flarum/utils/ScrollListener";

export default function () {
  //used for search
  extend(Search.prototype, "getCurrentSearch", function (items) {
    if (this.index <= 0) this.index = 99999999;
    if (this.sources != null && this.sources.length < 3) {
      this.sources.splice(0, 0, new ProductSearchSource());
    }
  });

  extend(Search.prototype, "config", function (isInitialized, data, context) {
    if (document.body.clientWidth < 768) {
      $(this.element).width("100%");
      this.lastPosition = 0;
      this.delta = 5;
      this.scrollListener = new ScrollListener(
        function cb(pos) {
          if (Math.abs(this.lastPosition - pos) <= this.delta) return;
          if (pos > this.lastPosition && pos > 1) {
            $(".Search").css("display", "none");
          } else {
            if (pos + $(window).height() < $(document).height()) {
              $(".Search").css("display", "block");
            }
          }
          this.lastPosition = pos;
        }.bind(this)
      );
      setTimeout(() => this.scrollListener.start());
      context.onunload = () => {
        this.scrollListener.stop();
      };

      $("body").on("focusin", "input, textarea", function (event) {
        var scroll = $(this).offset();
      });
    }
    $(this.element)
      .find("input")
      .attr("placeholder", "What are you looking for?");
    if (this.value() == "" || !this.hasFocus) {
      const searchString =
        app.current &&
        typeof app.current.searching === "function" &&
        app.current.searching();
      this.value(searchString || "" || m.route.param().query);
    }
  });
}
