import Component from 'flarum/Component';
import Placeholder from 'flarum/components/Placeholder';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import listItems from 'flarum/helpers/listItems';
import Select from 'flarum/components/Select';
import DealPlaceHolder from './../DealPlaceHolder';
import ScrollListener from 'flarum/utils/ScrollListener';
import DailyDealCard from './../dailyDeals/DailyDealCard';

export default class dealTypeResults extends Component
{  //Used for Dealmachine to view all deals for live,upcoming,expired https://tda.development/dealtype/l?stage=live&dealType=LIGHTNING_DEAL
  init()
  {
    const params = this.props.params;
    const routeName = this.props.routeName;
    this.loading=true;
    this.dealtypeList = [];
    this.refresh();
    this.moreResults = false;
    //bottom load more code // Harshal    
      this.scrollListener = new ScrollListener(this.onScroll.bind(this));   
   //bottom load more code end   
  }
  view()
  {
    //const sortOptions ={"onehrtime", "sixhrtime","twelvehrtime","twentyfourhrtime"};
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
      children: app.translator.trans('core.forum.discussion_list.load_more_button'),
      className: 'Button',
      onclick: this.loadMore.bind(this)
      });
    }
  const text = "It looks like there are currently no products available for this search. ";  
      return( 
        <div>
          {(this.dealtypeList.length>0 || this.loading)?
          <div id ="IndexPage_data" class="IndexPage-results">
            <div className="DiscussionList">
              <div class="dealtypesort">
              <span>Sort :</span>
                <div class="cc_productSort">
                  {listItems(this.viewItems(this.props.params.heading).toArray())}
                </div>
            </div>

            <div class="productlist">
              <ul className="DiscussionList-discussions cc_dealList">
                { this.loading?loading:(
                  this.dealtypeList.map(discussion => {
                  var DiscussionData = {
                          title : discussion.title,
                          Discount_rangefrom : discussion.Discount_rangefrom,
                          Discount_rangeto : discussion.Discount_rangeto,
                          MRP : discussion.MRP,
                          dealId : discussion.dealId,
                          dealPrice : discussion.dealPrice,
                          dealType : discussion.dealType,
                          disc_rank_HtoL : discussion.disc_rank_HtoL,
                          disc_rank_LtoH : discussion.disc_rank_LtoH,
                          imageurl : discussion.imageurl,
                          isCalculated : discussion.isCalculated,
                          isExpired : discussion.isExpired,
                          isLive : discussion.isLive,
                          merchantname : discussion.merchantname, 
                          pid : discussion.pid, 
                          price_rangefrom : discussion.price_rangefrom,                           
                          price_rangeto : discussion.price_rangeto,
                          price_rank_HtoL : discussion.price_rank_HtoL,                          
                          price_rank_LtoH : discussion.price_rank_LtoH,
                          sellingPrice : discussion.sellingPrice,
                          // url : discussion.url,
                          url : "https://www.amazon.in/gp/goldbox/all-deals?gb_edi="+discussion.dealId,
                          id : discussion._id.$oid,
                          dealStartTime : discussion.dealStartTime.$date.$numberLong,
                          dealEndTime : discussion.dealEndTime.$date.$numberLong,
                          heading : this.props.params.heading,
                          price_rangefrom_original : discussion.price_rangefrom_original,
                          price_rangeto_original : discussion.price_rangeto_original,
                        };
                  return (
                       <li class='cc_productCard dealtypecard'>
                        {DailyDealCard.component({DiscussionData})}
                      </li>
                    );
                  }))
                }
              <li>
                {(this.dealtypeList.length%20)===0?
                  DealPlaceHolder.component({})         
                :""}          
              </li>
            </ul>
            <div style="clear:both"></div>

            </div>
          </div> 
        </div>
      :
         
          <div className="DiscussionList">
            {Placeholder.component({ text })}
          </div>
        }
          </div>
        );
  }
   
    getPlaceHolders(size)
      {
            const placeHolderList = new ItemList();
            for(var i=0;i<size;i++)
            {
                  placeHolderList.add('palceholder-'+i.toString(),DealPlaceHolder.component());
            }
            return placeHolderList;
      }
  loadMore() {
    this.loading = true;
    this.loadResults(this.dealtypeList.length);  
  }
  onScroll(pos){
      if((this.dealtypeList.length%20)===0 && !this.loading)
      {
        if(pos >= $(document).height() - $(window).height()-450) {
          this.loadMore();              
        }
      }
   }
  config(isInitialized,context){
            if(isInitialized) return;
            setTimeout(() => this.scrollListener.start());
            context.onunload = () => {
              this.scrollListener.stop();
            };
   }
  parseResults(results){
     [].push.apply(this.dealtypeList, results.data.attributes);
      this.loading = false;
      this.moreResults = true;
      m.redraw();
    return results;

  }

  loadResults(perpage) {
    const preloadedDiscussions = app.preloadedDocument();
    if (preloadedDiscussions) {
      return m.deferred().resolve(preloadedDiscussions).promise;
    }
    const params = this.requestParams(perpage?perpage+1:0);
    const Api_url =  app.forum.attribute('baseUrl') +'/api/dailydeals'; 
        return app.request({
            method: 'POST',
            url: Api_url,
            data: params, 
        }).then(
              this.parseResults.bind(this),
            );    
          
  }
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.dealtypeList = [];
    }
   
    return this.loadResults().then(
         
      results => {

      },
      () => {
        this.loading = false;       
       // m.redraw();
      }
    );
  }
  requestParams(offset)
  {
    const params= this.props.params;
    
    if(offset != undefined)
    {
      params.size = offset;
    }  
    return params; 
  }

   viewItems(datahead)
  {
      const items = new ItemList();
      const sortMap ={select:"Select", totaldiscount: "Total Discount", effectivediscount: "Effective Discount", price: "Price"};
      const sortOptions ={select:"Select", totaldiscount: "Total Discount", effectivediscount: "Effective Discount", price: "Price"};
      items.add('sort',
        Select.component({
          options: sortOptions,
          value: this.props.params.sort || Object.keys(sortMap)[0],
          onchange: this.changeSort.bind(this)
        })
      );

      return items;
    }
  changeSort(sort) {  
    if(sort !== '') {
    var sort = sort;
    this.dealtypeList = []; 
    this.loading=true; 
    const params = this.requestParams();
    params.sort = sort;
    m.route(app.route(this.props.routeName,this.getParams('sort',sort)));
    const Api_url = app.forum.attribute('baseUrl') +'/api/dailydeals';
        return app.request({
                          method: 'POST',
                          url: Api_url,
                          data: params, 
                    }).then(
                            this.parseResults.bind(this),
                            );
    }
  }

  getParams(paramName,value){ 
    let paramsURL = Object.assign({},  m.route.param());
    paramsURL[paramName] = value;
    return paramsURL;
  }

     
}//end
