import Page from "flarum/components/Page";
import CouponListPage from "../coupon/CouponListPage";

export default class CouponIndexPage extends Page {
  //Used for Coupons Page(Indexpage)
  init() {
    super.init();

    const params = this.params();

    if (!app.cache.Coupon_DiscussionList) {
      var storeNM = this.storeLink;

      app.cache.Coupon_DiscussionList = new CouponListPage({ params, storeNM });
    }
  }

  view() {
    this.storeLink = window.location.pathname.split("/")[2];

    return m(
      "div",
      { config: this.config.bind(this) },
      <div className="container">
        <div class="IndexPage-results cc_couponResults cc_dealResultsnp">
          {app.cache.Coupon_DiscussionList.render()}
        </div>
      </div>
    );
  }

  /**
   * Get URL parameters that stick between filter changes.
   *
   * @return {Object}
   */
  stickyParams() {
    return {
      sort: m.route.param("sort"),
      q: m.route.param("q"),
    };
  }

  /**
   * Get parameters to pass to the Coupon_DiscussionList component.
   *
   * @return {Object}
   */
  params() {
    const params = this.stickyParams();

    params.filter = m.route.param("filter");

    return params;
  }
}
