import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';
import highlight from 'flarum/helpers/highlight';
import classList from 'flarum/utils/classList';
import KeyboardNavigatable from 'flarum/utils/KeyboardNavigatable';
import tagLabel from 'flarum/tags/helpers/tagLabel';
import tagIcon from 'flarum/tags/helpers/tagIcon';
import sortTags from 'flarum/tags/utils/sortTags';
import myDiscussionComposer from './myDiscussionComposer';

export default class CategoryDiscussionModal extends Modal {
  //If Category is not get through apple router then call the getcategory api to select the category
  init() {
    super.init();
    this.tags = app.store.all('tags');  
    //pradip lines
    var category = [];
    var i = 0;
    var categoryID = '';
    $.each(this.tags, function( index, value ) {      
         if(value.data.attributes.slug == 'category'){
           category[i] = value; 
           i++;    
           categoryID = value.data.id;
         }        
         if(value.data.relationships){
            if(value.data.relationships.parent){
                if(value.data.relationships.parent.data.id == categoryID){
                    category[i] = value; 
                    i++;
                }
            }
         }
    });
    this.tags = category;
    this.tags = sortTags(this.tags); 
    //new adde
    if(app.cache.selectedCategory==undefined || app.cache.selectedCategory.length==0)
    {
      this.myCategorycurrentTag=  [];   
      this.myCategorycurrentTag.push(app.store.getBy('tags', 'slug', 'category'));
      app.cache.selectedCategory=this.myCategorycurrentTag;
    }

    this.mySelectedTags = this.props.selectedTags;
    this.props = this.props.props;
    this.parentTag = null;
    if(app.cache.selectedCategory){        
        this.mySelectedTags = app.cache.selectedCategory;
        this.parentTag = app.cache.selectedCategory["0"];       
    }
         
    this.selected = [];
    this.filter = m.prop('');
    this.index = this.tags[0];
    this.focused = false;
    if (this.mySelectedTags.length) {
        this.props.selectedTags = this.mySelectedTags;      
        this.props.selectedTags.map(this.addTag.bind(this));
    } 
    this.navigator = new KeyboardNavigatable();
    this.navigator
      .onDown(() => this.setIndex(this.getCurrentNumericIndex() + 1, true))
      .onSelect(this.select.bind(this))
      .onRemove(() => this.selected.splice(this.selected.length - 1, 1));
  }
getCategory(string){
      var forString = string;
      app.request({
            method: 'POST',
            dataType: "json",
            data: this.data(forString),
            url: "http://tda.dev/api/getCategory"
      }).then(
            this.success.bind(this),
            this.failure.bind(this),
      );
  }
data(forString){
    return {
      string: forString,
    }
}

success(data) { }
failure(data) { }

  addTag(tag) {
   // if (!tag.canStartDiscussion()) return;
    // If this tag has a parent, we'll also need to add the parent tag to the
    // selected list if it's not already in there.
    const parent = tag.parent();
    if (parent) {
      const index = this.selected.indexOf(parent);
      if (index === -1) {
        this.selected.push(parent);
      }
    }
    this.selected.push(tag);
  }

  removeTag(tag) {
    const index = this.selected.indexOf(tag);
    if (index !== -1) {
      this.selected.splice(index, 1);
      // Look through the list of selected tags for any tags which have the tag
      // we just removed as their parent. We'll need to remove them too.
      this.selected
        .filter(selected => selected.parent() === tag)
        .forEach(this.removeTag.bind(this));
    }
  }

  className() {
    return 'TagDiscussionModal';
  }

  title() {
    return 'Choose all categories that may apply';      
  }

  content() {
    let tags = this.tags;
    const filter = this.filter().toLowerCase();
    tags = tags.filter(tag => {
      const parent = tag.parent();      
      return parent === false || this.selected.indexOf(parent) !== -1;
    });
      `                                                                              `
    if (filter) {
      tags = tags.filter(tag => tag.name().substr(0, filter.length).toLowerCase() === filter);
    }

    if (tags.indexOf(this.index) === -1) this.index = tags[0];

    return [
      <div className="Modal-body">
        <div className="TagDiscussionModal-form">
          <div className="TagDiscussionModal-form-input">
            <div className={'TagsInput FormControl ' + (this.focused ? 'focus' : '')}>
              <span className="TagsInput-selected">
                {this.selected.map(tag =>
                  <span className="TagsInput-tag" onclick={() => {
                    this.removeTag(tag);
                    this.onready();
                  }}>
                    {tagLabel(tag)}
                  </span>
                )}
              </span>
              <input className="FormControl"
                placeholder= 'Type category name..'
                value={this.filter()}
                oninput={m.withAttr('value', this.filter)}
                onkeydown={this.navigator.navigate.bind(this.navigator)}
                onfocus={() => this.focused = true}
                onblur={() => this.focused = false}/>
            </div>
          </div>
          <div className="TagDiscussionModal-form-submit App-primaryControl">
            {Button.component({
              type: 'submit',
              className: 'Button Button--primary',
              //disabled: primaryCount < this.minPrimary || secondaryCount < this.minSecondary,
              icon: 'check',
              children: app.translator.trans('flarum-tags.forum.choose_tags.submit_button')
            })}
          </div>
        </div>
      </div>,

      <div className="Modal-footer">
        <ul className="TagDiscussionModal-list SelectTagList">
          {tags
            .filter(tag => filter || !tag.parent() || this.selected.indexOf(tag.parent()) !== -1)
            .map(tag => (
              <li data-index={tag.id()}
                className={classList({
                  pinned: tag.position() !== null,
                  child: !!tag.parent(),
                  colored: !!tag.color(),
                  selected: this.selected.indexOf(tag) !== -1,
                  active: this.index === tag
                })}
                style={{color: tag.color()}}
                onmouseover={() => this.index = tag}
                onclick={this.toggleTag.bind(this, tag)}
              >
                {tagIcon(tag)}
                <span className="SelectTagListItem-name">
                  {highlight(tag.name(), filter)}
                </span>
                {tag.description()
                  ? (
                    <span className="SelectTagListItem-description">
                      {tag.description()}
                    </span>
                  ) : ''}
              </li>
            ))}
        </ul>
      </div>
    ];
  }

  toggleTag(tag) {
    const index = this.selected.indexOf(tag);

    if (index !== -1) {
      this.removeTag(tag);
    } else {
      this.addTag(tag);
    }

    if (this.filter()) {
      this.filter('');
      this.index = this.tags[0];
    }

    this.onready();
  }

  select(e) {
    // Ctrl + Enter submits the selection, just Enter completes the current entry
    if (e.metaKey || e.ctrlKey || this.selected.indexOf(this.index) !== -1) {
      if (this.selected.length) {
        this.$('form').submit();
      }
    } else {
      this.getItem(this.index)[0].dispatchEvent(new Event('click'));
    }
  }

  selectableItems() {
    return this.$('.TagDiscussionModal-list > li');
  }

  getCurrentNumericIndex() {
    return this.selectableItems().index(
      this.getItem(this.index)
    );
  }

  getItem(index) {
    return this.selectableItems().filter(`[data-index="${index.id()}"]`);
  }

  setIndex(index, scrollToItem) {
    const $items = this.selectableItems();
    const $dropdown = $items.parent();

    if (index < 0) {
      index = $items.length - 1;
    } else if (index >= $items.length) {
      index = 0;
    }

    const $item = $items.eq(index);

    this.index = app.store.getById('tags', $item.attr('data-index'));

    m.redraw();

    if (scrollToItem) {
      const dropdownScroll = $dropdown.scrollTop();
      const dropdownTop = $dropdown.offset().top;
      const dropdownBottom = dropdownTop + $dropdown.outerHeight();
      const itemTop = $item.offset().top;
      const itemBottom = itemTop + $item.outerHeight();

      let scrollTop;
      if (itemTop < dropdownTop) {
        scrollTop = dropdownScroll - dropdownTop + itemTop - parseInt($dropdown.css('padding-top'), 10);
      } else if (itemBottom > dropdownBottom) {
        scrollTop = dropdownScroll - dropdownBottom + itemBottom + parseInt($dropdown.css('padding-bottom'), 10);
      }

      if (typeof scrollTop !== 'undefined') {
        $dropdown.stop(true).animate({scrollTop}, 100);
      }
    }
  }

  onsubmit(e) {
    e.preventDefault();

    const tags = this.selected;    
    if (this.props.onsubmit) this.props.onsubmit(tags);
    
    
     const props = {
        tags,  
      };   
      
      app.cache.selectedCategory = tags;
   app.modal.close();   
   const component = new myDiscussionComposer({user: app.session.user});

  } 
}
