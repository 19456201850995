import { extend } from 'flarum/extend';
import app from 'flarum/app';
import Model from 'flarum/Model';
import Discussion from 'flarum/models/Discussion';
import NotificationGrid from 'flarum/components/NotificationGrid';
import DiscussionFeaturedPost from './components/DiscussionFeaturedPost';
import DiscussionOfferPost from './components/DiscussionOfferPost';
import DiscussionFeaturedNotification from './components/DiscussionFeaturedNotification';
//import DiscussionHotDealPost from './components/DiscussionHotDealPost';
//import DiscussionHotDealNotification from './components/DiscussionHotDealNotification';
//import DiscussionExpiredPost from './components/DiscussionExpiredPost';
//import DiscussionExpiredNotification from './components/DiscussionExpiredNotification';
import addBadge from './addBadge';
import addFeaturedControl from './addFeaturedControl';
import addHotDealControl from './addHotDealControl';
import addExpiredControl from './addExpiredControl';
import addOfferControl from './addOfferControl';
import addEditControl from './addEditControl';
import addApproveDealControl from './addApproveDealControl';
import addOrderdate from './addOrderdate';
import requestFeaturedControl from './requestFeaturedControl';
import requestSuperdealControl from './requestSuperdealControl';
//import DiscussionApproveDealPost from './components/DiscussionApproveDealPost';
//import DiscussionApproveDealNotification from './components/DiscussionApproveDealNotification';


export default function() {                   
  app.postComponents.discussionFeatured = DiscussionFeaturedPost;
  app.postComponents.discussionoffer =  DiscussionOfferPost;
  app.notificationComponents.discussionFeatured = DiscussionFeaturedNotification;
  //app.postComponents.discussionHotDeal = DiscussionHotDealPost;
  //app.notificationComponents.discussionHotDeal = DiscussionHotDealNotification;
  //app.postComponents.discussionExpired = DiscussionExpiredPost;
  //app.notificationComponents.discussionExpired = DiscussionExpiredNotification;
  //app.postComponents.discussionApproveDeal = DiscussionApproveDealPost;
  //app.notificationComponents.discussionApproveDeal = DiscussionApproveDealNotification;


  Discussion.prototype.isFeatured = Model.attribute('isFeatured');
  Discussion.prototype.canFeatured = Model.attribute('canFeatured');
  Discussion.prototype.isOffer = Model.attribute('isOffer');
  Discussion.prototype.canOffer = Model.attribute('canOffer');
  Discussion.prototype.isHotDeal = Model.attribute('isHotDeal');
  Discussion.prototype.canHotDeal = Model.attribute('canHotDeal');
  Discussion.prototype.isExpired = Model.attribute('isExpired');
  Discussion.prototype.canExpired = Model.attribute('canExpired');
  Discussion.prototype.isApproveDeal = Model.attribute('isApproveDeal');
  Discussion.prototype.canApprove = Model.attribute('canApprove');
  Discussion.prototype.isOrderdate = Model.attribute('isOrderdate');
  Discussion.prototype.canOrderdate = Model.attribute('canOrderdate');
  Discussion.prototype.isrequestFeatureddeal = Model.attribute('isrequestFeatureddeal');
  Discussion.prototype.canrequestFeatureddeal = Model.attribute('canrequestFeatureddeal');
  Discussion.prototype.isrequestSuperdeal = Model.attribute('isrequestSuperdeal');
  Discussion.prototype.canrequestSuperdeal = Model.attribute('canrequestSuperdeal');
  
  addFeaturedControl();
  addExpiredControl();
  addHotDealControl();
  addOfferControl(); 
  addEditControl();
  addApproveDealControl();
  addOrderdate();
  requestFeaturedControl();
  requestSuperdealControl();

  extend(NotificationGrid.prototype, 'notificationTypes', function (items) {
    items.add('discussionFeatured', {
      name: 'discussionFeatured',
      icon: 'thumbs-up',
      label: 'Someone Features a discussion I started'
    });
    items.add('discussionHotDeal', {
      name: 'discussionHotDeal',
      icon: 'comments',
      label: 'Someone HotDeals a discussion I started'
    });
     items.add('discussionOffer', {
      name: 'discussionOffer',
      icon: 'thumbs-up',
      label: 'Someone Offer a discussion I started'
    });
    
    
    
  });
  
  
};
