import EventPost from 'flarum/components/EventPost';

export default class DiscussionOfferPost extends EventPost {
  icon() {
    return this.props.post.content().offer
      ? 'offer'
      : 'unOffer';
  }

  descriptionKey() {
    return this.props.post.content().offer
      ? 'offer the discussion.'
      : 'unoffer the discussion.';
  }
}
