import { extend } from "flarum/extend";
import DiscussionListItem from "flarum/components/DiscussionListItem";
import DiscussionList from "flarum/components/DiscussionList";
import Button from "flarum/components/Button";
import DisplayProductDetails from "./DisplayProductDetails";
import DisplaycouponDetails from "./DisplaycouponDetails";
//import vote_bar from "tda/discussionVotes/components/vote_bar";
import DisplayDiscussionDeals from "./DisplayDiscussionDeals";
import DisplaySimilarDeals from "./DisplaySimilarDeals";

export default function () {
  DisplayProductDetails();
  DisplaycouponDetails();
  DisplayDiscussionDeals();
  DisplaySimilarDeals();

  extend(DiscussionListItem.prototype, "init", function (vdom) {
    if (!this.dealcardVisible) this.dealcardVisible = false;
  });
  extend(DiscussionList.prototype, "init", function (vdom) {
    if (!this.dealcardVisible) this.dealcardVisible = false;
  });
  extend(DiscussionListItem.prototype, "infoItems", function (items) {
    const discussion = this.props.discussion;
    this.superDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_superDeal.png";
    this.CouponDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_coupon.png";
    this.dealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_deal.png";
    this.featuredDealRibbon =
      app.forum.data.attributes.baseUrl +
      "/assets/images/ribbon_featuredDeal.png";
    this.ExpiredDealRibbon =
      app.forum.data.attributes.baseUrl + "/assets/images/ribbon_expired.png";

    var ribbon = "";
    var blockColour = "";

    var votes_discussion = discussion.data.attributes.votes_discussion;
    //items.add("myVoteBar", vote_bar.component({ discussion }), 600);
    if (discussion.data.attributes.isExpired) {
      //ribbon = this.ExpiredDealRibbon;
      ribbon = "Expired";
      this.color = "border-radius: 3px;display:inline;background-color: #888;text-align:right;right: 0;max-width: 135px;color: white;text-align: center;padding: 3px 5px 3px 5px;font-size: 0.9em;z-index: 50;";
      blockColour = "cc_expiredBlock cc_10chars2lines";
    } else {
      if (discussion.data.attributes.discussionType == "d") {
        if (discussion.data.attributes.isFeatured) {
          //ribbon = this.featuredDealRibbon;
          ribbon = "Value for Money";
          this.color = "border-radius: 3px;display:inline;background-color: #f16e00;text-align:right;right: 0;max-width: 135px;color: white;text-align: center;padding: 3px 5px 3px 5px;font-size: 0.9em;z-index: 50;";
          blockColour = "cc_featuredDealBlock cc_10chars2lines";
        } else if (discussion.data.attributes.isHotDeal) {
          //ribbon = this.superDealRibbon;
          ribbon = "Super Deal";
          this.color = "border-radius: 3px;display:inline;background-color: #ca2128;text-align:right;right: 0;max-width: 135px;color: white;text-align: center;padding: 3px 5px 3px 5px;font-size: 0.9em;z-index: 50;";
          blockColour = "cc_superDealBlock cc_10chars2lines";
        } else {
          //ribbon = this.dealRibbon;
          ribbon = "Deal";
          this.color = "border-radius: 3px;display:inline;background-color: #5292ab;text-align:right;right: 0;max-width: 135px;color: white;text-align: center;padding: 3px 5px 3px 5px;font-size: 0.9em;z-index: 50;";
          blockColour = "cc_dealBlock cc_10chars2lines";
        }
      } else if (discussion.data.attributes.discussionType == "c") {
        //ribbon = this.CouponDealRibbon;
        ribbon = "Coupon";
        this.color = "border-radius: 3px;display:inline;background-color: #b206ab;text-align:right;right: 0;max-width: 135px;color: white;text-align: center;padding: 3px 5px 3px 5px;font-size: 0.9em;z-index: 50;";
        blockColour = "cc_couponBlock cc_10chars2lines";
      }
    }
    items.add(
      "ProductImage",
      <div class="myDiscussionProductImage">
        <div class="cc_imageFrameForum">
          <span class="cc_imageHelper"></span>
          <img
            className="sm_img lazy"
            src={discussion.data.attributes.imageUrl_sm}
          />
        </div>
      </div>
    );
    
    if (discussion.data.attributes.discussionType == "d") {
      items.add(
        "ProductDetail",
        <div>
          <div class="" style={this.color}>
            {ribbon}
          </div> 
          <div class="cc_forumBuyShow" style="display: inline-block;">
            {Button.component({
              className: "Button Button--label Button--more",
              children: "Get Deal",
              icon: "shopping-cart",
              onclick: (e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(discussion.data.attributes.url);
              },
            })}
          </div>
          <div style="display: inline-block;margin-left:8px;">
            <span style="text-decoration: line-through; color: #AAA;">
              {app.translator.trans(
                "flarum-myindex.lib.number_suffix.currency"
              )}
              {discussion.data.attributes.maxPrice}
            </span>
            &nbsp;&nbsp;&nbsp;
            <span style="color: #C20000;">
              {app.translator.trans(
                "flarum-myindex.lib.number_suffix.currency"
              )}
              {discussion.data.attributes.currentPrice}/- &nbsp;
            </span>
          </div>
          <div class={blockColour}>
            {discussion.data.attributes.badgeText != ""
              ? m(
                  "span",
                  { class: "blockColourSpan" },
                  discussion.data.attributes.badgeText.length > 10
                    ? discussion.data.attributes.badgeText.slice(0, 10) +
                        (discussion.data.attributes.badgeText.length > 10
                          ? "..."
                          : "")
                    : discussion.data.attributes.badgeText
                )
              : ""}
          </div>
        </div>
      );
    }
    if (discussion.data.attributes.discussionType == "c") {
      if (discussion.data.attributes.badgeText != "") {
        items.add(
          "ProductDetail",
          <div style="display:inline;">
            <div class="" style={this.color}>
            {ribbon}
          </div>
          <div class={blockColour} style="height:40px">
            {discussion.data.attributes.badgeText != ""
              ? m(
                  "span",
                  { class: "blockColourSpan" },
                  discussion.data.attributes.badgeText.length > 6
                    ? discussion.data.attributes.badgeText.slice(0, 6) +
                        (discussion.data.attributes.badgeText.length > 6
                          ? "..."
                          : "")
                    : discussion.data.attributes.badgeText
                )
              : ""}
          </div></div>
        );
      }
      items.add(
        "buyNow",
        Button.component({
          id: "btncopycode",
          className: "Button Button--label Button--more",
          icon: "eye",
          style: "line-height: 8px;margin-left:2px",
          children: "Show",
          onmouseover: (e) => {
            $(e.currentTarget).text(discussion.data.attributes.couponcode);
          },
          onmouseout: (e) => {
            $(e.currentTarget).text("Show");
          },
        })
      );
    }
  });
}
