import Component from "flarum/Component";
import slidable from "flarum/utils/slidable";
import CouponExpiry from "./storePages/CouponExpiry";
import humanTime from "flarum/utils/humanTime";
import Badge from "flarum/components/Badge";
import app from "flarum/app";
import countdown from "jquery-countdown";
import WishlistModal from "./modal/WishlistModal";
import NewLoginModal from "./modal/NewLogin";

export default class AllDealCardMini extends Component {
  //used to display deal card on DealDiscussionList(All Deals),CategoryListData(on home page),DealsListData(on home page),DealResults(search)
  init() {
    this.noImageFound =
      app.forum.data.attributes.baseUrl + "/assets/images/no_image_found.png";
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/deal_expired.png";
    this.title = m.prop(this.props.DiscussionData.title || "");
    this.maxPrice = m.prop(this.props.DiscussionData.maxPrice || "");
    this.currentPrice = m.prop(this.props.DiscussionData.currentPrice || "");
    this.discount = m.prop(this.props.DiscussionData.discount || "");
    this.currency = m.prop(this.props.DiscussionData.currency || "");
    this.url = m.prop(this.props.DiscussionData.url || "");
    this.productName = m.prop(this.props.DiscussionData.productName || "");
    this.isFeatured = m.prop(this.props.DiscussionData.isFeatured || "");
    this.isHotDeal = m.prop(this.props.DiscussionData.isHotDeal || "");
    this.isExpired = m.prop(this.props.DiscussionData.isExpired || "");
    this.startTime = m.prop(this.props.DiscussionData.created_at || "");
    this.imageUrl_sm = m.prop(this.props.DiscussionData.imageUrl_sm || "");
    var urlcontent = this.imageUrl_sm().split('/');
    if (urlcontent[0] == "http:") {
      urlcontent[0] = "https:";
    }
    this.imageUrl = urlcontent.join('/');
    this.startUser = m.prop(this.props.DiscussionData.startUser || "");
    this.validFrom = m.prop(this.props.DiscussionData.validFrom || "");
    this.validTo = m.prop(this.props.DiscussionData.validTo || "");
    this.validFor = m.prop(this.props.DiscussionData.validFor || "");
    this.badgeText = m.prop(this.props.DiscussionData.badgeText || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    this.tags = m.prop(this.props.DiscussionData.tags || "");
    this.customBadge = null;
    if (this.badgeText()) {
      this.customBadge = this.badgeText();
    } else if (this.discount() > 0) {
      this.customBadge = this.discount() + "% Off";
    }
    this.validTo_Expired = false;
    this.pastExpired = false;
    this.discussionRoute =
      app.forum.data.attributes.baseUrl + "/d/" + this.discussionID();
    this.storeimagepath = "";
    this.secondarytag = m.prop(this.props.DiscussionData.secondarytag || "");
    this.dealtag = "";
    if (this.isHotDeal() == 1) {
      this.dealtag = "Super Deal";
      this.color = "background-color: Red";
    } else if (this.isFeatured() == 1) {
      this.dealtag = "Value For Money";
      this.color = "background-color: orange";
    } else {
      this.dealtag = "Deal";
      this.color = "background-color: grey";
    }
    this.routename = m.prop(this.props.DiscussionData.routename || "");
    this.start_user_id = m.prop(this.props.DiscussionData.start_user_id || "");
    this.refreshdate = m.prop(this.props.DiscussionData.refreshdate || "");
  }
  setCounDown(setDate, element, startText, endText, textColor) {
    $(element).show();
    $(element).css("color", textColor);
    $(element)
      .countdown(setDate)
      .on("update.countdown", function (event) {
        var format = "%H:%M:%S";
        if (event.offset.totalDays > 0) {
          format = "%-d day%!d " + format;
        }
        if (event.offset.weeks > 0) {
          format = "%-w week%!w " + format;
        }
        $(this).html(startText + event.strftime(format));
      })
      .on("finish.countdown", function (event) {
        $(this).html(endText).parent().addClass("disabled");
        $(this).css("color", textColor);
      });
  }
  checkFutureTime(myTime) {
    var checkTime = new Date(myTime).getTime();
    var now = new Date().getTime();
    if (checkTime > now) {
      return true;
    } else {
      return false;
    }
  }
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  view() {
    let disRefreshDate = new Date(this.refreshdate());
    var endDateForStamp = this.validTo();
    if (endDateForStamp != "0000-00-00 00:00:00") {
      if (CouponExpiry(this.validTo()) == "past") {
        this.validTo_Expired = true;
      }
    }
    if (this.isExpired() == "1") {
      this.validTo_Expired = true;
    }

    var tags = this.tags();
    if (tags)
      tags = tags = this.tags().filter(
        (model) =>
          model["parent"]() === app.store.getBy("tags", "slug", "stores")
      );
    else tags = this.tags();
    var gettag_image = app.store.getBy(
      "tags",
      "slug",
      this.props.DiscussionData.tag_slug
    );
    this.storeimagepath = gettag_image
      ? gettag_image.data.attributes.imagePath
      : "";
    var storeName = gettag_image ? gettag_image.data.attributes.name : "";
    this.storeName = storeName;

    const link =
      app.forum.data.attributes.baseUrl +
      "/Deals/category?size=0&categories=category&stores=" +
      storeName;
    let avatar_path = "";
    if (this.props.DiscussionData.avatar_path) {
      avatar_path =
        app.forum.data.attributes.baseUrl +
        "/assets/avatars/" +
        this.props.DiscussionData.avatar_path;
    }
    const user_url =
      app.forum.data.attributes.baseUrl +
      "/u/" +
      this.props.DiscussionData.username;
    return m(
      "div",
      { class: "dealCard_mini", config: this.configArea.bind(this) },
      <div className={"DiscussionListItem-content"}>
        <div>
          {this.customBadge ? (
            <div class="cc_hotness_DCM">{this.customBadge}</div>
          ) : (
            ""
          )}
        </div>
        <div
          class="cc_productBlock_mini cc_borderRightBottom"
          itemscope
          itemtype="http://schema.org/Product"
        >
          {this.start_user_id() !== ""
            ? this.startUserShow()
            : $(".noUser").css("margin-top", "0px")}
          <div class="noUser"></div>
          <a href={this.discussionRoute} config={m.route}>
            <div
              class="cc_imageBox"
              style={
                gettag_image
                  ? gettag_image.data.attributes.name == "TataCliq"
                    ? ""
                    : ""
                  : ""
              }
            >
              <div class="cc_imageFrameDealPage">
                {this.imageUrl ? (
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={this.imageUrl}
                    alt={storeName}
                    onError={"this.src='" + this.storeimagepath + "'"}
                  />
                ) : (
                  <img
                    itemprop="image"
                    className="sm_img lazy"
                    src={this.storeimagepath}
                    alt={storeName}
                  />
                )}
              </div>
            </div>
          </a>
          <div class="cc_detailsBox_mini">
            <a href={this.discussionRoute} config={m.route}>
              <div
                itemprop="offers"
                itemscope
                itemtype="http://schema.org/Offer"
              >
                {this.maxPrice() != this.currentPrice() ? (
                  <div class="cc_offerText_DCM">
                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    {this.maxPrice()}
                  </div>
                ) : (
                  <div class="cc_offerText"></div>
                )}
                {this.currentPrice() ? (
                  <div class="cc_productPrice_DCM">
                    <i
                      itemprop="priceCurrency"
                      content={app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                    ></i>

                    {app.translator.trans(
                      "flarum-myindex.lib.number_suffix.currency"
                    )}
                    {!this.validTo_Expired == true ? (
                      <span itemprop="price">{this.currentPrice()}/-</span>
                    ) : (
                      <span
                        itemprop="price"
                        style="text-decoration: line-through; "
                      >
                        {this.currentPrice()}/-
                      </span>
                    )}
                  </div>
                ) : (
                  <div class="cc_productPrice"></div>
                )}
                <span
                  itemprop="availability"
                  href="http://schema.org/InStock"
                />
                <span
                  itemprop="offeredBy"
                  itemscope
                  itemtype="http://schema.org/Organization"
                >
                  <span itemprop="legalName" content={storeName} />
                </span>
                <div
                  style="display: inline-block;padding-left: 6px;"
                  class="miniInfoIcon"
                >
                  <Badge
                    data-html="true"
                    type="hidden"
                    icon="fa fa-info"
                    label={
                      "Price as of " +
                      disRefreshDate +
                      "<br/>Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on " +
                      storeName +
                      " at the time of purchase will apply to the purchase of this product."
                    }
                  />
                </div>
              </div>
              <div class="cc_titleBox_DCM">
                <h3 class="cc_titleText_DCM" itemprop="name">
                  {this.title()}
                </h3>
              </div>
            </a>
            <div class="cc_bottomBar_DCM">
              <div class="cc_siteIcons_DCM">
                <span class="TagsLabel ">
                  <a
                    class=""
                    title={
                      "Explore All " +
                      storeName +
                      " Coupons, Deals and Offers Below."
                    }
                    href={link}
                  >
                    <span class="TagLabel-text">
                      {this.storeimagepath ? (
                        <img
                          src={this.storeimagepath}
                          alt={storeName}
                          height="16.5em"
                          onError={"javascript:this.style.display=none;"}
                        />
                      ) : storeName == "Others" || storeName == "" ? (
                        <img
                          src="/assets/favicon-dgykxiee.png"
                          height="16.5em"
                        />
                      ) : (
                        storeName
                      )}
                    </span>
                  </a>
                </span>
              </div>
              {this.url() ? (
                !this.validTo_Expired == true ? (
                  <div class="dealCardMini_Btn">
                    <a
                      class="button button-sm btn-danger cc_buyNowBtn_mini"
                      href={this.url() + "&store=" + storeName}
                      target="_blank" rel="nofollow"
                    >
                      GET DEAL
                    </a>
                  </div>
                ) : (
                  <div class="dealCardMini_Btn">
                    <a
                      class="Button hasIcon"
                      href={this.url()}
                      target="_blank"
                      style="background-color: #888;border: 1px solid #585858;padding: 0px 8px;border-radius: 35px;color: #fff !important"
                    >
                      Deal Expired
                    </a>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
            <div
              class="cc_expiryText"
              config={this.setCountDownTimer.bind(this)}
            >
              {!this.validTo_Expired == true ? (
                <div class="countdown" id={this.discussionID()}></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <a href={this.discussionRoute} config={m.route}>
          {!this.validTo_Expired == true ? (
            ""
          ) : (
            <div class="cc_expiredStamp_mini">
              <img
                className="sm_img lazy"
                src={this.expiredStamp}
                alt="expired Stamp"
              />
            </div>
          )}
        </a>
        <div class="cc_secondarytag">
          {this.secondarytag != "" ? <div>{this.secondarytag()}</div> : ""}
        </div>
        {this.routename() == "search" ||
          this.routename() == "myProduct" ||
          this.routename() == "Category" ? (
          this.dealtag != "" ? (
            <div class="cc_dealtag" style={this.color}>
              <div>{this.dealtag}</div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {/* <div class="cc_addbookmark">
          <button id="bookmark" value={this.discussionID()} data-cmd="yes" class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" style=" background: none !important;" onclick={this.addBookmark.bind(this)}><i class="far fa-bookmark" aria-hidden="true"></i></button>
        </div>
        <div class="cc_button pull-right">
          <button style="display:none;" id={this.discussionID()} class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.Compareproduct.bind(this)}><i class="fa fa-bell-o" aria-hidden="true"></i> Add To Compare</button>
        </div> */}
      </div>
    );
  }
  configArea(element, isInitialized) {
    $(".hotDealBar").show();
    $(".popularBar").show();
  }
  setCountDownTimer(element, isInitialized) {
    var FromStartText = "Starts in: ";
    var FromEndText = "This deal has started!";
    var ToStartText = "Ends in: ";
    var ToEndText = "This deal has expired!";

    var startsIn = this.validFrom().replace("T", " ");
    var endsIn = this.validTo().replace("T", " ");
    if (
      startsIn != "0000-00-00 00:00:00" &&
      this.checkFutureTime(startsIn) &&
      startsIn
    ) {
      this.setCounDown(
        startsIn.replace(".000Z", ""),
        element,
        FromStartText,
        FromEndText,
        "#00B504"
      );
    } else if (
      endsIn != "0000-00-00 00:00:00" &&
      this.checkFutureTime(endsIn) &&
      endsIn
    ) {
      this.setCounDown(
        endsIn.replace(".000Z", ""),
        element,
        ToStartText,
        ToEndText,
        "#C20000"
      );
    }
  }
  config(isInitialized) {
    if (isInitialized) return;


  }

  startUserShow() {
    const startUser = this.props.DiscussionData.username;
    let avatar_path = "";
    if (this.props.DiscussionData.avatar_path) {
      avatar_path =
        app.forum.data.attributes.baseUrl +
        "/assets/avatars/" +
        this.props.DiscussionData.avatar_path;
    }
    return (
      <a
        href={
          startUser
            ? app.forum.data.attributes.baseUrl + "/u/" + startUser
            : "#"
        }
        className="DiscussionListItem-author"
        title={startUser + " started " + humanTime(this.startTime())}
        config={function (element) {
          $(element).tooltip({ placement: "right" });
          m.route.apply(this, arguments);
        }}
      >
        {avatar_path ? (
          <img
            class="Avatar "
            src={avatar_path}
            alt={this.props.DiscussionData.username.charAt(0)}
          />
        ) : (
          <span class="Avatar " style="background: rgb(160, 229, 207);">
            {this.props.DiscussionData.username.charAt(0)}
          </span>
        )}
      </a>
    );
  }

  // addBookmark(data){
  //   if(app.session.user){
  //     if(data.path[1].value == this.props.DiscussionData.id){
  //       document.getElementById(this.props.DiscussionData.id).style.display = "block";
  //     }
  //   }else{
  //     app.modal.show(new NewLoginModal());
  //   }
  // }

  // Compareproduct(data){
  //   var product = this.props.DiscussionData;
  //   app.modal.show(
  //     new WishlistModal({product})
  //   );
  // }

  // addBookmark(data) {
  //   if (app.session.user) {
  //     var Data = [];
  //     var curPrice = this.currentPrice();
  //     var tid = "";
  //     var title = this.title();
  //     var userId = app.session.user.data.id;
  //     var WishListName = "General";
  //     this.userinput = "";
  //     var Alertproduct = "No";
  //     var id = this.discussionID();
  //     var Store = this.storeName;
  //     Data.push({ userId: userId, tid: tid, OldPrice: curPrice, title: title, WishListName: WishListName, UserInput: this.userinput, Alertproduct: Alertproduct, Id: id, Store: Store });

  //     if ($('#bookmark').attr('data-cmd') == "yes" && data.path[1].value == this.props.DiscussionData.id) {
  //       const Api_url = app.forum.attribute('baseUrl') + '/api/productalert';
  //       return app.request({
  //         method: 'POST',
  //         url: Api_url,
  //         data: Data,
  //       }).then(
  //         this.parseResults.bind(this),
  //       );
  //     } else {
  //       document.getElementById(this.props.DiscussionData.id).style.display = "none";
  //       $('#bookmark').attr('data-cmd', "yes");
  //     }
  //   } else {
  //     app.modal.show(new NewLoginModal());
  //   }
  // }

  // Compareproduct(data) {
  //   var product = this.props.DiscussionData;
  //   app.modal.show(
  //     new WishlistModal({ product })
  //   );
  // }

  // parseResults(result) {
  //   if (result.data.attributes.message == "inserted") {
  //     document.getElementById(this.discussionID()).style.display = "block";
  //     document.getElementById(this.discussionID()).innerHTML = 'Added to WishList <i class="fa fa-bell-o" aria-hidden="true"></i>';
  //     $('#bookmark').attr('data-cmd', "no");
  //   } else if (result.data.attributes.message == "Record Already Insearted") {
  //     document.getElementById(this.discussionID()).style.display = "block";
  //     document.getElementById(this.discussionID()).innerHTML = 'Already Added to Your WishList. <i class="fa fa-bell-o" aria-hidden="true"></i>';
  //     $('#bookmark').attr('data-cmd', "no");
  //   }
  // }
}
