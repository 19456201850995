import Component from "flarum/Component";
import Button from "flarum/components/Button";
import DealCardMini from "./DealCardMini";
import SlickSlider from "./SlickmainSlider";
import DealPlaceHolder from "./DealPlaceHolder";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
export default class DealsListSlider extends Component {
  //used for Store page
  init() {
    this.loading = true;
    this.moreResults = false;
    this.heading = this.props.params.heading;
    this.headingIcon = this.props.params.headingIcon;
    this.type = this.props.params.type ? this.props.params.type : "deal";
    this.discussions = null;
    this.discussions = [];
    this.DealList = [];
    this.slidesToShowDesktop = this.props.slidesToShowDesktop;
    this.slidesToScrollDesktop = this.props.slidesToScrollDesktop;
    this.color = this.props.params.color ? this.props.params.color : "#111";
    this.headingUnderline = this.props.params.headingUnderline ? true : false;
    this.refresh();
  }

  view() {
    const params = this.props.params;
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    if (this.discussions.length === 0 && !this.loading) {
      const text = app.translator.trans(
        "core.forum.discussion_list.empty_text"
      );
      return <div className="DiscussionList"></div>;
    }
    this.DealList = this.discussions;

    return (
      <div>
        <div
          class="sliderHeading"
          style={
            "color:" +
            this.color +
            (this.headingUnderline == true
              ? ";border-bottom:1px solid " + this.color
              : "")
          }
        >
          <h2 class="sliderTitle">
            <i class={this.headingIcon} aria-hidden="true"></i>
            {this.heading}
          </h2>
        </div>
        <div>
          {SlickSlider.component({
            slidesToShowDesktop: this.slidesToShowDesktop,
            slidesToScrollDesktop: this.slidesToScrollDesktop,
            children: this.loading
              ? loading
              : this.DealList.reduce(function (result, discussion) {
                  var DiscussionData = {
                    title: discussion.data.attributes.title,
                    maxPrice: discussion.data.attributes.maxPrice,
                    currentPrice: discussion.data.attributes.currentPrice,
                    discount: discussion.data.attributes.discount,
                    ////currency : discussion.data.attributes.currency,
                    url: discussion.data.attributes.url,
                    productName: discussion.data.attributes.productName,
                    isFeatured: discussion.data.attributes.isFeatured,
                    isHotDeal: discussion.data.attributes.isHotDeal,
                    isExpired: discussion.data.attributes.isExpired,
                    startTime: discussion.data.attributes.startTime,
                    imageUrl_sm: discussion.data.attributes.imageUrl_sm,
                    validFrom: discussion.data.attributes.validFrom,
                    validTo: discussion.data.attributes.validTo,
                    validFor: discussion.data.attributes.validFor,
                    badgeText: discussion.data.attributes.badgeText,
                    discussionType: discussion.data.attributes.discussionType,
                    //startUser: discussion.startUser(),
                    id: discussion.id(),
                    tags: discussion.tags(),
                    couponcode: discussion.data.attributes.couponcode,
                    secondarytag: discussion.data.attributes.secondarytag,
                    refreshDate: discussion.data.attributes.refreshDate,
                  };
                  if (!discussion.isHidden()) {
                    result.push(
                      DiscussionData.discussionType == "c"
                        ? DealCardMini.component({ DiscussionData })
                        : DealCardMini.component({ DiscussionData })
                    );
                  }
                  return result;
                }, []),
          })}
        </div>
        <div style="clear:both"></div>
      </div>
    );
  }

  /**
   * Get the parameters that should be passed in the API request to get
   * discussion results.
   *
   * @return {Object}
   * @api
   */
  requestParams() {
    const params = this.props.params.filter;
    params.sort = this.sortMap()[this.props.params.sort];
    return params;
  }
  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  /**
   * Get a map of sort keys (which appear in the URL, and are used for
   * translation) to the API sort value that they represent.
   *
   * @return {Object}
   */
  sortMap() {
    const map = {};
    if (this.props.params.q) {
      map.relevance = "";
    }
    map.latest = "-lastTime";
    map.top = "-commentsCount";
    map.newest = "-startTime";
    map.oldest = "startTime";
    return map;
  }

  /**
   * Clear and reload the discussion list.
   *
   * @public
   */
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.discussions = [];
    }

    return this.loadResults().then(
      (results) => {
        this.discussions = [];
        this.parseResults(results);
        m.redraw();
      },
      () => {
        this.loading = false;
        m.redraw();
      }
    );
  }

  /**
   * Load a new page of discussion results.
   *
   * @param {Integer} offset The index to start the page at.
   * @return {Promise}
   */
  loadResults(offset) {
    const params = this.requestParams();
    params.page = { offset };
    params.include = params.include.join(",");
    return app.store.find("discussions", params);
  }

  loadMore() {
    this.loading = true;
    this.loadResults(this.discussions.length).then(
      this.parseResults.bind(this)
    );
  }
  /**
   * Parse results and append them to the discussion list.
   *
   * @param {Discussion[]} results
   * @return {Discussion[]}
   */
  parseResults(results) {
    [].push.apply(this.discussions, results);
    this.loading = false;
    this.moreResults = !!results.payload.links.next;
    m.lazyRedraw();
    return results;
  }

  /**
   * Remove a discussion from the list if it is present.
   *
   * @param {Discussion} discussion
   * @public
   */
  removeDiscussion(discussion) {
    const index = this.discussions.indexOf(discussion);

    if (index !== -1) {
      this.discussions.splice(index, 1);
    }
  }

  /**
   * Add a discussion to the top of the list.
   *
   * @param {Discussion} discussion
   * @public
   */
  addDiscussion(discussion) {
    this.discussions.unshift(discussion);
  }
}
