import Component from "flarum/Component";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import priceGraph from "./priceGraph";
import Button from "flarum/components/Button";
import SimilarProductsSlider from "./productdisplay/SimilarProductsSlider";
import CompareProduct from "./CompareProduct";
import humanTime from "flarum/utils/humanTime";
import Badge from "flarum/components/Badge";
export default class ProductDetail extends Component {
  //used to display single product
  init() {
    super.init();
    this.loading = true;
    this.product = [];
    this.count = 1;
    this.priceflag = false;
    this.tid = this.props.tid;
    this.getProductDetails();
    this.comparetitle = this.props.title;
  }
  view() {
    if (this.loading) {
      loading = LoadingIndicator.component();
      return <div>{loading}</div>;
    }
    let loading;
    const product = this.product;
    this.productprice = this.product;
    this.features = this.product["0"].attributes.Features
      ? this.product["0"].attributes.Features
      : [];
    this.productid = this.product["0"].attributes.id
      ? this.product["0"].attributes.id
      : "";
    this.mrp = this.product["0"].attributes.maxPrice
      ? this.product["0"].attributes.maxPrice
      : 0;
    this.title = this.product["0"].attributes.title
      ? this.product["0"].attributes.title
      : "";
    this.brand = this.product["0"].attributes.brand
      ? this.product["0"].attributes.brand
      : "";
    this.description = this.product["0"].attributes.description
      ? this.product["0"].attributes.description
      : "";
    this.imageURL = this.product["0"].attributes.imageUrl_sm
      ? this.product["0"].attributes.imageUrl_sm
      : "";
    this.merchantname = this.product["0"].attributes.merchantName
      ? this.product["0"].attributes.merchantName.toLowerCase()
      : "";
    this.tags = app.store.getBy("tags", "slug", this.merchantname);
    this.pid = this.product["0"].attributes.pid
      ? this.product["0"].attributes.pid
      : "";
    this.productURL = this.product["0"].attributes.productURL
      ? this.product["0"].attributes.productURL
      : "";
    this.linkid = this.product["0"].attributes.linkid.$oid;
    this.productOutOfStock = this.product["0"].attributes.productOutOfStock;
    this.discount = this.product["0"].attributes.discount;
    this.url =
      app.forum.data.attributes.baseUrl +
      "/blossom?bid=" +
      this.linkid +
      "&url=" +
      this.productURL +
      "&src=product";
    this.PriceTracker = this.product["0"].attributes.PriceTracker
      ? this.product["0"].attributes.PriceTracker
      : "";
    this.sellingPrice = this.PriceTracker[
      Object.keys(this.PriceTracker)[Object.keys(this.PriceTracker).length - 1]
    ];
    var CategoryPath = this.product[0].attributes.tdaCategory
      ? this.product[0].attributes.tdaCategory
      : "";
    var PathArr = [];
    this.LastUpdatedOn = this.product[0].attributes.LastUpdatedOn.$date.$numberLong;
    this.storeimagepath = this.tags.data.attributes.imagePath;
    this.TDAProductId = this.product[0].attributes.TDAProductId;
    this.tid = this.product[0].attributes.tid;
    this.Selling_Price = this.product["0"].attributes.sellingPrice
      ? this.product["0"].attributes.sellingPrice
      : 0;
    this.Hotness = this.product[0].attributes.Hotness
      ? this.product[0].attributes.Hotness
      : 0;
    let MerchantName = this.product["0"].attributes.merchantName
      ? this.product["0"].attributes.merchantName
      : "";
    //start for Breadcrum
    if (CategoryPath.length > 0) {
      $.each(CategoryPath, function (index, value) {
        if (value.tdaCategoryPath != null) {
          PathArr.push(value.tdaCategoryPath.split(">"));
        }
      });
      var max = PathArr[0] ? PathArr[0].length : "";
      for (var i = 1; i < PathArr.length; i++) {
        if (PathArr[i].length > max) {
          max = PathArr[i].length;
          var indi = i;
        }
      }
      if (
        indi === undefined &&
        this.product[0].attributes.tdaCategory[0].tdaCategoryPath !==
          "Undefined"
      ) {
        this.tdaCategoryPath = this.product[0].attributes.tdaCategory[0].tdaCategoryPath;
      } else {
        this.tdaCategoryPath = this.product[0].attributes.tdaCategory[indi]
          ? this.product[0].attributes.tdaCategory[indi].tdaCategoryPath
          : "";
      }
    } else {
      this.tdaCategoryPath = "";
    }
    //end Breadcrum
    this.brandname = this.product[0].attributes.brand;
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/out_of_stock.png";
    this.outOfStock =
      app.forum.data.attributes.baseUrl +
      "/assets/images/out_of_stock-corner.png";
    this.topprice = [];
    this.lowprice = [];
    const prizeSum = [];
    var total = 0;
    //start make a new average  prize start harshal
    this.PriceTracker.map((value) => {
      prizeSum.push((total += value.selling_price));
    });
    var last = prizeSum[prizeSum.length - 1];
    this.averagevalue = last / this.PriceTracker.length;
    //end  new average  prize
    //start display lowest price+
    this.product.map((value) => {
      var sprice =
        value.attributes.PriceTracker[value.attributes.PriceTracker.length - 1];
      var id = value.attributes.id;
      var tid = value.attributes.tid;
      var sellpricing = sprice.selling_price;
      if (sellpricing != 0) {
        this.lowprice.push({
          id: id,
          topsellPrice: sellpricing,
          tid: tid,
          PriceTracker: value.attributes.PriceTracker,
        });
      }
      this.topprice.push({
        id: id,
        topsellPrice: sellpricing,
        tid: tid,
        PriceTracker: value.attributes.PriceTracker,
      });
    });
    var topminimum = Math.min.apply(
      Math,
      this.lowprice.map(function (i, o) {
        return i.topsellPrice;
      })
    );
    var toppos = this.lowprice.findIndex((x) => x.topsellPrice === topminimum);
    this.topid = this.lowprice.length > 0 ? this.lowprice[toppos].id : "";
    var topSellingPrice =
      this.lowprice.length > 0 ? this.lowprice[toppos].topsellPrice : 0;
    //end display lowest price
    var tags = this.tags;
    //Start For Caluation of 3 days old product, 30 minutes old product and between 3 days & 30 minutes
    var fromDate = this.LastUpdatedOn;
    var updatedDate = new Date(parseInt(this.LastUpdatedOn)).toISOString();
    var displayDate = new Date(parseInt(this.LastUpdatedOn)).toString();
    var toDate = new Date().getTime();
    var timeDiff = (toDate / 1000 - fromDate / 1000) / 3600; // will give difference in hrs
    var minutes = ((toDate - fromDate) / (60 * 1000)) % 60; // will give difference in minutes
    var calculatetime = timeDiff.toFixed(0) + "." + minutes.toFixed(0);
    if (timeDiff > 72) {
      if (this.count <= 1) {
        this.refreshPrices(this.topprice);
        this.count++;
      }
    }
    if (calculatetime <= 72 && calculatetime > 0.3) {
      $("#refresh_button").hide();
      $("#refresh_button_load").show();
    }
    //end For Caluation of 3 days old product, 30 minutes old product and between 3 days & 30 minutes
    //Start Sort the Products inside the table on the basis of price
    var pricesort = [];
    this.product
      .filter(function (value) {
        return value;
      })
      .sort(function (a, b) {
        for (var s = 0; s < a.attributes.PriceTracker.length; s++) {
          return (
            a.attributes.PriceTracker[a.attributes.PriceTracker.length - 1]
              .selling_price -
            b.attributes.PriceTracker[b.attributes.PriceTracker.length - 1]
              .selling_price
          );
        }
      })
      .map(function (value) {
        pricesort.push(value);
      });
    this.Refinepricesort = [];
    for (var d = 0; d < pricesort.length; d++) {
      if (
        pricesort[d].attributes.PriceTracker[
          pricesort[d].attributes.PriceTracker.length - 1
        ].selling_price == 0
      ) {
        this.Refinepricesort.push(pricesort[d]);
        delete pricesort[d];
      }
    }
    if (this.Refinepricesort.length > 0) {
      $.each(this.Refinepricesort, function (key, value) {
        pricesort.shift();
        pricesort.push(value);
      });
    }
    //end Sort the Products inside the table on the basis of price

    //start make a new average  prize & Lowest price
    const Sum = [];
    const lowpricesimilar = [];
    var total = 0;
    if (this.compareproduct.compareproductlist != undefined) {
      this.compareproduct.compareproductlist.map((value) => {
        Sum.push((total += value.attributes._source.sellingPrice));
        lowpricesimilar.push({
          slug: value.attributes._source.slug,
          priceselling: value.attributes._source.sellingPrice,
        });
      });
      if (this.compareproduct.compareproductlist.length != 0) {
        var last = Sum[Sum.length - 1];
        this.average = last / this.compareproduct.compareproductlist.length;
      } else {
        this.average = 0;
      }
    }
    var minimum = Math.min.apply(
      Math,
      lowpricesimilar.map(function (i, o) {
        return i.priceselling;
      })
    );
    var index = lowpricesimilar.findIndex((x) => x.priceselling === minimum);
    var slug = lowpricesimilar.length > 0 ? lowpricesimilar[index].slug : "";
    var minimumSellingPrice =
      lowpricesimilar.length > 0 ? lowpricesimilar[index].priceselling : 0;
    //end make a new average  prize & Lowest price

    const link = true;
    return (
      <div class="container cc_container">
        {this.discount != "" ? (
          <div class="cc_compareBadgeText" style="right: 5px;">
            {this.discount ? <span>{this.discount.toFixed(2)}% Off</span> : ""}
          </div>
        ) : (
          ""
        )}
       
        <div class="cc_compareDetailsHeader" style="padding-top:10px;">
          <div class="cc_compareHeaderLeft">
            <div class="cc_compareImageFrame">
              <span class="cc_compareImageHelper"></span>
              {this.imageURL ? (
                <img
                  itemprop="image"
                  src={this.imageURL.replace(/\s/g, "")}
                  alt="Product Image"
                  onError={"this.src='" + this.storeimagepath + "'"}
                />
              ) : (
                <img
                  itemprop="image"
                  src={this.storeimagepath}
                  alt="no image found"
                />
              )}
              {!this.productOutOfStock == true ? (
                ""
              ) : (
                <div class="cc_productDetailExpiredStamp">
                  <img
                    width="104px"
                    src={this.expiredStamp}
                    alt="expired Stamp"
                  />
                </div>
              )}
            </div>
          </div>
          <div class="cc_compareHeaderRight">
          <div class="breadcrum" >
              {this.tdaCategoryPath
                ? this.generateBreadCrumb(this.tdaCategoryPath)
                : ""}
          </div>
            <h2 class="cc_compareDetailTitle" itemprop="name" style="margin:0;">
              {m.trust(this.title)}
            </h2>
            {this.brandname ? (
              <div class="cc_comapretiltleHorRule">
                &nbsp;<span>By</span>
                <a
                  href={app.route(
                    "brand",
                    this.getParams("brands", this.brandname)
                  )}
                  config={m.route}
                >
                  {" "}
                  {this.brandname}
                </a>
              </div>
            ) : (
              ""
            )}
            {/* <hr class="cc_comapreHorRule" /> */}
            <span
              itemprop="availability"
              href="http://schema.org/InStock"
            ></span>
            <span
              itemprop="offeredBy"
              itemscope
              itemtype="http://schema.org/Organization"
            ></span>
            <span itemprop="legalName" content={this.merchantname}></span>
            <span class="brand" style="display: none;">
              {this.brand}
            </span>

            <div class="cc_compareHeaderTable">
              <div class="cc_compareHeaderLHS">
                <div class="cc_compareHeaderTableBox cc_comparePaddingTop cc_compareVendorSite" style="line-height:0;">
                  {this.tags ? (
                    <span class="TagsLabel ">
                      <a class="" title="" href={"/t/" + this.merchantname}>
                        <img
                          src={this.tags.data.attributes.imagePath}
                          height="20"
                        />
                      </a>
                    </span>
                  ) : (
                    this.merchantname
                  )}
                </div>
                <div class="cc_compareHeaderTableBox cc_compareListPrice">
                  <h3>Listed Price:&nbsp;</h3>
                  {this.mrp !== 0 ? (
                    <span id="lowmrp" style="text-decoration: line-through; font-size:0.8em">
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      {this.mrp}
                    </span>
                  ) : (
                    <span id="lowmrp">Not Available</span>
                  )}
                </div>
                <div class="cc_compareHeaderTableBox cc_dealTextColour cc_compareLowestPrice">
                  {topSellingPrice !== 0 ? (
                    <div style="line-height:1;">
                      <h3>Selling Price:</h3>
                      <span id="lowsellprice" itemprop="price" style="font-size:16px;">
                        {app.translator.trans(
                          "flarum-myindex.lib.number_suffix.currency"
                        )}
                        {topSellingPrice.toFixed(2)}
                      </span>
                      <div style="display: inline-block;" class="miniInfoIcon">
                        <Badge
                          type="hidden"
                          icon="fa fa-info"
                          style="padding-left:0px;"
                          label={
                            "Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on " +
                            MerchantName +
                            " at the time of purchase will apply to the purchase of this product."
                          }
                        />
                      </div>
                      <div id="refresh_button_load" style=" display:inline-block;" >
                    {Button.component({
                      icon: "refresh",
                      className: "Button",
                      onclick: this.refreshPrices.bind(this),
                      className:"icon fas fa-sync Button-icon cc_button_refresh",
                    })}
                  </div>
                  <div class="" style="display:inline-block;">
                  <div id="refresh_button" class="cc_compareRefreshBlock">
                    {timeDiff < 72 ? (
                      calculatetime <= 72 && calculatetime > 1.0 ? (
                        <p>Prices are Refreshed {humanTime(updatedDate)}</p>
                      ) : (
                        <p>Prices refreshed {humanTime(updatedDate)}</p>
                      )
                    ) : (
                      <p>
                        <i class="fa fa-spin fa-refresh"></i> We are refreshing
                        prices.. Please wait....
                      </p>
                    )}
                  </div>
                </div>
                      <br />
                      <span style="color: #626262 !important;font-size: 12px; padding-left: 0px;">
                        {displayDate
                          ? "(Price recorded as on " + displayDate + ")"
                          : ""}
                      </span>
                     
                    </div>
                  ) : (
                    <h3>Price Not Available </h3>
                  )}
                </div>
                
                {this.compareproduct == undefined ||
                this.average == 0 ||
                this.average == undefined ? (
                  ""
                ) : (
                  <div class="cc_compareHeaderTableBox cc_comparePaddingTop cc_compareAvgPrice">
                    <h3>Average Price:&nbsp;</h3>
                    <span id="averageprice" itemprop="price">
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      {parseFloat(this.average).toFixed(2)}
                    </span>
                  </div>
                )}

                {lowpricesimilar.length == 1 ||
                lowpricesimilar.length == 0 ||
                minimumSellingPrice >= this.Selling_Price ? (
                  ""
                ) : (
                  <div class="cc_compareHeaderTableBox cc_compareVendorSite cc_minimumSellingPrice">
                    {minimumSellingPrice !== 0 && minimumSellingPrice > 1 ? (
                      <h3>
                        <span id="lowsellprice" itemprop="price">
                          Similar Product Available For Lower Price:&nbsp;
                          <a
                            class="cc_minimumSellingPrice"
                            href={
                              app.forum.data.attributes.baseUrl +
                              "/product_details/" +
                              slug
                            }
                            config={m.route}
                          >
                            {minimumSellingPrice.toFixed(2) + " "}
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          </a>
                        </span>
                      </h3>
                    ) : (
                      <span id="lowsellprice" itemprop="price">
                        Checking for Lowest Price&nbsp;
                      </span>
                    )}
                  </div>
                )}

               
                <div class="cc_compareHeaderTableBox cc_compareBuyNowBlock">
                  {this.url ? (
                    <a
                      href={this.url}
                      class="Button Button--primary"
                      target="_blank" rel="nofollow"
                    >
                      <i class="fa fa-shopping-cart"></i> Buy Now
                    </a>
                  ) : (
                    ""
                  )}
                  {/*{Button.component({
                                            className: 'Button Button--primary',
                                            icon: '',
                                            onclick: function () {
                                               if (app.session.user) {
                                                   app.modal.show(new AlertModal({'data':product}));
                                                } else {
                                                  app.modal.show(
                                                    new LogInModal({})
                                                  );
                                                }
                                            }
                                          },"Alert")}*/}
                </div>
              </div>
              <div class="cc_compareHeaderRHS">
                {this.features.length > 0 ? (
                  <div class="cc_compareHeaderTableBox cc_compareDetails">
                    <h3>Product Features:</h3>
                    <ul class="cc_productFeature">
                      {this.features.map((feature) => {
                        return <li>{m.trust(feature)}</li>;
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both;"></div>
        <div class="cc_compareDetailsContent">
          <div>
            {this.compareproduct.compareproductlist &&
            this.compareproduct.compareproductlist.length != 0 ? (
              {/* <hr class="cc_comapreHorRule" /> */}
            ) : (
              ""
            )}
            {this.compareproduct.compareproductlist &&
            this.compareproduct.compareproductlist.length != 0 ? (
              <h3>Compare Similar Products : </h3>
            ) : (
              ""
            )}
            {this.compareproduct ? this.compareproduct.render() : ""}
          </div>
        </div>
        {this.productprice[0].attributes.PriceTracker.length > 1 ||
        this.description.length > 10 ? (
          {/* <hr class="cc_comapreHorRule" /> */}
        ) : (
          ""
        )}
        <div class="cc_compareGraph">
          {this.productprice[0].attributes.PriceTracker.length > 1 ? (
            <div class="pricegraph">
              <h3> Price Trend:</h3>
              {priceGraph.component({
                children: this.productprice,
                priceflag: this.priceflag,
              })}{" "}
            </div>
          ) : (
            ""
          )}
          {this.description.length > 10 ? (
            <div style="padding:20px;">
              <h3>Product Details</h3>
              {m.trust(this.description)}
            </div>
          ) : (
            ""
          )}
        </div>
       {/* <hr class="cc_comapreHorRule" /> */}
        <div>
          <div>
            {SimilarProductsSlider.component({
              tid: this.tid,
              title: this.comparetitle,
            })}
          </div>
        </div>
      </div>
    );
  }

  getProductDetails() {
    const Api_url =
      app.forum.data.attributes.baseUrl + "/api/getProductDetails";
    return app
      .request({
        method: "GET",
        dataType: "json",
        url: Api_url,
        data: { product_id: this.tid },
      })
      .then(this.processDetails.bind(this));
  }

  processDetails(productData) {
    this.product = productData.data;
    this.loading = false;
    var tid = this.product[0].attributes.tid;
    var mrp = this.product[0].attributes.maxPrice;
    var sellingPrice = this.product[0].attributes.PriceTracker[
      Object.keys(this.product[0].attributes.PriceTracker)[
        Object.keys(this.product[0].attributes.PriceTracker).length - 1
      ]
    ].selling_price;
    this.compairePrice(tid, mrp, sellingPrice);
    m.redraw();
  }

  compairePrice(tid, mrp, sellingPrice) {
    if (mrp != 0) {
      var parameter = { tid: tid, mrp: mrp };
      this.compareproduct = new CompareProduct({
        params: parameter,
        routeName: this.props.routeName,
      });
    } else {
      var parameter = { tid: tid, sellingPrice: sellingPrice };
      this.compareproduct = new CompareProduct({
        params: parameter,
        routeName: this.props.routeName,
      });
    }
  }

  generateBreadCrumb(pathname) {
    var paths = pathname.split(">");
    var IndexLink = "";
    var Link = "";
    // remove the last element if there was a / at the end of the pathname
    paths =
      paths[paths.length - 1] === "" ? paths.slice(0, paths.length - 1) : paths;
    // remove the first element if the second one is an empty string which means that we are in the root of the website
    paths = paths[1] === "" ? paths.slice(1) : paths;
    var breadcrumb = paths.map((path, index) => {
      // Add the > symbol only between two links
      var arrow = index !== paths.length - 1 ? " > " : " ";
      // The first element should receive the <IndexLink> React element
      if (index === 0) {
        var first_index = path.replace(" & ", "-");
        first_index = first_index.replace(/,/g, "");
        first_index = first_index.replace(/\s/g, "-");
        return (
          <li>
            <a
              config={m.route}
              href={
                app.forum.data.attributes.baseUrl +
                "/Category/" +
                first_index +
                "?src=cat&categories=" +
                encodeURIComponent(path)
              }
              activeClassName="active"
            >
              {path}
            </a>
            {arrow}
          </li>
        );
      }
      // Build the path for the current URL
      var url = paths.slice(0, index + 1).join(">");
      // HTML structure for every link except the first
      var first = paths[0].replace(" & ", "-");
      first = first.replace(/,/g, "");
      first = first.replace(/\s/g, "-");
      return (
        <li>
          <a
            config={m.route}
            href={
              app.forum.data.attributes.baseUrl +
              "/Category/" +
              first +
              "?src=cat&categories=" +
              encodeURIComponent(url)
            }
          >
            {path}
          </a>
          {arrow}
        </li>
      );
    });
    // Return a list of links
    return <ul className="inline-list">{breadcrumb}</ul>;
  }
  getParams(paramName, value) {
    let paramsURL = Object.assign({}, m.route.param());
    paramsURL[paramName] = value;
    paramsURL.src = "brand";
    delete paramsURL.product_id;
    return paramsURL;
  }
  refreshPrices(product) {
    this.pdetails = this.topprice;
    const Api_url = app.forum.attribute("baseUrl") + "/api/RefreshDetails";
    return app
      .request({
        method: "POST",
        url: Api_url,
        data: this.pdetails,
      })
      .then(this.parseResults.bind(this));
  }
  parseResults(result) {
    if (result.data[0].attributes.refreshpricedata.length != 0) {
      const newSellprice = [];
      Object.keys(result.data[0].attributes.refreshpricedata).map(function (
        key
      ) {
        var sellingprice =
          result.data[0].attributes.refreshpricedata[key].selling_price;
        var key = key;
        var newprice = result.data[0].attributes.refreshpricedata[key];
        newSellprice.push({
          tid: key,
          selling_price: sellingprice,
          new_price: newprice,
        });
      });
      for (var i = 0; i < newSellprice.length; i++) {
        for (var j = 0; j < this.product.length; j++) {
          if (newSellprice[i].tid == this.product[j].attributes.tid) {
            this.product[j].attributes.PriceTracker.push(
              newSellprice[i].new_price
            );
            this.product[j].attributes.productOutOfStock =
              newSellprice[i].new_price.productOutOfStock;
            this.product[j].attributes.LastUpdatedOn =
              newSellprice[i].new_price.timestamp;
          } else {
            this.product[j].attributes.LastUpdatedOn =
              newSellprice[i].new_price.timestamp;
          }
        }
      }
      this.priceflag = true;
      $("#refresh_button_load").hide();
      $("#refresh_button").show();
      m.redraw(true);
    } else {
      var currentdate = new Date();
      this.product[0].attributes.LastUpdatedOn.$date.$numberLong = currentdate
        .getTime()
        .toString();
      this.priceflag = true;
      m.lazyRedraw();
    }
  }
}
