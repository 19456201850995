import { extend } from "flarum/extend";
import HeaderPrimary from "flarum/components/HeaderPrimary";
import Button from "flarum/components/Button";
import LinkButton from "flarum/components/LinkButton";
import myButtons from "./myButtons";
import Dropdown from "flarum/components/Dropdown";
import StoreMenu from "./StoreMenu";
import EndpointsApiUrls from "./EndpointsApiUrls";

export default function () {
  //used to display navigation menu
  extend(HeaderPrimary.prototype, "items", function (items) {
    let composeButton = new myButtons();
    const canStartDiscussion =
      app.forum.attribute("canStartDiscussion") || !app.session.user;
    const urlHome = app.forum.attribute("baseUrl") + "/myIndex";
    const urlAll = app.forum.attribute("baseUrl") + "/all";
    const urlmyCategory = app.forum.attribute("baseUrl") + "/myCategory";
    const ApiEndpoint = new EndpointsApiUrls();
    this.categoryTagOther = app.store
      .all("tags")
      .filter(
        (model) =>
          model["parent"]() === app.store.getBy("tags", "slug", "category")
      );
    this.storeTagOther = app.store
      .all("tags")
      .filter(
        (model) =>
          model["parent"]() === app.store.getBy("tags", "slug", "stores")
      );
    this.categoryTagOther = this.categoryTagOther.sort(myfunction);
    this.storeTagOther = this.storeTagOther.sort(myfunction);

    function myfunction(a, b) {
      a = a.data.attributes.name;
      b = b.data.attributes.name;
      return a.localeCompare(b);
    }
    items.add(
      "Plus",
      Button.component({
        //children: 'Share Now',
        icon: "fas Button-icon fa-bars",
        className: "plsuBtns",
        itemClassName: "",
        onclick: composeButton.hideAndSeek.bind(composeButton),
        disabled: !canStartDiscussion,
      }),
      
    );
    items.add(
      "newDeal",
      Button.component({
        //children: "New Deal",
        icon: "fas fa-gift",
        className:
          "plsuBtns Button--primary IndexPage-newDiscussion newDealBtn",
        itemClassName: "",
        //style:"display:none;",
        onclick: composeButton.newProduct.bind(composeButton),
        disabled: !canStartDiscussion,
      })
    );
    items.add(
      "newcoupon",
      Button.component({
        //children: "New Coupon",
        icon: "fas fa-tag",
        className:
          "plsuBtns Button--primary IndexPage-newDiscussion newcouponBtn",
        itemClassName: "",
        //style:"display:none;",
        onclick: composeButton.newcoupon.bind(composeButton),
        disabled: !canStartDiscussion,
      })
    );
    items.add(
      "mynewDiscussion",
      Button.component({
        //children: "New Discussion",
        icon: "far fa-comments",
        className:
          "plsuBtns Button--primary IndexPage-newDiscussion mynewDiscussionBtn",
        itemClassName: "",
        //style:"display:none;",
        onclick: composeButton.newDiscussion.bind(composeButton),
        disabled: !canStartDiscussion,
      })
    );
    items.add(
      "Deals",
      LinkButton.component({
        className: "Button Button--user Button--flat",
        href: "/Deals/category",
        children: "ALL DEALS",
      }),
      500
    );
    items.add(
      "Coupon",
      LinkButton.component({
        className: "Button Button--user Button--flat",
        href: "/Coupons/stores",
        children: "COUPONS",
      }),
      500
    );
    if (ApiEndpoint.isFeatureOn("STOREMENU")) {
      items.add(
        "stores",
        StoreMenu.component({
          label: "STORES",
          children: this.storeTagOther.map(function (link) {
            return LinkButton.component({
              className: "Button Button--user Button--flat",
              href: "/t/" + link.data.attributes.slug,
              children: link.data.attributes.name,
            });
          }),
          menuClassName: "Dropdown-menu--left colUL-4",
          buttonClassName: "Button Button--user Button--flat",
          caretIcon: "",
          icon: "",
        }),
        500
      );
    }
    items.add(
      "categories",
      Dropdown.component({
        label: "CATEGORIES",
        children: this.categoryTagOther.map(function (link) {
          return LinkButton.component({
            Image: "",
            className: "",
            href: "/Category/" + link.data.attributes.slug,
            children: link.data.attributes.name,
          });
        }),
        menuClassName: "Dropdown-menu--left colUL-3",
        buttonClassName: "Button Button--user Button--flat",
        caretIcon: "",
        icon: "",
      }),
      500
    );
    items.add(
      "forum",
      LinkButton.component({
        className: "Button Button--user Button--flat",
        href: urlAll,
        children: "FORUM",
      }),
      500
    );
    if (ApiEndpoint.isFeatureOn("PRICEDROP")) {
      items.add(
        "pricedrops",
        LinkButton.component({
          className: "Button Button--user Button--flat",
          href: "/Pricedrop",
          children: "PRICE DROPS",
        }),
        500
      );
    }
     
    //    items.add('DailyDeals',
    //   LinkButton.component({
    //     className: "Button Button--user Button--flat",
    //     href: '/Dealmachine',
    //     children: 'DEAL MACHINE',
    //   }),
    // );

    //     if(app.session.user){
    //   items.add('Wishlist',
    //   LinkButton.component({
    //     className: "Button Button--user Button--flat",
    //     href: '/Wishlist',
    //     children: 'WISHLIST',
    //   }),
    // );
    // }

    // items.add(
    //   "rhsMenu",
    //   <div class="cc_iconsHolder">
    //     <div class="cc_RHSMenuBtn">
    //       <a
    //         href="https://play.google.com/store/apps/details?id=com.thedealapp.thedealapp&hl=en"
    //         target="_blank"
    //         style="color:#a4c639"
    //       >
    //         <i class="fab fa-android fa-2x"></i>
    //       </a>
    //     </div>
    //     <div class="cc_RHSMenuBtn" style="color:#666">
    //       <a
    //         href="https://itunes.apple.com/us/app/the-deal-app/id1324403713"
    //         target="_blank"
    //         style="color:#666"
    //       >
    //         <i class="fab fa-apple fa-2x"></i>
    //       </a>
    //     </div>
    //     <div class="cc_RHSMenuBtn">
    //       <a
    //         href="https://twitter.com/the_dealapp?lang=en"
    //         target="_blank"
    //         style="color:#1da1f2"
    //       >
    //         <i class="fab fa-twitter fa-2x"></i>
    //       </a>
    //     </div>
    //     <div class="cc_RHSMenuBtn">
    //       <a
    //         href="https://www.facebook.com/thedealapp/"
    //         target="_blank"
    //         style="color:#4867aa"
    //       >
    //         <i class="fab fa-facebook fa-2x"></i>
    //       </a>
    //     </div>

    //     <div class="cc_RHSMenuBtn">
    //       <a
    //         href="https://chrome.google.com/webstore/detail/the-deal-app/iclkejcbdkfpjknmkladdjjkeefmpoci?hl=en"
    //         target="_blank"
    //         style="color:#f0361d"
    //       >
    //         <i class="fab fa-chrome fa-2x"></i>
    //       </a>
    //     </div>
    //   </div>
    // );
  });
}
