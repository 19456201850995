import Component from 'flarum/Component';
import ItemList from 'flarum/utils/ItemList';
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
//used for Loading Indicator in many pages
export default class DealPlaceHolder extends Component {
  init() { }
  dealPlaceHolder() {
    return (<div class="ph-item cc_productCardSingle" style="min-width:220px;min-height:280px;padding:5px 2px;margin:.5rem 1rem 0.8rem 0.5rem">
      <div class="ph-col-12">
        <div class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-12 empty"></div>
        </div>

        <div class="empty"></div>
        <div class="ph-row">
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
          <div class="ph-col-12"></div>
         

        </div>
        <div class="ph-row">
        <div class="ph-col-4 "></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-4"></div>
        </div>
      </div>

    </div>);
  }
  navPlaceHolder() {
    return (<div class="ph-item" style="min-width:150px;min-height:230px">
      <div class="ph-col-12">
        <div class="ph-row">
          <div class="ph-col-12 big"></div>
          <div class="ph-col-8"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-9"></div>
          <div class="ph-col-3 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-2 empty"></div>
          <div class="ph-col-9"></div>
          <div class="ph-col-3 empty"></div>
          <div class="ph-col-8"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-8"></div>
          <div class="ph-col-4 empty"></div>
        </div>
      </div>
      <div class="ph-col-12">
        <div class="ph-row">
          <div class="ph-col-12 big"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
        </div>
      </div>
      <div class="ph-col-12">
        <div class="ph-row">
          <div class="ph-col-12 big"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
          <div class="ph-col-1"></div>
          <div class="ph-col-1 empty"></div>
          <div class="ph-col-10"></div>
        </div>
      </div>

    </div>);
  }
  getPlaceHolderList(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add('palceholder-' + i.toString(), this.dealPlaceHolder());
    }
    return placeHolderList;

  }
  view() {
    const type = this.props.type;
    if (type == 'nav') {
      return (this.navPlaceHolder());
    }
    else
      return (this.dealPlaceHolder());
  }
}


