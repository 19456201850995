import Notification from 'flarum/components/Notification';

export default class UpvotedNotification extends Notification {
    icon() { return 'thumbs-up'; }

    href() { return app.route.post(this.props.notification.subject()); }

    content() { return 'upvote'; }

    excerpt() { return this.props.notification.subject().contentPlain(); }
}
