import Component from "flarum/Component";
import avatar from "flarum/helpers/avatar";
import humanTime from "flarum/utils/humanTime";
import slidable from "flarum/utils/slidable";
import extractText from "flarum/utils/extractText";
import CouponExpiry from "./storePages/CouponExpiry";
import Coupomod from "./coupon/CouponModalv2";

export default class FeatureCouponslide extends Component {
  //used to display banners on homepage
  init() {
    const discussion = this.props.DiscussionData;
    this.imageurl_sm = m.prop(this.props.DiscussionData.imageurl_sm || "");
    this.imageurl_lg = m.prop(this.props.DiscussionData.imageurl_lg || "");
    this.badgetext = m.prop(this.props.DiscussionData.badgetext || "");
    this.discussionID = m.prop(this.props.DiscussionData.id || "");
    this.title = m.prop(this.props.DiscussionData.title || "");
    this.discussionRoute =
      app.forum.data.attributes.baseUrl + "/d/" + this.discussionID();
    //this.imageurl_sm = this.imageurl_sm().replace(/^http:\/\//i, 'https://');
    this.merchantName = m.prop(this.props.DiscussionData.merchantName || "");
    if (this.merchantName == "") {
      this.merchantName = m.prop(this.props.DiscussionData.merchantname || "");
    }
  }


  view() {
    let discussion = this.props.DiscussionData;
    return m(
      "div",
      { class: "", config: this.configArea.bind(this) },
      <div class="" style="box-shadow:-1px 1px 3px 0px rgba(0,0,0,0.3); border-radius: 10px;">
        <div style="background: #194571;border-radius: 5px 5px 0px 0px;height: 45px;text-align:center;padding:13px;color:#fff;">
          {this.badgetext()}
        </div>
        {this.props.DiscussionData.discussiontype != "auto" ?
          <a href={this.discussionRoute} config={m.route}>
            <div style="width:100%;max-height:300px;display:table;">
              <div style="display:table-cell;vertical-align:middle;padding:20px;text-align:center;">
                {this.imageurl_sm() == "" ? <div style="font-size:25px!important;padding:10px!important">{this.merchantName()}</div> : <picture>
                  <source
                    media="(max-width: 639px)"
                    srcset={this.imageurl_sm()}
                  ></source>
                  <source
                    media="(min-width: 640px)"
                    srcset={this.imageurl_lg()}
                  ></source>
                  <img
                    src={this.imageurl_sm()}
                    style="border-radius: 5px;max-height:320px;max-width:100%;margin-left: auto;margin-right: auto;height: 50px;"
                  />
                </picture>}
              </div>
            </div>
          </a>
          :
          <div config={this.configArea.bind(this)} onclick={this.getCouponCode.bind(this)}>
            <div style="width:100%;max-height:300px;display:table;">
              <div style="display:table-cell;vertical-align:middle;padding:20px;text-align:center;">
                {this.imageurl_sm() == "" ? <div style="font-size:25px!important;padding:10px!important">{this.merchantName()}</div> : <picture>
                  <source
                    media="(max-width: 639px)"
                    srcset={this.imageurl_sm()}
                  ></source>
                  <source
                    media="(min-width: 640px)"
                    srcset={this.imageurl_lg()}
                  ></source>
                  <img
                    src={this.imageurl_sm()}
                    style="border-radius: 5px;max-height:320px;max-width:100%;margin-left: auto;margin-right: auto;height: 50px;"
                  />
                </picture>}
              </div>
            </div>
          </div>
        }

        <div style="background: #194571;border-radius: 0px 0px 5px 5px;height: 45px;color:#fff;text-align: center;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;padding: 13px;">
          {" "}
          {this.title()}
        </div>
      </div>
    );
  }
  configArea(element, isInitialized) {
  }
  config(isInitialized) {
    if (isInitialized) return;

    // If we're on a touch device, set up the discussion row to be slidable.
    // This allows the user to drag the row to either side of the screen to
    // reveal controls.

  }

  getCouponCode(e) {
    //e.stopPropagation();
    //e.preventDefault();
    if (this.props.DiscussionData != undefined) {
      const discussion = { _source: this.props.DiscussionData };
      app.modal.show(new Coupomod({ discussion }));
      this.url = discussion._source.url;
      var Url = this.url;

      var RedirectUrl = window.open(Url, "_blank");
      if (RedirectUrl) {
        //Browser has allowed it to be opened
        RedirectUrl.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    }
  }

}
