import Component from "flarum/Component";
import Button from "flarum/components/Button";
import AllDealCardMini from "./AllDealCardMini";
import CouponCardMini from "./CouponCardMini";
import SlickSlider from "./SlickmainSlider";
import DealPlaceHolder from "./DealPlaceHolder";
import ItemList from "flarum/utils/ItemList";
import listItems from "flarum/helpers/listItems";
import EndpointsApiUrls from "./EndpointsApiUrls";

export default class DealsListData extends Component {
  //used in myIndexPage for super & latest deals
  init() {
    this.loading = true;
    this.loaded = false;
    this.moreResults = false;
    this.heading = this.props.params.heading;
    this.headingName = this.props.params.headingName;
    this.headingIcon = this.props.params.headingIcon;
    "tag" in this.props.params
      ? (this.tag = "?tag=" + this.props.params.tag)
      : "";
    this.discussions = null;
    this.discussions = [];
    this.DealList = [];
    this.DealListimage = [];
    this.DealListdata = [];
    this.DealListseemore = [];
    this.slidesToShowDesktop = this.props.slidesToShowDesktop;
    this.slidesToScrollDesktop = this.props.slidesToScrollDesktop;
    this.color = this.props.params.color ? this.props.params.color : "#111";
    this.headingUnderline = this.props.params.headingUnderline ? true : false;
    this.bannerImage = this.props.params.bannerImagePath;
    // m.startComputation();
    this.refresh();
    //m.redraw();
  }
  config() {
    if (!this.loading) this.loaded = true;
  }
  view() {
    const params = this.props.params;

    let loading;

    if (this.loading) {
      loading = listItems(this.getPlaceHolders(22).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    if (this.discussions.length === 0 && !this.loading) {
      const text = app.translator.trans(
        "core.forum.discussion_list.empty_text"
      );
      return <div></div>;
    }

    var DiscussionData = {};

    return (
      <div>
        <div class="sliderHeading" style={"color:" + this.color}>
          <h2 class="sliderTitle">
            <i class={this.headingIcon} aria-hidden="true"></i>
            {this.heading}
          </h2>
        </div>
        <div>
          {SlickSlider.component({
            tag: this.tag,
            headingName: this.headingName,
            sliderbannerimage: this.bannerImage,
            slidesToShowDesktop: this.slidesToShowDesktop,
            slidesToScrollDesktop: this.slidesToScrollDesktop,
            children: this.loading ? loading : this.DealList,
          })}
        </div>
        <div style="clear:both"></div>
      </div>
    );
  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }
  /**
   * Clear and reload the discussion list.
   *
   * @public
   */
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.discussions = [];
    }

    return this.loadResults().then(
      (results) => {
        this.discussions = [];
        this.parseResults(results);
        //m.redraw();
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Load a new page of discussion results.
   *
   * @param {Integer} offset The index to start the page at.
   * @return {Promise}
   */
  loadResults(offset) {
    const params = {};
    if (this.heading == "Super Deals") {
      params.categories = this.headingName;
    } else {
      params.categories = this.headingName;
    }
    params.size = 0;
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");

    const Api_url =
      this.heading == "Super Deals"
        ? ApiEndpoint + "/superdeal"
        : ApiEndpoint + "/latestdeal";
    return app
      .request({ method: "GET", url: Api_url })
      .then(this.parseResults.bind(this));
  }

  loadMore() {
    this.loading = true;
    this.loadResults(this.discussions.length).then(
      this.parseResults.bind(this)
    );
  }
  /**
   * Parse results and append them to the discussion list.
   *
   * @param {Discussion[]} results
   * @return {Discussion[]}
   */
  parseResults(results) {
    if (results.result !== undefined) {
      if (results.result.hits !== undefined) {
        if (results.result.hits.hits) {
          [].push.apply(this.discussions, results.result.hits.hits);
          this.DealList = [];
          this.DealListdata = this.discussions.reduce(function (
            result,
            discussion
          ) {
            var DiscussionData = {
              title: discussion._source.title,
              maxPrice: discussion._source.maxprice,
              currentPrice: discussion._source.currentprice,
              discount: discussion._source.discount,
              ////currency : discussion.data.attributes.currency,
              url: discussion._source.url,
              productName: discussion._source.productName,
              isFeatured: discussion._source.is_featured,
              isOffer: discussion._source.is_offer,
              isHotDeal: discussion._source.is_hotdeal,
              isExpired: discussion._source.is_expired,
              imageUrl_sm: discussion._source.imageurl_sm,
              noImageFound: discussion._source.noImageUrl_sm,
              validFrom: discussion._source.validfrom,
              validTo: discussion._source.validto,
              validFor: discussion._source.validfor,
              badgeText: discussion._source.badgetext,
              tag_slug: discussion._source.tag_slug,
              avatar_path: discussion._source.avatar_path,
              username: discussion._source.username,
              startUser: "",
              id: discussion._id,
              tags: "",
              couponcode: discussion._source.couponcode,
              secondarytag: discussion._source.secondarytag,
              start_user_id: discussion._source.user_id,
              start_time: discussion._source.start_time,
              refreshdate: discussion._source.refreshdate,
              created_at: discussion._source.created_at,
            };
            result.push(
              DiscussionData.discussionType == "c"
                ? CouponCardMini.component({ DiscussionData })
                : AllDealCardMini.component({ DiscussionData })
            );
            return result;
          },
          []);

          this.DealListimage = new Array(
            m("img", { src: this.bannerImage, style: { height: "205px" } })
          );

          if (this.bannerImage) {
            this.DealList = this.DealList.concat(this.DealListimage);
          }
          if (this.DealListdata) {
            this.DealList = this.DealList.concat(this.DealListdata);
          }
          if (this.heading) {
            this.DealList = this.DealList.concat(this.DealListseemore);
          }
        }
      }
      this.loading = false;

      // m.endComputation();

      m.redraw();

      return results;
    }
  }

  /**
   * Remove a discussion from the list if it is present.
   *
   * @param {Discussion} discussion
   * @public
   */
  removeDiscussion(discussion) {
    const index = this.discussions.indexOf(discussion);

    if (index !== -1) {
      this.discussions.splice(index, 1);
    }
  }

  /**
   * Add a discussion to the top of the list.
   *
   * @param {Discussion} discussion
   * @public
   */
  addDiscussion(discussion) {
    this.discussions.unshift(discussion);
  }
}
