import Modal from 'flarum/components/Modal';
import app from 'flarum/app';
import priceGraph from './priceGraph';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import GetTDAProductID from './GetTDAProductID';

export default class PriceGraphModal extends Modal {
  //used to call price graph on deal page
  init() {
    super.init();
    this.pricegraphdata = [];
    let Getproductdetailsdata = new GetTDAProductID();
    let Product_ID = Getproductdetailsdata.getProductid(this.props.storeName, this.props.pid);
    this.compareproduct = this.props.compareproduct;
    this.loading = true;
    if ("enc" in this.props) {
      this.param123 = { product_id: Product_ID, enc: 1 };
    } else {
      this.param123 = { product_id: Product_ID };
    }
    this.getProductDetails(Product_ID);
    this.priceflag = false;
  }
  getProductDetails(Product_ID) {

    const param = this.param123;
    const Api_url = app.forum.data.attributes.baseUrl + '/api/priceGraph';
    app.request({
      method: 'GET',
      dataType: "json",
      data: param,
      url: Api_url
    }).then(
      this.processDetails.bind(this)
    );
  }

  processDetails(results) {
    this.pricegraphdata = results.data.attributes;
    this.loading = false;
    this.priceflag = true;
    m.redraw();
  }
  className() {
    return 'Modal--Big ';
  }

  title() {
    return app.translator.trans('Compare');
  }

  content() {
    if (this.loading) {
      return (<div class="loading"> {LoadingIndicator.component()} </div>);
    }

    else //if(this.pricegraphdata.length>0)
    {
      // if(this.pricegraphdata['0'].PriceTracker.length>0){
      return [
        m('div', { className: 'Modal-body', style: "background-color: #fffcfc" }, [
          (this.pricegraphdata.length > 0 && this.pricegraphdata['0'].PriceTracker != undefined && this.pricegraphdata['0'].PriceTracker.length > 0) ?
            m('div', { className: 'cc_compareGraph' }, [
              m('div', { className: "pricegraph" },
                priceGraph.component({ 'children': this.pricegraphdata, priceflag: this.priceflag })
              )
            ])
            : m('div', { style: "padding-bottom: 20px;" }, "Uh Oh! Price Graph currently not available for this product. We will try to do better next time."),
          m('div',
            (this.compareproduct.length != 0 ? this.compareproduct.render() : "")
          )
        ])
      ];
      // }else
      // {
      //   return (<div><p class="text-center">Uh Oh! Price Graph currently not available for this product. We will try to do better next time.</p></div>);
      // }
    }
    // else
    // {
    //     return (<div><p class="text-center">Uh Oh! Price Graph currently not available for this product. We will try to do better next time.</p></div>);
    // }
  }
}
