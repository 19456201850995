import EventPost from 'flarum/components/EventPost';

export default class DiscussionFeaturedPost extends EventPost {
  icon() {
    return this.props.post.content().Featured
      ? 'Featured'
      : 'unFeatured';
  }

  descriptionKey() {
    return this.props.post.content().Featured
      ? 'featured the discussion.'
      : 'unfeatured the discussion.';
  }
}
