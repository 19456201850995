import Page from 'flarum/components/Page';
import CouponResults from './../search/CouponResults';

export default class CouponSearchPage extends Page {
  //used for Search page(Indexpage) https://thedealapp.in/product?query=samsung
      init() {
            super.init();
            const params1 = m.route.param();            
            this.CouponResults = new CouponResults({params:params1,routeName:this.props.routeName});
      }
     view() {
      return(
          <div class="couponsearchResultsPage" >
            <div className="container storeContainer">
              <nav >
                <div hidden>
                  <i class="fa fa-thumbs-up" aria-hidden="true"></i> Results from deals posted by users
                </div>
              </nav>
            </div>
            <div className="container resultsContainer">
               {this.CouponResults.render()}
            </div>
        </div>
      )
    }
}