import { extend } from 'flarum/extend';
import DiscussionControls from 'flarum/utils/DiscussionControls';
import DiscussionPage from 'flarum/components/DiscussionPage';
import Button from 'flarum/components/Button';

export default function requestFeaturedControl() {
  //used in single discussion
  extend(DiscussionControls, 'moderationControls', function(items, discussion) {
    if (discussion.canrequestFeatureddeal()) {
          if (!discussion.isrequestFeatureddeal()){
                    items.add('RequestFeatured', Button.component({
                      children: 'Request Value for Money',
                      icon: 'fa fa-thumbs-up',
                      onclick: this.RequestFeaturedAction.bind(discussion)
                    }));
          }
          // else{
          //         items.add('RequestFeatured', Button.component({
          //             children: 'Undo Value for Money',
          //             icon: 'fa fa-thumbs-down',
          //             onclick: this.RequestFeaturedAction.bind(discussion),    
          //           }));  
          // }
                    
    }
  });

  DiscussionControls.RequestFeaturedAction = function() {
    this.save({isrequestFeatureddeal: !this.isrequestFeatureddeal()}).then(() => {
      if (app.current instanceof DiscussionPage) {
        app.current.stream.update();
      }
      if(this.data.attributes.isFeatured == false){
        alert("Your request to make this deal as a value for money has been submitted");
      }
      m.redraw();
    });
  };
}
