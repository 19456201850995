import Page from 'flarum/components/Page';
import ProductDetail from './../ProductDetail';
import LoadingIndicator from 'flarum/components/LoadingIndicator';

export default class productDetailsPage extends Page {
  //used for Single product page(Indexpage) https://thedealapp.in/product_details/n6hwk1rv2-samsung-guru-e1200-white
      init() {
        super.init();
        this.loading = false;
        this.product_id = m.route.param('product_id');
        const productid = this.product_id.split("-");
        if(productid[0].length==9)
        {
          this.tid = productid[0];
          this.titleString = this.product_id.substring(this.product_id.indexOf('-') + 1);
          this.title = this.titleString.replace(new RegExp('-', 'g')," ");
        }
        this.productdata = [];
      }
      view() {
         const tid = this.tid;
         const title = this.title;
        var loading;
      if (this.loading) {
        loading = LoadingIndicator.component();
        return( <div>{loading} </div>)
      } 
            return(  
             <div>
                 {this.tid ?
                  <div>
                    <div>
                       {ProductDetail.component({tid:tid,title:title})}
                    </div>
                  </div>
                  : ''}
              </div>
          )
      }
}