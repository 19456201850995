import Component from 'flarum/Component';
import DiscussionComposer from 'flarum/components/DiscussionComposer';
import EditProductComposer from './EditProductComposer';
import EditCouponComposer from './EditCouponComposer';
import LogInModal from 'flarum/components/LogInModal';
export default class myButtons extends Component {
  //used to display the various buttons on website
      init() {}
      hideAndSeek() { 
            $('.newDealBtn').toggle(250);
            $('.mynewDiscussionBtn').toggle(250);
            $('.newcouponBtn').toggle(250);
            if($(".plsuBtns > i").hasClass("fa-bars")) {
                  $(".plsuBtns > i").removeClass('fa-bars');
                  $(".plsuBtns > i").addClass('fa-times');
            }  else if($(".plsuBtns > i").hasClass("fa-times")) {
                  $(".plsuBtns > i").removeClass('fa-times');
                  $(".plsuBtns > i").addClass('fa-bars');
            }
      }
      newDiscussion() {   
            const deferred = m.deferred();
            if (app.session.user) {
              this.composeNewDiscussion(deferred);
            } else {
              app.modal.show(
                new LogInModal({
                  onlogin: this.composeNewDiscussion(deferred).bind(this, deferred)
                })
              );
            }
            return deferred.promise;
      }
      composeNewDiscussion(deferred) {
            const component = new DiscussionComposer({user: app.session.user});
            app.composer.load(component);
            app.composer.show();
            deferred.resolve(component);
            return deferred.promise;
      }
      newProduct() {
            const deferred = m.deferred();
            if (app.session.user)  {
                  this.composeNewProduct(deferred);
            } else {
                  app.modal.show(
                        new LogInModal({
                              onlogin: this.composeNewProduct.bind(this, deferred)
                        })
                  );
            }
            return deferred.promise;
      }
  newcoupon() {
        const deferred = m.deferred();
        if (app.session.user) {
          this.composeNewCoupon(deferred);
        } else {
          app.modal.show(
            new LogInModal({
              onlogin: this.composeNewCoupon.bind(this, deferred)
            })
          );
        }
        return deferred.promise;
  }

  composeNewProduct(deferred) {
   m.route("/newDealComposer");
  }
   composeNewCoupon(deferred) {
    m.route('/newCoupon');
  }
  editProduct(post) {   
      app.composer.load(new EditProductComposer({ post: post }));
      app.composer.show();
  }
  editCoupon(post) {
      app.composer.load(new EditCouponComposer({ post: post }));
      app.composer.show();
  }
}