import Modal from 'flarum/components/Modal';
import ForgotPasswordModal from 'flarum/components/ForgotPasswordModal';
import SignUpModal from 'flarum/components/SignUpModal';
import Alert from 'flarum/components/Alert';
import Button from 'flarum/components/Button';
import LogInButtons from 'flarum/components/LogInButtons';
import Switch from 'flarum/components/Switch';
import extractText from 'flarum/utils/extractText';
import listItems from 'flarum/helpers/listItems';
import ItemList from 'flarum/utils/ItemList';
import { truncate } from 'flarum/utils/string'
import { getPlainContent } from 'flarum/utils/string'
import Select from 'flarum/components/Select';
import KeywordPriceDrop from '../wishlist/KeywordPriceDrop';

export default class AlertModalKeyword extends Modal {
   //Used for AlertModalKeyword
  init() {
        super.init();
        this.pricedropmethod = "";
        this.pricedropinputRs = "";
        this.pricedropinputPer = "";
        this.multiselect=this.props.multiselect;
        $('.item-alertsort select.Select-input.FormControl').val("select");
        app.cache.displaycheck = false;
        let paramdata = m.route.param("categories") ? m.route.param("categories").split('>') : '';
    var CategoryName = paramdata[paramdata.length-1];
        //$("#topicname").val(CategoryName ? CategoryName : "General");
        this.topicnamevalue = CategoryName ? CategoryName : "General";
    }


  className() {
    return 'Modal--Big brandModal';
  }

  title() {
    return app.translator.trans("Track Product");
  }

  hide(){
    $('.item-alertsort select.Select-input.FormControl').val("");
    $('.item-searchsort select.Select-input.FormControl').val("");
    $("#dataerror").html("");
    app.modal.close();
  }
  content() {
   
    

    return [
      m('div', {className: 'Modal-body AlertModalBox'}, [
      m('div',{id: 'dataerror',style:{color:"red"}},"" ),
       m('div', {className: 'dataalert'}, [
        m("table", {className: 'alerttable'}, [
          m("div",
              m("tr", 
                m("td", {className: 'topic'},"WishList Name : "),
                m("td", m('input[type=text][name=topicname][id=topicname]',({value:this.topicnamevalue})))
              ),
              m("tr",
                m("td", {className: 'alerttype'},"Alert Type: "),
                m("td",  m('label',{id: "keywordalertlabel"},m("input[type=radio]",({name:"alertoption",id:"alertoption",value:"keywordalert",onclick:this.alerttype.bind(this)})),m('span', 'Alert Using Keywords')),
                         m('br'),
                         m('label',{id: "alertproductlabel"},m("input[type=radio]",({name:"alertoption",id:"alertoption",value:"alertproduct",onclick:this.alerttype.bind(this)})),m('span', 'Alert By Selecting Products'))),
              ),
              m("tr[className=recordtype][style=display:none]", 
                m("td"),
                m("td", <div class="cc_searchrecord cc_searchItems">
                          {listItems(this.searchItems().toArray())}
                        </div>
                  )
              )
            ),
            
            m("div[className=trackProductnew hide]",
               this.KeywordPriceDrop != undefined ? this.KeywordPriceDrop.render() : ""
             ),
            m("div[className=trackProductexist hide]",
               this.KeywordPriceDrop != undefined ? this.KeywordPriceDrop.render() : ""
             )
        ])
       ])
      ]),
      
       
        
    ];

  }

  /**
   * Open the forgot password modal, prefilling it with an email if the user has
   * entered one.
   *
   * @public
   */
  forgotPassword() {
    app.modal.show(new ForgotPasswordModal(props));
  }

  /**
   * Open the sign up modal, prefilling it with an email/username/password if
   * the user has entered one.
   *
   * @public
   */
  signUp() {
    app.modal.show(new SignUpModal(props));
  }

  alerttype(event){
    if(this.topicnamevalue != $("#topicname").val()){
      this.topicnamevalue = $("#topicname").val();
    }
    if(event.target.value == "keywordalert"){
      $(".recordtype").show();
      $('.item-searchsort select.Select-input.FormControl').val("select");
      //$(".trackProduct").show();
      //$(".searchkeyword").show();
    }else if(event.target.value == "alertproduct"){
      $(".alertcheckbox").show();
      $(".alertcheck").addClass('focus');
      app.cache.displaycheck = true;
      app.modal.close();
      $('html, body').animate({
      scrollTop: $("div.DiscussionList").offset().top
    },2000)
    }else{
      $(".trackProductnew").removeClass("trackProductnew show").addClass("trackProductnew hide");
      $(".trackProductexist").removeClass("trackProductexist show").addClass("trackProductexist hide");
      $(".searchkeyword").removeClass("searchkeyword show").addClass("searchkeyword hide");
    }
  }

  searchItems(){
    //$('.item-searchsort select.Select-input.FormControl').val("select");
    const items = new ItemList();
    const sortMap ={select: "Select", NewRecord: "Add New Record for Search", ExistingRecord: "Edit Record for Search "};
    const sortOptions ={select: "Select", NewRecord: "Add New Record for Search", ExistingRecord: "Edit Record for Search "};
    items.add('searchsort',
      Select.component({
        options: sortOptions,
        value:m.route.param().searchsort || Object.keys(sortMap)[0],
        id:"selectsearchsort",
        onchange: this.changerecord.bind(this)
      })
    );

    return items;
  }

  changerecord(data){
    if(this.topicnamevalue != $("#topicname").val()){
      this.topicnamevalue = $("#topicname").val();
    }
    const params = m.route.param();
    params.searchsort = data;
    var userId = app.session.user.data.id;
    var WishListName = $("#topicname").val();
    params.userId = userId;
    params.WishListName = WishListName;
    var checked = "";
    if(data =="NewRecord"){
      //$('.item-searchsort select.Select-input.FormControl').val("Add New Record for Search").change();
      $(".trackProductnew").removeClass("trackProductnew hide").addClass("trackProductnew show");
      $(".trackProductexist").removeClass("trackProductexist show").addClass("trackProductexist hide");
      $(".searchkeyword").removeClass("searchkeyword hide").addClass("searchkeyword show");
      this.KeywordPriceDrop = new KeywordPriceDrop({params:params,routeName:this.props.routeName});
      $("#dataerror").html("");
    }else if(data =="ExistingRecord"){
      //$('.item-searchsort select.Select-input.FormControl').val("Edit Record for Search").change();
      $(".trackProductnew").removeClass("trackProductnew show").addClass("trackProductnew hide");
      $(".trackProductexist").removeClass("trackProductexist hide").addClass("trackProductexist show");
      $(".searchkeyword").removeClass("searchkeyword show").addClass("searchkeyword hide");
      this.KeywordPriceDrop = new KeywordPriceDrop({params:params,routeName:this.props.routeName});
      $("#dataerror").html("");
    }else{
      $(".trackProductnew").removeClass("trackProductnew show").addClass("trackProductnew hide");
      $(".trackProductexist").removeClass("trackProductexist show").addClass("trackProductexist hide");
      $(".searchkeyword").removeClass("searchkeyword show").addClass("searchkeyword hide");
      $('#topicname').removeAttr("disabled");
      $("#dataerror").html("Please Select the Proper option");
    }

  }

  // viewItems(){
  //   $('.item-alertsort select.Select-input.FormControl').val("select");
  //   const items = new ItemList();
  //   const sortMap ={select: "Select", PriceRangeInRs: "Price In Rs.", Discount: "Discount"};
  //   const sortOptions ={select: "Select", PriceRangeInRs: "Price In Rs.", Discount: "Discount"};
  //   items.add('alertsort',
  //     Select.component({
  //       options: sortOptions,
  //       value:m.route.param().alertsort || Object.keys(sortMap)[0],
  //       id:"selectoption",
  //       onchange: this.changevalue.bind(this)
  //     })
  //   );

  //   return items;
  // }
  changevalue(data){
    this.pricedropmethod = data;
    var alertsort = data;
    const params = m.route.param();
    params.alertsort = data;
    
    if(this.pricedropmethod == "PriceRangeInRs"){
      $("#userinputdataRs").show();
      $("#userinputdataPer").hide();
    }else if(this.pricedropmethod == "Discount"){
      $("#userinputdataRs").hide();
      $("#userinputdataPer").show();
    }else{
      $("#userinputdataRs").hide();
      $("#userinputdataPer").hide();
    }

    $("#searchkeywordinput").on( "keyup", function(event)
    {
      var yourInput = $(this).val();
      var re = /[`~!@#$%^&*()_|+=?;:'<>\{\}\[\]\\\/]/gi;
      var isSplChar = re.test(yourInput);
      if(isSplChar)
      {
        var no_spl_char = yourInput.replace(/[`~!@#$%^&*()_|+=?;:'<>\{\}\[\]\\\/]/gi, '');
        $(this).val(no_spl_char);
      }
    });

    $(".dateid").on( "click", function(event) {
      if(event.target.closest( "input" ).value == "noendtime"){
        $("#endaftertext").attr('disabled', true);
        $("#validFromdatetimepicker").attr('disabled', true);
      }else if(event.target.closest( "input" ).value == "endafter"){
        $("#endaftertext").attr('disabled', false);
        $("#validFromdatetimepicker").attr('disabled', true);
      }else{
        $("#endaftertext").attr('disabled', true);
        $("#validFromdatetimepicker").attr('disabled', false);
      }
    });

  }


  alertChanges(AlertData){
    $("#dataerror").html("");
    var userId = app.session.user.data.id;
    var WishListName = $("#topicname").val();
    var searchkey = $("#searchkeywordinput").val();
    var Endafterdate = $(".Endafterdate").val();
    var dealInput = $(".dealInput").val();
    var selectedOption = $("input:radio[name=date]:checked").val();
    //var decimalnumbers = /^[0-9]+(\.)?[0-9]+$/;
    var numbers = /^(\d{1,})+$/;
    var valid = true;
    var Endtime = "";
    this.pricedropinputRs = $(".userinputdataRs").val();
    this.pricedropinputPer = $(".userinputdataPer").val();
    var checked_date = document.querySelector('#enddatetime input[name="date"]:checked');
    var pricedropinputPer = this.pricedropinputPer;
    var pricedropinputRs = this.pricedropinputRs;
        
        if($('.item-alertsort select.Select-input.FormControl').val() == "select"){
          $("#dataerror").html("Please Enter the Price Drop of Selected Type");
           valid = false;
        }

        if(valid == true){    
            if(pricedropinputRs == "" && pricedropinputPer == ""){
              $("#dataerror").html("Please Enter the Price Drop of Selected Type");
              valid = false;
            }else if(pricedropinputRs != "" && pricedropinputPer == ""){
              if(pricedropinputRs.match(numbers)){
                if(pricedropinputRs > 0){
                  valid = true;  
                  $("#dataerror").html("");
                }else{
                  $("#dataerror").html("Please Enter the Price Greater than 0");
                  valid = false;
                }
              }else{
                $("#dataerror").html("Please input numeric characters without decimal(.)");
                valid = false;
              }
            }else if(pricedropinputRs == "" && pricedropinputPer != ""){
              if(pricedropinputPer.match(numbers)){
                if(pricedropinputPer < 100 && pricedropinputPer > 0){
                  valid = true;  
                  $("#dataerror").html("");
                }else{
                  $("#dataerror").html("discount less than 100% & discount greater than 0% ");
                  valid = false;
                }
              }else{
                $("#dataerror").html("Please input numeric characters without decimal(.)");
                valid = false;
              }
             }
           }

           if(valid == true){
            if(searchkey != ""){
              valid = true;
              $("#dataerror").html("");
            }else{
              $("#dataerror").html("Please Enter the Keywords for your search");
                valid = false;
            }
          }

          if(valid == true){
            if(selectedOption =="noendtime"){
              var Endtime = Date.parse(new Date("1/1/2099 00:00:00"));
            }else if(selectedOption =="endafter"){
              if(Endafterdate != "" || Endafterdate.match(numbers)){
                var days = $(".Endafterdate").val();
                var Endtime = (new Date().getTime()+(days*24*60*60*1000));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End After Days only in Numbers");
                valid = false;
              }
            }else if(selectedOption == "endby"){
              if(dealInput != ""){
                var Endtime = Date.parse(new Date(dealInput));
              }else{
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End By Date");
                valid = false;
              }
            }else if(checked_date === null){
                var Endtime = 0;
                $("#dataerror").html("Please Enter the End Time");
                valid = false;
            }

          if(checked_date === null){  //Test if something was checked
          $("#dataerror").html("Please Select the End Time");
            valid = false;
          }
        }
      

      if(valid){
        if(this.multiselect){
        var AlertData =[];
        var Data = [];
        var tdaCategoryPath = "";
        var pricedropmethod = this.pricedropmethod;
        var pricedropinputRs = this.pricedropinputRs;
        var pricedropinputPer = this.pricedropinputPer;
        var CategoryPath = m.route.param().categories;

          var PathArr=[];
          if(CategoryPath!=null){
              tdaCategoryPath = CategoryPath; 
          }else{
            tdaCategoryPath = "";
          }
          
          var UserInput ="";
          var PriceDrop="";
          if(pricedropmethod == "Discount"){
            PriceDrop = "DD";
            UserInput = pricedropinputPer;
          }
          if(pricedropmethod == "PriceRangeInRs"){
            PriceDrop = "PD";
            UserInput = pricedropinputRs;
          }
    
          Data.push({userId:userId,categorypath:tdaCategoryPath,Endtime:Endtime,WishListName:WishListName,PriceDrop:PriceDrop,UserInput:UserInput,SearchKey:searchkey,DateOption:selectedOption}); 
      }
      console.log("data",Data);exit();
      const Api_url = app.forum.attribute('baseUrl') +'/api/userSearch';
      return app.request({
        method: 'GET',
        url: Api_url,
        data: Data, 
        }).then(
          this.parseResults.bind(this),
      ); 

      }
  }

  parseResults(result){
    if(result.data.attributes.message =="Record Already Exits")
    {
      $("#dataerror").html("Alert for this search is alerady created");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else if(result.data.attributes.message =="inserted"){
      $("#dataerror").css("color","Blue").html("Thank You for your input.");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else if(result.data.attributes.message =="Not inserted"){
      $("#dataerror").html("Alert for this search is not added in your wishlist. please fill the correct data");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else if(result.data.attributes.message =="Record Updated successfully"){
      $("#dataerror").html("Record Updated successfully");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }else{
      $("#dataerror").html("");
      $('.alertcheckbox').removeAttr('checked');
      $(".alertbutton").hide();
    }
    
  }

  // config(isInitialized) {
  //     this.datepicker();

  // }

  // datepicker() {

  //   var today = new Date();
  //   var dd = today.getDate();
  //   var mm = today.getMonth() + 1; //January is 0!
  //   var yyyy = today.getFullYear();
  //   if (dd < 10) {
  //     dd = '0' + dd;
  //   }
  //   if (mm < 10) {
  //     mm = '0' + mm;
  //   }


  //   $('#validFromdatetimepicker').datetimepicker({
  //     minDate: 0,
  //      onShow:function( ct ){
  //         this.setOptions({
  //           Date:jQuery('#validFromdatetimepicker').val()?jQuery('#validFromdatetimepicker').val():false
  //         })
  //         },
  //   });
    

  // }


  onready() {
   
  }

 //  onsubmit(e) {
 //    console.log(e);
 //    var selected=[];
 //    $('.selectedclss input:checked').each(function() {
 //      selected.push($(this).attr('name'));
 //    });
 //    console.log(selected);
 //    //e.preventDefault();

 // //   this.loading = true;
 //  }

  onerror(error) {
    if (error.status === 401) {
      error.alert.props.children = app.translator.trans('core.forum.log_in.invalid_login_message');
    }

    super.onerror(error);
  }
}//end
