import Component from 'flarum/Component';
import Button from 'flarum/components/Button';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Placeholder from 'flarum/components/Placeholder';
import CouponDiscussionListItem from './../coupon/CouponDiscussionListItem';
import CouponExpiry from './../coupon/CouponExpiry';
import Switch from 'flarum/components/Switch';

export default class CouponDiscussionList extends Component {
  //Used in Coupons for display Coupon List
  init() {
    this.darkMode = m.prop('');
    this.isExpiredChk = false;
    this.loading = true;
    this.storename='';
    this.moreResults = false;
    this.discussions = [];
    this.refresh();
  }

  view() {
    const params = this.props.params;
    let loading;

    if (this.loading) {
      loading = LoadingIndicator.component();
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans('core.forum.discussion_list.load_more_button'),
        className: 'Button',
        onclick: this.loadMore.bind(this)
      });
    }

    if (this.discussions.length === 0 && !this.loading) {
      const text = "It looks like there are currently no Coupons available for this store. "
      return (
        <div className="DiscussionList">
          {Placeholder.component({ text })}
        </div>
      );
    }

    return (
      <div className="DiscussionList">
            <div className="cc_hideSwitch">
                {Switch.component({
                  state: this.darkMode(),
                  children: "Hide Expired Coupons",
                  onchange: this.darkMode
                })}
        </div>
        <div id="couponstores" class="cc_couponStoreHeader">
          <h1 style="margin: 0px;">{this.storename} Coupons</h1>
        </div>
        <ul className="DiscussionList-discussions cc_couponList">
          {this.discussions.map(discussion => {
            var cstatus = this.darkMode();
            return (
              cstatus == true ?
                (Date.parse(discussion.data.attributes.validTo) || 0) != "0" ?
                  CouponExpiry(discussion.data.attributes.validTo) != "Expired" ?
                    <li key={discussion.id()} data-id={discussion.id()}>
                      {CouponDiscussionListItem.component({ discussion, params, cstatus })}
                    </li>
                    : ""
                  : <li key={discussion.id()} data-id={discussion.id()}>
                    {CouponDiscussionListItem.component({ discussion, params, cstatus })}
                  </li>
                : cstatus == false ?
                  <li key={discussion.id()} data-id={discussion.id()}>
                    {CouponDiscussionListItem.component({ discussion, params, cstatus })}
                  </li>
                  : ""
            );
          })}
        </ul>
        <div className="DiscussionList-loadMore">
          {loading}
        </div>
      </div>
    );
  }

  /**
   * Get the parameters that should be passed in the API request to get
   * discussion results.
   *
   * @return {Object}
   * @api
   */
  requestParams() {
    const params = { include: ['startUser', 'lastUser'], filter: {} };
    params.sort = this.sortMap()[this.props.params.sort];
    var hostnameTag;
    hostnameTag = app.store.getBy('tags', 'slug', this.props.params.tags);
    hostnameTag.data.attributes.slug!="stores" ? this.storename=hostnameTag.data.attributes.name : this.storename='';
    this.props.params.q = 'is:cupn';
    if (this.props.params.q) {
      params.filter.q = this.props.params.q;
      params.include.push('relevantPosts', 'relevantPosts.discussion', 'relevantPosts.user');
    }
    return params;
  }

  /**
   * Get a map of sort keys (which appear in the URL, and are used for
   * translation) to the API sort value that they represent.
   *
   * @return {Object}
   */
  sortMap() {
    const map = {};

    if (this.props.params.q) {
      map.relevance = '';
    }
    map.latest = '-lastTime';
    map.top = '-commentsCount';
    map.newest = '-startTime';
    map.oldest = 'startTime';

    return map;
  }

  /**
   * Clear and reload the discussion list.
   *
   * @public
   */
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.discussions = [];
    }

    return this.loadResults().then(
      results => {
        this.discussions = [];
        this.parseResults(results);
      },
      () => {
        this.loading = false;
        m.redraw();
      }
    );
  }

  /**
   * Load a new page of discussion results.
   *
   * @param {Integer} offset The index to start the page at.
   * @return {Promise}
   */
  loadResults(offset) {
    const preloadedDiscussions = app.preloadedDocument();

    if (preloadedDiscussions) {
      return m.deferred().resolve(preloadedDiscussions).promise;
    }

    const params = this.requestParams();
    params.page = { offset };
    params.include = params.include.join(',');

    return app.store.find('discussions', params);
  }

  /**
   * Load the next page of discussion results.
   *
   * @public
   */
  loadMore() {
    this.loading = true;

    this.loadResults(this.discussions.length)
      .then(this.parseResults.bind(this));
  }

  /**
   * Parse results and append them to the discussion list.
   *
   * @param {Discussion[]} results
   * @return {Discussion[]}
   */
  parseResults(results) {
    [].push.apply(this.discussions, results);

    this.loading = false;
    this.moreResults = !!results.payload.links.next;

    m.lazyRedraw();

    return results;
  }
}