import Component from 'flarum/Component';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import Placeholder from 'flarum/components/Placeholder';
import Button from 'flarum/components/Button';
import DailyDealCard from './../dailyDeals/DailyDealCard';
import listItems from 'flarum/helpers/listItems';
import ItemList from 'flarum/utils/ItemList';
import Select from 'flarum/components/Select';
import SlickmainSlider from './../SlickmainSlider';
export default class DailyDealResults extends Component
{ //Used for Dealmachine to show sliders live,upcoming,expired deals
	init()
	{
		this.loading=true;
		this.deallist = [];
		this.refresh();
		this.moreResults = false;   
	}
	view()
	{
		const params = this.props.params;
	    let loading;
	    if (this.loading) {
	      loading = LoadingIndicator.component();
	    } else if (this.moreResults) {
	      loading = Button.component({
	        children: app.translator.trans('core.forum.discussion_list.load_more_button'),
	        className: 'Button',
	        onclick: this.loadMore.bind(this)
	      });
	    }

	    if (this.deallist.length === 0 && !this.loading) {
	      const text = "It looks like there are currently no Deals available for this search. "
	      return (
	        <div className="DiscussionList">
	          {Placeholder.component({ text })}
	        </div>
	      );
	    }

	    if (this.deallist.length !== 0) {
		    const deals = this.deallist;
		    return (
		    	<div style="width: 93%;margin:15px auto;" config={this.configSlider.bind(this)}>
			    	<div id="dealstores" class="cc_productListHeader">
                      <span>Sort For {this.props.params.heading} :</span>
                      <div class="cc_productSort">
                        {listItems(this.viewItems(this.props.params.heading).toArray())}
                      </div>
                      <div id="viewall">
					 	<a class="Button Button--label" href={app.route("dealtype",this.getParams("sort",params))} config={m.route}> View All</a>
					  </div>
                    </div>
		    		<h3 class="popularBar">{this.props.params.heading} </h3>
		    		{SlickmainSlider.component({headingName:this.props.params.heading,slidesToShowDesktop:this.slidesToShowDesktop, slidesToScrollDesktop:this.slidesToScrollDesktop, children: this.loading?loading:this.dealData})}
		    	</div>
		    	)   
		    	
		 }
		 return(
		 	<div>
		 	{loading}
		 	</div>
		 	);
	}	
	requestParams()
	{
		return this.props.params;
	}
	loadResults()
	{
		 const params = this.requestParams();
		 const Api_url = app.forum.attribute('baseUrl') +'/api/dailydeals'; 
       
         return app.request({
                          method: 'POST',
                          url: Api_url,
                          data: params, 
                    }).then(
                            this.parseResults.bind(this),
                            );
	}
	
	refresh(clear = true) {
	    if (clear) {
	      this.loading = true;
	      this.deallist = [];
	    }

	    return this.loadResults().then(
	      results => {
	      },
	      () => {
	        this.loading = false;
	        m.redraw();
	      }
	    );
  	}
  	parseResults(results){
	    this.parseDiscussionResults(results);               
  		this.loading = false;
	    m.lazyRedraw();
		return results;
  	}
  	parseDiscussionResults(results)
  	{
  		this.deallist=[];
  		this.dealData=[];
  		[].push.apply(this.deallist, results.data.attributes);
	    this.loading = false; 
	    this.dealData  = this.deallist.map(discussion => {
                      var DiscussionData = {
                          title : discussion.title,
                          Discount_rangefrom : discussion.Discount_rangefrom,
                          Discount_rangeto : discussion.Discount_rangeto,
                          MRP : discussion.MRP,
                          dealId : discussion.dealId,
                          dealPrice : discussion.dealPrice,
                          dealType : discussion.dealType,
                          disc_rank_HtoL : discussion.disc_rank_HtoL,
                          disc_rank_LtoH : discussion.disc_rank_LtoH,
                          imageurl : discussion.imageurl,
                          isCalculated : discussion.isCalculated,
                          isExpired : discussion.isExpired,
                          isLive : discussion.isLive,
                          merchantname : discussion.merchantname, 
                          pid : discussion.pid, 
                          price_rangefrom : discussion.price_rangefrom,                           
                          price_rangeto : discussion.price_rangeto,
                          price_rank_HtoL : discussion.price_rank_HtoL,                          
                          price_rank_LtoH : discussion.price_rank_LtoH,
                          sellingPrice : discussion.sellingPrice,
                          // url : discussion.url,
                          url : "https://www.amazon.in/gp/goldbox/all-deals?gb_edi="+discussion.dealId,
                          id : discussion._id.$oid,
                          dealStartTime : discussion.dealStartTime.$date.$numberLong,
						  dealEndTime : discussion.dealEndTime.$date.$numberLong,
						  heading : this.props.params.heading,
						  price_rangefrom_original : discussion.price_rangefrom_original,
						  price_rangeto_original : discussion.price_rangeto_original,
                      	}
                        return DailyDealCard.component({DiscussionData});
                      
                    });
	    m.lazyRedraw();
	    return results;
  	}
	config(isInitialized)
	{
		if(isInitialized) return;
	}
	configSlider(element, isInitialized, context) {
    	if (isInitialized && !(this.deallist)) return;
    	const sortOptions ={select:"Select", totaldiscount: "Total Discount", effectivediscount: "Effective Discount", price: "Price"};
    }
	
	viewItems(datahead)
	{
	    const items = new ItemList();
	    const sortMap ={select:"Select", totaldiscount: "Total Discount", effectivediscount: "Effective Discount", price: "Price"};
	    const sortOptions ={select:"Select", totaldiscount: "Total Discount", effectivediscount: "Effective Discount", price: "Price"};
	    items.add('sort',
	      Select.component({
	        options: sortOptions,
	        value: this.props.params.sort || Object.keys(sortMap)[0],
	        onchange: this.changeSort.bind(this)
	      })
	    );

	    return items;
	  }
	changeSort(sort) {	
		if(sort !== '') {
		var sort = sort;
		const params = this.requestParams();
		params.sort = sort;
                const Api_url = app.forum.attribute('baseUrl') +'/api/dailydeals';
      	return app.request({
                          method: 'POST',
                          url: Api_url,
                          data: params, 
                    }).then(
                            this.parseResults.bind(this),
                            );
		}
	}

	getParams(paramName,value)
  { 
    let paramsURL = Object.assign({},  value);
    paramsURL.src="dealmachine";
    if(value.stage == "live"){
   	  paramsURL.dealtype = "l";
    }else if(value.stage == "upcoming"){
		paramsURL.dealtype = "u";
    }else if(value.stage == "expired"){
		paramsURL.dealtype = "e";
    }
    delete paramsURL.src;
    delete paramsURL.heading;
    delete paramsURL.merchantname;
    return paramsURL;     
  }
}