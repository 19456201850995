import Component from "flarum/Component";

export default class vote_bar extends Component {
  init() {
    super.init();
    this.discussion = this.props.discussion;
    this.votes_discussion = this.props.discussion.data.attributes.votes_discussion;
    this.plusMinus = 0;
    this.display_stars = 0;
    if (
      this.discussion.data.relationships.upvotesD &&
      this.discussion.data.relationships.downvotesD
    ) {
      this.displayed_votes =
        this.discussion.data.relationships.upvotesD.data.length -
        this.discussion.data.relationships.downvotesD.data.length;
    }
    if (this.displayed_votes > 0) {
      this.display_stars = this.displayed_votes > 5 ? 5 : this.displayed_votes;
    } else if (this.displayed_votes < 0) {
      this.display_stars =
        this.displayed_votes < -5 ? 5 : -this.displayed_votes;
    }
    // if(this.votes_discussion>0) {
    //      this.display_stars = this.votes_discussion>100?5:this.votes_discussion;
    // }else if(this.votes_discussion<0) {
    //      this.display_stars = this.votes_discussion<-100?5: -this.votes_discussion;
    // }
    if (this.displayed_votes > 0) {
      this.plusMinus = 1;
    } else if (this.displayed_votes < 0) {
      this.plusMinus = -1;
    } else {
      this.plusMinus = 0;
    }
    // if(this.votes_discussion>0) {
    //      this.plusMinus = 1;
    // }else if(this.votes_discussion<0) {
    //      this.plusMinus = -1;
    // }else{
    //        this.plusMinus = 0;
    // }
    this.count = [];
    for (var i = 0; i < this.display_stars; i++) {
      this.count[i] = 1;
    }
  }
  view() {
    var voteBarCalss = "";
    if (this.plusMinus === 0) {
      var voteBarCalss = "voteBarZero";
    } else {
      var voteBarCalss = "voteBar";
    }
    return (
      <span Class={voteBarCalss}>
        {this.plusMinus === 1
          ? this.count.map(this.getIconPlus, {})
          : this.plusMinus === -1
          ? this.count.map(this.getIconMinus, {})
          : ""}
      </span>
    );
  }
  getIconPlus() {
    return (
      <div Class="voteBarIcon voteUpIcon">
        <i class="fas fa-thumbs-up" aria-hidden="true"></i>
      </div>
    );
  }
  getIconMinus() {
    return (
      <span Class="voteBarIcon voteDownIcon">
        <i class="fas fa-thumbs-down" aria-hidden="true"></i>
      </span>
    );
  }
}
