import Page from "flarum/components/Page";
import LogInModal from "flarum/components/LogInModal";
import LoadingIndicator from "flarum/components/LoadingIndicator";
import TextEditor from "flarum/components/TextEditor";
import listItems from "flarum/helpers/listItems";
import ItemList from "flarum/utils/ItemList";

/**
 * The `myComposerBody` component handles the body, or the content, of the
 * composer. Subclasses should implement the `onsubmit` method and override `headerTimes`.
 * ### Props
 * - `originalContent`
 * - `submitLabel`
 * - `placeholder`
 * - `user`
 * - `confirmExit`
 * - `disabled`
 * @abstract
 */
//used in myDiscussionComposer,myDiscussionCoupon
export default class myComposerBody extends Page {
  init() {
    super.init();
    /**
     * Whether or not the component is loading.
     *
     * @type {Boolean}
     */
    this.loading = false;

    /**
     * The content of the text editor.
     *
     * @type {Function}
     */
    this.content = m.prop(this.props.originalContent);

    /**
     * The text editor component instance.
     *
     * @type {TextEditor}
     */
    this.editor = new TextEditor({
      submitLabel: "Post Product",
      placeholder:
        "Enter additional description describing the deal here. You may include additional coupons, terms and conditions, wiki, etc.",
      onchange: this.content,
      onsubmit: this.onsubmit.bind(this),
      value: this.content(),
    });
    if (!app.session.user) {
      app.modal.show(new LogInModal());
    }
  }
  view() {
    // If the component is loading, we should disable the text editor.
    this.editor.props.disabled = this.loading;
    if (!app.composer.isFullScreen()) app.composer.fullScreen();
    this.goToRootPath = function () {
      m.route(app.forum.attribute("baseUrl"));
    };
    this.toggleInfoText = function () {
      $("#_infoText").slideToggle("fast");
    };
    var BaseUrl = app.forum.attribute("baseUrl");
    return (
      <div className={"ComposerBody " + (this.props.className || "")}>
        <div
          className="Hero WelcomeHero"
          style={
            m.route().replace("/", "") === "newCoupon"
              ? "background: #a52a2a !important;color:#FFF;"
              : "background: #9a001a !important;color:#FFF;"
          }
        >
        </div>
        <div class="cc_infoToggle" style={BaseUrl == "https://thedealapp.in" ? "background: #2d3e50;" : "background: #3071a9;"}>
          <div class="cc_infoBtnBlock">
            <button
              class="Button"
              id="infoBtn"
              onclick={this.toggleInfoText.bind()}
            >
              <i class="fa fa-question-circle fa-1x"></i>
            </button>
            <button
              class="Button"
              id="closeBtn"
              onclick={this.goToRootPath.bind()}
            >
              <i class="fa fa-times fa-1x"></i>
            </button>
          </div>
          <div className="containerNarrow">
              <h2 className="Hero-title">
                {m.route().replace("/", "") === "newCoupon"
                  ? "Post New Coupon"
                  : "Post New Deal"}
              </h2>
            </div>
          <div
            class="cc_moreInfoTextCoupon"
            id="_infoText"
            style="display:none;"
          >
            {m.route().replace("/", "") === "newCoupon"
              ? m.trust(
                  "Below are quick tips for posting Coupons:<br/>1. Start by pasting Coupon/Site url. Prefer to use the url that is most related to this coupon.<br/>2. Make sure coupon code is correct.Use the title that best describes the coupon.<br/>3. Choose one or multiple categories of products that may apply to the coupon.<br/>4. Choose a Badge text upto 20 characters for the coupon that best represents benefit of coupon. Such as 40% OFF , 300 CASHBACK etc.<br/>5. Enter any additional coupon details, such as terms and conditions, validity and tips.<br/>"
                )
              : m.trust(
                  "Below are quick tips for posting deals:<br/>1. Start by pasting deal url. We will try our best to reduce your effort in filling rest of the details.<br/>2. Make sure Price details are correct. Use Title that best describes the deal<br/>3. Choose one ore multiple categories of products that may apply to the deal<br/>4. The Deal post will be previewed in Post preview. Edit as necessary to make it more informative. Include steps to claim deal if possible."
                )}
          </div>
        </div>
        <div className="cc_ComposerBody-content">
          <ul className="ComposerBody-header" style="margin:0;">
            {listItems(this.headerItems().toArray())}
          </ul>
        </div>
        <div className="cc_ComposerBody-preview">
          <ul style="margin-top: 0px;">
            {listItems(this.previewItems().toArray())}
          </ul>
        </div>
        <div style="clear:both;"></div>
        {LoadingIndicator.component({
          className: "ComposerBody-loading" + (this.loading ? " active" : ""),
        })}
      </div>
    );
  }

  /**
   * Draw focus to the text editor.
   */
  focus() {
    this.$(":input:enabled:visible:first").focus();
  }

  /**
   * Check if there is any unsaved data – if there is, return a confirmation
   * message to prompt the user with.
   *
   * @return {String}
   */
  preventExit() {
    const content = this.content();

    return (
      content &&
      content !== this.props.originalContent &&
      this.props.confirmExit
    );
  }

  /**
   * Build an item list for the composer's header.
   *
   * @return {ItemList}
   */
  headerItems() {
    return new ItemList();
  }
  previewItems() {
    return new ItemList();
  }

  /**
   * Handle the submit event of the text editor.
   *
   * @abstract
   */
  onsubmit() {}

  /**
   * Stop loading.
   */
  loaded() {
    this.loading = false;
    m.redraw();
  }
}
